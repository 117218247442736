import React from 'react';
import PropTypes from 'prop-types';
import StandardConditionsOfSale from './StandardConditionsOfSale';
import FooterCapContainer from '../ui/FooterCapContainer';
import Button from '../ui/Button';
import { IonModal } from '@ionic/react';

const StandardConditionsOfSaleModal = (props) => {
  return (
    <IonModal isOpen={props.showModal} cssClass="modal">
      <div className="overflow-scroll p-3">
        <h1 className="text-h2 font-bold mb-2">Standard conditions of sale</h1>
        <StandardConditionsOfSale />
      </div>
      <FooterCapContainer className="px-3 py-1">
        <Button onClick={() => props.setShowModal(false)}>Close</Button>
      </FooterCapContainer>
    </IonModal>
  );
};

StandardConditionsOfSaleModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

StandardConditionsOfSaleModal.defaultProps = {
  showModal: false,
  setShowModal: null,
};

export default StandardConditionsOfSaleModal;
