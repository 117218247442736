import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import Loader from '../../components/ui/Loader';
import { selectSupportCategory } from '../../store/actions';
import { ReactComponent as OrderIcon } from '../../assets/icons/64/order.svg';
import { ReactComponent as AppIcon } from '../../assets/icons/64/app.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/64/message.svg';
import useSupportFallback from '../../hooks/useSupportFallback';

const SupportIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const supportData = useSupportFallback();
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const [loaderIcon, setLoaderIcon] = useState('');
  const [loaderTitle, setLoaderTitle] = useState('');

  function displayIcon(name) {
    switch (name) {
      case 'My orders':
        return <OrderIcon className="text-dulux-primary" />;
      case 'My app':
        return <AppIcon className="text-dulux-primary" />;
      case 'General':
        return <MessageIcon className="text-dulux-primary" />;
      default:
        return '';
    }
  }

  function onClick(value, name) {
    dispatch(selectSupportCategory(value));
    setLoaderTitle(name);
    setLoaderIcon(displayIcon(name));
    setLoaderEnabled(true);
    setTimeout(function () {
      setLoaderEnabled(false);
      history.push('/support/select');
    }, 1000);
  }

  const supportCategories = supportData.map((item) => {
    return (
      <div
        className="border-b h-full flex flex-col justify-end p-1"
        key={item.key}
        onClick={() => onClick(item.key, item.name)}
      >
        <div className="mb-1">{displayIcon(item.name)}</div>
        <h2 className="font-heading text-h2 text-dulux-dark">{item.name}</h2>
      </div>
    );
  });

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderBack title="Back" />
          <HeaderTitle title="Get help with..." borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>
          <div className="h-full flex flex-col justify-between">
            {supportCategories}
          </div>
        </IonContent>
        <Loader
          isEnabled={loaderEnabled}
          title={loaderTitle}
          background="#edf2f7"
          icon={loaderIcon}
        />
      </IonPage>
    </>
  );
};

export default SupportIndex;
