import { createSelector } from 'reselect';
import { selectStoresById } from './root';
import { getEnabledFulfillmentList } from '../../utils/fulfillment';
import { DeliveryMethodType, Store } from '../../types';
import { selectPickupOrdersDesc } from './orders';
import { selectUserPreferredStoreId } from './user';
import { find, uniq } from 'lodash';

export const selectStores = createSelector([selectStoresById], (stores) =>
  Object.values(stores)
);

export const selectStoresPriorityPickupEnabled = createSelector(
  [selectStores],
  (stores) =>
    stores.filter((store) => {
      const allowedList = getEnabledFulfillmentList(store.fulfillmentOptions);
      return allowedList.includes(DeliveryMethodType.PRIORITY_PICKUP);
    })
);

export const selectStoresScheduledPickupEnabled = createSelector(
  [selectStores],
  (stores) =>
    stores.filter((store) => {
      const allowedList = getEnabledFulfillmentList(store.fulfillmentOptions);
      return allowedList.includes(DeliveryMethodType.SCHEDULED_PICKUP);
    })
);

export const selectRecentStoresPickup = createSelector(
  [selectStores, selectPickupOrdersDesc],
  (stores, orders) => {
    if (orders.length) {
      const matchingStoresFound: Store[] = [];

      orders.forEach((order: any) => {
        const found = find(stores, (store) => {
          return (
            store.shortTitle === order.shipping_address.address1 &&
            store.area === order.shipping_address.address2
          );
        });

        if (found) {
          matchingStoresFound.push(found);
        }
      });

      return matchingStoresFound ? uniq(matchingStoresFound) : [];
    }

    return [];
  }
);

export const selectStoresOmitPreferredStore = createSelector(
  [selectStores, selectUserPreferredStoreId],
  (stores, userPreferredStoreId) => {
    return stores.filter((store) => store.id !== userPreferredStoreId);
  }
);

export const selectPriorityPickupOptions = createSelector(
  [selectRecentStoresPickup, selectUserPreferredStoreId],
  (recentPickupStores, preferredStoreId) => {
    const pickupStores = recentPickupStores.filter(
      (store) => store.id !== preferredStoreId
    );

    return pickupStores ? pickupStores : [];
  }
);

export const selectPreferredStore = createSelector(
  [selectStoresById, selectUserPreferredStoreId],
  (storesById, preferredStoreId) => {
    return storesById[preferredStoreId];
  }
);
