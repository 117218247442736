import React from 'react';
import { batch, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PaintBucket from '../../assets/images/loader/paint-bucket.gif';
import { useHistory } from 'react-router';
import CartTotals from './CartTotals';
import useSupportFallback from '../../hooks/useSupportFallback';
import {
  selectSupportCategory,
  selectSupportCurrentQuery,
} from '../../store/actions';
import { find } from 'lodash';
import { useAnalyticsContext } from '../../services/analytics/context';

const CartTotalsDisplay = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const supportData = useSupportFallback();
  const analytics = useAnalyticsContext();
  const faqCategoryKey = 'my-orders';
  const faqQueryKey = 'View my pricing';
  const supportCat = find(
    supportData,
    (category) => category.key === faqCategoryKey
  );
  const supportItem = find(
    supportCat.items,
    (item) => item.query === faqQueryKey
  );

  function handleFaqs() {
    analytics.trackEvent('Pricing FAQs click', {
      properties: {
        category: 'Cart',
        label: 'Order total unavailable',
      },
    });

    batch(() => {
      dispatch(selectSupportCategory(faqCategoryKey));
      dispatch(selectSupportCurrentQuery(supportItem));
    });

    history.push('/support/select');
    history.push('/support/select/detail');
  }

  function render() {
    if (props.pricesCalculated) {
      if (props.pricingIsLoading) {
        return (
          <div className="w-full">
            <img
              src={PaintBucket}
              width="180"
              height="auto"
              className="my-0 mx-auto pt-5"
              alt="Paint bucket getting filled up"
            />
            <div className="flex items-center justify-center p-3 font-heading text-h3 text-dulux-primary border-t border-gray-400">
              Calculating...
            </div>
          </div>
        );
      }

      if (props.subtotal && props.tax && props.total) {
        return (
          <CartTotals
            subtotal={props.subtotal}
            tax={props.tax}
            total={props.total}
          />
        );
      }

      return (
        <div className="w-full text-center pt-3 pb-4 px-2">
          <h2 className="py-2 font-heading text-h3 text-dulux-red font-bold">
            Order total unavailable
          </h2>
          <p className="pb-1">
            One or more items could not be calculated. Your account pricing will
            be applied and included in your invoice.
          </p>
          <span className="text-dulux-primary block" onClick={handleFaqs}>
            For more info view pricing FAQs
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className="pt-3 pb-2 px-4">
          <h2 className="font-heading text-h3 text-dulux-dark text-center p-2">
            Account pricing
          </h2>
          <p className="text-center pb-1">
            Your regular account pricing applies to paint products. Tap the
            button below to calculate the cost of your order.
          </p>
        </div>
        <div
          className="flex items-center justify-center p-3 font-heading text-h3 text-dulux-primary border-t border-gray-400"
          onClick={props.handleShowPricing}
        >
          Calculate order total
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center border rounded-lg border-gray-400 m-1">
      {render()}
    </div>
  );
};

CartTotalsDisplay.propTypes = {
  handleShowPricing: PropTypes.func,
  pricesCalculated: PropTypes.bool,
  pricingIsLoading: PropTypes.bool,
  subtotal: PropTypes.string,
  tax: PropTypes.string,
  total: PropTypes.string,
};

CartTotalsDisplay.defaultProps = {
  handleShowPricing: null,
  pricesCalculated: false,
  pricingIsLoading: true,
  subtotal: '',
  tax: '',
  total: '',
};

export default CartTotalsDisplay;
