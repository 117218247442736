import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import {
  getAttribute,
  isDelivery,
  isPickup,
  isCancelled,
  isFulfilled,
  isNotFulfilled,
  DELIVERY_TIME_KEY,
} from '../../utils/order';

export const selectOrdersByIds = (state: RootState) => state.orders.byId;

export const selectOrders = (state: RootState) =>
  Object.values(state.orders.byId);

export const selectIsLoadingOrders = (state: RootState) => {
  const user = state.user.user || { orders: {} };

  return (
    Object.keys(state.orders.byId).length !==
    Object.keys(user.orders || {}).length
  );
};

export const selectOrdersDesc = createSelector([selectOrders], (orders) => {
  return orders.sort((order1: any, order2: any) =>
    order2.created_at > order1.created_at ? 1 : -1
  );
});

export const selectDeliveryOrdersDesc = createSelector(
  [selectOrdersDesc],
  (orders) => {
    return orders.filter((order) => isDelivery(order));
  }
);

export const selectPickupOrdersDesc = createSelector(
  [selectOrdersDesc],
  (orders) => {
    return orders.filter((order) => isPickup(order));
  }
);

export const selectOrdersByDeliveryTimeAsc = createSelector(
  [selectOrders],
  (orders) => {
    const mapped = orders.map((order: any) => {
      return {
        ...order,
        deliveryTime: getAttribute(order.note_attributes, DELIVERY_TIME_KEY),
      };
    });

    return mapped.sort((order1, order2) => {
      if (order2.deliveryTime === order1.deliveryTime) {
        return order2.order_number > order1.order_number ? 1 : -1;
      }

      return order2.deliveryTime > order1.deliveryTime ? -1 : 1;
    });
  }
);

export const selectActiveOrders = createSelector([selectOrdersDesc], (orders) =>
  orders.filter((order) => isNotFulfilled(order) && !isCancelled(order))
);

export const selectActiveOrdersByDeliveryTimeAsc = createSelector(
  [selectOrdersByDeliveryTimeAsc],
  (orders) =>
    orders.filter((order) => isNotFulfilled(order) && !isCancelled(order))
);

export const selectFulfilledOrders = createSelector(
  [selectOrdersDesc],
  (orders) => orders.filter((order) => isFulfilled(order) || isCancelled(order))
);

export const selectCurrentOrderId = (state: RootState) =>
  state.orders.currentOrder;

export const selectCurrentOrder = createSelector(
  [selectOrdersByIds, selectCurrentOrderId],
  (ordersById, currentOrderId) => {
    return currentOrderId ? ordersById[currentOrderId] : null;
  }
);
