import {
  CheckoutActionType,
  CheckoutActionTypes,
  CheckoutState,
} from './types';

import { UserActionType, UserActionTypes } from '../user/types';

const initialState: CheckoutState = {
  assistedDelivery: false,
  constructionSite: false,
  callOnArrival: false,
  showAllStores: false,
};

const checkout = (
  state: CheckoutState = initialState,
  action: CheckoutActionTypes | UserActionTypes
): CheckoutState => {
  switch (action.type) {
    case CheckoutActionType.APPLY_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload.deliveryMethod,
      };

    case CheckoutActionType.APPLY_DELIVERY_TIMEFRAME:
      return {
        ...state,
        deliveryTimeframe: action.payload.deliveryTimeframe,
      };

    case CheckoutActionType.APPLY_LOCATION_ADDRESS:
      return {
        ...state,
        locationAddress: action.payload.locationAddress,
      };

    case CheckoutActionType.APPLY_ASSISTED_DELIVERY:
      return {
        ...state,
        assistedDelivery: action.payload.assistedDelivery,
      };

    case CheckoutActionType.APPLY_CONSTRUCTION_SITE:
      return {
        ...state,
        constructionSite: action.payload.constructionSite,
      };

    case CheckoutActionType.APPLY_CALL_ON_ARRIVAL:
      return {
        ...state,
        callOnArrival: action.payload.callOnArrival,
      };

    case CheckoutActionType.APPLY_DELIVERY_NOTES:
      return {
        ...state,
        deliveryNotes: action.payload.deliveryNotes,
      };

    case CheckoutActionType.APPLY_ORDER_REFERENCE:
      return {
        ...state,
        orderReference: action.payload.orderReference,
      };

    case CheckoutActionType.APPLY_SKIP_LOCATION:
      return {
        ...state,
        skipLocation: action.payload.skipLocation,
      };

    case CheckoutActionType.APPLY_SHOW_ALL_STORES:
      return {
        ...state,
        showAllStores: action.payload.showAllStores,
      };

    case CheckoutActionType.CLEAR_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: undefined,
      };

    case CheckoutActionType.CLEAR_DELIVERY_TIMEFRAME:
      return {
        ...state,
        deliveryTimeframe: undefined,
      };

    case CheckoutActionType.CLEAR_LOCATION_ADDRESS:
      return {
        ...state,
        locationAddress: undefined,
      };

    case CheckoutActionType.CLEAR_ASSISTED_DELIVERY:
      return {
        ...state,
        assistedDelivery: false,
      };

    case CheckoutActionType.CLEAR_CONSTRUCTION_SITE:
      return {
        ...state,
        constructionSite: false,
      };

    case CheckoutActionType.CLEAR_CALL_ON_ARRIVAL:
      return {
        ...state,
        callOnArrival: false,
      };

    case CheckoutActionType.CLEAR_DELIVERY_NOTES:
      return {
        ...state,
        deliveryNotes: undefined,
      };

    case CheckoutActionType.CLEAR_ORDER_REFERENCE:
      return {
        ...state,
        orderReference: undefined,
      };

    case CheckoutActionType.CLEAR_SKIP_LOCATION:
      return {
        ...state,
        skipLocation: undefined,
      };

    case CheckoutActionType.CLEAR_SHOW_ALL_STORES:
      return {
        ...state,
        showAllStores: false,
      };

    case CheckoutActionType.CLEAR_CHECKOUT:
      return initialState;

    case UserActionType.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default checkout;
