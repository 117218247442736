import {
  CheckoutActionType,
  ApplyDeliveryMethodAction,
  ApplyDeliveryTimeframeAction,
  CheckoutState,
  ApplyDeliveryNotesAction,
  ApplyLocationAddressAction,
  ClearCheckoutAction,
  ApplySkipLocationAction,
  ClearSkipLocationAction,
  ApplyOrderReferenceAction,
  ApplyShowAllStoresAction,
  ClearShowAllStoresAction,
} from '../reducers/checkout/types';

export const applyDeliveryMethod = (
  deliveryMethod: CheckoutState['deliveryMethod']
): ApplyDeliveryMethodAction => {
  return {
    type: CheckoutActionType.APPLY_DELIVERY_METHOD,
    payload: {
      deliveryMethod,
    },
  };
};

export const applyDeliveryTimeframe = (
  deliveryTimeframe: CheckoutState['deliveryTimeframe']
): ApplyDeliveryTimeframeAction => {
  return {
    type: CheckoutActionType.APPLY_DELIVERY_TIMEFRAME,
    payload: {
      deliveryTimeframe,
    },
  };
};

export const applyDeliveryAddress = (
  locationAddress: CheckoutState['locationAddress']
): ApplyLocationAddressAction => {
  return {
    type: CheckoutActionType.APPLY_LOCATION_ADDRESS,
    payload: {
      locationAddress,
    },
  };
};

export const applyDeliveryNotes = (
  deliveryNotes: CheckoutState['deliveryNotes']
): ApplyDeliveryNotesAction => {
  return {
    type: CheckoutActionType.APPLY_DELIVERY_NOTES,
    payload: {
      deliveryNotes,
    },
  };
};

export const applyOrderReference = (
  orderReference: CheckoutState['orderReference']
): ApplyOrderReferenceAction => {
  return {
    type: CheckoutActionType.APPLY_ORDER_REFERENCE,
    payload: {
      orderReference,
    },
  };
};

export const applySkipLocation = (
  skipLocation: CheckoutState['skipLocation']
): ApplySkipLocationAction => {
  return {
    type: CheckoutActionType.APPLY_SKIP_LOCATION,
    payload: {
      skipLocation,
    },
  };
};

export const applyShowAllStores = (
  showAllStores: CheckoutState['showAllStores']
): ApplyShowAllStoresAction => {
  return {
    type: CheckoutActionType.APPLY_SHOW_ALL_STORES,
    payload: {
      showAllStores,
    },
  };
};

export const clearSkipLocation = (): ClearSkipLocationAction => {
  return {
    type: CheckoutActionType.CLEAR_SKIP_LOCATION,
  };
};

export const clearShowAllStores = (): ClearShowAllStoresAction => {
  return {
    type: CheckoutActionType.CLEAR_SHOW_ALL_STORES,
  };
};

export const clearCheckout = (): ClearCheckoutAction => {
  return {
    type: CheckoutActionType.CLEAR_CHECKOUT,
  };
};
