import { ColourActionType, ColourActionTypes, ColourState } from './types';
import { ColourId } from '../../../types';

const initialState: ColourState = {
  byId: {},
  byBase: {},
};

const colours = (
  state: ColourState = initialState,
  action: ColourActionTypes
): ColourState => {
  switch (action.type) {
    case ColourActionType.RECEIVE:
      return {
        ...state,
        byId: action.payload.byId,
        byBase: action.payload.byBase,
      };

    default:
      return state;
  }
};

export const getColour = (state: ColourState, colourId: ColourId) => {
  return state.byId[colourId];
};

export const getColourIdsForBase = (state: ColourState, base: string) => {
  return state.byBase[base.toLowerCase()] || [];
};

export default colours;
