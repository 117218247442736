import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PriorityDeliveryIcon } from '../../assets/icons/64/priority-delivery.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/64/delivery.svg';

const OrderMethodItem = (props) => {
  let available = props.enabled;

  if (
    props.maxLitres &&
    props.totalLitres &&
    props.totalLitres > props.maxLitres
  ) {
    available = false;
  }

  const time = available ? props.time : 'Unavailable';

  function timeMessage() {
    if (!props.enabled) {
      return 'COVID restriction';
    }

    if (available) {
      return 'Latest arrival';
    }

    return `Order is over ${props.maxLitres}L`;
  }

  function displayIcon() {
    switch (props.title) {
      case 'Priority':
        return <PriorityDeliveryIcon />;
      case 'Standard':
        return <DeliveryIcon />;
      default:
        return <DeliveryIcon />;
    }
  }

  function displayIconBg() {
    switch (props.title) {
      case 'Priority':
        return 'bg-dulux-primary';
      case 'Standard':
        return 'bg-dulux-blue';
      default:
        return 'bg-dulux-blue';
    }
  }

  function handleClick() {
    if (available) {
      return props.onClick();
    }
  }

  return (
    <div
      onClick={handleClick}
      className={
        'flex justify-between px-1 border-b ' + (available ? '' : 'opacity-50')
      }
    >
      {props.title && (
        <div className="flex items-center w-2/12 py-3">
          <div
            className={
              'pb-100 w-full rounded relative text-white ' + displayIconBg()
            }
          >
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
              {displayIcon()}
            </div>
          </div>
        </div>
      )}
      {(props.title || props.popular) && (
        <div className="flex items-center w-4/12 py-3 relative pl-1">
          {props.title && (
            <span className="font-heading text-h3">{props.title}</span>
          )}
          {props.popular && (
            <div
              className="absolute top-0 text-technical px-2 rounded-br rounded-bl"
              style={{
                marginTop: '-1px',
                paddingTop: '.2rem',
                paddingBottom: '.2rem',
                backgroundColor: '#F2CB0A',
              }}
            >
              Popular
            </div>
          )}
        </div>
      )}
      {time && (
        <div className="flex flex-col items-center justify-center w-4/12 py-3">
          <div
            className="iphone-padding border inline-flex items-center rounded-full border-dulux-dark text-center"
            style={{ paddingTop: '.2rem', paddingBottom: '.2rem' }}
          >
            {time}
          </div>
          <span className={`text-small ${!available && 'text-red-500'}`}>
            {timeMessage()}
          </span>
        </div>
      )}
      {available && (
        <div className="flex justify-end items-center w-2/12 py-3">
          <span className="text-dulux-primary text-small sm:text-body">
            {props.price()}
          </span>
        </div>
      )}
    </div>
  );
};

OrderMethodItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  time: PropTypes.string,
  price: PropTypes.func,
  popular: PropTypes.bool,
  totalLitres: PropTypes.number,
  maxLitres: PropTypes.number,
  enabled: PropTypes.bool,
};

OrderMethodItem.defaultProps = {
  onClick: null,
  title: '',
  time: '',
  price: null,
  popular: false,
  totalLitres: 0,
  enabled: true,
};

export default OrderMethodItem;
