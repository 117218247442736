/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { IonAlert, IonContent, IonHeader, IonPage } from '@ionic/react';
import { batch, useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import OrderSummary from '../../components/order/OrderSummary';
import {
  selectRootState,
  selectLocationAddress,
  selectOrderReference,
  selectDeliveryNotes,
  selectDeliveryMethodObject,
  selectDeliveryTimeframeLabel,
  selectLocationAddressFormattedAddress,
  selectDeliveryTimeframe,
  selectCartItemsWithPricing,
  selectPricingTotals,
} from '../../store/selectors';
import {
  applyCurrentOrder,
  clearCart,
  clearCheckout,
  clearSelection,
} from '../../store/actions';
import { useAnalyticsContext } from '../../services/analytics/context';
import { useFirebaseContext } from '../../services/firebase/context';
import { useHistory } from 'react-router';
import { getCartItems } from '../../store/reducers';
import { createShopifyOrderData } from '../../services/shopify';
import { getNote } from '../../utils/text';
import useTimeout from '../../hooks/useTimeout';
import useFulfillmentMethodEnabled from '../../hooks/useFulfillmentMethodEnabled';

const Summary = () => {
  const analytics = useAnalyticsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const firebase = useFirebaseContext();
  const methodEnabled = useFulfillmentMethodEnabled();

  const rootState = useSelector(selectRootState);
  const cartItems = getCartItems(rootState);
  const deliveryMethod = useSelector(selectDeliveryMethodObject);
  const deliveryAddress = useSelector(selectLocationAddress);
  const deliveryAddressTitle = useSelector(
    selectLocationAddressFormattedAddress
  );
  const deliveryTimeframe = useSelector(selectDeliveryTimeframe);
  const deliveryTimeframeLabel = useSelector(selectDeliveryTimeframeLabel);
  const deliveryNotes = useSelector(selectDeliveryNotes);
  const orderReference = useSelector(selectOrderReference);
  const pricingItems = useSelector(selectCartItemsWithPricing);
  const pricingTotals = useSelector(selectPricingTotals);

  const timeToUndoOrder = 5000;
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderSending, setOrderSending] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!methodEnabled) {
      if (history.location.pathname === '/checkout/summary') {
        history.push('/checkout?error=true');
      }
    }
  }, [history, methodEnabled]);

  const timeoutRef = useTimeout(
    () => {
      if (orderPlaced) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        placeOrder();
      }
    },
    timeToUndoOrder,
    orderPlaced
  );

  const cancelTimeout = () => {
    analytics.trackEvent('Order Cancelled', {
      properties: {
        category: 'Checkout',
      },
    });
    clearTimeout(timeoutRef.current);
    setOrderPlaced(false);
  };

  function placeOrder() {
    setOrderSending(true);

    analytics.ecommerce.checkoutStepCompleted(5);

    return deliveryAddress.structured
      .then((structuredAddress) =>
        createShopifyOrderData(
          cartItems,
          deliveryMethod,
          deliveryTimeframe,
          structuredAddress,
          orderReference
        )
      )
      .then(({ lineItems, attributes, address, tags }) =>
        firebase.createOrder(
          lineItems,
          address,
          attributes,
          tags,
          deliveryNotes
        )
      )
      .then((data) => {
        // Send the user to the order if it came through
        if (data) {
          const orderId = data.data.id;

          dispatch(applyCurrentOrder(orderId));
          history.push('/orders');
          history.push('/orders/detail');
          history.push('/orders/detail/status');

          analytics.ecommerce.orderCompleted({
            order_id: orderId,
            total: pricingTotals.grossValue || 1,
            subtotal: pricingTotals.netValue || 1,
            revenue: pricingTotals.netValue || 1,
            tax: pricingTotals.taxAmount || 1,
            products: pricingItems,
          });
        } else {
          history.push('/orders');
        }

        // Clear the checkout after we've navigated away so that it doesn't show errors
        setTimeout(() => {
          batch(() => {
            dispatch(clearCart());
            dispatch(clearCheckout());
            dispatch(clearSelection());
          });
        }, 100);
      })
      .catch((err) => {
        console.error('Error creating order: ', err);
        setHasError(true);
      })
      .finally(() => {
        setOrderPlaced(false);
        setOrderSending(false);
      });
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderBack
          description={getNote(orderReference, deliveryNotes)}
          title="Order notes"
        />
        <HeaderTitle title="Order summary" borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        {deliveryMethod ? (
          <OrderSummary
            timeToUndoOrder={5000}
            onSubmitOrder={placeOrder}
            cancelTimeout={cancelTimeout}
            orderPlaced={orderPlaced}
            orderSending={orderSending}
            setOrderPlaced={setOrderPlaced}
            method={deliveryMethod.name}
            type={deliveryMethod.type}
            time={deliveryTimeframeLabel}
            address={deliveryAddressTitle}
            note={deliveryNotes}
            orderReference={orderReference}
          />
        ) : (
          <div className="bg-dulux-bg flex flex-col justify-center items-center h-full">
            <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
              Finding order
            </h2>
          </div>
        )}

        <IonAlert
          isOpen={hasError}
          header={'Your order was not submitted'}
          message={
            'We encountered a problem submitting your order. Please try again or contact your Rep'
          }
          backdropDismiss={false}
          buttons={[
            {
              text: 'Confirm',
              handler: () => {
                setHasError(false);
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Summary;
