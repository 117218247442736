import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/ui/Button.jsx';

const OrderStatus = (props) => {
  return (
    <div className="h-full flex flex-col bg-dulux-dark">
      {props.orderNumber && (
        <div className="text-dulux-dark flex justify-center items-center py-1 px-1 border-b border-gray-500">
          <h1 className="text-h2 leading-h2 font-heading m-0 text-white">
            Order {props.orderNumber}
          </h1>
        </div>
      )}
      {(props.method || props.time) && (
        <div className="p-1 border-b border-gray-500 flex flex-col justify-center items-center">
          {props.method && <div className="text-gray-600">{props.method}</div>}
          {props.time && <div className="text-gray-600">{props.time}</div>}
        </div>
      )}
      <div className="h-full flex flex-col justify-center items-center text-center p-1">
        <div className="font-heading text-h1 text-white px-3">
          {props.status}
        </div>
        {props.isButtonEnabled && (
          <div className="mt-4 w-full">
            <Button
              theme="light"
              onClick={() => props.onClick && props.onClick()}
            >
              I have received my order
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

OrderStatus.propTypes = {
  orderNumber: PropTypes.string,
  method: PropTypes.string,
  time: PropTypes.string,
  status: PropTypes.string,
  isButtonEnabled: PropTypes.bool,
  onClick: PropTypes.func,
};

OrderStatus.defaultProps = {
  orderNumber: '',
  method: '',
  time: '',
  status: '',
  isButtonEnabled: false,
  onClick: null,
};

export default OrderStatus;
