import React, { useState } from 'react';
import { IonContent, IonPage, IonHeader, IonAlert } from '@ionic/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SettingsUserDetails from '../components/settings/SettingsUserDetails';
import SettingsUserDetailsItem from '../components/settings/SettingsUserDetailsItem';
import SettingsUserPreferences from '../components/settings/SettingsUserPreferences';
import SettingsUserPreferencesItem from '../components/settings/SettingsUserPreferencesItem';
import { ReactComponent as ChevronRightIcon } from '../assets/icons/20/chevron-right.svg';
import HeaderTitle from '../components/header/HeaderTitle';
import HeaderBack from '../components/header/HeaderBack';
import { useFirebaseContext } from '../services/firebase/context';
import { selectUser } from '../store/selectors';

const Settings = () => {
  const user = useSelector(selectUser);
  const firebase = useFirebaseContext();
  const history = useHistory();
  const [showSignOutAlert, setShowSignOutAlert] = useState(false);

  const [isTouchedMarketingEmails, setIsTouchedMarketingEmails] = useState(
    false
  );
  const [isTouchedSmsNotification, setIsTouchedSmsNotification] = useState(
    false
  );
  const [isTouchedEmailNotification, setIsTouchedEmailNotification] = useState(
    false
  );
  const [isTouchedStaySignedIn, setIsTouchedStaySignedIn] = useState(false);

  function termsAndConditionsOfUseOnClick() {
    history.push('/settings/terms-and-conditions-of-use');
  }

  function privacyPolicyOnClick() {
    history.push('/settings/privacy-policy');
  }

  function standardConditionsOfSaleOnClick() {
    history.push('/settings/standard-conditions-of-sale');
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderBack title="Home" />
          <HeaderTitle title="Settings" borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>
          {user ? (
            <>
              <SettingsUserDetails title="My details">
                <SettingsUserDetailsItem
                  label="First name"
                  value={user.firstName}
                />
                <SettingsUserDetailsItem
                  label="Last name"
                  value={user.lastName}
                />
                <SettingsUserDetailsItem label="Email" value={user.email} />
                <SettingsUserDetailsItem
                  label="Business / Company"
                  value={user.businessName}
                />
                <SettingsUserDetailsItem
                  label="Dulux account no."
                  value={user.accountId}
                  noBorder
                />
              </SettingsUserDetails>
              <SettingsUserPreferences title="Account and order updates">
                <SettingsUserPreferencesItem
                  label="Send me SMS updates"
                  checked={user.enableSmsNotification}
                  onChange={(e) => {
                    firebase.updateUserData({
                      enableSmsNotification: e.target.checked,
                    });
                    setIsTouchedSmsNotification(true);
                  }}
                />
                <SettingsUserPreferencesItem
                  label="Send me email updates"
                  checked={user.enableEmailNotification}
                  onChange={(e) => {
                    firebase.updateUserData({
                      enableEmailNotification: e.target.checked,
                    });
                    setIsTouchedEmailNotification(true);
                  }}
                />
                <SettingsUserPreferencesItem
                  label="Keep me signed in"
                  checked={user.staySignedIn}
                  onChange={(e) => {
                    firebase.updateUserData({
                      staySignedIn: e.target.checked,
                    });
                    setIsTouchedStaySignedIn(true);
                  }}
                />
              </SettingsUserPreferences>
              <div
                className="flex justify-center items-center px-1 py-3 font-heading text-dulux-primary"
                onClick={() => setShowSignOutAlert(true)}
              >
                Sign Out
              </div>
              <SettingsUserPreferences title="Tips and specials">
                <SettingsUserPreferencesItem
                  label="Send me marketing"
                  checked={user.enableMarketingEmails}
                  onChange={(e) => {
                    firebase.updateUserData({
                      enableMarketingEmails: e.target.checked,
                    });
                    setIsTouchedMarketingEmails(true);
                  }}
                />
              </SettingsUserPreferences>
              <SettingsUserPreferences title="Data and privacy">
                <div
                  className="p-1 border-b flex justify-between items-center"
                  onClick={() => termsAndConditionsOfUseOnClick()}
                >
                  <p className="text-dulux-dark">Terms and conditions of use</p>
                  <div>
                    <div className="bg-blue-100 w-6 h-6 flex justify-center items-center">
                      <ChevronRightIcon className="text-dulux-primary" />
                    </div>
                  </div>
                </div>
                <div
                  className="p-1 border-b flex justify-between items-center"
                  onClick={() => privacyPolicyOnClick()}
                >
                  <p className="text-dulux-dark">Privacy policy</p>
                  <div>
                    <div className="bg-blue-100 w-6 h-6 flex justify-center items-center">
                      <ChevronRightIcon className="text-dulux-primary" />
                    </div>
                  </div>
                </div>
                <div
                  className="p-1 border-b flex justify-between items-center"
                  onClick={() => standardConditionsOfSaleOnClick()}
                >
                  <p className="text-dulux-dark">Standard conditions of sale</p>
                  <div>
                    <div className="bg-blue-100 w-6 h-6 flex justify-center items-center">
                      <ChevronRightIcon className="text-dulux-primary" />
                    </div>
                  </div>
                </div>
              </SettingsUserPreferences>
              <IonAlert
                isOpen={
                  isTouchedSmsNotification &&
                  user.enableSmsNotification === false
                }
                header={'Are you sure?'}
                message={
                  'Switching off notifications disables features within the app that are important when ordering paint.'
                }
                backdropDismiss={false}
                buttons={[
                  {
                    text: 'Cancel',
                    handler: () => {
                      firebase.updateUserData({
                        enableSmsNotification: true,
                      });
                    },
                  },
                  {
                    text: 'Confirm',
                    handler: () => {
                      firebase.updateUserData({
                        enableSmsNotification: false,
                      });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={
                  isTouchedEmailNotification &&
                  user.enableEmailNotification === false
                }
                header={'Are you sure?'}
                message={
                  'Switching off notifications disables features within the app that are important when ordering paint.'
                }
                backdropDismiss={false}
                buttons={[
                  {
                    text: 'Cancel',
                    handler: () => {
                      firebase.updateUserData({
                        enableEmailNotification: true,
                      });
                    },
                  },
                  {
                    text: 'Confirm',
                    handler: () => {
                      firebase.updateUserData({
                        enableEmailNotification: false,
                      });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={
                  isTouchedMarketingEmails &&
                  user.enableMarketingEmails === false
                }
                header={'Are you sure?'}
                message={
                  'Switching this off will stop you from receiving any further promotions, recommendations, and inspiration from Dulux and Dulux Representatives.'
                }
                backdropDismiss={false}
                buttons={[
                  {
                    text: 'Cancel',
                    handler: () => {
                      firebase.updateUserData({
                        enableMarketingEmails: true,
                      });
                    },
                  },
                  {
                    text: 'Confirm',
                    handler: () => {
                      firebase.updateUserData({
                        enableMarketingEmails: false,
                      });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={isTouchedStaySignedIn && user.staySignedIn === false}
                header={'Are you sure?'}
                message={`Switching this off means you'll need to sign in each time you open the app.`}
                backdropDismiss={false}
                buttons={[
                  {
                    text: 'Cancel',
                    handler: () => {
                      firebase.updateUserData({
                        staySignedIn: true,
                      });
                    },
                  },
                  {
                    text: 'Confirm',
                    handler: () => {
                      firebase.updateUserData({
                        staySignedIn: false,
                      });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={showSignOutAlert}
                header={'Sign out'}
                message={'Are you sure you would like to sign out?'}
                backdropDismiss={false}
                buttons={[
                  {
                    text: 'Cancel',
                    handler: () => {
                      setShowSignOutAlert(false);
                    },
                  },
                  {
                    text: 'Confirm',
                    handler: () => {
                      firebase.signOut();
                    },
                  },
                ]}
              />
            </>
          ) : (
            <div className="bg-dulux-bg flex flex-col justify-center items-center h-full">
              <div className="spinner px-1 mb-1">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                Loading profile
              </h2>
            </div>
          )}
          <div className="p-2 text-gray-300 text-center">
            {process.env.REACT_APP_VERSION || ''}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Settings;
