import { PricingActionType, PricingActionTypes, PricingState } from './types';
import { UserActionType, UserActionTypes } from '../user/types';

const initialState: PricingState = {
  isLoadingPricing: false,
};

const pricing = (
  state: PricingState = initialState,
  action: PricingActionTypes | UserActionTypes
): PricingState => {
  switch (action.type) {
    case PricingActionType.REQUEST:
      return {
        ...state,
        isLoadingPricing: action.payload.isLoadingPricing,
      };

    case PricingActionType.RECEIVE:
      return {
        items: action.payload.items,
        total: action.payload.total,
        isLoadingPricing: false,
      };

    case PricingActionType.COMPLETE:
      return {
        ...state,
        isLoadingPricing: action.payload.isLoadingPricing,
      };

    case PricingActionType.CLEAR:
    case UserActionType.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default pricing;
