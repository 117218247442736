import { pick, omit } from 'lodash';
import { QueryActionType, QueryActionTypes, QueryState } from './types';
import { UserActionType, UserActionTypes } from '../user/types';

const initialState: QueryState = {
  productQuery: {},
};

const selection = (
  state: QueryState = initialState,
  action: QueryActionTypes | UserActionTypes
): QueryState => {
  switch (action.type) {
    case QueryActionType.APPLY_PRODUCT:
      return {
        productQuery: {
          ...action.payload.productQuery,
        },
        cartItemId: state.cartItemId,
      };

    case QueryActionType.APPLY_PRODUCT_SHEEN: {
      const query = pick(state.productQuery, ['range', 'name']);

      return {
        productQuery: {
          ...query,
          sheen: action.payload.sheen,
        },
        cartItemId: state.cartItemId,
      };
    }

    case QueryActionType.APPLY_PRODUCT_SHEEN_IMAGE: {
      return {
        ...state,
        productQuery: {
          ...state.productQuery,
          sheenImage: action.payload.sheenImage,
        },
      };
    }

    case QueryActionType.APPLY_COLOUR: {
      const query = pick(state.productQuery, ['range', 'name', 'sheen']);

      return {
        ...state,
        productQuery: {
          ...query,
          base: action.payload.base,
        },
        colourId: action.payload.colourId,
        colourStrength: action.payload.colourStrength || 1,
      };
    }

    case QueryActionType.APPLY_PRODUCT_SIZE:
      return {
        ...state,
        productQuery: {
          ...state.productQuery,
          size: action.payload.size,
        },
      };

    case QueryActionType.APPLY_QUANTITY: {
      return {
        ...state,
        quantity: action.payload.quantity,
      };
    }

    case QueryActionType.APPLY_SELECTION: {
      const productQuery = pick(action.payload.productQuery, [
        'range',
        'name',
        'sheen',
        'sheenImage',
        'sheenColour',
        'base',
        'size',
      ]);

      return {
        ...action.payload,
        productQuery: {
          ...productQuery,
        },
      };
    }

    case QueryActionType.CLEAR_QUANTITY: {
      return {
        ...state,
        quantity: undefined,
      };
    }

    case QueryActionType.CLEAR_PRODUCT_SHEEN: {
      const query = pick(state.productQuery, ['range', 'name']);

      return {
        ...omit(state, ['quantity', 'colourId', 'colourStrength']),
        productQuery: {
          ...query,
        },
        cartItemId: state.cartItemId,
      };
    }

    case QueryActionType.CLEAR_PRODUCT_SHEEN_IMAGE: {
      return {
        ...state,
        productQuery: {
          ...omit(state.productQuery, 'sheenImage'),
        },
      };
    }

    case QueryActionType.CLEAR_COLOUR: {
      const query = pick(state.productQuery, ['range', 'name', 'sheen']);

      return {
        ...omit(state, ['quantity', 'colourId', 'colourStrength']),
        productQuery: {
          ...query,
        },
        cartItemId: state.cartItemId,
      };
    }

    case QueryActionType.CLEAR_PRODUCT_SIZE: {
      const query = pick(state.productQuery, [
        'range',
        'name',
        'sheen',
        'sheenImage',
        'sheenColour',
        'base',
      ]);

      return {
        ...omit(state, ['quantity']),
        productQuery: {
          ...query,
        },
      };
    }

    case QueryActionType.CLEAR_QUERY:
      return {
        ...state,
        productQuery: {},
        colourId: undefined,
        colourStrength: undefined,
        quantity: undefined,
        cartItemId: undefined,
      };

    case UserActionType.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default selection;
