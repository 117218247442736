export function sheenBackground(sheenTitle: string) {
  switch (sheenTitle) {
    case 'Matt':
    case 'Flat':
    case 'Ceiling Flat':
      return '#2D89CA';
    case 'Low Sheen':
    case 'Low Gloss':
      return '#EA4F3A';
    case 'Semi Gloss':
      return '#59C301';
    case 'Gloss':
    case 'High Gloss':
      return '#F2CB0A';
    default:
      return '#FFFFFF';
  }
}

export default sheenBackground;
