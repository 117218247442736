import { FeatureActionType, FeatureActionTypes } from './types';

const initialState = {};

const features = (
  state = initialState,
  action: FeatureActionTypes
): Record<string, any> => {
  switch (action.type) {
    case FeatureActionType.APPLY_FEATURE_VOTES:
      return {
        ...state,
        [action.payload.feature]: action.payload.votes,
      };

    default:
      return state;
  }
};

export default features;
