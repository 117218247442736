import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import { clearSupportCurrentQuery } from '../../store/actions';
import {
  selectSelectedSupportCategory,
  selectSupportState,
} from '../../store/selectors';

const SupportDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const supportState = useSelector(selectSupportState);
  const category = useSelector(selectSelectedSupportCategory);

  function onBackClick() {
    dispatch(clearSupportCurrentQuery());
    history.goBack();
  }

  function onClick() {
    history.push('/support/contact');
  }

  function transform(node, index) {
    if (node.type === 'tag' && node.name === 'p') {
      node.attribs.class = 'pb-2';
    }

    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.class = 'text-dulux-primary';
    }

    if (node.type === 'tag' && node.name === 'ul') {
      node.attribs.class = 'mb-2 ml-2 list-disc';
    }

    if (node.type === 'tag' && node.name === 'ol') {
      node.attribs.class = 'mb-2 ml-2 list-decimal';
    }

    if (node.type === 'tag' && node.name === 'li') {
      node.attribs.class = 'ml-2 mb-2';
    }

    return convertNodeToElement(node, index, transform);
  }

  const options = {
    decodeEntities: true,
    transform,
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderBack
            title={category && category.name}
            description="I want to..."
            onClick={onBackClick}
          />
          <HeaderTitle
            title={supportState.currentQuery && supportState.currentQuery.query}
            borderColour="#e2e8f0"
          />
        </IonHeader>
        <IonContent>
          {supportState.currentQuery && (
            <div className="p-1">
              {ReactHtmlParser(supportState.currentQuery.content, options)}
            </div>
          )}
          {/* <div>
            <h3>Were our instructions helpful?</h3>
            <SplitButtons>
              <Button onClick={() => console.log('Yes')}>Yes</Button>
              <Button onClick={() => console.log('No')}>No</Button>
            </SplitButtons>
          </div> */}
          <div className="p-1">
            <div className="border rounded">
              <p className="text-center px-4 py-2 border-b text-small">
                Tap the button below for additional help with your query.
              </p>
              <p
                onClick={onClick}
                className="text-center px-4 py-2 text-dulux-primary"
              >
                Talk to a rep
              </p>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default SupportDetail;
