import { RootState } from '../reducers';

// Root
export const selectRootState = (state: RootState) => state;

// Cart
export const selectCartItems = (state: RootState) => state.cart.items;

// Catalog
export const selectCatalog = (state: RootState) => state.catalog;

// Checkout
export const selectCheckout = (state: RootState) => state.checkout;

// Colour
export const selectColourState = (state: RootState) => state.colours;
export const selectColoursById = (state: RootState) => state.colours.byId;

// Features
export const selectFeaturesState = (state: RootState) => state.features;

// Orders
export const selectOrdersById = (state: RootState) => state.orders.byId;

// Pricing
export const selectPricing = (state: RootState) => state.pricing;

// Products
export const selectProductsById = (state: RootState) => state.products.byId;

// Selection
export const selectSelectionQuery = (state: RootState) => state.selection;
export const selectProductQuery = (state: RootState) =>
  state.selection.productQuery;
export const selectProductRangeQuery = (state: RootState) =>
  state.selection.productQuery.range;
export const selectProductNameQuery = (state: RootState) =>
  state.selection.productQuery.name;
export const selectProductSheenQuery = (state: RootState) =>
  state.selection.productQuery.sheen;
export const selectProductBaseQuery = (state: RootState) =>
  state.selection.productQuery.base;
export const selectProductSizeQuery = (state: RootState) =>
  state.selection.productQuery.size;
export const selectSelectedColourId = (state: RootState) =>
  state.selection.colourId;
export const selectSelectedColourStrength = (state: RootState) =>
  state.selection.colourStrength;
export const selectSelectedQuantity = (state: RootState) =>
  state.selection.quantity;
export const selectSelectedCartItemId = (state: RootState) =>
  state.selection.cartItemId;

// stores
export const selectStoresById = (state: RootState) => state.stores.byId;

// Support
export const selectSupportState = (state: RootState) => state.support;
export const selectSupportStateById = (state: RootState) => state.support.byId;

export const selectCurrentSupportCategory = (state: RootState) =>
  state.support.currentCategory;
export const selectCurrentSupportQuery = (state: RootState) =>
  state.support.currentQuery;

// User
export const selectUserState = (state: RootState) => state.user;
