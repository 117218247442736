import { isPlatform } from '@ionic/react';

export const environment = process.env.NODE_ENV || 'production';

export const isDevelopment = environment === 'development';
export const isProduction = environment === 'production';

export const hasNativeCapability = () => {
  return isPlatform('capacitor');
};

export const buildEnvironment = process.env.REACT_APP_BUILD_ENV || 'Production';

export const buildIsLocal = buildEnvironment === 'Local';
export const buildIsMaster = buildEnvironment === 'Master';
export const buildIsProduction = buildEnvironment === 'Production';
