import {
  ApplyFeatureVotesAction,
  FeatureActionType,
} from '../reducers/features/types';

export const applyFeatureVotes = (
  feature: string,
  votes: number
): ApplyFeatureVotesAction => {
  return {
    type: FeatureActionType.APPLY_FEATURE_VOTES,
    payload: {
      feature,
      votes,
    },
  };
};
