import React, { useState } from 'react';
import { Plugins } from '@capacitor/core';
import { IonContent, IonHeader, IonPage, IonTextarea } from '@ionic/react';
import { useAnalyticsContext } from '../../services/analytics/context';
import { useFirebaseContext } from '../../services/firebase/context';
import OrderFeedback from '../../components/order/OrderFeedback';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import { colourForRating, descriptionForRating } from '../../utils/rating';
import Button from '../../components/ui/Button';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectCurrentOrder } from '../../store/selectors';
import { submitFeedback } from '../../utils/orderSurvey';

const OrderFeedbackPage = () => {
  const { App } = Plugins;
  const history = useHistory();
  const firebase = useFirebaseContext();
  const analytics = useAnalyticsContext();
  const order = useSelector(selectCurrentOrder);
  const [rating, setRating] = useState();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  // Android only
  App.addListener('backButton', (data) => {
    if (rating) {
      setRating(undefined);
    }
  });

  function submit(requestCallback) {
    if (loading) {
      return;
    }

    setLoading(true);

    submitFeedback(firebase, order, rating, comment, requestCallback)
      .then(() => {
        analytics.trackEvent('Survey Submitted', {
          properties: {
            category: 'Order feedback',
            label: `Rating: ${rating}`,
            value: rating,
          },
        });

        setRating(undefined);
        setComment('');

        history.goBack();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function headerTitle() {
    if (!rating) {
      return 'How would you rate Trade Direct in terms of:';
    }

    if (rating < 3) {
      return "We'd like to know more about your experience.";
    }

    return 'Any other comments?';
  }

  function handleBack() {
    setRating(undefined);
  }

  function displayHelp() {
    if (rating <= 2) {
      return (
        <div className="border m-1 rounded-md text-center">
          <div className="py-2 px-1 border-b">
            <p className="text-h3">We&apos;d like to help</p>
            <p className="px-6 mt-2">
              Tap the button below for additional help with your experience.
            </p>
          </div>
          <div className="p-1 py-2 border-b">
            <span
              className="text-h3 text-dulux-primary"
              onClick={() => submit(true)}
            >
              Get help
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <IonPage>
      <IonHeader>
        {rating && (
          <HeaderBack
            description={descriptionForRating(rating)}
            title="Ease of ordering"
            backgroundColour={colourForRating(rating)}
            onClick={handleBack}
          />
        )}
        <HeaderTitle title={headerTitle()} borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        {!rating && (
          <div className="h-full flex flex-col justify-center">
            <div className="border-b mb-3 pb-2">
              <h2 className="font-heading text-h2 leading-h2 text-dulux-dark text-center">
                Ease of ordering?
              </h2>
            </div>

            <OrderFeedback onClick={(r) => setRating(r)} />
          </div>
        )}

        {rating && (
          <>
            <div className="h-full flex flex-col">
              <div className="h-full overflow-scroll">
                <div className="p-1">
                  <p className="mb-1">Add your comments here</p>
                  <IonTextarea
                    className="w-full p-1 bg-white"
                    rows="2"
                    autoGrow={true}
                    autofocus={true}
                    value={comment}
                    onIonChange={(e) => setComment(e.target.value)}
                  />
                </div>
                {displayHelp()}
              </div>
            </div>
          </>
        )}
      </IonContent>
      {rating && (
        <Button onClick={() => submit(false)}>
          {loading ? 'Submitting...' : 'Done'}
        </Button>
      )}
    </IonPage>
  );
};

export default OrderFeedbackPage;
