import { ProductMap } from '../store/reducers/products/types';
import { ProductQuery } from '../store/reducers/selection/types';
import { filter } from 'lodash';
import imageComponent from './productImages';
import Size1LImage from '../assets/images/product/paint/size/size-1L@2x.png';
import Size2LImage from '../assets/images/product/paint/size/size-2L@2x.png';
import Size4LImage from '../assets/images/product/paint/size/size-4L@2x.png';
import Size8LImage from '../assets/images/product/paint/size/size-8L@2x.png';
import Size10LImage from '../assets/images/product/paint/size/size-10L@2x.png';
import Size15LImage from '../assets/images/product/paint/size/size-15L@2x.png';
import { Product } from '../types';

export function isAccessory(range: string) {
  return (
    range === 'Application' || range === 'Preperation' || range === 'Protection'
  );
}

/*
 * Filter the products with the given query.
 */
export function filterProducts(productsById: ProductMap, query: ProductQuery) {
  if (Object.keys(query).length === 0) {
    return Object.values(productsById);
  }

  return filter(Object.values(productsById), query);
}

/*
 * Get the unique values remaining for a certain
 * property on an array of Products.
 */
export function getAvailableProductProperties(
  products: Product[],
  property: keyof Product
): (keyof Product)[] {
  const ids = new Set();

  products.forEach((product) => {
    // Product must have the field, which must contain a valid value
    if (property in product && product[property]) {
      ids.add(product[property]);
    }
  });

  return Array.from(ids) as (keyof Product)[];
}

export function productBackgroundColour(
  productRange: string,
  productName: string
) {
  switch (productRange) {
    case 'Dulux':
      switch (productName) {
        case 'Wash&Wear':
          return '#010C20';
        case '1Step Prep':
          return '#757575';
        case 'Aquanamel':
          return '#2D89CA';
        case 'Weathershield':
          return '#F2CB0A';
        case 'Super Enamel':
          return '#010C20';
        case 'Ceiling White':
          return '#89CCCB';
        default:
          return '#010C20';
      }
    case 'Dulux Professional':
      switch (productName) {
        case 'Professional Enamel':
          return '#17571A';
        case 'Professional Interior/Exterior':
          return '#611D72';
        case 'Professional Interior':
          return '#13377E';
        case 'Professional Preparation':
          return '#5A504D';
        default:
          return '#010C20';
      }
    case 'Berger':
      switch (productName) {
        case 'Berger Gold Label':
          return '#010C20';
        default:
          return '#010C20';
      }
    case 'Hadrian':
      switch (productName) {
        case 'Hadrian':
          return '#50534A';
        default:
          return '#50534A';
      }
    case 'Application':
    case 'Preperation':
    case 'Protection':
      return '#FFFFFF';
    default:
      return '#010C20';
  }
}

export function productSizeImage(size: string) {
  switch (size) {
    case '1L':
      return Size1LImage;
    case '2L':
      return Size2LImage;
    case '4L':
      return Size4LImage;
    case '8L':
      return Size8LImage;
    case '10L':
      return Size10LImage;
    case '15L':
      return Size15LImage;
    default:
      return '';
  }
}

export function productImage(productRange: string, productName: string) {
  return imageComponent(productRange, productName);
}
