import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import HeaderTitle from '../components/header/HeaderTitle';
import CatalogTabs from '../components/catalog/CatalogTabs';
import CatalogPaint from '../components/catalog/CatalogPaint';
import CatalogAccessories from '../components/catalog/CatalogAccessories';
import Loader from '../components/ui/Loader';
import { useAnalyticsContext } from '../services/analytics/context';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductQuery, selectCatalogActiveTab } from '../store/selectors';
import { CatalogTabType } from '../store/reducers/catalog/types';
import {
  applyProductSelectionQuery,
  selectActiveCatalogTab,
} from '../store/actions';
import {
  isAccessory,
  productBackgroundColour,
  productImage,
} from '../utils/product';

const Catalog = () => {
  const analytics = useAnalyticsContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeTab = useSelector(selectCatalogActiveTab);
  const productQuery = useSelector(selectProductQuery);
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const [accessoryIcon, setAccessoryIcon] = useState();

  function handleClick(query) {
    analytics.trackEvent('Product Clicked', {
      properties: {
        category: 'Catalog',
        label: JSON.stringify(query),
      },
    });

    dispatch(applyProductSelectionQuery(query));
    setLoaderEnabled(true);
    setTimeout(function () {
      setLoaderEnabled(false);
      history.push('/product');
    }, 1000);
  }

  function onTabClick(e) {
    dispatch(selectActiveCatalogTab(e.target.id));
    const tab = document.getElementById(e.target.id);
    tab.scrollTop = 0;
    analytics.trackEvent('Product List Viewed', {
      properties: {
        category: 'Catalog',
        label: e.target.id,
      },
    });
  }

  function renderImage() {
    if (isAccessory(productQuery.range)) {
      return accessoryIcon;
    }

    return (
      <img
        className="w-6/12 mb-1"
        src={productImage(productQuery.range, productQuery.name)}
        alt="product"
      />
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderTitle title="Order" borderColour="#e2e8f0" />
        <CatalogTabs onClick={(e) => onTabClick(e)} selectedTab={activeTab} />
      </IonHeader>
      <IonContent>
        {activeTab === CatalogTabType.PAINT && (
          <div className="h-full bg-dulux-bg overflow-scroll">
            <CatalogPaint
              onClick={({ range, name }) => handleClick({ range, name })}
            />
          </div>
        )}
        {activeTab === CatalogTabType.ACCESSORIES && (
          <div className="h-full bg-dulux-bg overflow-scroll">
            <CatalogAccessories
              onClick={({ range, name }) => handleClick({ range, name })}
              setAccessoryIcon={setAccessoryIcon}
            />
          </div>
        )}
      </IonContent>
      <Loader
        isEnabled={loaderEnabled}
        title={isAccessory(productQuery.range) ? productQuery.name : ''}
        background={productBackgroundColour(
          productQuery.range,
          productQuery.name
        )}
        image={renderImage()}
      />
    </IonPage>
  );
};

export default Catalog;
