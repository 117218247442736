import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getOrderStatus,
  getOrderDeliveryMethod,
  getAttribute,
  isDelivery,
  getOrderDeliveryTimeLabel,
  getColourId,
  isCancelled,
  isFulfilled,
  getOrderStatusDescription,
} from '../../utils/order';
import { reject } from 'lodash';
import HeaderTitle from '../../components/header/HeaderTitle';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { selectCurrentOrder, selectRootState } from '../../store/selectors';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/64/priority-delivery.svg';
import CartItem from '../../components/cart/CartItem';
import CartHeader from '../../components/cart/CartHeader';
// import CartTotals from '../../components/cart/CartTotals';
import HeaderBack from '../../components/header/HeaderBack';
import {
  selectedTimeLabel,
  selectedNoteLabel,
  titleCase,
} from '../../utils/text';
import { isAccessory } from '../../utils/product';
import { displayStrengthIconInline } from '../../utils/productImages';
import { getColour, getProduct } from '../../store/reducers';
import { OrderStatus } from '../../types';

const OrderDetail = (props) => {
  const history = useHistory();
  const rootState = useSelector(selectRootState);
  const currentOrder = useSelector(selectCurrentOrder);

  const orderRef = currentOrder
    ? getAttribute(currentOrder.note_attributes, 'Order Reference')
    : '';

  const currentOrderName = currentOrder ? currentOrder.order_number : null;
  const currentOrderStatus = currentOrder ? getOrderStatus(currentOrder) : null;

  function displayStatusIcon() {
    switch (currentOrderStatus) {
      case OrderStatus.RECEIVED:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.OUT_FOR_DELIVERY:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.AWAITING_PICKUP_IN_STORE:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.AWAITING_PICKUP_IN_LOCKER:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.DELIVERED:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.PICKED_UP:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      default:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
    }
  }

  function handleOnClick() {
    history.push('/orders/detail/status');
  }

  function handleBack() {
    history.push('/orders');
  }

  function renderStrengthIcon(accessory, item) {
    const strengthAttr = getAttribute(
      item.properties,
      'Colour Strength',
      false
    );

    if (!strengthAttr || accessory) {
      return;
    }

    if (!accessory) {
      return displayStrengthIconInline(Number(strengthAttr));
    }
  }

  function getLineItems() {
    if (!currentOrder) {
      return;
    }

    const mappedItems = currentOrder.line_items.map((item, i) => {
      const colourId = getColourId(item.properties);
      const colour = (colourId && getColour(rootState, colourId)) || null;
      const product = (item.sku && getProduct(rootState, item.sku)) || null;

      // If we can't find the product, we can guess that it's an accessory by
      // the absence of a colour iD
      const accessory = product ? isAccessory(product.range) : !item.colourId;

      return (
        <CartItem
          key={item.sku}
          onClick={handleOnClick}
          isAccessory={accessory}
          backgroundColour={colour && colour.hex}
          quantity={item.quantity}
          image={product && product.sheenImage}
          range={product && product.range}
          name={product ? product.name : item.name}
          sheen={product && product.sheen}
          displayStrengthIcon={() => renderStrengthIcon(accessory, item)}
          colourBrand={getAttribute(
            item.properties,
            'Colour Brand',
            colour && colour.colourBrand
          )}
          colourName={getAttribute(
            item.properties,
            'Colour Name',
            colour && titleCase(colour.colourName)
          )}
          size={product && product.size}
        />
      );
    });

    return reject(mappedItems, () => null);
  }

  function formatAddress() {
    if (!currentOrder) {
      return;
    }

    const shippingAddress = currentOrder.shipping_address;
    const suburbCity = shippingAddress.address2
      ? shippingAddress.address2
      : shippingAddress.city;

    return `${shippingAddress.address1},
    ${suburbCity}
    ${shippingAddress.province_code}
    ${shippingAddress.zip}`;
  }

  function renderTimeLabel() {
    if (isCancelled(currentOrder) || isFulfilled(currentOrder)) {
      return;
    }

    return selectedTimeLabel(getOrderDeliveryMethod(currentOrder));
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderTitle
          title={`Order ${currentOrderName}`}
          borderColour="#e2e8f0"
        />
        <HeaderBack title="Orders" onClick={handleBack} />
      </IonHeader>
      <IonContent>
        <div className="flex border-t border-b" onClick={handleOnClick}>
          <div className="w-5/12 bg-gray-200 p-1 pt-2 flex flex-col justify-between">
            <div className="flex items-center">Order {currentOrderName}</div>
          </div>
          <div className="w-7/12 bg-white p-1 pt-2 flex flex-col">
            <div className="flex justify-between items-center">
              {currentOrderStatus && (
                <div className="text-dulux-primary flex items-center">
                  {displayStatusIcon()}
                  {getOrderStatusDescription(currentOrderStatus)}
                </div>
              )}
              {currentOrder && (
                <div className="text-gray-500">{renderTimeLabel()}</div>
              )}
            </div>
            {currentOrder && (
              <div className="bg-dulux-dark text-white rounded-full text-center text-small mt-1 px-1 py-1">
                {getOrderDeliveryTimeLabel(currentOrder)}
              </div>
            )}
            <div className="flex items-center mt-1 text-dulux-dark">
              {currentOrder && currentOrder.shipping_address.address1}
            </div>
          </div>
        </div>
        <div className="border-b overflow-hidden">
          <CartHeader />
          {getLineItems()}
          {/*<CartTotals />*/}
          <div className="bg-white border-b">
            <div className="p-1 flex flex-col">
              <p className="text-gray-600">
                {currentOrder && isDelivery(currentOrder)
                  ? 'Delivery'
                  : 'Pickup'}{' '}
                address
              </p>
              <p className="text-dulux-dark text-h3 font-heading">
                {formatAddress()}
              </p>
            </div>
          </div>

          {orderRef && (
            <div className="bg-white border-b">
              <div className="p-1 flex flex-col">
                <p className="text-gray-600">Order reference</p>
                <p className="text-dulux-dark text-h3 font-heading">
                  {orderRef}
                </p>
              </div>
            </div>
          )}

          {currentOrder && currentOrder.note && (
            <div className="bg-white border-b">
              <div className="p-1 flex flex-col">
                <p className="text-gray-600">
                  {currentOrder &&
                    selectedNoteLabel(getOrderDeliveryMethod(currentOrder))}
                </p>
                <p className="text-dulux-dark text-h3 font-heading">
                  {currentOrder.note}
                </p>
              </div>
            </div>
          )}

          {/*<div className="bg-white border-b">*/}
          {/*  <div className="p-1 flex justify-between">*/}
          {/*    <p className="text-dulux-dark">Order Subtotal</p>*/}
          {/*    <p className="text-dulux-dark font-heading">*/}
          {/*      {currentOrder && currentOrder.subtotal_price}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="bg-white border-b">*/}
          {/*  <div className="p-1 flex justify-between">*/}
          {/*    <p className="text-dulux-dark">*/}
          {/*      {currentOrder && isDelivery(currentOrder)*/}
          {/*        ? 'Delivery'*/}
          {/*        : 'Pickup'}{' '}*/}
          {/*      fee*/}
          {/*    </p>*/}
          {/*    <p className="text-dulux-dark font-heading">*/}
          {/*      {calculateDeliveryFee()}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="bg-white">*/}
          {/*  <div className="p-1 flex justify-between">*/}
          {/*    <p className="text-dulux-dark">Total tax</p>*/}
          {/*    <p className="text-dulux-dark font-heading">*/}
          {/*      {currentOrder && currentOrder.total_tax}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="bg-white border-b">*/}
          {/*  <div className="p-1 flex justify-between">*/}
          {/*    <p className="text-dulux-dark">Total (GST INC)</p>*/}
          {/*    <p className="text-dulux-dark font-heading">*/}
          {/*      {currentOrder && currentOrder.total_price}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OrderDetail;
