import { createContext, useContext } from 'react';
import Firebase from './firebase';

export const FirebaseContext = createContext({});

export const FirebaseProvider = FirebaseContext.Provider;

export const useFirebaseContext = () => {
  return useContext(FirebaseContext) as Firebase;
};
