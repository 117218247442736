import React from 'react';

const StandardConditionsOfSale = (props) => {
  return (
    <div className="p-1">
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">1. Definitions</h2>
        <p className="mb-1">In these terms and conditions:</p>
        <p className="mb-1">
          <strong>“Australian Consumer Law”</strong> means Schedule 2 of the
          Competition and Consumer Act 2010 (Cth) and any other equivalent
          legislation (such as the Fair Trading Acts (or equivalent legislation)
          in each State and Territory in Australia);
        </p>
        <p className="mb-1">
          <strong>“Consequential Loss”</strong> means any loss or damage
          suffered by a party or any other person which is indirect or
          consequential, or which relates to loss of revenue, loss of income,
          loss of business, loss of profits, loss of goodwill or credit, loss of
          business reputation, future reputation or publicity, loss of use, loss
          of interest, damage to credit rating, or loss or denial of
          opportunity;
        </p>
        <p className="mb-1">
          <strong>“Contract”</strong> means the contract for the purchase of
          Goods consisting of these conditions of sale, any Trading Account
          Application Form submitted by you and accepted by us (if applicable),
          any Trade Card Form submitted by you and accepted by us (if
          applicable) and the accepted Orders for Goods placed under this
          Contract (as applicable);
        </p>
        <p className="mb-1">
          <strong>“Defect”</strong> means a defect, flaw or imperfection in the
          Goods which prevents the Goods from being used for the purposes
          intended for such Goods or which makes the use of the Goods dangerous,
          but does not include anything which has been disclosed by us as a
          feature or limitation of the Goods prior to the date of purchase, or
          any defect, flaw or imperfection that is trivial or insubstantial;
        </p>
        <p className="mb-1">
          <strong>“Goods”</strong> means all products and services agreed to be
          supplied by us to you from time to time under this agreement;
        </p>
        <p className="mb-1">
          <strong>“GST”</strong> means any goods and services tax and any
          replacement or similar tax;
        </p>
        <p className="mb-1">
          <strong>“GST Law”</strong> meansA New Tax System (Goods and Services
          Tax) Act 1999 (Cth);
        </p>
        <p className="mb-1">
          <strong>“Invoice”</strong> means, if applicable, the invoice issued by
          us to you setting out the amount to be paid by you for the Goods
          purchased under this Contract;
        </p>
        <p className="mb-1">
          <strong>“Order”</strong> means any order for Goods placed by you with
          us, in whatever form;
        </p>
        <p className="mb-1">
          <strong>“you”</strong> means the customer for Goods purchased under
          this Contract; and
        </p>
        <p className="mb-1">
          <strong>“we”</strong> or <strong>“us”</strong> means the DuluxGroup
          entity which is specified on the Invoice issued to you in relation to
          the Goods purchased under this Contract (or otherwise, DuluxGroup
          (Australia) Pty Ltd).
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">2. General</h2>
        <p className="mb-1">
          (a) To the maximum extent permitted by law and subject to sub-clause
          2(b), this Contract:
        </p>
        <ol className="mb-1 pl-3" style={{ listStyle: 'decimal' }}>
          <li className="mb-1">
            is in addition to any specific terms contained on our app and/or
            website relating to the supply of the Goods;
          </li>
          <li className="mb-1">
            otherwise supersedes and excludes all prior and other discussions,
            representations (contractual or otherwise) and arrangements relating
            to the supply of the Goods including, but not limited to, those
            relating to the performance of the Goods or the results that ought
            to be expected from using the Goods; and
          </li>
          <li className="mb-1">
            overrides any quotes, invoices, accepted or modified Orders and your
            terms and conditions of purchase (if any), exchanged between the
            parties whether or not such documents expressly provide that they
            override this Contract.
          </li>
        </ol>
        <p className="mb-1">
          (b) If there is an inconsistency between any other terms agreed by you
          and us in writing (including those documents referred to in clause
          2(a)(iii)) and/or this Contract, this Contract will prevail to the
          extent of any inconsistency.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">3. Orders</h2>
        <p className="mb-1">(a) Orders are subject to product availability.</p>
        <p className="mb-1">
          (b) We may accept or reject any Order or any variation or modification
          of an Order requested by you in our sole discretion. Once accepted by
          us, this Contract will apply to the Goods the subject of the Order.
        </p>
        <p className="mb-1">
          (c) We may accept or reject any Order cancellation request by you in
          our sole discretion. If a cancellation request is accepted by us, you
          will be liable for any direct loss or expense incurred by us in
          respect of that Order.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">4. Liability</h2>
        <p className="mb-1">
          (a) To the maximum extent permitted by law and subject to clause 4(b):
        </p>
        <ol className="mb-1 pl-3" style={{ listStyle: 'decimal' }}>
          <li className="mb-1">
            we exclude all liability for any loss, however caused (including by
            our negligence), suffered or incurred by you in connection with a
            breach of this Contract or the supply of the Goods to you in excess
            of the total value of the Order which is the subject of the claim,
            whether or not we were aware of the possibility of such loss to you
            when this Contract was entered into;
          </li>
          <li className="mb-1">
            other than as set out in this Contract, we exclude all conditions,
            representations, warranties and guarantees, whether express or
            implied, by contract, trade or otherwise; and
          </li>
          <li className="mb-1">
            notwithstanding any other clause of this Contract, we exclude all
            liability whatsoever to you for any Consequential Loss relating in
            any way to the supply of Goods to you.
          </li>
        </ol>
        <p className="mb-1">
          If the Australian Consumer Law confers certain rights, guarantees or
          remedies on you which are not able to be excluded, restricted or
          modified except in limited circumstances, our liability for breach of
          any such right, guarantee or remedy is limited to us:
        </p>
        <ol className="mb-1 pl-3" style={{ listStyle: 'decimal' }}>
          <li className="mb-1">
            replacing the Goods or supplying equivalent goods;
          </li>
          <li className="mb-1">repairing the Goods; </li>
          <li className="mb-1">
            paying the cost of replacing the Goods or of acquiring equivalent
            goods; or
          </li>
          <li className="mb-1">
            paying the cost of having the Goods repaired.
          </li>
        </ol>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">5. Advice</h2>
        <p className="mb-1">
          Except for any guarantees, rights or remedies which cannot be excluded
          or modified under the Australian Consumer Law, you hereby acknowledge
          that you have not relied on any service involving skill or judgment,
          or on any advice, recommendation, information or assistance provided
          by us in relation to the Goods or their use or application.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">6. Delivery</h2>
        <p className="mb-1">
          Where you have opted to have Goods delivered to you, we will make all
          reasonable efforts to have the Goods delivered to you by the estimated
          delivery date. However, time is not of the essence under this Contract
          and, except where we have an obligation under the Australian Consumer
          Law, we shall not be liable for any failure to deliver or delay in
          delivery for any reason. Alternatively, you may opt to collect Goods
          from such location as specified by us where we have indicated that
          this is an option available to you.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">7. Defects</h2>
        <p className="mb-1">
          (a) You must examine the Goods for Defects upon delivery or collection
          (as applicable) and notify us of any Defects in writing within 30 days
          of delivery or collection (as applicable). Except where the Australian
          Consumer Law requires, if you do not notify us of any Defects within
          30 days of delivery/collection you shall be deemed to have accepted
          the Goods.
        </p>
        <p className="mb-1">
          (b) You must preserve any Goods that are found to have a Defect in the
          state in which they were delivered or collected and allow us (or our
          nominated agent) access to your premises to inspect the Goods. If we,
          upon inspection, agree with you that such Goods have a Defect, the
          remedies set out in clause 4(b) will apply.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">8. Risk and title</h2>
        <p className="mb-1">
          Unless otherwise agreed in writing, all risk and title in and to the
          Goods purchased shall pass to you when the Goods are delivered to the
          address specified in your Order or upon your collection of the Goods
          from the location specified by us (as applicable).
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">9. Price</h2>
        <p className="mb-1">
          The price charged for the Goods will be the price listed on our online
          store app at the time of purchase.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">10. Resale of goods</h2>
        <p className="mb-1">
          If you resell Goods or services, then you and your contractors must:
        </p>
        <p className="mb-1">
          (a) provide to the purchaser any instructions or information about the
          use or care of the Goods or services, information about repairs and
          any express warranties intended for the purchaser which are provided
          by us to you for the purpose of being provided to the purchaser;
        </p>
        <p className="mb-1">
          (b) not make any statement or representation about the goods or
          services which is not consistent with information provided by us or
          make any representation on behalf of us;
        </p>
        <p className="mb-1">
          (c) not make any misleading or deceptive statements about the Goods,
          services or us or which disparages them or us;
        </p>
        <p className="mb-1">
          (d) if you or your contractors also provide related installation or
          other services, perform those services in accordance with any
          instructions and guidelines provided by us;
        </p>
        <p className="mb-1">
          (e) promptly notify us of any claims that the Goods or services do not
          comply with the mandatory guarantees under the Australian Consumer Law
          or which involve safety issues or serious injury or death, and then
          co-operate with us in relation to the handling of those claims;
        </p>
        <p className="mb-1">
          (f)comply with your obligations under the Australian Consumer Law in
          relation to mandatory guarantees; and
        </p>
        <p className="mb-1">
          (g) not tamper with, alter, obscure or remove any trade marks which
          appear on the Goods or alter the labels or packaging in which they are
          intended to be offered to purchasers.
        </p>
        <p className="mb-1">
          You indemnify us against all liability, loss, costs, damages or
          expense (including legal costs and expenses) incurred or suffered by
          us on a full compensation basis to the extent that it arises from a
          breach of this clause 10 by you or your contractors.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">11. Force majeure</h2>
        <p className="mb-1">
          (a) We are not liable for any failure to perform any of our
          obligations under this Contract as a result of any event beyond our
          reasonable control including, without limitation, where we are
          prevented or hindered from manufacturing, delivering or supplying the
          Goods as a result of any strikes, lockouts or other labour difficulty,
          inability to obtain any necessary materials, products, equipment,
          facilities or services from usual suppliers on usual terms, power or
          water shortage, accidents or breakdowns of plant, machinery, software,
          hardware or communication network. In such circumstances, we may
          suspend performance of any obligations under this Contract while the
          event continues. We shall not incur any liability to you in respect of
          such suspension.
        </p>
        <p className="mb-1">
          (b) If any of the above events occur for more than 30 days, we may,
          without liability, terminate any affected Order and/or this Contract
          immediately by notice in writing to you.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">12. Release</h2>
        <p className="mb-1">
          Except where legislation which cannot be excluded (such as the
          Australian Consumer Law) would make this clause 12 illegal, or where
          the inclusion of this clause 12 would otherwise make us liable to a
          penalty, you release us from any claim that is made against us for
          damages or otherwise in respect of any loss, damage, death or injury
          arising from negligence or otherwise caused directly or indirectly by
          or arising out of the use or condition of Goods sold to you, except to
          the extent that such loss, damage, death or injury has been caused by
          us.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">13. Severance</h2>
        <p className="mb-1">
          If any provision of this Contract or its application to any person or
          circumstance is or becomes invalid, illegal or unenforceable the
          provision shall, so far as possible, be read down to such extent as
          may be necessary to ensure that it is not invalid, illegal or
          unenforceable. If any provision or part of it cannot be so read down
          the provision or part of it shall be deemed to be void and severable
          and the remaining provisions of this Contract shall not in any way be
          affected or impaired.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          14. Variation and assignment
        </h2>
        <p className="mb-1">
          These terms may be varied and updated by us from time to time. When
          you offer to purchase Goods from us, you are offering to acquire them
          on the most up-to-date version of these terms as notified to you by
          us. You may not assign your rights under this Contract without our
          prior written consent.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">15. Governing law</h2>
        <p className="mb-1">
          This Contract is governed by the law of the State of Victoria. You and
          we submit to the non-exclusive jurisdiction of the courts of the State
          of Victoria, Australia.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          16. GST and other taxes and duties
        </h2>
        <p className="mb-1">
          Notwithstanding any other clause in this Contract, to the extent that
          any supply made under or in connection with this Contract is a taxable
          supply (as defined by the GST Law), you must pay to us, in addition to
          the consideration provided for under this Contract for that supply
          (unless it expressly includes GST) an amount (additional amount) equal
          to the amount of that consideration (or its GST exclusive market
          value) multiplied by the rate at which GST is imposed in respect of
          the supply. You are responsible for paying any other duties, taxes or
          charges, including any stamp duty (if applicable), in relation to the
          Goods.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">17. Waiver</h2>
        <p className="mb-1">
          Waiver by us of a breach of this Contractor of any right or power
          arising on a breach of this Contract must be in writing and signed by
          us. A right or power created or arising on a breach of this Contract
          is not waived by any failure to exercise or delay in exercising, or a
          partial exercise of, that or any other right or power.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">18. No right to offset</h2>
        <p className="mb-1">
          No amount owing, whether present or future, actual, contingent or
          prospective and on any account whatsoever by you to us may be offset
          against any amount owing whether present, future, actual, contingent
          or prospective of you to us hereunder or on any other account
          whatsoever.
        </p>
      </div>
      <div>
        <h2 className="font-heading text-h3 mb-1">19. Intellectual property</h2>
        <p className="mb-1">
          The purchase of Goods under this Contract does not confer on you any
          licence or assignment of any copyright, patent, design or trade mark,
          or any other intellectual property right (whether registered,
          registrable or not) that subsists in the Goods.
        </p>
      </div>
    </div>
  );
};

export default StandardConditionsOfSale;
