import {
  CatalogActionType,
  CatalogActionTypes,
  CatalogState,
  CatalogTabType,
} from './types';
import { UserActionType, UserActionTypes } from '../user/types';

const initialState: CatalogState = {
  activeTab: CatalogTabType.PAINT,
};

const catalog = (
  state: CatalogState = initialState,
  action: CatalogActionTypes | UserActionTypes
): CatalogState => {
  switch (action.type) {
    case CatalogActionType.APPLY_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };

    case CatalogActionType.CLEAR_ACTIVE_TAB:
      return {
        ...state,
        activeTab: undefined,
      };

    case UserActionType.LOGOUT:
    default:
      return initialState;
  }
};

export default catalog;
