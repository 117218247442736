import { Action } from 'redux';
import { Store } from '../../../types';

// !IMPORTANT! The index signature parameter type in StoreMap below
// cannot be a type alias, make sure you update it if you change the ProductId
export interface StoreMap {
  [key: string]: Store;
}

export interface StoresState {
  byId: StoreMap;
}

export enum StoresActionType {
  RECEIVE = 'STORES_RECEIVE',
}

export interface ReceiveStoresAction extends Action {
  type: StoresActionType.RECEIVE;
  payload: StoresState;
}

export type StoresActionTypes = ReceiveStoresAction;
