import React from 'react';
import PropTypes from 'prop-types';

const CartTotals = (props) => {
  function renderTotals() {
    return (
      <div className="flex flex-col px-1 py-3 w-full">
        <div className="pb-2 text-small font-heading font-bold text-h4 text-dulux-dark">
          Total amount payable
        </div>
        <div className="flex py-1">
          <div className="w-5/12 flex justify-first">
            <span className="text-small">Ex GST</span>
          </div>
          <div className="w-7/12 flex justify-end font-heading text-h3 text-dulux-dark">
            {props.subtotal}
          </div>
        </div>
        <div className="flex items-center py-1">
          <div className="w-5/12 flex justify-first">
            <span className="text-small">GST</span>
          </div>
          <div className="w-7/12 flex justify-end font-heading text-h3 text-dulux-dark">
            {props.tax}
          </div>
        </div>
        <div className="flex items-center pt-1">
          <div className="w-5/12 flex justify-first">
            <span className="text-small">Inc GST</span>
          </div>
          <div className="w-7/12 flex justify-end font-heading text-h2 text-dulux-dark">
            {props.total}
          </div>
        </div>
      </div>
    );
  }

  function renderError() {
    return <h2>Order total unavailable</h2>;
  }

  function render() {
    if (props.subtotal && props.tax && props.total) {
      return renderTotals();
    }

    return renderError();
  }

  return <>{render()}</>;
};

CartTotals.propTypes = {
  subtotal: PropTypes.string,
  tax: PropTypes.string,
  total: PropTypes.string,
};

CartTotals.defaultProps = {
  subtotal: '',
  tax: '',
  total: '',
};

export default CartTotals;
