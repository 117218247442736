import React from 'react';
import PropTypes from 'prop-types';

const SettingsUserDetailsItem = (props) => {
  return (
    <div className={'bg-white ' + (props.noBorder ? '' : 'border-b')}>
      <div className="p-1 flex flex-col">
        {props.label && (
          <div className="flex justify-start items-center text-gray-500">
            {props.label}
          </div>
        )}
        {props.value && (
          <div className="flex justify-start items-center text-dulux-dark text-h2 font-heading">
            {props.value}
          </div>
        )}
      </div>
    </div>
  );
};

SettingsUserDetailsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  noBorder: PropTypes.bool,
};

SettingsUserDetailsItem.defaultProps = {
  label: '',
  value: '',
  noBorder: false,
};

export default SettingsUserDetailsItem;
