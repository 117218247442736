/**
 * User
 */

export interface DuluxRep {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  hours: WeeklyOpenHours;
}

export enum UserStatus {
  REGISTRATION_REQUIRED = 'registration_required',
  VERIFICATION_REQUIRED = 'verification_required',
  APPROVED = 'approved',
  DENIED = 'denied',
}

/**
 * Order
 */

export enum PrimaryOrderStatus {
  RECEIVED = 'RECEIVED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum OrderStatus {
  RECEIVED = 'Received',
  OUT_FOR_DELIVERY = 'Out for Delivery',
  AWAITING_PICKUP_IN_STORE = 'Awaiting Pickup in Store',
  AWAITING_PICKUP_IN_LOCKER = 'Awaiting Pickup in Parcel Locker',
  DELIVERED = 'Delivered',
  PICKED_UP = 'Picked Up',
  CANCELLED = 'Cancelled',
}

/**
 * Product
 */

export type ProductId = string;

export interface Product {
  productId: ProductId;
  fullName: string;
  range: string;
  name: string;
  sheen: string | null;
  base: string | null;
  size: string | null;

  sheenImage?: string;
  sheenColour?: string;

  colour?: Colour;
  pretinted?: boolean;
  pretintedColour?: string;
  interior?: boolean;
  exterior?: boolean;
  prep?: boolean;
}

/**
 * Colour
 */

export type ColourId = string;
export type ColourStrength = number | 0.25 | 0.5 | 0.75 | 1;

export interface Colour {
  colourId: ColourId;
  baseName: string;
  colourName: string;
  hex: string;
  productBrand?: string;
  colourBrand?: string;
  atlasCode?: string;
  isInterior?: boolean;
  isExterior?: boolean;
  strengths?: boolean;
}

/**
 * Fulfillment
 */

export enum DeliveryMethodType {
  PRIORITY_PICKUP = 'priority-pickup',
  PRIORITY_DELIVERY = 'priority-delivery',

  REGULAR_PICKUP = 'regular-pickup',
  REGULAR_DELIVERY = 'regular-delivery',

  SCHEDULED_PICKUP = 'scheduled-pickup',
  SCHEDULED_DELIVERY = 'scheduled-delivery',

  // Unused
  AFTER_HOURS_PICKUP = 'after-hours-pickup',
}

/**
 * Store
 */

export type StoreId = string;

export interface Store {
  id: string; // dtc-preston
  type: string | 'dulux' | 'inspirations';
  label: string; // Dulux Trade Centre Preston
  title: string; // 148-150 Bell St, Preston
  area: string; // Preston
  shortTitle: string; // 148-150 Bell St
  backgroundColour: string; // #CACACA
  address: string; // 148-150 Bell St, Preston VIC 3072, Australia
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  contact: {
    email: string;
    phoneNumber: string;
  };
  notifications: {
    [event: string]: {
      phoneNumber?: string;
    };
  };
  fulfillmentOptions: {
    [key in DeliveryMethodType]?: {
      enabled?: boolean;
    };
  };
}

/**
 * Util
 */

export interface TimeOfDay {
  hour: number;
  minute?: number;
}

export interface OpenHours {
  open: number | TimeOfDay;
  close: number | TimeOfDay;
}

export interface WeeklyOpenHours {
  default: OpenHours;

  monday?: OpenHours;
  tuesday?: OpenHours;
  wednesday?: OpenHours;
  thursday?: OpenHours;
  friday?: OpenHours;
  saturday?: OpenHours;
  sunday?: OpenHours;
}
