import { Action } from 'redux';
import {
  Colour,
  ColourId,
  ColourStrength,
  Product,
  ProductId,
} from '../../../types';

// !IMPORTANT! The index signature parameter type in QuantityMap below
// cannot be a type alias, make sure you update it if you change this
export type CartItemId = number;

export interface CartItem {
  cartItemId: CartItemId;
  productId: ProductId;
  colourId: ColourId | null;
  colourStrength: ColourStrength | null;
  quantity: number;
}

export interface EnrichedCartItem extends CartItem {
  product: Product;
  colour?: Colour;
}

export interface CartState {
  items: CartItem[];
  nextItemId: CartItemId;
  pricesCalculated?: boolean;
}

export enum CartActionType {
  ADD = 'CART_ADD',
  REMOVE = 'CART_REMOVE',
  CLEAR = 'CART_CLEAR',
  UPDATE_ITEM = 'CART_ITEM_UPDATE',
  PRICES_CALCULATED = 'CART_PRICES_CALCULATED',
}

export interface AddToCartAction extends Action {
  type: CartActionType.ADD;
  payload: {
    productId: ProductId;
    colourId: ColourId | null;
    colourStrength: ColourStrength | null;
    quantity?: number;
    cartItemId?: CartItemId;
  };
}

export interface UpdateCartItemAction extends Action {
  type: CartActionType.UPDATE_ITEM;
  payload: {
    productId: ProductId;
    colourId: ColourId | null;
    colourStrength: ColourStrength | null;
    quantity: number;
    cartItemId: CartItemId;
  };
}

export interface RemoveFromCartAction extends Action {
  type: CartActionType.REMOVE;
  payload: {
    cartItemId: CartItemId;
  };
}

export interface CartPricesCalculatedAction extends Action {
  type: CartActionType.PRICES_CALCULATED;
  payload: {
    pricesCalculated: boolean;
  };
}

export interface ClearCartAction extends Action {
  type: CartActionType.CLEAR;
}

export type CartActionTypes =
  | AddToCartAction
  | RemoveFromCartAction
  | UpdateCartItemAction
  | CartPricesCalculatedAction
  | ClearCartAction;
