import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import GoogleMapSearchItem from './GoogleMapSearchItem';
import { IonInput } from '@ionic/react';
import { ReactComponent as SearchIcon } from '../../assets/icons/32/search.svg';
import Address from '../../models/Address';
import FooterCapContainer from '../ui/FooterCapContainer';

const GoogleMapsSearch = (props) => {
  const maps = window.google && window.google.maps;
  const inputInactiveClasses =
    'bg-dulux-primary text-dulux-white h-7 rounded-xl text-body-large ion-padding-left-override';
  const inputActiveClasses =
    'text-dulux-dark placeholder-dulux-dark text-body-large h-4';

  const [inputFocused, setInputFocused] = useState(false);
  const [address, setAddress] = useState('');

  const searchOptions = {
    // Location biasing
    location: new maps.LatLng(-37.8136, 144.9631), // TODO: Use the users geo-location
    radius: 50 * 1000, // 50km radius
    // Restrict to addresses in AU and NAz only
    types: ['address'],
    componentRestrictions: { country: ['au', 'nz'] },
  };

  function getSearchInputClasses() {
    return inputFocused ? inputActiveClasses : inputInactiveClasses;
  }

  function handleFocus(e) {
    setInputFocused(true);
  }

  function handleBlur(e) {
    setInputFocused(false);
  }

  function handleChange(addressDescription) {
    setAddress(addressDescription);
  }

  function handleSelect(addressDescription, placeId) {
    props.onSelect(new Address(addressDescription));

    // geocodeByPlaceId(placeId)
    //   .then((results) => results[0])
    //   .then((result) => props.onSelect(result))
    //   .catch((error) => console.error('Error', error));

    setAddress('');
  }

  function renderRecentLocations() {
    if (props.recentDeliveryAddresses) {
      return (
        <>
          <div className="px-1 py-2 bg-gray-100 text-gray-500 border-b border-t border-gray-300 flex justify-center items-center">
            Recent addresses
          </div>
          {props.recentDeliveryAddresses.map((recentAddress) => {
            return (
              <GoogleMapSearchItem
                key={recentAddress.latLon}
                description={recentAddress.formatted_address}
                label={recentAddress.formatted_address}
                dateOrdered={recentAddress.dateOrdered}
                onClick={
                  () =>
                    props.onSelect(new Address(recentAddress.formatted_address))
                  // geocodeByAddress(recentAddress.formatted_address)
                  //   .then((results) => results[0])
                  //   .then((result) => props.onSelect(result))
                }
              />
            );
          })}
        </>
      );
    }
  }

  return (
    <PlacesAutocomplete
      ref={(refObj) => {
        // Override the handleInputOnBlur function so that it doesn't clear the suggestions
        if (refObj) refObj.handleInputOnBlur = () => null;
      }}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={300}
      searchOptions={searchOptions}
      shouldFetchSuggestions={props.active}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps({
          placeholder: 'Find an address',
          className:
            'search-input text-h3 text-dulux-dark placeholder-dulux-dark leading-h2 font-heading border-b',
        });

        return (
          <div className="h-full bg-dulux-bg flex flex-col">
            <div className="h-full overflow-scroll">
              {loading && (
                <div
                  className="flex justify-center items-center h-full"
                  key="suggestions-loading"
                >
                  <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                    Loading...
                  </h2>
                </div>
              )}
              {suggestions.length >= 1 &&
                suggestions.map((suggestion) => {
                  const label =
                    `${suggestion.formattedSuggestion.mainText}, ` +
                    `${suggestion.formattedSuggestion.secondaryText}`;

                  return (
                    <GoogleMapSearchItem
                      key={suggestion.id}
                      description={suggestion.description}
                      itemProps={getSuggestionItemProps(suggestion)}
                      label={label}
                    />
                  );
                })}
              {suggestions.length === 0 && renderRecentLocations()}
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <FooterCapContainer rounded={!inputFocused}>
                <IonInput
                  {...inputProps}
                  onIonFocus={(e) => handleFocus(e)}
                  onIonBlur={(e) => handleBlur(e)}
                  onIonChange={inputProps.onChange}
                  className={getSearchInputClasses()}
                />
                {!inputFocused && (
                  <span
                    className="absolute w-5 z-10 pointer-events-none"
                    style={{
                      top: '50%',
                      left: '1.5rem',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <SearchIcon className="icon-dulux-white pointer-events-none" />
                  </span>
                )}
              </FooterCapContainer>
            </form>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

GoogleMapsSearch.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  recentDeliveryAddresses: PropTypes.array,
};

GoogleMapsSearch.defaultProps = {
  active: true,
  recentDeliveryAddresses: [],
};

export default GoogleMapsSearch;
