import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import Button from '../components/ui/Button.jsx';
import RandomColour from '../containers/dev/RandomColour';
import DevTools from '../containers/dev/DevTools';
import SplitButtons from '../components/ui/SplitButtons';
import Search from '../components/ui/Search';
import HeaderTitle from '../components/header/HeaderTitle';
import HeaderSubTitle from '../components/header/HeaderSubTitle';
import HeaderBack from '../components/header/HeaderBack';
import OrderLocation from '../components/order/OrderLocation.jsx';
import OrderStatus from '../components/order/OrderStatus.jsx';
import OrderFeedback from '../components/order/OrderFeedback.jsx';
import TimeSelect from '../components/ui/TimeSelect';
import HomeSummary from '../components/home/HomeSummary.jsx';
import Estimate from '../services/fulfillment/estimate';
import { ReactComponent as DuluxLogo } from '../assets/images/brand/dulux-logo.svg';
import { DeliveryMethodType } from '../types';

const Components = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [feedbackValue, setFeedbackValue] = useState('');

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onRatingClick = (value) => {
    setFeedbackValue(value);
  };

  useEffect(() => {
    console.log(feedbackValue);
  }, [feedbackValue]);

  // const mapDeliveryTimes = () => {
  //   return deliveryTimes(new Date(), DeliveryMethodType.PRIORITY_DELIVERY, 2)
  //     .flat()
  //     .map(item => {
  //       return {
  //         value: item,
  //         text: format(item, 'EEEE h:mma')
  //       };
  //     });
  // };

  return (
    <IonPage>
      <IonHeader>
        <HeaderBack title="Confirm address" description="22 Rogers Cres" />
        <HeaderTitle title="Components" borderColour="#e2e8f0" />
        <HeaderSubTitle title="Sub Title" backgroundColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        <HomeSummary
          backgroundColour="#F9D464"
          temperature="32"
          humidity="18"
          colourLogo={<DuluxLogo style={{ width: '60px', height: '30px' }} />}
          colourName="Sunbound"
          firstName="David"
          // settingsOnClick={}
          // supportOnClick={}
        />
        <OrderFeedback onClick={(value) => onRatingClick(value)} />
        <OrderStatus
          orderNumber="#235245"
          method="Priority delivery"
          time="Arrives by 10:00am today"
          status="Preparing your order"
          isButtonEnabled={true}
        />
        <TimeSelect
          options={new Estimate()
            .deliveryTimes(DeliveryMethodType.REGULAR_DELIVERY, 7)
            .flat()}
        />
        {/*<Select*/}
        {/*  title="Choose delivery time"*/}
        {/*  options={mapDeliveryTimes()}*/}
        {/*  onClick={value => console.log(value)}*/}
        {/*/>*/}
        <div className="h-full flex flex-col">
          <div className="h-full overflow-scroll">
            <OrderLocation
              image="https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              label="Current Location"
              title="40 Rose St, Fitzroy North"
            />
            <OrderLocation
              image="https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              label="Home"
              title="90 Nicholson St, Fitzroy"
              duration="2d"
            />
            <OrderLocation
              image="https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              label="Preferred"
              title="DTC Preston"
              duration="20m Drive"
              backgroundColour="#002C65"
            />
            <OrderLocation
              image="https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              title="DTC Doncaster"
              duration="30m Drive"
              backgroundColour="#F2CB0A"
            />
          </div>
          <div className="mt-auto">
            <Search />
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol>
              <Search onChange={(e) => onSearchChange(e)} />
              <div className="mt-1 font-heading">
                {searchTerm && 'Search term: '}
                {searchTerm && <strong>{searchTerm}</strong>}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SplitButtons>
                <Button>Add Item</Button>
                <Button theme="success">Checkout</Button>
              </SplitButtons>
            </IonCol>
          </IonRow>
          <RandomColour />
          <RandomColour />
          <RandomColour />
          <RandomColour />
          <IonRow>
            <IonCol>
              <DevTools />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Button>Next</Button>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Button theme="success">Confirm Order</Button>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Button theme="error">Cancel Order</Button>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Button theme="light">About Dulux</Button>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Button theme="ghost">See More Products</Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Components;
