import qs from 'qs';

export function getStreetViewImageUrl(
  address: string,
  width = 400,
  height = 400
) {
  if (!address) {
    return '';
  }

  const parameters = {
    size: `${width}x${height}`,
    location: address,
    key: 'AIzaSyDcDzPvvOb0L1xLGzzjFVjN6pgiUMwZE4g',
  };

  return `https://maps.googleapis.com/maps/api/streetview?${qs.stringify(
    parameters
  )}`;
}
