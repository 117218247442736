import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-scrollable-picker';
import { getHours, isSameDay, format, formatISO } from 'date-fns';
import { uniqBy } from 'lodash-es';
import Button from './Button';
import FooterCapContainer from './FooterCapContainer';
import { DeliveryMethodType } from '../../types';

function getDeliveryTimesForDay(times, selection) {
  return times
    .filter((time) => isSameDay(time.delivery, selection.delivery))
    .map((time) => {
      // If the delivery method is regular delivery, then label with
      // "morning/afternoon" otherwise use the actual time.
      const label =
        time.method === DeliveryMethodType.REGULAR_DELIVERY
          ? getHours(time.delivery) > 12
            ? 'Afternoon'
            : 'Morning'
          : format(time.delivery, 'p');

      return { value: time, label };
    });
}

function getDeliveryOptions(deliveryMethod, times, selectedDay = null) {
  const uniqueDays = uniqBy(times, (time) => {
    return formatISO(time.delivery, { representation: 'date' });
  });

  const day = uniqueDays.map((time) => ({
    value: time,
    label: format(time.delivery, 'EEE d LLL'),
  }));

  const selection = selectedDay || day[0].value;

  const time = getDeliveryTimesForDay(times, selection);

  return {
    day,
    time,
  };
}

class TimeSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      method: props.method,
      times: props.options,
      valueGroups: {
        day: props.options[0],
        time: props.options[0],
      },
      optionGroups: getDeliveryOptions(props.method, props.options, null),
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ times, valueGroups, method }) => {
      const options = getDeliveryOptions(method, times, value);

      return {
        valueGroups: {
          // Only remember the day if the time changes
          ...(name === 'time' && {
            ...valueGroups,
            time: value,
          }),
          ...(name === 'day' && {
            day: value,
            time: options.time[0].value,
          }),
        },
        // Set new options for the selected day
        optionGroups: options,
      };
    });
  };

  selectTime = () => {
    this.props.onClick(this.state.valueGroups.time);
  };

  render() {
    const { optionGroups, valueGroups } = this.state;

    return (
      <div className="h-full overflow-scroll flex flex-col">
        <div className="flex justify-center items-center h-full overflow-scroll">
          <Picker
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={this.handleChange}
          />
        </div>
        <FooterCapContainer className="mt-auto">
          <Button onClick={this.selectTime}>Confirm</Button>
        </FooterCapContainer>
      </div>
    );
  }
}

TimeSelect.propTypes = {
  method: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
};

TimeSelect.defaultProps = {
  options: [],
  onClick: null,
};

export default TimeSelect;
