import { find, pick } from 'lodash';
import {
  CartActionType,
  CartActionTypes,
  CartItem,
  CartItemId,
  CartState,
} from './types';
import { UserActionType, UserActionTypes } from '../user/types';

const initialState: CartState = {
  items: [],
  nextItemId: 0,
  pricesCalculated: false,
};

function findItem(
  items: CartItem[],
  parameters: Partial<CartItem>
): CartItem | undefined {
  return find(
    items,
    pick(parameters, ['productId', 'colourId', 'colourStrength'])
  );
}

function incrementItemQuantity(item: CartItem, quantity: number): CartItem {
  return {
    ...item,
    quantity: Number(item.quantity) + Number(quantity),
  };
}

const cart = (
  state: CartState = initialState,
  action: CartActionTypes | UserActionTypes
): CartState => {
  switch (action.type) {
    case CartActionType.ADD: {
      const foundItem = findItem(state.items, action.payload);

      // Just increment the quantity if we can find another item with the same properties
      if (foundItem) {
        const newItem = incrementItemQuantity(
          foundItem,
          action.payload.quantity || 1
        );

        return {
          ...state,
          items: state.items.map((item) =>
            item.cartItemId === foundItem.cartItemId ? newItem : item
          ),
        };
      }

      // Not found, add new item to the array
      return {
        items: [
          ...state.items,
          {
            ...action.payload,
            cartItemId: state.nextItemId,
            quantity: action.payload.quantity || 1,
            colourStrength: action.payload.colourStrength || 1,
          },
        ],
        nextItemId: state.nextItemId + 1,
        pricesCalculated: state.pricesCalculated,
      };
    }

    case CartActionType.UPDATE_ITEM: {
      // Using filter instead of find because we want the overall index of the
      // updating item
      const cartItemIndex = state.items.findIndex(
        (item) => item.cartItemId === action.payload.cartItemId
      );

      // Check to see if there is a matching item in the cart
      const foundExistingItem = findItem(state.items, action.payload);

      // If we can find another item with the same properties
      // which is not the current item being edited, combine quantities on
      // found item and remove the old item
      if (
        foundExistingItem &&
        action.payload.cartItemId !== foundExistingItem.cartItemId
      ) {
        const updatedExistingItem = incrementItemQuantity(
          foundExistingItem,
          action.payload.quantity || 1
        );

        const newItems = state.items.map((item) => {
          if (item.cartItemId === foundExistingItem.cartItemId) {
            return updatedExistingItem;
          }

          return item;
        });

        // Remove item which was being edited
        newItems.splice(cartItemIndex, 1);

        return {
          ...state,
          items: newItems,
        };
      }

      const cartItems = state.items;

      cartItems[cartItemIndex] = action.payload;

      return {
        ...state,
        items: cartItems,
      };
    }

    case CartActionType.PRICES_CALCULATED:
      return {
        ...state,
        pricesCalculated: action.payload.pricesCalculated,
      };

    case CartActionType.REMOVE:
      return {
        ...state,
        items: state.items.filter(
          (item) => item.cartItemId !== action.payload.cartItemId
        ),
      };

    case UserActionType.LOGOUT:
    case CartActionType.CLEAR:
      return initialState;

    default:
      return state;
  }
};

export const getCartItem = (state: CartState, id: CartItemId) =>
  state.items.find((item) => item.cartItemId === id);

export default cart;
