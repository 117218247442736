import { ReactComponent as BrushesIcon } from '../assets/icons/accessories/brushes.svg';
import { ReactComponent as BucketsAndTraysIcon } from '../assets/icons/accessories/buckets-and-trays.svg';
import { ReactComponent as PolesIcon } from '../assets/icons/accessories/poles.svg';
import { ReactComponent as RollersIcon } from '../assets/icons/accessories/rollers.svg';
import { ReactComponent as MiniRollersIcon } from '../assets/icons/accessories/mini-rollers.svg';
import { ReactComponent as ToolsIcon } from '../assets/icons/accessories/tools.svg';
import { ReactComponent as CleanersIcon } from '../assets/icons/accessories/cleaners.svg';
import { ReactComponent as FillersAndSealersIcon } from '../assets/icons/accessories/fillers-and-sealers.svg';
import { ReactComponent as DropClothIcon } from '../assets/icons/accessories/drop-cloth.svg';
import { ReactComponent as MaskingFilmIcon } from '../assets/icons/accessories/masking-film.svg';
import { ReactComponent as SafetyIcon } from '../assets/icons/accessories/safety.svg';
import { ReactComponent as SandingIcon } from '../assets/icons/accessories/sanding.svg';
import { ReactComponent as TapeIcon } from '../assets/icons/accessories/tape.svg';

const accessoryCategories = [
  {
    range: 'Application',
    name: 'Brushes',
    text: 'Brushes',
    icon: BrushesIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Application',
    name: 'Rollers',
    text: 'Rollers',
    icon: RollersIcon,
    iconClasses: 'w-7 h-7 mr-1 text-dulux-dark',
  },
  {
    range: 'Application',
    name: 'Mini rollers',
    text: 'Mini rollers',
    icon: MiniRollersIcon,
    iconClasses: 'w-7 h-7 mr-1 text-dulux-dark',
  },
  {
    range: 'Protection',
    name: 'Tapes',
    text: 'Tapes',
    icon: TapeIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Protection',
    name: 'Masking films',
    text: 'Masking films',
    icon: MaskingFilmIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Preperation',
    name: 'Fillers and sealants',
    text: 'Fillers and sealants',
    icon: FillersAndSealersIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Protection',
    name: 'Drop sheets',
    text: 'Drop sheets',
    icon: DropClothIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Application',
    name: 'Buckets and trays',
    text: 'Buckets and trays',
    icon: BucketsAndTraysIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Application',
    name: 'Frames and poles',
    text: 'Frames and poles',
    icon: PolesIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Preperation',
    name: 'Sanding',
    text: 'Sanding',
    icon: SandingIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Protection',
    name: 'Safety',
    text: 'Safety',
    icon: SafetyIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Preperation',
    name: 'Tools',
    text: 'Tools',
    icon: ToolsIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
  {
    range: 'Preperation',
    name: 'Cleaning',
    text: 'Cleaning',
    icon: CleanersIcon,
    iconClasses: 'w-7 h-7 text-dulux-dark',
  },
];

export default accessoryCategories;
