import { Action } from 'redux';
import { Product } from '../../../types';

// !IMPORTANT! The index signature parameter type in ProductMap below
// cannot be a type alias, make sure you update it if you change the ProductId
export interface ProductMap {
  [key: string]: Product;
}

export interface ProductState {
  byId: ProductMap;
}

export enum ProductActionType {
  RECEIVE = 'PRODUCTS_RECEIVE',
  RECEIVE_RAW = 'PRODUCTS_RECEIVE_RAW',
}

export enum ProductStep {
  SHEEN = 'sheen',
  COLOUR = 'colour',
  SIZE = 'size',
  QUANTITY = 'quantity',
  ADDED_TO_CART = 'added_to_cart',
}

export interface ReceiveProductsAction extends Action {
  type: ProductActionType.RECEIVE;
  payload: ProductState;
}

export interface ReceiveProductsCsvAction extends Action {
  type: ProductActionType.RECEIVE_RAW;
  payload: {
    products: any;
  };
}

export type ProductActionTypes =
  | ReceiveProductsAction
  | ReceiveProductsCsvAction;
