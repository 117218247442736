import { createSelector } from 'reselect';
import { getCartItem } from '../reducers';
import {
  selectCartItems,
  selectRootState,
  selectSelectedCartItemId,
} from './root';

export const selectCartItemCount = createSelector(
  [selectCartItems],
  (cartItems) => cartItems.length
);

export const selectCartPricesCalculated = createSelector(
  [selectRootState],
  (rootState) => rootState.cart.pricesCalculated
);

export const selectEditingCartItem = createSelector(
  [selectRootState, selectSelectedCartItemId],
  (rootState, selectedCartItemId) => {
    if (selectedCartItemId === undefined) {
      return null;
    }

    return getCartItem(rootState, selectedCartItemId);
  }
);
