import React from 'react';
import PropTypes from 'prop-types';
import { contrastingColour } from '../../utils/colour';

const HeaderTitle = (props) => {
  return (
    <div
      className="bg-dulux-white text-dulux-dark flex justify-start items-center top-0 left-0 w-full py-1 px-1 border-b"
      style={{
        color: contrastingColour(props.backgroundColour),
        backgroundColor: props.backgroundColour,
        borderColor: props.borderColour
          ? props.borderColour
          : props.backgroundColour,
      }}
    >
      <h1 className="text-h2 leading-h2 font-heading m-0">{props.title}</h1>
    </div>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string,
  backgroundColour: PropTypes.string,
  borderColour: PropTypes.string,
};

HeaderTitle.defaultProps = {
  title: '',
  backgroundColour: '#FFFFFF',
  borderColour: '',
};
export default HeaderTitle;
