import { useEffect, useState } from 'react';

const useInputValidation = (value: string, required: true) => {
  const [labelClass, setLabelClass] = useState('text-dulux-field-disabled');
  const [hasError, setHasError] = useState(false);
  const [borderClass, setBorderClass] = useState('border-dulux-mid');
  const [isDirty, setIsDirty] = useState(false);
  const marginBottomClass = !hasError ? 'mb-1 ' : '';

  function makeDirty() {
    if (!isDirty) {
      setIsDirty(true);
    }
  }

  function handleOnBlur() {
    if (value === '') {
      setLabelClass('text-dulux-field-disabled');
      return;
    }

    setLabelClass('text-inspirations-blue');
  }

  useEffect(() => {
    if (value === '' && isDirty) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [value, isDirty, setHasError]);

  useEffect(() => {
    if (required && hasError) {
      setBorderClass('border-dulux-red');
      return;
    }

    if (value === '') {
      setBorderClass('border-dulux-mid');
    } else {
      setBorderClass('border-dulux-blue');
    }
  }, [required, value, hasError, setBorderClass]);

  return {
    labelClass,
    setLabelClass,
    hasError,
    borderClass,
    marginBottomClass,
    makeDirty,
    handleOnBlur,
  };
};

export default useInputValidation;
