/* eslint-disable @typescript-eslint/camelcase */
import React, { useRef, useState } from 'react';
import { find } from 'lodash-es';
import { IonSelectOption } from '@ionic/react';
import { selectStores } from '../store/selectors';
import { useSelector } from 'react-redux';
import { UserType } from '../services/firebase/types';
import Button from '../components/ui/Button';
import InputTextGroup from '../components/form/InputTextGroup';
import InputSelectGroup from '../components/form/InputSelectGroup';
import { useFirebaseContext } from '../services/firebase/context';
import PrivacyPolicyModal from '../components/terms/PrivacyPolicyModal';
import { ReactComponent as TradeDirectLogo } from '../assets/images/brand/dulux-trade-direct.svg';

const Register = () => {
  const firebase = useFirebaseContext();
  const stores = useSelector(selectStores);
  const formRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [registerErrorMessages, setRegisterErrorMessages] = useState({});
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [customerType, setCustomerType] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [duluxAccountNumber, setDuluxAccountNumber] = useState('');
  const [preferredStore, setPreferredStore] = useState('');

  const customerTypeOptions = [
    {
      key: UserType.EXISTING_CUSTOMER,
      value: 'An existing Dulux account holder',
    },
    {
      key: UserType.NEW_CUSTOMER,
      value: 'Interested in setting up an account',
    },
    // {
    //   key: UserType.SUBCONTRACTOR,
    //   value: 'a subcontractor',
    // },
  ];

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  function handleScroll() {
    scrollToRef(formRef);
  }

  function enableSubmit() {
    return (
      email &&
      customerType &&
      firstname &&
      lastname &&
      address &&
      businessName &&
      preferredStore
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    setFormSubmitting(true);

    firebase
      .register({
        email,
        trade_direct_reg_customertype: customerType,
        trade_direct_reg_firstname: firstname,
        trade_direct_reg_lastname: lastname,
        trade_direct_reg_businessaddress: address,
        trade_direct_reg_businessname: businessName,
        trade_direct_reg_accountnumber: duluxAccountNumber,
        trade_direct_reg_preferredstore: preferredStore,
      })
      .catch((err) => {
        setFormSubmitting(false);

        if (err && err.details) {
          handleScroll();
          const errorDetails = err.details.error.details;
          // console.log(errorDetails);
          if (errorDetails) {
            const errorsMapped = errorDetails.map((error) => {
              return { [error.context.key]: error.message };
            });

            setRegisterErrorMessages(errorsMapped);
          }
        } else {
          console.log(err);
        }
      });
  }

  function renderStoreLabel(store) {
    return store.type === 'dulux' ? `DTC ${store.area}` : store.label;
  }

  function renderStoreOptions() {
    return stores.map((store) => {
      return (
        <IonSelectOption key={store.id} value={store.id}>
          {renderStoreLabel(store)}
        </IonSelectOption>
      );
    });
  }

  function renderOptions(options) {
    return options.map((option) => {
      return (
        <IonSelectOption key={option.key} value={option.key}>
          {option.value}
        </IonSelectOption>
      );
    });
  }

  function renderLabel(label, required = true) {
    if (label) {
      return required ? `${label}*` : label;
    }
  }

  function serverErrorMessage(inputName) {
    if (registerErrorMessages) {
      const error = find(
        registerErrorMessages,
        (message) => Object.keys(message)[0] === inputName
      );

      if (error) {
        return error[inputName];
      }
    }
  }

  return (
    <div className="bg-dulux-light-pressed h-full flex flex-col items-center overflow-y-scroll pt-9">
      <div className="pb-1 px-8 text-center">
        <TradeDirectLogo className="mx-auto my-0" />
        <h1 className="font-heading text-h2 pt-6 pb-2">Register now</h1>
        <p className="text-h3">
          First, we&rsquo;ll need some important information
        </p>
      </div>
      <form
        onSubmit={(e) => handleSubmit(e)}
        ref={formRef}
        className="w-full block px-2"
      >
        <InputSelectGroup
          name="trade_direct_reg_customertype"
          label={renderLabel('I am')}
          placeholder="Please select"
          value={customerType}
          onChange={setCustomerType}
          options={renderOptions(customerTypeOptions)}
          errorMessage="Please select a customer type"
          serverErrorMessage={serverErrorMessage}
        />

        <InputTextGroup
          name="trade_direct_reg_firstname"
          label={renderLabel('First name')}
          placeholder="David"
          value={firstname}
          onInput={setFirstname}
          errorMessage="Please enter your first name"
          serverErrorMessage={serverErrorMessage}
        />

        <InputTextGroup
          name="trade_direct_reg_lastname"
          label={renderLabel('Last name')}
          placeholder="Smith"
          value={lastname}
          onInput={setLastname}
          errorMessage="Please enter your last name"
          serverErrorMessage={serverErrorMessage}
        />

        <InputTextGroup
          name="email"
          type="email"
          inputMode="email"
          label={renderLabel('Email')}
          placeholder="Davidsmith@gmail.com"
          value={email}
          onInput={setEmail}
          errorMessage="Please enter a valid email address"
          serverErrorMessage={serverErrorMessage}
        />

        <InputTextGroup
          name="trade_direct_reg_businessaddress"
          label={renderLabel('Address')}
          placeholder="1 Smith St Collingwood 3066"
          value={address}
          onInput={setAddress}
          errorMessage="Please enter your home or business address"
          serverErrorMessage={serverErrorMessage}
        />

        <InputTextGroup
          name="trade_direct_reg_businessname"
          label={renderLabel('Business name')}
          placeholder="Fitzroy painting"
          value={businessName}
          onInput={setBusinessName}
          errorMessage="Please enter the name of your trade business"
          serverErrorMessage={serverErrorMessage}
        />

        {customerType === UserType.EXISTING_CUSTOMER && (
          <InputTextGroup
            required={false}
            name="trade_direct_reg_accountnumber"
            label={renderLabel('Dulux account number', false)}
            type="password"
            inputMode="number"
            placeholder="&#183;&#183;&#183;&#183;&#183;&#183;"
            value={duluxAccountNumber}
            onInput={setDuluxAccountNumber}
            errorMessage="Please enter your Dulux account number"
            serverErrorMessage={serverErrorMessage}
          />
        )}

        <InputSelectGroup
          name="trade_direct_reg_preferredstore"
          label={renderLabel('Preferred store')}
          placeholder="Please select"
          value={preferredStore}
          onChange={setPreferredStore}
          options={renderStoreOptions()}
          errorMessage="Please select your preferred store"
          serverErrorMessage={serverErrorMessage}
        />

        <div className="border px-2 py-3 mt-1 rounded bg-white text-center">
          <p>
            By registering you agree for us to use your information in
            accordance with our{' '}
            <span
              onClick={() => setShowModal(true)}
              className="text-dulux-primary"
            >
              privacy policy
            </span>
          </p>
        </div>

        <div className="my-2">
          <Button
            type="submit"
            title="Register"
            disabled={!enableSubmit() || formSubmitting}
          >
            {formSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </div>

        <PrivacyPolicyModal showModal={showModal} setShowModal={setShowModal} />
      </form>
    </div>
  );
};

export default Register;
