import React from 'react';
import PropTypes from 'prop-types';
import { IonToggle } from '@ionic/react';

const SettingsUserPreferencesItem = (props) => {
  return (
    <div className="flex justify-between items-center px-1 py-3">
      {props.label && (
        <h3 className="text-h3 font-heading text-dulux-dark">{props.label}</h3>
      )}
      <IonToggle checked={props.checked} onIonChange={props.onChange} />
    </div>
  );
};

SettingsUserPreferencesItem.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

SettingsUserPreferencesItem.defaultProps = {
  checked: false,
  label: '',
  onChange: null,
};

export default SettingsUserPreferencesItem;
