import { selectFeaturesState } from './root';
import { createSelector } from 'reselect';
import { map, orderBy } from 'lodash';

export const selectFeaturesByVoteCount = createSelector(
  [selectFeaturesState],
  (features) => {
    const mapped = map(features, (feature, key) => {
      return {
        id: key,
        value: feature,
      };
    });

    return orderBy(mapped, ['value', 'id'], ['desc', 'asc']);
  }
);
