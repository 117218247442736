import { createContext, useContext } from 'react';
import Push from './push';

export const PushContext = createContext({});

export const PushProvider = PushContext.Provider;

export const usePushContext = () => {
  return useContext(PushContext) as Push;
};
