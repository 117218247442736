import { combineReducers } from 'redux';
import cart, * as fromCart from './reducers/cart/reducer';
import catalog from './reducers/catalog/reducer';
import checkout from './reducers/checkout/reducer';
import colours, * as fromColours from './reducers/colours/reducer';
import features from './reducers/features/reducer';
import orders from './reducers/orders/reducer';
import pricing from './reducers/pricing/reducer';
import products, * as fromProducts from './reducers/products/reducer';
import selection from './reducers/selection/reducer';
import stores, * as fromStores from './reducers/stores/reducer';
import support from './reducers/support/reducer';
import user from './reducers/user/reducer';
import { CartItem, CartItemId } from './reducers/cart/types';
import { ColourId, ProductId, StoreId } from '../types';

const reducers = combineReducers({
  cart,
  catalog,
  checkout,
  colours,
  features,
  orders,
  pricing,
  products,
  selection,
  stores,
  support,
  user,
});

export type RootState = ReturnType<typeof reducers>;

export const getColour = (state: RootState, id: ColourId) =>
  fromColours.getColour(state.colours, id);

export const getProduct = (state: RootState, id: ProductId) =>
  fromProducts.getProduct(state.products, id);

export const getStore = (state: RootState, id: StoreId) =>
  fromStores.getStore(state.stores, id);

const enrichCartItem = (state: RootState, item: CartItem) => ({
  ...item,
  product: getProduct(state, item.productId),
  colour: item.colourId ? getColour(state, item.colourId) : undefined,
});

export const getCartItem = (state: RootState, id: CartItemId) => {
  const item = fromCart.getCartItem(state.cart, id);

  if (!item) {
    return null;
  }

  return enrichCartItem(state, item);
};

export const getCartItems = (state: RootState) =>
  state.cart.items.map((item) => enrichCartItem(state, item));

export default reducers;
