import { Thunk } from '../index';
import {
  AddToCartAction,
  CartActionType,
  CartItemId,
  CartPricesCalculatedAction,
  ClearCartAction,
  RemoveFromCartAction,
  UpdateCartItemAction,
} from '../reducers/cart/types';
import { get } from 'lodash';
import { ColourId, ColourStrength, ProductId } from '../../types';

const addToCartUnsafe = (
  productId: ProductId,
  colourId: ColourId | null,
  colourStrength: ColourStrength | null,
  quantity = 1,
  cartItemId: CartItemId
): AddToCartAction => ({
  type: CartActionType.ADD,
  payload: {
    productId,
    colourId,
    colourStrength,
    quantity,
    cartItemId,
  },
});

const updateCartItemUnsafe = (
  productId: ProductId,
  colourId: ColourId | null,
  colourStrength: ColourStrength | null,
  quantity = 1,
  cartItemId: CartItemId
): UpdateCartItemAction => ({
  type: CartActionType.UPDATE_ITEM,
  payload: {
    productId,
    colourId,
    colourStrength,
    quantity,
    cartItemId,
  },
});

export const addToCart = (
  productId: ProductId,
  colourId: ColourId | null,
  colourStrength: ColourStrength | null = 1,
  quantity = 1
): Thunk<void> => (dispatch, getState) => {
  if (
    productId in getState().products.byId &&
    (colourId === null || colourId in getState().colours.byId)
  ) {
    const cartItemId = get(getState(), 'selection.cartItemId');

    if (cartItemId === undefined) {
      dispatch(
        addToCartUnsafe(
          productId,
          colourId,
          colourStrength,
          quantity,
          cartItemId
        )
      );
    } else {
      dispatch(
        updateCartItemUnsafe(
          productId,
          colourId,
          colourStrength,
          quantity,
          cartItemId
        )
      );
    }
  }
};

const removeFromCartUnsafe = (
  cartItemId: CartItemId
): RemoveFromCartAction => ({
  type: CartActionType.REMOVE,
  payload: {
    cartItemId,
  },
});

export const removeFromCart = (cartItemId: CartItemId): Thunk<void> => (
  dispatch,
  getState
) => {
  dispatch(removeFromCartUnsafe(cartItemId));
};

export const removeEditingFromCart = (): Thunk<void> => (
  dispatch,
  getState
) => {
  const cartItemId = getState().selection.cartItemId;

  if (cartItemId !== undefined) {
    dispatch(removeFromCart(cartItemId));
  }
};

export const cartPricesCalculated = (
  pricesCalculated: boolean
): CartPricesCalculatedAction => ({
  type: CartActionType.PRICES_CALCULATED,
  payload: {
    pricesCalculated,
  },
});

export const clearCart = (): ClearCartAction => ({
  type: CartActionType.CLEAR,
});
