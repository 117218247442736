import * as firebase from 'firebase/app';

export default class FirebaseFunctions {
  readonly login: firebase.functions.HttpsCallable;
  readonly register: firebase.functions.HttpsCallable;
  readonly checkPrice: firebase.functions.HttpsCallable;
  readonly createOrder: firebase.functions.HttpsCallable;
  readonly createTicket: firebase.functions.HttpsCallable;

  // Deprecated
  readonly syncUser: firebase.functions.HttpsCallable;

  constructor() {
    this.login = this.callable('login');
    this.register = this.callable('register');
    this.checkPrice = this.callable('checkPrice');
    this.createOrder = this.callable('createOrder');
    this.createTicket = this.callable('createTicket');

    this.syncUser = this.callable('syncUser');
  }

  callable(name: string) {
    return firebase.functions().httpsCallable(name);
  }
}
