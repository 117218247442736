import React from 'react';
import { useSelector } from 'react-redux';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import {
  format,
  setHours,
  startOfHour,
  isBefore,
  isAfter,
  addDays,
} from 'date-fns';
import { selectDuluxRep } from '../../store/selectors';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import Button from '../../components/ui/Button';
import FooterCapContainer from '../../components/ui/FooterCapContainer';
import { ReactComponent as SuccessIcon } from '../../assets/icons/20/success.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/20/time.svg';
import { ReactComponent as DuluxRepLogo } from '../../assets/images/brand/dulux-rep-logo.svg';

const FallbackPage = () => (
  <IonPage>
    <IonHeader>
      <HeaderBack title="Back" description="Get help" />
      <HeaderTitle title="Contact Rep" borderColour="#e2e8f0" />
    </IonHeader>
    <IonContent>
      <div className="p-1 py-2 flex flex-col justify-center items-center">
        <p className="text-dulux-dark font-heading text-h3 text-center mt-2 px-4">
          You do not currently have an allocated rep.
        </p>
      </div>
    </IonContent>
  </IonPage>
);

const SupportRep = (props) => {
  const rep = useSelector(selectDuluxRep);

  if (!rep) {
    return <FallbackPage />;
  }

  const now = new Date();
  const dayOfWeek = format(now, 'cccc').toLowerCase();
  const hours = rep.hours[dayOfWeek] || rep.hours.default;

  console.log('day of week', dayOfWeek);

  // Get today's open/close hours
  const open = setHours(startOfHour(now), hours.open);
  const close = setHours(startOfHour(now), hours.close);

  console.log('open', open);
  console.log('close', close);

  const available = isAfter(now, open) && isBefore(now, close);

  let returnTime = 9;

  if (!available) {
    const dayOfWeekTomorrow = format(addDays(now, 1), 'cccc').toLowerCase();
    const hoursTomorrow = rep.hours[dayOfWeekTomorrow] || rep.hours.default;

    returnTime = hoursTomorrow.open;
  }

  function renderStatus() {
    if (available) {
      return (
        <div className="flex items-center">
          <div className="bg-dulux-success w-3 h-3 rounded-full mr-1 flex justify-center items-center">
            <SuccessIcon className="w-2 h-2 text-white" />
          </div>
          <p className="text-small text-dulux-dark">Available</p>
        </div>
      );
    }

    return (
      <>
        <div className="bg-dulux-success w-3 h-3 rounded-full mr-1 flex justify-center items-center">
          <TimeIcon className="w-2 h-2 text-dulux-dark" />
        </div>
        <span>Back at {returnTime}am</span>
      </>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderBack title="Back" description="Get help" />
        <HeaderTitle title="Contact Rep" borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        <div className="border-b p-1 py-2 flex flex-col justify-center items-center">
          <DuluxRepLogo className="mb-1 text-dulux-dark" />
          <h1 className="text-dulux-dark text-h1 font-heading">{`${rep.firstName} ${rep.lastName}`}</h1>
        </div>
        <div className="p-1 py-2 flex flex-col justify-center items-center">
          {renderStatus()}
          <p className="text-dulux-dark font-heading text-h3 text-center mt-2 px-4">
            We hope to resolve your query within 1hr.
          </p>
          <p className="text-dulux-dark text-small text-center mt-2 px-2">
            You may be put on hold while our representatives are servicing other
            requests.
          </p>
        </div>
      </IonContent>
      <FooterCapContainer>
        <Button url={`tel:${rep.phoneNumber}`}>Call Support</Button>
      </FooterCapContainer>
    </IonPage>
  );
};

export default SupportRep;
