import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/64/delivery.svg';
import { ReactComponent as PriorityPickupIcon } from '../../assets/icons/64/priority-pickup.svg';
import { ReactComponent as LockerIcon } from '../../assets/icons/64/locker.svg';
import { ReactComponent as PaintTinIcon } from '../../assets/icons/64/paint-tin.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/64/time.svg';
import { OrderStatus } from '../../types';
import { getOrderStatusDescription } from '../../utils/order';

const OrderItem = (props) => {
  function displayStatusIcon() {
    switch (props.status) {
      case OrderStatus.RECEIVED:
        return <TimeIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.OUT_FOR_DELIVERY:
        return <DeliveryIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.AWAITING_PICKUP_IN_STORE:
        return (
          <PriorityPickupIcon className="w-3 h-3 text-dulux-primary mr-1" />
        );
      case OrderStatus.AWAITING_PICKUP_IN_LOCKER:
        return <LockerIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.DELIVERED:
        return <PaintTinIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      case OrderStatus.PICKED_UP:
        return <PaintTinIcon className="w-3 h-3 text-dulux-primary mr-1" />;
      default:
        return '';
    }
  }

  return (
    <>
      <div className="flex border-b" onClick={() => props.onClick()}>
        <div className="w-5/12 bg-gray-200 p-1 pt-2 flex flex-col justify-between">
          {props.title && (
            <h3 className="font-heading text-h3 text-dulux-dark border-b border-gray-400 pb-1">
              {props.title}
            </h3>
          )}
          {props.label && (
            <div className="flex items-center">{props.label}</div>
          )}
        </div>
        <div className="w-7/12 bg-white p-1 pt-2 flex flex-col">
          <div className="flex flex-wrap justify-between items-center">
            {props.status && (
              <div className="text-dulux-primary flex flex-wrap items-center">
                {displayStatusIcon()}
                {getOrderStatusDescription(props.status)}
              </div>
            )}
            {props.timeLabel && (
              <div className="text-gray-500">{props.timeLabel}</div>
            )}
          </div>
          {props.time && (
            <div className="bg-dulux-dark text-white rounded-full text-center text-small mt-1 px-1 py-1">
              {props.time}
            </div>
          )}
          {props.address && (
            <div className="flex items-center mt-1 text-dulux-dark">
              {props.address}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

OrderItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  timeLabel: PropTypes.string,
  time: PropTypes.string,
  address: PropTypes.string,
};

OrderItem.defaultProps = {
  onClick: null,
  title: '',
  label: '',
  status: '',
  timeLabel: '',
  time: '',
  address: '',
};

export default OrderItem;
