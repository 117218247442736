import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAnalyticsContext } from '../services/analytics/context';
import qs from 'qs';
import {
  selectCurrentSupportCategory,
  selectCurrentSupportQuery,
  selectCurrentOrder,
  selectCatalogActiveTab,
  selectCartItemsWithPricing,
  selectPricingTotals,
} from '../store/selectors';

export default function useTracking() {
  const analytics = useAnalyticsContext();
  const location = useLocation();
  const currentSupportCategory = useSelector(selectCurrentSupportCategory);
  const currentSupportQuery = useSelector(selectCurrentSupportQuery);
  const currentOrder = useSelector(selectCurrentOrder);
  const activeCatalogTab = useSelector(selectCatalogActiveTab);
  const pricingItems = useSelector(selectCartItemsWithPricing);
  const pricingTotals = useSelector(selectPricingTotals);

  const [prevPathname, setPrevPathname] = useState('');
  const [prevSearch, setPrevSearch] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/cart':
        analytics.ecommerce.cartViewed({
          products: pricingItems,
        });
        break;
    }
  }, [location, analytics, pricingItems, pricingTotals]);

  useEffect(() => {
    switch (location.pathname) {
      case '/checkout':
        analytics.ecommerce.checkoutStepViewed(1);
        break;
      case '/checkout/delivery-times':
        analytics.ecommerce.checkoutStepViewed(2);
        break;
      case '/checkout/location':
        analytics.ecommerce.checkoutStepViewed(3);
        break;
      case '/checkout/notes':
        analytics.ecommerce.checkoutStepViewed(4);
        break;
      case '/checkout/summary':
        analytics.ecommerce.checkoutStepViewed(5);
        break;
    }
  }, [location, analytics]);

  useEffect(() => {
    if (prevPathname === location.pathname && prevSearch === location.search) {
      return;
    }

    // custom handling on the /login page
    // tracking implementation is on the page component
    if (location.pathname === '/login') {
      return;
    }

    let additionalData = {};

    switch (location.pathname) {
      case '/catalog':
        additionalData = {
          category: activeCatalogTab,
        };
        break;
      case '/support/select':
        additionalData = {
          title: currentSupportCategory,
        };
        break;
      case '/support/select/detail':
        additionalData = {
          title: currentSupportQuery ? currentSupportQuery.query : '',
        };
        break;
      case '/orders/detail':
        additionalData = {
          title: currentOrder ? `Order id: ${currentOrder.id}` : '',
        };
        break;
      case '/orders/detail/status':
        additionalData = {
          title: currentOrder ? `Order id: ${currentOrder.id}` : '',
        };
        break;
      case '/product': {
        // Entire product flow uses params, initial hit of page should not be counted
        if (location.search === '') {
          return;
        }

        // Remove '?'
        const cleanedSearch = location.search.substring(
          1,
          location.search.length
        );

        additionalData = qs.parse(cleanedSearch);
        break;
      }
      default:
        break;
    }

    /*
     * From Segment
     * Note that when sending page views from one of Segment's server-side libraries,
     * a url property is required. Otherwise, Google Analytics
     * will silently reject your page event.
     */
    analytics.page(`${location.pathname}${location.search}`, {
      properties: {
        ...additionalData,
        url: `${location.pathname}${location.search}`,
      },
    });
    setPrevPathname(location.pathname);
    setPrevSearch(location.search);
  }, [
    location,
    analytics,
    prevPathname,
    prevSearch,
    currentOrder,
    currentSupportQuery,
    currentSupportCategory,
    activeCatalogTab,
  ]);

  return {
    location,
    analytics,
  };
}
