import Firebase, { EVENTS } from '../firebase';
import * as firebase from 'firebase';
import { cleanupOrders, receiveOrder } from '../../../store/actions';
import { User } from '../types';
import { Subscription } from '../../../models/PubSub';

interface OrderRefs {
  // Where key is the order ID
  [key: number]: firebase.database.Reference;
}

export default class FirebaseOrders {
  private firebase: Firebase;
  private busSubscription: Subscription | undefined;

  private orderRefs: OrderRefs = {};
  private onOrderValueListener: any;

  constructor(fb: Firebase) {
    this.firebase = fb;

    this.onOrderValueListener = this.onOrderValue.bind(this);
  }

  boot() {
    if (this.busSubscription) {
      this.busSubscription.unsubscribe();
    }

    this.busSubscription = this.firebase.bus.subscribe(
      EVENTS.onUserData,
      (user) => this.onUserData(user)
    );
  }

  onUserData(user: User | null | undefined) {
    // If we don't have a user then we're probably logged out
    if (!user) {
      this.removeAllListeners();
      return;
    }

    const userOrderIds = Object.keys(user.orders || {})
      .reverse() // Reverse the order so that we load the most recent first
      .map((id) => Number(id));

    // Unsubscribe from orders that aren't on our account
    Object.keys(this.orderRefs).forEach((orderIdString) => {
      const orderId = Number(orderIdString);

      if (!userOrderIds.includes(Number(orderId))) {
        this.removeListener(orderId);
      }
    });

    // Subscribe to new orders that have been added
    userOrderIds.forEach((orderId) => {
      // We're already listening - do nothing
      if (this.orderRefs[orderId]) return;

      this.orderRefs[orderId] = this.order(orderId);
      this.orderRefs[orderId].on('value', this.onOrderValueListener);
    });

    // Cleanup so that we don't have any orders in state for the user that have been removed
    this.firebase.dispatch(cleanupOrders(userOrderIds));
  }

  removeAllListeners() {
    Object.values(this.orderRefs).forEach((ref) => {
      ref.off('value', this.onOrderValueListener);
    });

    this.orderRefs = {};

    this.firebase.dispatch(cleanupOrders([]));
  }

  removeListener(orderId: number) {
    const ref = this.orderRefs[orderId];

    if (!ref) {
      return console.error(
        'Attempting to remove order listener that does not exist'
      );
    }

    ref.off('value', this.onOrderValueListener);

    delete this.orderRefs[orderId];
  }

  /*
   * Called with a snapshot of an order changes.
   */
  onOrderValue(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      // Order was deleted and should be cleaned up in cleanupOrders()
      return;
    }

    this.firebase.dispatch(receiveOrder(snapshot.val()));
  }

  order = (id: number) => this.firebase.db.ref(`orders/${id}`);
}
