import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { setObject } from '../utils/storage';
import { IonSlides, IonSlide } from '@ionic/react';
import Button from '../components/ui/Button';
import ProductSlide from '../assets/images/carousel/onboarding/screen1@2x.png';
import ColourSlide from '../assets/images/carousel/onboarding/screen2@2x.png';
import AmountSlide from '../assets/images/carousel/onboarding/screen3@2x.png';
import MethodSlide from '../assets/images/carousel/onboarding/screen4@2x.png';
import FooterCapContainer from '../components/ui/FooterCapContainer';
import { useAnalyticsContext } from '../services/analytics/context';

const Onboarding = (props) => {
  const analytics = useAnalyticsContext();
  const history = useHistory();
  const carouselTop = useRef();
  const carouselBottom = useRef();
  const [nextLabel, setNextLabel] = useState('Next');

  function updateNextLabel(ref) {
    if (ref) {
      ref.isEnd().then((result) => {
        if (result) {
          setNextLabel('Get started');
          return;
        }

        setNextLabel('Next');
      });
    }
  }

  function handleSlideWillChangeTop() {
    if (carouselTop.current) {
      carouselTop.current.getActiveIndex().then((index) => {
        carouselBottom.current.slideTo(index);
      });
    }

    updateNextLabel(carouselTop.current);
  }

  function handleSlideWillChangeBottom() {
    if (carouselBottom.current) {
      carouselBottom.current.getActiveIndex().then((index) => {
        carouselTop.current.slideTo(index);
      });

      updateNextLabel(carouselBottom.current);
    }
  }

  function handleOnClick() {
    if (carouselBottom.current && carouselTop.current) {
      carouselBottom.current.isEnd().then((result) => {
        if (result) {
          setObject(props.onboardingKey, true).then(() => {
            history.push('/home');
          });
          return;
        }

        carouselBottom.current.slideNext();
        carouselTop.current.slideNext();
      });
    }
  }

  function handleSlideEnd() {
    analytics.trackEvent('Carousel Swiped', {
      properties: {
        category: 'Welcome',
        label: 'Onboarding',
      },
    });
  }

  function renderTopCarousel() {
    return (
      <IonSlides
        ref={carouselTop}
        onIonSlideWillChange={(e) => handleSlideWillChangeTop(e)}
      >
        <IonSlide>
          <img
            src={ProductSlide}
            className="px-6"
            alt="The Product list selection view"
          />
        </IonSlide>
        <IonSlide>
          <img
            src={ColourSlide}
            className="px-6"
            alt="The Colour selection view"
          />
        </IonSlide>
        <IonSlide>
          <img
            src={AmountSlide}
            className="px-6"
            alt="The Litreage selection view"
          />
        </IonSlide>
        <IonSlide>
          <img
            src={MethodSlide}
            className="px-6"
            alt="The Fulfillment method view"
          />
        </IonSlide>
      </IonSlides>
    );
  }

  function renderBottomCarousel() {
    return (
      <IonSlides
        ref={carouselBottom}
        pager={true}
        onIonSlideWillChange={(e) => handleSlideWillChangeBottom(e)}
        onIonSlideTransitionEnd={(e) => handleSlideEnd(e)}
      >
        <IonSlide>
          <div className="px-1 pt-5 pb-4 text-center">
            <h2 className="font-heading text-h2 pb-2">Pick product</h2>
            <p>
              Tap &apos;Order&apos; on the homescreen to browse paint products.
            </p>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="px-1 pt-5 pb-4  text-center">
            <h2 className="font-heading text-h2 pb-2">Add colour</h2>
            <p>
              Tint your paint selection with over 15,000 colours to choose from.
            </p>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="px-1 pt-5 pb-4  text-center">
            <h2 className="font-heading text-h2 pb-2">Select amount</h2>
            <p>Specify the size and quantity you need for the job.</p>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="px-1 pt-5 pb-4  text-center">
            <h2 className="font-heading text-h2 pb-2">
              Choose pickup or delivery
            </h2>
            <p>Tell us where you want it and when, it&apos;s that simple.</p>
          </div>
        </IonSlide>
      </IonSlides>
    );
  }

  return (
    <div className="bg-dulux-light-pressed h-full flex flex-col overflow-hidden">
      <div className="w-full pt-6 overflow-x-visible">
        {renderTopCarousel()}
      </div>
      <FooterCapContainer
        className="w-full bottom-0 bg-white z-10"
        style={{
          display: 'block',
          position: 'absolute',
          minHeight: '330px',
        }}
      >
        {renderBottomCarousel()}
        <span className="mt-4 pb-4">
          <Button onClick={handleOnClick}>{nextLabel}</Button>
        </span>
      </FooterCapContainer>
    </div>
  );
};

Onboarding.propTypes = {
  onboardingKey: PropTypes.string.isRequired,
};

export default Onboarding;
