import { keyBy } from 'lodash';
import { ProductActionType, ProductActionTypes, ProductState } from './types';
import { ProductId } from '../../../types';

const initialState: ProductState = {
  byId: {},
};

const products = (
  state: ProductState = initialState,
  action: ProductActionTypes
): ProductState => {
  switch (action.type) {
    case ProductActionType.RECEIVE_RAW: {
      const data = action.payload.products.map((p: any) => {
        return {
          productId: p.sku,
          fullName: p.fullProductName,
          range: p.range,
          name: p.name,
          sheen: p.sheen || null,
          base: p.base || null,
          size: p.size || null,

          sheenImage: p.sheenImage || null,
          sheenColour: p.sheenColour || null,

          // interior: p.interior || false,
          // exterior: p.exterior || false,
          // prep: p.prep || false,
        };
      });

      return {
        ...state,
        byId: keyBy(data, 'productId'),
      };
    }

    case ProductActionType.RECEIVE:
      return {
        ...state,
        byId: action.payload.byId,
      };

    default:
      return state;
  }
};

export const getProduct = (state: ProductState, productId: ProductId) => {
  return state.byId[productId];
};

export default products;
