import { createContext, useContext } from 'react';
import Analytics from './analytics';

export const AnalyticsContext = createContext({});

export const AnalyticsProvider = AnalyticsContext.Provider;

export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext) as Analytics;
};
