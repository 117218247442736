import { UserActionType, UserActionTypes, UserState } from './types';

const initialState: UserState = {
  auth: {
    isInitialising: true,
    phoneNumber: '',
    verificationId: '',
    verificationCode: '',
    isLoadingVerification: false,
    verificationError: undefined,
    isLoadingLogin: false,
    loginError: undefined,
  },
  user: undefined,
  rep: undefined,
};

const user = (
  state: UserState = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case UserActionType.VERIFICATION_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          phoneNumber: action.payload.phoneNumber,
          verificationId: '',
          verificationCode: '',
          isLoadingVerification: true,
        },
      };

    case UserActionType.VERIFICATION_FAILURE:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoadingVerification: false,
          verificationError: action.payload.error,
        },
      };

    case UserActionType.VERIFICATION_TIMEOUT:
      // Do nothing if we've finished loading
      if (!state.auth.isLoadingVerification) {
        return state;
      }

      return {
        ...state,
        auth: {
          ...state.auth,
          isLoadingVerification: false,
          verificationError: 'Your request timed out',
        },
      };

    case UserActionType.RECEIVE_VERIFICATION_ID:
      return {
        ...state,
        auth: {
          ...state.auth,
          verificationId: action.payload.verificationId,
          isLoadingVerification: false,
        },
      };

    case UserActionType.RECEIVE_VERIFICATION_ID_AND_CODE:
      return {
        ...state,
        auth: {
          ...state.auth,
          verificationId: action.payload.verificationId,
          verificationCode: action.payload.verificationCode,
          isLoadingVerification: false,
        },
      };

    case UserActionType.CLEAR_VERIFICATION_ID:
      return {
        ...state,
        auth: {
          ...state.auth,
          verificationId: '',
        },
      };

    case UserActionType.CLEAR_VERIFICATION_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          verificationError: undefined,
        },
      };

    case UserActionType.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          loginError: undefined,
        },
      };

    case UserActionType.LOGIN_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoadingLogin: true,
        },
      };

    case UserActionType.LOGIN_SUCCESS:
      return {
        ...state,
        auth: {
          ...initialState.auth,
          isInitialising: false,
        },
        user: {
          uid: action.payload.uid,
          phoneNumber: action.payload.phoneNumber,
          providerData: action.payload.providerData,
        },
      };

    case UserActionType.LOGIN_FAILURE:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoadingLogin: false,
          loginError: action.payload.error,
        },
      };

    case UserActionType.LOGIN_TIMEOUT:
      // Do nothing if we've finished loading
      if (!state.auth.isLoadingLogin) {
        return state;
      }

      return {
        ...state,
        auth: {
          ...state.auth,
          isLoadingLogin: false,
          loginError: 'Your request timed out',
        },
      };

    case UserActionType.LOGOUT:
      return {
        ...initialState,
        auth: {
          ...initialState.auth,
          isInitialising: false,
        },
      };

    case UserActionType.RECEIVE_USER_DATA:
      // We should already have a logged in user...
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };

    case UserActionType.RECEIVE_REP_DATA:
      return {
        ...state,
        rep: action.payload.rep,
      };

    case UserActionType.INITIALISED:
      return {
        ...state,
        auth: {
          ...state.auth,
          isInitialising: false,
        },
      };

    default:
      return state;
  }
};

export default user;
