import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUser,
  selectActiveOrdersByDeliveryTimeAsc,
  selectIsLoadingOrders,
} from '../store/selectors';
import HomeSummary from '../components/home/HomeSummary';
import HomeOrders from '../components/home/HomeOrders';
import HomeInfo from '../components/home/HomeInfo';
import HomeAbout from '../components/home/HomeAbout';
import HomeFeatures from '../components/home/HomeFeatures';
import Button from '../components/ui/Button';
import useWeather from '../hooks/useWeather';
import FooterCapContainer from '../components/ui/FooterCapContainer';
import { applyCurrentOrder } from '../store/actions';
import { ReactComponent as DuluxLogo } from '../assets/images/brand/dulux-logo.svg';
import ProductsImage from '../assets/images/homepage/homepage-products@2x.png';
import PickupAndDeliveryImage from '../assets/images/homepage/homepage-pickup-and-delivery@2x.png';
// import ColoursImage from '../assets/images/homepage/homepage-colours@2x.png';
import { useFirebaseContext } from '../services/firebase/context';
import { useAnalyticsContext } from '../services/analytics/context';

const Home = (props) => {
  const firebase = useFirebaseContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalyticsContext();
  const user = useSelector(selectUser);
  const isLoadingOrders = useSelector(selectIsLoadingOrders);
  const activeOrders = useSelector(selectActiveOrdersByDeliveryTimeAsc);
  const { humidity, temperature, weatherHex, weatherName } = useWeather();

  const firstName = user ? user.firstName : null;
  const userFeatures = (user && user.features) || {};

  function handleOrderClick(orderId) {
    dispatch(applyCurrentOrder(orderId));
    history.push('/orders/detail');
    history.push('/orders/detail/status');
  }

  function handleOrderPaintClick() {
    history.push('/catalog');
  }

  function handleSeeAllOrdersOnClick() {
    history.push('orders');
  }

  function handleSettingsClick() {
    history.push('/settings');
  }

  function handlePickupAndDeliveryClick() {
    history.push('/pickup-and-delivery');
  }

  function handleSupportClick() {
    history.push('/support');
  }

  function handleVote(feature, vote) {
    firebase.voting
      .castVote(feature, vote)
      .then(() =>
        analytics.trackEvent('Feature Voted', {
          properties: {
            category: 'Home',
            label: feature,
            value: vote ? 1 : 0,
          },
        })
      )
      .catch((e) => console.error(e));
  }

  // function handleCreateTicket() {
  //   console.log(
  //     firebase.createTicket([
  //       { property: 'subject', value: 'Support Request' },
  //       { property: 'content', value: 'This is a support request' }
  //     ])
  //   );
  // }

  return (
    <IonPage>
      <IonContent>
        <HomeSummary
          backgroundColour={weatherHex}
          temperature={temperature}
          humidity={humidity}
          colourLogo={<DuluxLogo style={{ width: '60px', height: '30px' }} />}
          colourName={weatherName}
          firstName={firstName}
          settingsOnClick={handleSettingsClick}
        />
        <HomeOrders
          loading={isLoadingOrders}
          orders={activeOrders}
          allOrdersOnClick={() => handleSeeAllOrdersOnClick()}
          orderOnClick={(orderId) => handleOrderClick(orderId)}
        />
        <div className="pb-2">
          <HomeInfo
            title="Products"
            text="Choose from a range of popular paint products and accessories to get the job done."
            image={ProductsImage}
            altText="Dulux paint products"
            onClick={handleOrderPaintClick}
          />
          {/* <HomeInfo
            title="Colours"
            text="Browse from over 17,000 colours and complete your jobs with Dulux Authentic Colour confidence."
            image={ColoursImage}
            altText="Dulux colour swatches"
          /> */}
          <HomeInfo
            title="Pickup and delivery"
            text="Have your order delivered or collect it when it suits you with delivery, pickup and scheduling options available."
            image={PickupAndDeliveryImage}
            altText="Pickup and delivery icons"
            onClick={handlePickupAndDeliveryClick}
          />
        </div>
        <HomeAbout />
        <HomeFeatures handleVote={handleVote} userFeatures={userFeatures} />
      </IonContent>

      <FooterCapContainer>
        <Button onClick={handleSupportClick} theme="ghost">
          Get help
        </Button>
        <Button onClick={handleOrderPaintClick}>Order</Button>
      </FooterCapContainer>

      {/* <Button onClick={handleCreateTicket}>Test create ticket</Button> */}
    </IonPage>
  );
};

export default Home;
