import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import { DeliveryMethodGroupType } from '../../store/reducers/checkout/types';

const ShowAllDTC = (props) => {
  const allStoresRef = useRef();
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  const variants = {
    visible: {
      opacity: 1,
      display: 'block',
    },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };
  function handleScroll() {
    scrollToRef(allStoresRef);
  }

  return (
    <>
      <motion.div
        className="p-1"
        initial={false}
        variants={variants}
        animate={props.showAllStores ? 'hidden' : 'visible'}
        transition={{ ease: 'easeOut', duration: 0.15 }}
      >
        <Button
          onClick={() => {
            props.setAllStoresVisible(true);
            setTimeout(handleScroll, 300);
          }}
        >
          View all stores
        </Button>
      </motion.div>
      <motion.div
        ref={allStoresRef}
        initial={false}
        variants={variants}
        animate={props.showAllStores ? 'visible' : 'hidden'}
        transition={{ ease: 'easeOut', duration: 0.15 }}
      >
        <h2 className="font-heading text-h3 text-dulux-dark p-1 border-b">
          All stores
        </h2>
        {props.renderStores(DeliveryMethodGroupType.PICKUP)}
      </motion.div>
    </>
  );
};

ShowAllDTC.propTypes = {
  showAllStores: PropTypes.bool,
  setAllStoresVisible: PropTypes.func,
  renderStores: PropTypes.func,
};

ShowAllDTC.defaultProps = {
  showAllStores: false,
  setAllStoresVisible: null,
  renderStores: null,
};

export default ShowAllDTC;
