/*
 * Fallback for iOS issue. When app is freshly installed,
 * supportData in App.tsx is loaded in, but when it comes to using
 * the data, the support state is empty. If the app is closed
 * and reopened, the data is present.
 */
import supportData from '../data/support.json';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receiveData } from '../store/actions';
import { selectSupportStateById } from '../store/selectors';
import { isEmpty } from 'lodash';

export default function useSupportFallback() {
  const supportStateById = useSelector(selectSupportStateById);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(supportStateById)) {
      dispatch(receiveData(supportData));
    }
  }, [supportStateById, dispatch]);

  return supportData;
}
