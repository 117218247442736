import {
  ApplyActiveTabAction,
  CatalogActionType,
  CatalogState,
  ClearActiveTabAction,
} from '../reducers/catalog/types';

export const selectActiveCatalogTab = (
  activeTab: CatalogState['activeTab']
): ApplyActiveTabAction => {
  return {
    type: CatalogActionType.APPLY_ACTIVE_TAB,
    payload: { activeTab },
  };
};

export const clearActiveCatalogTab = (): ClearActiveTabAction => {
  return {
    type: CatalogActionType.CLEAR_ACTIVE_TAB,
  };
};
