import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { ReactComponent as InfoCircleIcon } from '../../assets/icons/20/info-circle.svg';
import { useAnalyticsContext } from '../../services/analytics/context';

const OrderMethods = (props) => {
  const analytics = useAnalyticsContext();
  const [deliveryInfoEnabled, setDeliveryInfoEnabled] = useState(false);

  function renderInfo() {
    return props.info.map((item, key) => {
      return (
        <div className="pt-2 pb-1" key={key}>
          {item.title && (
            <h3 className="font-heading text-h3 text-dulux-dark">
              {item.title}
            </h3>
          )}
          {item.description && (
            <p className="text-dulux-dark mb-2">{item.description}</p>
          )}
        </div>
      );
    });
  }

  function onInfoClick() {
    if (!deliveryInfoEnabled) {
      analytics.trackEvent(`Fulfillment Info Read`, {
        properties: {
          category: 'Checkout',
          label: `Fulfilment Options - ${props.title}`,
        },
      });
    }
    setDeliveryInfoEnabled(!deliveryInfoEnabled);
  }

  return (
    <>
      <div className="bg-white border-b">
        <div className="p-1">
          <div className="flex flex-col">
            <div className="flex justify-between items-center pt-3">
              {props.title && (
                <h2 className="font-heading text-h2 text-dulux-dark">
                  {props.title}
                </h2>
              )}
              {props.info && (
                <div
                  className={
                    'w-5 h-5 rounded-sm flex justify-center items-center ' +
                    (deliveryInfoEnabled ? 'bg-dulux-primary' : 'bg-blue-200')
                  }
                  onClick={onInfoClick}
                >
                  <div
                    className={
                      deliveryInfoEnabled ? 'text-white' : 'text-dulux-primary'
                    }
                  >
                    <InfoCircleIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          {props.info && (
            <motion.div
              initial={false}
              animate={{
                height: deliveryInfoEnabled ? 'auto' : 0,
              }}
              className="overflow-hidden"
            >
              {renderInfo()}
            </motion.div>
          )}
        </div>
      </div>
      {props.groupItems}
      {props.scheduledItem}
    </>
  );
};

OrderMethods.propTypes = {
  title: PropTypes.string,
  info: PropTypes.array,
  groupItems: PropTypes.array,
  type: PropTypes.string,
  onClick: PropTypes.func,
  scheduleOnClick: PropTypes.func,
  scheduledItem: PropTypes.node,
  totalLitres: PropTypes.number,
  method: PropTypes.object,
};

OrderMethods.defaultProps = {
  title: '',
  type: '',
  info: [],
  totalLitres: 0,
  groupItems: null,
  onClick: null,
  scheduleOnClick: null,
  scheduledItem: null,
};

export default OrderMethods;
