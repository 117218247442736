import { Action } from 'redux';

export interface Support {
  name: string;
  key: string;
  items: SupportQaType[];
}

export interface SupportMap {
  [key: string]: Support;
}

export interface SupportQaType {
  query: string;
  content: string;
}

export interface SupportState {
  byId: SupportMap;
  currentCategory?: string | 'my-orders' | 'my-app' | 'general';
  currentQuery?: SupportQaType;
}

export enum SupportActionType {
  APPLY_CURRENT_CATEGORY = 'APPLY_CURRENT_CATEGORY',
  APPLY_CURRENT_QUERY = 'APPLY_CURRENT_QUERY',
  APPLY_SUPPORT = 'APPLY_SUPPORT',
  CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY',
  CLEAR_CURRENT_QUERY = 'CLEAR_CURRENT_QUERY',
  CLEAR_SUPPORT = 'CLEAR_SUPPORT',
  RECEIVE_DATA = 'SUPPORT_RECEIVE_DATA',
}

export interface ApplySupportCategoryAction extends Action {
  type: SupportActionType.APPLY_CURRENT_CATEGORY;
  payload: Pick<SupportState, 'currentCategory'>;
}

export interface ApplySupportCurrentQueryAction extends Action {
  type: SupportActionType.APPLY_CURRENT_QUERY;
  payload: Pick<SupportState, 'currentQuery'>;
}

export interface ApplySupportAction extends Action {
  type: SupportActionType.APPLY_SUPPORT;
  payload: SupportState;
}

export interface ClearSupportCategoryAction extends Action {
  type: SupportActionType.CLEAR_CURRENT_CATEGORY;
}

export interface ClearSupportCurrentQueryAction extends Action {
  type: SupportActionType.CLEAR_CURRENT_QUERY;
}

export interface ClearSupportAction extends Action {
  type: SupportActionType.CLEAR_SUPPORT;
}

export interface ReceiveDataAction extends Action {
  type: SupportActionType.RECEIVE_DATA;
  payload: {
    categories: Support[];
  };
}

export type SupportActionTypes =
  | ApplySupportCategoryAction
  | ApplySupportCurrentQueryAction
  | ApplySupportAction
  | ClearSupportCategoryAction
  | ClearSupportCurrentQueryAction
  | ClearSupportAction
  | ReceiveDataAction;
