import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { batch, useDispatch, useSelector } from 'react-redux';
import { IonHeader, IonPage, IonContent } from '@ionic/react';
import {
  selectActiveCatalogTab,
  selectColour,
  selectQuantity,
  selectSize,
  selectProductSheen,
  selectProductSheenImage,
  addToCart,
  clearProductSheen,
  clearColour,
  clearProductSize,
  clearQuantity,
  clearSelection,
  removeEditingFromCart,
  editCartItemSelection,
  clearSkipLocation,
} from '../store/actions';
import { CatalogTabType } from '../store/reducers/catalog/types';
import ProductSelection from '../components/product/ProductSelection';
import HeaderTitle from '../components/header/HeaderTitle';
import Loader from '../components/ui/Loader';
import qs from 'qs';
import { map, reduce, isUndefined, isNull, isEmpty, keyBy, omit } from 'lodash';
import { getColour } from '../store/reducers/colours/reducer';
import { ProductStep } from '../store/reducers/products/types';
import {
  selectRecentlyOrderedColours,
  selectRecentlyOrderedColourStrengths,
  selectAvailablePopularColours,
  selectEnrichedAvailableSheens,
  selectEditingCartItem,
  selectSelectedColour,
  selectAvailableProducts,
  selectAvailableSizes,
  selectProductQuery,
  selectColourState,
  selectSelectedColourId,
  selectSelectedQuantity,
  selectSelectedColourStrength,
} from '../store/selectors';
import titleCase from '../utils/text';
import { isAccessory, productSizeImage } from '../utils/product';
import ProductSheen from '../components/product/ProductSheen';
import ProductColour from '../components/product/ProductColour';
import ProductSize from '../components/product/ProductSize';
import ProductQuantity from '../components/product/ProductQuantity';
import HeaderBack from '../components/header/HeaderBack';
import { ReactComponent as DuluxLogo } from '../assets/images/brand/dulux-logo.svg';
import { contrastingColour } from '../utils/colour';
import { useAnalyticsContext } from '../services/analytics/context';

// How long to wait before applying a selection.
// This is usually directly tied to how long the loader is displayed.
const SELECTION_DELAY = 1000;
const LOADER_DELAY = SELECTION_DELAY;

const DULUX_PRODUCT_BRAND = 'Dulux';
const COLORBOND_PRODUCT_BRAND = 'Colorbond';

const Product = () => {
  const analytics = useAnalyticsContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const productQuery = useSelector(selectProductQuery);
  const selectedColour = useSelector(selectSelectedColour);
  const selectedColourId = useSelector(selectSelectedColourId);
  const selectedColourStrength = useSelector(selectSelectedColourStrength);
  const selectedQuantity = useSelector(selectSelectedQuantity);
  const editingCartItem = useSelector(selectEditingCartItem);
  const isEditing = !!editingCartItem;
  const colourState = useSelector(selectColourState);
  const products = useSelector(selectAvailableProducts);
  const sheens = useSelector(selectEnrichedAvailableSheens);
  const popularColours = useSelector(selectAvailablePopularColours);
  const recentlyOrderedColours = useSelector(selectRecentlyOrderedColours);
  const recentlyOrderedColourStrengths = useSelector(
    selectRecentlyOrderedColourStrengths
  );
  const sizes = useSelector(selectAvailableSizes);
  const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [searchEnabled, setSearchEnabled] = useState();
  const [colourStrengthEnabled, setColourStrengthEnabled] = useState();
  const [colourIdSelected, setColourIdSelected] = useState();
  const [searchColourIdSelected, setSearchColourIdSelected] = useState();
  const [
    searchColourStrengthEnabled,
    setSearchColourStrengthEnabled,
  ] = useState();
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const [sheenTitle, setSheenTitle] = useState('');
  const [sheenImage, setSheenImage] = useState('');
  const [sheenBackgroundColour, setSheenBackgroundColour] = useState('');
  const [colourTitle, setColourTitle] = useState('');
  const [colourBackground, setColourBackground] = useState('');
  const [sizeTitle, setSizeTitle] = useState('');
  const [quantityTitle, setQuantityTitle] = useState('');
  const [queryString, setQueryString] = useState();
  const [skipLocation, setSkipLocation] = useState();
  const [currentStep, setCurrentStep] = useState();

  function resetPopupStates() {
    setSearchEnabled(undefined);
    setColourStrengthEnabled(undefined);
    setColourIdSelected(undefined);
    setSearchColourStrengthEnabled(undefined);
    setSearchColourIdSelected(undefined);
  }

  function resetUseStates() {
    setSheenTitle('');
    setSheenImage('');
    setSheenBackgroundColour('');
    setColourTitle('');
    setColourBackground('');
    setSizeTitle('');
    setQuantityTitle('');
  }

  function resetSkipLocation() {
    setSkipLocation(undefined);
  }

  function handleClearProduct() {
    batch(() => {
      dispatch(removeEditingFromCart());
      dispatch(clearSelection());
    });
    resetPopupStates();
  }

  function loadSelection(callback) {
    setLoaderEnabled(true);

    setTimeout(() => {
      setLoaderEnabled(false);
    }, LOADER_DELAY);

    setTimeout(callback, SELECTION_DELAY);
  }

  function handleSelectQuantity(quantity) {
    setQuantityTitle(quantity);

    loadSelection(() => {
      dispatch(selectQuantity(quantity));
    });
  }

  function handleClearQuantity() {
    dispatch(clearQuantity());
  }

  function handleSelectSheen(sheen, image, colour) {
    setSheenTitle(sheen);
    setSheenImage(image ? image : '');
    setSheenBackgroundColour(colour ? colour : '#FFFFFF');

    loadSelection(() => {
      batch(() => {
        dispatch(selectProductSheen(sheen));

        if (image) {
          dispatch(selectProductSheenImage(image));
        }
      });
    });
  }

  function handleClearSheen() {
    dispatch(clearProductSheen());
  }

  function handleSelectColour(colourId, colourStrength) {
    const colourObj = getColour(colourState, colourId);
    setColourTitle(titleCase(colourObj.colourName));
    setColourBackground(colourObj.hex);

    loadSelection(() => {
      dispatch(selectColour(colourId, colourStrength));
    });
  }

  function handleClearColour() {
    dispatch(clearColour());
  }

  function handleSelectSize(size) {
    setSizeTitle(size);

    loadSelection(() => {
      dispatch(selectSize(size));
    });
  }

  function handleClearSize() {
    dispatch(clearProductSize());
  }

  function handleAddToCart() {
    if (products.length !== 1) {
      console.error('There should only be one product by this step.', products);
    }

    // TODO: Account for editing
    analytics.ecommerce.productAdded({
      productId: products[0].productId,
      product: products[0],
      colourId: selectedColour && selectedColour.colourId,
      colour: selectedColour,
      colourStrength: selectedColourStrength,
      quantity: selectedQuantity,
    });

    loadSelection(() => {
      batch(() => {
        dispatch(
          addToCart(
            products[0].productId,
            selectedColour && selectedColour.colourId,
            selectedColourStrength,
            selectedQuantity
          )
        );
        dispatch(selectActiveCatalogTab(CatalogTabType.PAINT));
        dispatch(clearSelection());
      });

      clearSkipLocation();
      resetUseStates();
      history.push('/cart');
    });
  }

  function handleRemoveFromCart() {
    let data = {
      properties: {
        productId: editingCartItem.productId,
        quantity: editingCartItem.quantity,
        colourId: editingCartItem ? editingCartItem.colourId : undefined,
        colourStrength: editingCartItem
          ? editingCartItem.colourStrength
          : undefined,
      },
    };

    if (isAccessory(productQuery.range)) {
      data = omit(data, ['properties.colourId', 'properties.colourStrength']);
    }

    analytics.trackEvent('Product Removed', {
      properties: {
        category: 'Product',
        label: JSON.stringify(data.properties),
      },
    });

    batch(() => {
      dispatch(removeEditingFromCart());
      dispatch(clearSelection());
      dispatch(selectActiveCatalogTab(CatalogTabType.PAINT));
    });
    clearSkipLocation();
    resetUseStates();
    history.push('/cart');
  }

  function handleColourClick(colourId, enabled) {
    const colourData = getColour(colourState, colourId);

    analytics.trackEvent('Colour Viewed', {
      properties: {
        category: 'Product',
        label: JSON.stringify({ colourId, ...colourData }),
      },
    });
    setColourIdSelected(colourId);
    setColourStrengthEnabled(enabled);
  }

  function handleColourSearchItemClick(colourId, enabled) {
    const colourData = getColour(colourState, colourId);

    analytics.trackEvent('Colour Viewed (from search)', {
      properties: {
        category: 'Product',
        label: JSON.stringify({ colourId, ...colourData }),
      },
    });
    setSearchColourIdSelected(colourId);
    setSearchColourStrengthEnabled(enabled);
  }

  function handleSelectColourClick(colourId, colourStrength) {
    const colourData = getColour(colourState, colourId);
    let label = 'Colour Clicked';

    if (searchColourStrengthEnabled) {
      label += ' (from search)';
    }

    analytics.trackEvent(label, {
      properties: {
        category: 'Product',
        label: JSON.stringify({ colourId, colourStrength, ...colourData }),
      },
    });
    handleSelectColour(colourId, colourStrength);
    resetPopupStates();
  }

  function displayTitle() {
    if (selectedQuantity) {
      return 'Item summary';
    }
    if ('size' in productQuery) {
      return 'Select quantity';
    }
    if ('base' in productQuery) {
      return 'Select size';
    }
    if ('sheen' in productQuery) {
      return 'Pick colour';
    }
    if ('range' in productQuery) {
      return isAccessory(productQuery.range)
        ? 'Select accessory'
        : 'Select sheen level';
    }
    return 'Select sheen level';
  }

  function displayBackTitle() {
    const qsMap = qs.parse(queryString);

    if (
      'searchColourStrengthEnabled' in qsMap &&
      qsMap['searchColourStrengthEnabled'] === 'true'
    ) {
      return 'Colour search';
    }
    if (
      'colourStrengthEnabled' in qsMap &&
      qsMap['colourStrengthEnabled'] === 'true'
    ) {
      return 'Colour';
    }
    if ('searchEnabled' in qsMap && qsMap['searchEnabled'] === 'true') {
      return 'Colour';
    }

    if (isEditing && skipLocation === currentStep) {
      return 'Item summary';
    }

    if ('size' in productQuery) {
      if (isAccessory(productQuery.range) && isNull(productQuery.size)) {
        return 'Accessory';
      }

      return 'Size';
    }
    if ('base' in productQuery) {
      if (isAccessory(productQuery.range)) {
        return 'Accessory';
      }

      // If value is set to null, it means we skipped
      // this step as there were no selectable options
      if (isNull(productQuery.base)) {
        return 'Sheen level';
      }

      return 'Colour';
    }
    if ('sheen' in productQuery) {
      return 'Sheen level';
    }
    return 'Choose range';
  }

  function displayLoaderTitle() {
    switch (currentStep) {
      case ProductStep.SHEEN:
        return sheenTitle;
      case ProductStep.COLOUR:
        return colourTitle;
      case ProductStep.SIZE:
        return sizeTitle;
      case ProductStep.QUANTITY:
        return quantityTitle ? 'x' + quantityTitle : '';
      case ProductStep.ADDED_TO_CART:
        return isEditing ? 'Updating cart item' : 'Adding product to cart';
      default:
        return '';
    }
  }

  function displayLoaderBackground() {
    switch (currentStep) {
      case ProductStep.SHEEN:
        return sheenBackgroundColour;
      case ProductStep.COLOUR:
        return colourBackground;
      case ProductStep.SIZE:
        return '#CBD5E0';
      case ProductStep.QUANTITY:
        return '#FFFFFF';
      default:
        return '#FFFFFF';
    }
  }

  function displayLoaderImage() {
    switch (currentStep) {
      case ProductStep.SHEEN:
        return (
          <>
            {isAccessory(productQuery.range) ? (
              <img
                className="w-15 mb-1"
                src={`/assets/images/product/accessories/${sheenImage}@2x.png`}
                alt="product"
              />
            ) : (
              <img
                className="w-15 mb-1"
                src={`/assets/images/product/paint/sheen/${sheenImage}@2x.png`}
                alt="product"
              />
            )}
          </>
        );
      case ProductStep.COLOUR:
      case ProductStep.SIZE:
      case ProductStep.QUANTITY:
      default:
        return '';
    }
  }

  function displayLoaderSpinner() {
    switch (currentStep) {
      case ProductStep.ADDED_TO_CART:
        return true;
      default:
        return false;
    }
  }

  function renderBrand(brand, hex) {
    if (brand === DULUX_PRODUCT_BRAND) {
      return <DuluxLogo style={{ width: '50px' }} />;
    } else if (brand === COLORBOND_PRODUCT_BRAND) {
      return (
        <h3
          className="font-heading text-h4 mt-1"
          style={{ color: contrastingColour(hex) }}
        >
          {brand}
        </h3>
      );
    }

    return (
      <h3
        className="font-heading text-h4 mt-1"
        style={{ color: contrastingColour(hex) }}
      >
        Colour matched to <br></br> {brand}
      </h3>
    );
  }

  const sheenOptions = sheens.map((enrichedSheen) => {
    const { sheen, image, colour } = enrichedSheen;

    return (
      <ProductSheen
        key={sheen}
        onClick={() => handleSelectSheen(sheen, image, colour)}
        title={sheen}
        image={
          isAccessory(productQuery.range)
            ? `/assets/images/product/accessories/${image}@2x.png`
            : `/assets/images/product/paint/sheen/${image}@2x.png`
        }
      />
    );
  });

  function recentColourStrengths(colourId) {
    if (!colourId) {
      return null;
    }

    let data = recentlyOrderedColourStrengths.filter(
      (item) => item.colourId === colourId
    );

    data = data.map((colour) => {
      return {
        dateOrdered: colour.dateOrdered,
        strength: colour.strength,
      };
    });

    return keyBy(data, (item) => item.strength);
  }

  const colourOptions = () => {
    if (!recentlyOrderedColours) {
      return;
    }

    return map(recentlyOrderedColours, (colourData) => {
      const colour = colourData[0];

      return (
        <ProductColour
          key={colour.colourId}
          onColourClick={handleColourClick}
          onColourConfirmClick={handleSelectColourClick}
          colourId={colour.colourId}
          hex={colour.hex}
          colourName={colour.colourName}
          hasStrengthOptions={colour.strengths || false}
          renderBrand={() => renderBrand(colour.productBrand, colour.hex)}
          colourStrengthEnabled={colourStrengthEnabled}
          setColourStrengthEnabled={setColourStrengthEnabled}
          colourIdSelected={colourIdSelected}
          setColourIdSelected={setColourIdSelected}
          dateOrdered={colour.dateOrdered}
          atlasCode={colour.atlasCode}
          recentColourStrengths={recentColourStrengths(colourIdSelected)}
        />
      );
    });
  };

  const sizeOptions = sizes.map((size) => (
    <ProductSize
      key={size}
      onClick={() => handleSelectSize(size)}
      title={size}
      image={productSizeImage(size)}
    />
  ));

  const quantityOptions = quantities.map((quantity) => (
    <ProductQuantity
      key={quantity}
      onClick={() => handleSelectQuantity(quantity)}
      title={quantity}
    />
  ));

  // Determine step
  useEffect(() => {
    if (selectedQuantity) {
      setCurrentStep(ProductStep.ADDED_TO_CART);
      return;
    }
    if ('size' in productQuery) {
      setCurrentStep(ProductStep.QUANTITY);
      return;
    }
    if ('base' in productQuery) {
      setCurrentStep(ProductStep.SIZE);
      return;
    }
    if ('sheen' in productQuery) {
      setCurrentStep(ProductStep.COLOUR);
      return;
    }

    setCurrentStep(ProductStep.SHEEN);
  }, [selectedQuantity, productQuery]);

  // Build up the query params based on state
  useEffect(() => {
    const qsData = {
      colourId: selectedColourId,
      colourStrength: selectedColourStrength,
      quantity: selectedQuantity,
      searchEnabled,
      colourStrengthEnabled,
      searchColourStrengthEnabled,
    };

    const urlString = reduce(
      qsData,
      (result, item, key) => {
        if (isUndefined(item) || isNull(item)) {
          return result;
        }

        return `${result}&${key}=${item}`;
      },
      qs.stringify(productQuery)
    );

    setQueryString(urlString);
  }, [
    productQuery,
    selectedColourId,
    selectedColourStrength,
    selectedQuantity,
    searchEnabled,
    colourStrengthEnabled,
    searchColourStrengthEnabled,
  ]);

  /* If we leave this route, reset the component internal states
   * queryString needs to be reset when we leave this route,
   * otherwise the useEffect functions in this component will run
   * history.onBack()
   */
  useEffect(() => {
    if (history.location.pathname !== '/product') {
      resetUseStates();
      resetPopupStates();
      resetSkipLocation();
      setQueryString(undefined);

      if (isEditing) {
        dispatch(clearSelection());
      }
    }
  }, [dispatch, isEditing, history, history.location.pathname]);

  useEffect(() => {
    if (history.location.pathname === '/product') {
      let pathWithQs = history.location.pathname;

      if (queryString) {
        pathWithQs = `${pathWithQs}?${queryString}`;
      }

      // Replace the /product path with the /product?[qs] version
      if (history.location.search === '') {
        history.replace(pathWithQs);
        return;
      }

      history.push(pathWithQs);
    }
  }, [
    queryString,
    history,
    history.location.pathname,
    history.location.search,
  ]);

  // Handle the back event. Order important: correlates to the steps
  useEffect(() => {
    if (history.action !== 'POP') {
      return;
    }

    const qsMap = qs.parse(queryString);

    if (
      'searchColourStrengthEnabled' in qsMap &&
      qsMap['searchColourStrengthEnabled'] === 'true'
    ) {
      setSearchColourStrengthEnabled(undefined);
      return;
    }
    if ('searchEnabled' in qsMap && qsMap['searchEnabled'] === 'true') {
      setSearchEnabled(undefined);
      return;
    }
    if (
      'colourStrengthEnabled' in qsMap &&
      qsMap['colourStrengthEnabled'] === 'true'
    ) {
      setColourStrengthEnabled(undefined);
      return;
    }

    // While editing from cart, going 'back' to the product summary means reapplying the cartItem
    // to the selection
    if (isEditing && skipLocation === currentStep) {
      dispatch(editCartItemSelection(editingCartItem.cartItemId));
      return;
    }

    if ('quantity' in qsMap) {
      dispatch(clearQuantity());
    } else if ('size' in qsMap) {
      if (isEmpty(qsMap.size)) {
        dispatch(clearProductSheen());
      }
      dispatch(clearProductSize());
    } else if ('base' in qsMap) {
      if (isEmpty(qsMap.base)) {
        dispatch(clearProductSheen());
      }
      dispatch(clearColour());
    } else if ('sheen' in qsMap) {
      dispatch(clearProductSheen());
    } else if ('range' in qsMap && 'name' in qsMap) {
      dispatch(clearSelection());
      history.push('/catalog');
    }
  }, [
    history.location.search,
    history.action,
    history,
    queryString,
    dispatch,
    isEditing,
    skipLocation,
    currentStep,
    editingCartItem,
  ]);

  return (
    <IonPage>
      <IonHeader>
        {currentStep !== ProductStep.ADDED_TO_CART && (
          <HeaderBack title={displayBackTitle()} />
        )}
        <HeaderTitle title={displayTitle()} borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        <ProductSelection
          sheenOptions={sheenOptions}
          colourOptions={colourOptions()}
          sizeOptions={sizeOptions}
          quantityOptions={quantityOptions}
          currentStep={currentStep}
          productQuery={productQuery}
          colour={selectedColour}
          popularColours={popularColours}
          colourStrength={selectedColourStrength}
          qty={selectedQuantity}
          setSkipLocation={setSkipLocation}
          renderBrand={(productBrand, hex) => renderBrand(productBrand, hex)}
          searchEnabled={searchEnabled}
          setSearchEnabled={setSearchEnabled}
          searchColourIdSelected={searchColourIdSelected}
          setSearchColourIdSelected={setSearchColourIdSelected}
          colourStrengthEnabled={colourStrengthEnabled}
          searchColourStrengthEnabled={searchColourStrengthEnabled}
          setSearchColourStrengthEnabled={setSearchColourStrengthEnabled}
          onColourSearchItemClick={handleColourSearchItemClick}
          onColourConfirmClick={handleSelectColourClick}
          onAddToCart={handleAddToCart}
          onClearProduct={handleClearProduct}
          onClearSheen={handleClearSheen}
          onClearColour={handleClearColour}
          onClearSize={handleClearSize}
          onClearQuantity={handleClearQuantity}
          isEditing={isEditing}
          onRemoveFromCart={handleRemoveFromCart}
        />
      </IonContent>
      <Loader
        isEnabled={loaderEnabled}
        title={displayLoaderTitle()}
        background={displayLoaderBackground()}
        image={displayLoaderImage()}
        isSpinnerEnabled={displayLoaderSpinner()}
      />
    </IonPage>
  );
};

export default Product;
