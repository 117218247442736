import React from 'react';
import PropTypes from 'prop-types';

const ProductQuantity = (props) => {
  return (
    <div
      className="bg-white border-b flex justify-center items-center p-2"
      onClick={() => props.onClick()}
    >
      {props.title && (
        <h2 className="font-heading text-h2 text-dulux-dark">{props.title}</h2>
      )}
    </div>
  );
};

ProductQuantity.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.number.isRequired,
};

ProductQuantity.defaultProps = {
  onClick: null,
  title: 0,
};

export default ProductQuantity;
