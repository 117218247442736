import {
  ClearPricingAction,
  CompletePricingAction,
  PricingActionType,
  PricingState,
  ReceivePricingAction,
  RequestPricingAction,
} from '../reducers/pricing/types';

export const requestPricing = (
  isLoadingPricing: boolean
): RequestPricingAction => {
  return {
    type: PricingActionType.REQUEST,
    payload: {
      isLoadingPricing,
    },
  };
};

export const receivePricing = (data: PricingState): ReceivePricingAction => {
  return {
    type: PricingActionType.RECEIVE,
    payload: data,
  };
};

export const completePricing = (
  isLoadingPricing: boolean
): CompletePricingAction => {
  return {
    type: PricingActionType.COMPLETE,
    payload: {
      isLoadingPricing,
    },
  };
};

export const clearPricing = (): ClearPricingAction => {
  return {
    type: PricingActionType.CLEAR,
  };
};
