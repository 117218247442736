import React from 'react';
import PropTypes from 'prop-types';

const CatalogPaintItem = (props) => {
  return (
    <div onClick={() => props.onClick({})}>
      <div
        className="w-full p-2 flex flex-col justify-center items-center"
        style={{
          background: props.background,
          minHeight: '6rem',
        }}
      >
        {props.image && (
          <img
            src={props.image}
            alt={props.name}
            style={{ width: '12rem' }}
          ></img>
        )}
      </div>
    </div>
  );
};

CatalogPaintItem.propTypes = {
  image: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
};

CatalogPaintItem.defaultProps = {
  image: '',
  background: '',
  onClick: null,
  name: '',
};

export default CatalogPaintItem;
