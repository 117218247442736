import {
  PermissionType,
  PluginListenerHandle,
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
  PushNotificationToken,
} from '@capacitor/core';
import { FCM } from 'capacitor-fcm';
import { hasNativeCapability } from '../../utils/environment';
import * as Sentry from '@sentry/browser';

const { PushNotifications, Permissions } = Plugins;
const fcm = new FCM();

class Push {
  private registerErrorCallback: ((reason?: any) => void) | undefined;
  private registerErrorListener: PluginListenerHandle | undefined;
  private registerSuccessListener: PluginListenerHandle | undefined;

  get plugin() {
    return PushNotifications;
  }

  get canPush() {
    // Only works on native devices
    if (!hasNativeCapability()) {
      console.warn('Cannot use push notifications in non-native environments');
      return false;
    }

    return true;
  }

  checkPermission() {
    return Permissions.query({
      name: PermissionType.Notifications,
    });
  }

  /**
   * Request permission to send push notifications.
   * Returns true if permission is granted.
   */
  async requestPermission() {
    if (!this.canPush) {
      return false;
    }

    const hasPermission = await this.checkPermission();

    if (hasPermission.state === 'denied') {
      Sentry.captureMessage('Push notification access denied');
    }

    if (hasPermission.state === 'granted') {
      return true;
    }

    return PushNotifications.requestPermission().then(
      (response) => response.granted
    );
  }

  register() {
    if (!this.canPush) {
      return;
    }

    // Remove listeners if they exist
    if (this.registerErrorCallback) {
      this.registerErrorCallback(
        'Push registration cancelled due to duplicate call'
      );
    }

    if (this.registerErrorListener) {
      this.registerErrorListener.remove();
    }

    if (this.registerSuccessListener) {
      this.registerSuccessListener.remove();
    }

    return new Promise<PushNotificationToken>((resolve, reject) => {
      this.registerErrorCallback = reject;

      // Some issue with your setup and push will not work
      this.registerErrorListener = this.plugin.addListener(
        'registrationError',
        (error: any) => {
          this.registerErrorCallback = undefined;
          reject(error);
        }
      );

      // On success; save the token in Firebase
      this.registerSuccessListener = this.plugin.addListener(
        'registration',
        (token: PushNotificationToken) => {
          this.registerErrorCallback = undefined;
          resolve(token);
        }
      );

      // Register with Apple / Google to receive push via APNS/FCM
      this.plugin.register();
    });
  }

  unregister() {
    return fcm.deleteInstance();
  }

  async getToken() {
    const register = this.register();

    if (!register) {
      return null;
    }

    return (await fcm.getToken()).token;
  }

  /**
   * Called when a notification is received when the app is open.
   *
   * @param callback
   */
  onNotificationReceived(callback: (notification: PushNotification) => void) {
    if (!this.canPush) {
      return;
    }

    return this.plugin.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => callback(notification)
    );
  }

  /**
   * Called when a notification is tapped on to open a closed app.
   *
   * @param callback
   */
  onNotificationActionPerformed(
    callback: (notification: PushNotificationActionPerformed) => void
  ) {
    if (!this.canPush) {
      return;
    }

    return this.plugin.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => callback(notification)
    );
  }
}

export default Push;
