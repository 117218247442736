import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { applyDeliveryAddress, clearSkipLocation } from '../../store/actions';
import useFulfillmentMethodEnabled from '../../hooks/useFulfillmentMethodEnabled';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import Loader from '../../components/ui/Loader';
import GoogleMapsSearch from '../../components/search/GoogleMapsSearch';
import StoreSearch from '../../components/ui/StoreSearch';
import { getStreetViewImageUrl } from '../../services/google/street-view';
import {
  selectSkipLocation,
  selectDeliveryMethod,
  selectLocationAddressFormattedAddress,
  selectDeliveryTimeframeLabel,
  selectRecentDeliveryAddresses,
  selectStores,
} from '../../store/selectors';
import { useAnalyticsContext } from '../../services/analytics/context';
import { DeliveryMethodType } from '../../types';

const DeliveryLocation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalyticsContext();
  const methodEnabled = useFulfillmentMethodEnabled();
  const stores = useSelector(selectStores);

  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const locationAddressTitle = useSelector(
    selectLocationAddressFormattedAddress
  );
  const recentDeliveryAddresses = useSelector(selectRecentDeliveryAddresses);
  const [isDelivery, setIsDelivery] = useState(true);
  const [title, setTitle] = useState('Confirm address');
  const [backTitle, setBackTitle] = useState('Delivery time');
  const skipLocation = useSelector(selectSkipLocation);

  const streetViewImage = getStreetViewImageUrl(locationAddressTitle, 400);
  const streetViewImageAltText = `Street view of ${locationAddressTitle}`;

  const onSelect = (value) => {
    dispatch(applyDeliveryAddress(value));

    setLoaderEnabled(true);

    analytics.ecommerce.checkoutStepCompleted(3);

    setTimeout(function () {
      setLoaderEnabled(false);
      return history.push('/checkout/notes');
    }, 1000);
  };

  const handleBackClick = () => {
    history.goBack();

    if (skipLocation === history.location.pathname) {
      dispatch(clearSkipLocation());
    }
  };

  const deliveryTimeframeLabel = useSelector(selectDeliveryTimeframeLabel);
  const deliveryMethodType = useSelector(selectDeliveryMethod);

  useEffect(() => {
    if (!methodEnabled) {
      if (history.location.pathname === '/checkout/location') {
        history.push('/checkout?error=true');
      }
    }
  }, [history, methodEnabled]);

  useEffect(() => {
    switch (deliveryMethodType) {
      case DeliveryMethodType.PRIORITY_DELIVERY:
      case DeliveryMethodType.REGULAR_DELIVERY:
      case DeliveryMethodType.SCHEDULED_DELIVERY:
        setTitle('Confirm address');
        setBackTitle('Delivery time');
        setIsDelivery(true);
        break;
      default:
        setTitle('Choose store');
        setBackTitle('Choose time');
        setIsDelivery(false);
    }
  }, [deliveryMethodType]);

  useEffect(() => {
    if (skipLocation === history.location.pathname) {
      setBackTitle('Order method');
    }
  }, [skipLocation, history.location.pathname]);

  const contentRender = () => {
    return isDelivery ? (
      <GoogleMapsSearch
        onSelect={(value) => onSelect(value)}
        recentDeliveryAddresses={recentDeliveryAddresses}
      />
    ) : (
      <StoreSearch onSelect={(value) => onSelect(value)} stores={stores} />
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <HeaderBack
          description={deliveryTimeframeLabel}
          title={backTitle}
          onClick={handleBackClick}
        />
        <HeaderTitle title={title} borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>{contentRender()}</IonContent>
      <Loader
        isEnabled={loaderEnabled}
        isAddress
        background={isDelivery ? '#FFFFFF' : '#002C65'}
        title={locationAddressTitle}
        image={<img src={streetViewImage} alt={streetViewImageAltText} />}
      />
    </IonPage>
  );
};

export default DeliveryLocation;
