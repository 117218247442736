import { Action } from 'redux';

export interface Order {
  id: number;
  order_number?: number;
  created_at?: string;
}

interface OrderMap {
  [key: string]: Order;
}

export interface OrderState {
  byId: OrderMap;
  currentOrder?: number;
}

export enum OrderActionType {
  RECEIVE_ORDERS = 'RECEIVE_ORDERS',
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  APPLY_CURRENT_ORDER = 'APPLY_CURRENT_ORDER',
  CLEANUP_ORDERS = 'CLEANUP_ORDERS',
}

// Import a complete set of orders
export interface ReceiveOrdersAction extends Action {
  type: OrderActionType.RECEIVE_ORDERS;
  payload: {
    orders: Order[];
  };
}

// Append/update a single order
export interface ReceiveOrderAction extends Action {
  type: OrderActionType.RECEIVE_ORDER;
  payload: {
    order: Order;
  };
}

export interface ApplyCurrentOrderAction extends Action {
  type: OrderActionType.APPLY_CURRENT_ORDER;
  payload: Pick<OrderState, 'currentOrder'>;
}

// Delete any orders in the state that don't match the IDs provided
export interface CleanupOrdersAction extends Action {
  type: OrderActionType.CLEANUP_ORDERS;
  payload: {
    orderIds: Order['id'][];
  };
}

export type OrderActionTypes =
  | ReceiveOrdersAction
  | ReceiveOrderAction
  | ApplyCurrentOrderAction
  | CleanupOrdersAction;
