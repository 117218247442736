import WashAndWearLowSheenLogo from '../assets/images/product/paint/logo/wash&wear-low-sheen@2x.png';
import OneStepPrepLogo from '../assets/images/product/paint/logo/1step-prep@2x.png';
import AquanamelLogo from '../assets/images/product/paint/logo/aquanamel-gloss@2x.png';
import WeathershieldLogo from '../assets/images/product/paint/logo/weathershield-2@2x.png';
import SuperEnamelLogo from '../assets/images/product/paint/logo/super-enamel-gloss@2x.png';
import CeilingLogo from '../assets/images/product/paint/logo/ceiling-white@2x.png';
import EnamelLogo from '../assets/images/product/paint/logo/professional-enamel@2x.png';
import InteriorExteriorLogo from '../assets/images/product/paint/logo/professional-interior-exterior@2x.png';
import InteriorLogo from '../assets/images/product/paint/logo/professional-interior@2x.png';
import PrepLogo from '../assets/images/product/paint/logo/professional-preparation@2x.png';
import BergerGoldLabelLogo from '../assets/images/product/paint/logo/berger-gold-label@2x.png';
import HadrianLogo from '../assets/images/product/paint/logo/hadrian@2x.png';
import { getStrengthIconOutlineSmall } from './colour';
import React from 'react';

export default function imageComponent(
  productRange: string,
  productName: string
) {
  switch (productRange) {
    case 'Dulux':
      switch (productName) {
        case 'Wash&Wear':
          return WashAndWearLowSheenLogo;
        case '1Step Prep':
          return OneStepPrepLogo;
        case 'Aquanamel':
          return AquanamelLogo;
        case 'Weathershield':
          return WeathershieldLogo;
        case 'Super Enamel':
          return SuperEnamelLogo;
        case 'Ceiling White':
          return CeilingLogo;
        default:
          return '';
      }
    case 'Dulux Professional':
      switch (productName) {
        case 'Professional Enamel':
          return EnamelLogo;
        case 'Professional Interior/Exterior':
          return InteriorExteriorLogo;
        case 'Professional Interior':
          return InteriorLogo;
        case 'Professional Preparation':
          return PrepLogo;
        default:
          return '';
      }
    case 'Berger':
      switch (productName) {
        case 'Berger Gold Label':
          return BergerGoldLabelLogo;
        default:
          return '';
      }
    case 'Hadrian':
      switch (productName) {
        case 'Hadrian':
          return HadrianLogo;
        default:
          return '';
      }
    case 'Application':
    case 'Preperation':
    case 'Protection':
      return '';
    default:
      return '';
  }
}

export function displayStrengthIconInline(strength: number) {
  const StrengthIcon = getStrengthIconOutlineSmall(strength);
  if (StrengthIcon) {
    return (
      <StrengthIcon
        width="15"
        height="15"
        style={{ display: 'inline-block', margin: '-5px 3px auto auto' }}
      />
    );
  }
}
