import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import {
  selectedTimeLabel,
  selectedOrderSummaryTitle,
  getStoreName,
  selectedLocationLabel,
  selectedNoteLabel,
  isPickup,
} from '../../utils/text';
import { useAnalyticsContext } from '../../services/analytics/context';
import FooterCapContainer from '../ui/FooterCapContainer';
import StandardConditionsOfSaleModal from '../terms/StandardConditionsOfSaleModal';

const OrderSummary = (props) => {
  const [showModal, setShowModal] = useState(false);
  const analytics = useAnalyticsContext();

  const title = props.orderSending
    ? 'Confirming your order'
    : 'Placing your order';

  const body = props.orderSending
    ? "We're finalising the details around your order."
    : "Please don't close or navigate away from this screen until confirmed.";

  const getSummaryTitle = () => {
    if (isPickup(props.type)) {
      return `${selectedOrderSummaryTitle(props.type)} ${getStoreName(
        props.address
      )}`;
    }

    return props.method;
  };

  function onClick() {
    analytics.trackEvent('Order Placed', {
      properties: {
        category: 'Checkout',
      },
    });
    props.setOrderPlaced(true);
  }

  function handleUndo() {
    // Only allow the order to be cancelled if it's not already sending
    if (!props.orderSending) {
      props.cancelTimeout();
    }
  }

  return (
    <div className="h-full bg-dulux-bg flex flex-col">
      <div className="flex h-full flex-col overflow-scroll">
        {(props.method || props.time) && (
          <div className="p-1 bg-gray-200 border-b border-gray-300 flex flex-col justify-center items-center">
            {props.method && (
              <div className="text-gray-600">{getSummaryTitle()}</div>
            )}
            {props.time && (
              <div className="text-gray-600">
                {selectedTimeLabel(props.type)} {props.time}
              </div>
            )}
          </div>
        )}
        {!props.orderPlaced ? (
          <>
            {props.address && (
              <div className="p-1 border-b border-gray-300 flex flex-col">
                <div className="text-gray-600 text-center">
                  {selectedLocationLabel(props.type)}
                </div>
                <div className="text-gray-600 text-center text-h3 font-heading text-dulux-dark overflow-y-scroll">
                  {props.address}
                </div>
              </div>
            )}
            {props.orderReference && (
              <div className="p-1 border-b border-gray-300 flex flex-col">
                <div className="text-gray-600 text-center">Order reference</div>
                <div className="text-gray-600 text-center text-h3 font-heading text-dulux-dark overflow-y-scroll">
                  {props.orderReference}
                </div>
              </div>
            )}
            {props.note && (
              <div className="p-1 border-b border-gray-300 flex flex-col">
                <div className="text-gray-600 text-center">
                  {selectedNoteLabel(props.type)}
                </div>
                <div className="text-gray-600 text-center text-h3 font-heading text-dulux-dark overflow-y-scroll">
                  {props.note}
                </div>
              </div>
            )}
            <div className="border px-2 py-3 m-1 rounded bg-white text-center">
              <p>Every order submitted is subject to our</p>
              <p
                onClick={() => setShowModal(true)}
                className="text-dulux-primary"
              >
                standard conditions of sale
              </p>
            </div>
          </>
        ) : (
          <div className="p-1 px-4 h-full flex flex-col justify-center items-center">
            <div className="spinner px-1 mb-2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <h2 className="text-h2 font-heading text-dulux-dark mb-3">
              {title}
            </h2>
            <p className="text-dulux-dark text-center text-small">{body}</p>
          </div>
        )}
      </div>
      <FooterCapContainer className="p-1">
        {!props.orderPlaced ? (
          <Button onClick={onClick} theme="primary">
            Send order
          </Button>
        ) : (
          <Button
            theme="error"
            onClick={handleUndo}
            disabled={props.orderSending}
          >
            Undo order
          </Button>
        )}
      </FooterCapContainer>
      <StandardConditionsOfSaleModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

OrderSummary.propTypes = {
  type: PropTypes.string,
  method: PropTypes.string,
  address: PropTypes.string,
  note: PropTypes.string,
  orderReference: PropTypes.string,
  time: PropTypes.string,
  orderPlaced: PropTypes.bool,
  orderSending: PropTypes.bool,
  onSubmitOrder: PropTypes.func.isRequired,
  cancelTimeout: PropTypes.func.isRequired,
  setOrderPlaced: PropTypes.func.isRequired,
};

OrderSummary.defaultProps = {
  type: '',
  method: '',
  address: '',
  note: '',
  orderReference: '',
  time: '',
  orderPlaced: false,
  orderSending: false,
  onSubmitOrder: null,
  cancelTimeout: null,
  setOrderPlaced: null,
};

export default OrderSummary;
