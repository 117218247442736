import { createSelector } from 'reselect';
import { selectSupportState, selectCurrentSupportCategory } from './root';
import { getSupportCategory } from '../reducers/support/reducer';

export const selectSelectedSupportCategory = createSelector(
  [selectSupportState, selectCurrentSupportCategory],
  (supportState, selectedCategory) => {
    if (!selectedCategory) {
      return null;
    }

    return getSupportCategory(supportState, selectedCategory);
  }
);
