import React from 'react';
import PropTypes from 'prop-types';

const CartHeader = (props) => {
  return (
    <div className="bg-white p-1 flex items-center border-b border-gray-400">
      <div className="w-2/12 border-r border-gray-400 py-1 pr-1">
        <span className="text-small">Qty</span>
      </div>
      <div
        className={
          'flex justify-center items-center border-gray-400 py-1 px-1 ' +
          (props.hasPrice ? 'w-8/12 border-r' : 'w-10/12')
        }
      >
        <span className="text-small">Product</span>
      </div>
      {props.hasPrice && (
        <div className="w-2/12 px-1 text-right">
          <span className="text-small">Price</span>
        </div>
      )}
    </div>
  );
};

CartHeader.propTypes = {
  hasPrice: PropTypes.bool,
};

CartHeader.defaultProps = {
  hasPrice: false,
};

export default CartHeader;
