import React from 'react';

const PrivacyPolicy = (props) => {
  return (
    <div className="p-1">
      <div className="mb-6">
        <h2 className="font-heading text-h2 mb-1">
          Our commitment to protect your privacy
        </h2>
        <p className="mb-1">
          DuluxGroup recognises the importance of your privacy and how important
          it is for us to protect your personal information. We are committed to
          protecting personal information which we hold and to complying with
          all relevant privacy laws in the jurisdictions in which we operate.
          This document describes how we manage your personal information and
          safeguard your privacy.
        </p>
        <p className="mb-1">
          References to ‘DuluxGroup’, ‘we’ and ‘us’ include DuluxGroup Limited
          and its related bodies corporate. Please see our website at
          www.duluxgroup.com.au for more information about our companies and
          brands.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          What personal information do we collect and hold about you, and how do
          we collect it?
        </h2>
        <p className="mb-1">
          The kinds of personal information we collect and hold will depend upon
          the type of products and services we provide to you or obtain from
          you. We may collect your personal information:
        </p>
        <ul className="mb-1 pl-3" style={{ listStyle: 'disc' }}>
          <li className="mb-1">
            when you request a product or service from us or enter a competition
            of ours;
          </li>
          <li className="mb-1">
            when we make a record of any communication and other interaction you
            have with us (including telephone, email and online); and
          </li>
          <li className="mb-1">
            if you have or had one of our products, when we record information
            about your use of that product or your opinions about that product
            when you respond to a survey.
          </li>
        </ul>
        <p className="mb-1">
          The type of information we collect and hold about you may include your
          name, address, telephone numbers, email address, bank account and
          credit card details, date of birth, identification information and
          organisation. If you apply for trade credit, we may also collect and
          hold credit information about you.
        </p>
        <p className="mb-1">
          We will also collect information about you when you use our website -
          see the &apos;Your privacy on our websites and the Business
          +Plus&apos; section below.
        </p>
        <p className="mb-1">
          We generally collect personal information directly from you whenever
          it is reasonable practicable to do so. We may also collect personal
          information from other parties such as our related bodies corporate,
          service providers, our agents or representatives, your employer, your
          agents or representatives (such as your advisers) and from publicly
          available sources of information.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          What happens if you do not provide the information we request?
        </h2>
        <p className="mb-1">
          The personal information we request is generally optional, but if you
          choose not to provide personal information to us, we may not be able
          to fulfil your request or provide you with the product or service you
          require. Sometimes, however, there are situations where we are
          required by law to collect certain personal information from you and,
          if this is the case, we will take reasonable steps to inform you of
          the law that imposes this requirement.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          Why do we collect your personal information, and what do we do with
          it?
        </h2>
        <p className="mb-1">
          We collect, use and disclose your personal information to carry on our
          business; to provide, administer, improve and personalise our products
          and services; to identify and interact with you; to let you know about
          other products and services which might interest you; to assess and
          improve the quality of our products and services; to protect our
          lawful interests; to deal with your concerns and enquiries; and to
          assist us generally in managing transactions with you, suppliers,
          consumers and others.
        </p>
        <p className="mb-1">
          We may contact you by telephone, mail, electronically or otherwise to
          provide you with marketing, advertising or other material about our
          products and services we think you might be interested in, or to
          follow up on your interest in particular products and services, unless
          or until you tell us not to or we are restricted by law. We may also
          disclose your personal information to market research agencies, who
          may contact you in a similar manner to conduct market research in
          relation to products and services you have acquired from or are
          offered by DuluxGroup and related matters (unless or until you tell us
          not to). If you do not want to receive direct marketing messages or
          want to change your contact preferences, contact us (see &lsquo;How to
          contact us&rsquo; below).
        </p>
        <p className="mb-1">
          We may also exchange information with other DuluxGroup entities and
          with our service providers including our print service providers, call
          centres and mail houses, advertising agencies, accountants, auditors
          and lawyers, credit reporting and collection agencies, and providers
          of archival, banking, payment, data processing, data analysis,
          information broking, research, investigation, website and technology
          services. We may also disclose your personal information in corporate
          transactions involving the transfer of all or part of our assets
          (including debts) or business or in a corporate restructure.
        </p>
        <p className="mb-1">
          We may also be required or authorised by law to disclose your personal
          information. For example, we may disclose your personal information to
          a court in response to a subpoena or to a relevant taxation agency. We
          may also disclose information to other persons where you have provided
          your consent for us to do so.
        </p>
        <p className="mb-1">
          Some of the third parties to which we disclose personal information
          may be located in New Zealand, Papua New Guinea, Fiji, Singapore, Hong
          Kong, Peoples Republic of China, the United State of America, the
          United Kingdom, throughout the European Union and other countries and
          you acknowledge that, while they will often be subject to
          confidentiality or privacy obligations, they may not always follow the
          requirements of Australian privacy laws.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          How do we hold and protect your personal information?
        </h2>
        <p className="mb-1">
          We hold personal information electronically and in hard copy form. We
          take reasonable steps to protect your personal information from loss,
          misuse, modification, or unauthorised access, use and disclosure.
          These steps include access control for our buildings, confidentiality
          obligations imposed on our employees and service providers, and the
          use of security measures for computer system access.
        </p>
        <p className="mb-1">
          Once we no longer require your personal information for any of the
          purposes for which it was collected we will, subject to any legal
          obligations and responsibilities, take reasonable steps to destroy or
          de-identify your personal information.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          Your privacy on our websites and the Business +Plus
        </h2>
        <p className="mb-1">
          This privacy policy also applies to the websites operated by us under
          various domain names (including dulux.com.au and the other sites we
          operate on which this privacy policy is made available) and the online
          ‘Business +Plus’ service we offer to our customers. Please refer to
          our Business +Plus terms and conditions for more information about how
          we handle personal information in relation to this service. If you
          transact electronically with another member of DuluxGroup, you should
          refer to the website of that member for more information on its
          website privacy and security procedures.
        </p>
        <p className="mb-1">
          We operate websites which may utilise ‘cookies’ to collect personal
          information. A cookie is a small file that is stored on your hard disk
          by a website. Cookies contain information which is readable by the
          website that issued the cookie to you. Cookies cannot execute programs
          or access other information on your computer. Your web browser can be
          configured to reject cookies issued by our websites, but if you do so,
          some functionality that we provide on our websites may become
          unavailable as a result.
        </p>
        <p className="mb-1">
          When you visit a website of ours, we may collect the following
          information from you: your IP address, domain name, the date, time and
          duration of your visit, the pages and documents you accessed, the
          previous site you had accessed (ie the referring URL), the type of
          browser you are using and information you volunteer such as survey
          information, name and email address.
        </p>
        <p className="mb-1">
          While we take care that the personal information you give us on our
          websites is protected, the Internet is not a secure environment. If
          you are considering sending us any personal information through one of
          our websites, the Business +Plus or by other means, please be aware
          that the information may be insecure in transit, particularly where no
          encryption is used (eg. email, standard HTTP). We are subject to laws
          requiring us to protect information once it comes into our possession.
        </p>
        <p className="mb-1">
          You may be able to access external websites, operated by companies
          unrelated to us, by clicking on links we have provided. Those other
          websites are not subject to our privacy standards and we are not
          responsible for their privacy practices or the content of such other
          websites. You should contact those websites directly to ascertain
          their privacy standards, policies and procedures.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">Credit reporting</h2>
        <p className="mb-1">
          This section applies in relation to our handling personal information
          from credit reporting bodies (CRBs) and certain other consumer
          credit-related personal information described below (credit
          information). We generally handle credit information in the context of
          commercial credit rather than consumer credit arrangements. For
          example, we may handle information about an individual’s consumer
          credit worthiness where we provide trade credit to sole traders, or
          where directors provide personal guarantees in relation to trade
          credit we extend to companies.
        </p>
        <p className="mb-1">
          We may collect and hold any types of credit information about an
          individual permitted under the Privacy Act, including:
        </p>
        <ul className="mb-1 pl-3" style={{ listStyle: 'disc' }}>
          <li className="mb-1">
            name, sex, date of birth, driver’s licence number, employer and
            three most recent addresses;
          </li>
          <li className="mb-1">
            confirmation of previous information requests about the individual
            to CRBs made by other credit providers and credit insurers;
          </li>
          <li className="mb-1">
            details of previous credit applications, including the amount and
            type of credit and credit limit;
          </li>
          <li className="mb-1">
            details of current and previous credit arrangements, including
            credit providers, start/end dates and certain terms and conditions;
          </li>
          <li className="mb-1">
            permitted payment default information, including information about
            related payment arrangements and subsequent repayment;
          </li>
          <li className="mb-1">
            information about serious credit infringements (e.g. fraud);
          </li>
          <li className="mb-1">
            information about adverse court judgments and insolvency;
          </li>
          <li className="mb-1">
            publicly available information about the individual’s credit
            worthiness; and
          </li>
          <li className="mb-1">
            any credit score or credit risk assessment indicating a CRB’s or
            credit provider&apos;s analysis of the individual’s eligibility for
            consumer credit.
          </li>
        </ul>
        <p className="mb-1">
          This credit information may include information about your
          arrangements with other credit providers as well as with us.
        </p>
        <p className="mb-1">
          Where we collect credit information about you from a CRB, we may use
          that information to produce our own assessments and ratings in respect
          of your credit worthiness.
        </p>
        <p className="mb-1">
          We may disclose credit information to CRBs to assist the CRBs to
          maintain information about you to provide to other credit providers
          for credit assessments. We may collect credit information from CRBs
          for purposes including, to the extent permitted by law, to assess your
          credit or guarantee application, manage your credit/guarantee, assign
          debts and collect overdue payments. We may also exchange credit
          information with debt buyers and other credit providers.
        </p>
        <p className="mb-1">The CRBs we use may include: </p>
        <ul className="mb-1 pl-3" style={{ listStyle: 'disc' }}>
          <li className="mb-1">
            Equifax, GPO Box 964 North Sydney 2059, www.mycreditfile.com.au,
            1300 762 207;
          </li>
          <li className="mb-1">
            illion, Ground floor, 479 St. Kilda Road Melbourne 3004,
            www.illion.com.au, email is PACaustral@illion.com.au, 1300 734 806;
          </li>
          <li className="mb-1">
            Experian, GPO Box 1969, North Sydney NSW 2060,
            <a
              href="http://www.experian.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.experian.com.au
            </a>
            , 1300 784 134;
          </li>
          <li className="mb-1">
            Tasmanian Collection Service, 29 Argyle Street, Hobart,
            www.tascol.com.au, enquiries@tascol.com.au, (03) 6213 5555.
          </li>
        </ul>
        <p className="mb-1">You have the right to request CRBs not to: </p>
        <ul className="mb-1 pl-3" style={{ listStyle: 'disc' }}>
          <li className="mb-1">
            use your credit information to determine your eligibility to receive
            direct marketing from credit providers; and
          </li>
          <li className="mb-1">
            use or disclose your credit information it holds about you for a
            period of 21 days (called a &lsquo;ban period&rsquo; without your
            consent if you believe on reasonable grounds that you have been the
            victim of fraud, including identity fraud. When you apply for
            credit, you are taken to agree to us accessing your personal
            information (including credit-related information) held with a
            credit reporting body, even if there is a ban period in place, for
            the purposes of assessing an application for credit or in order to
            collect overdue payments.
          </li>
        </ul>
        <p className="mb-1">
          CRBs may use credit information they hold to respond to requests from
          us or other credit providers to &quot;prescreen&quot; you for direct
          marketing. You can ask a not to do this. However, if you are our
          customer you may still receive direct marketing from us (unless you
          ask us not to) that has not been &quot;pre-screened&quot;.
        </p>
        <p className="mb-1">
          Please see other sections of this Privacy Policy for further
          information regarding access, correction, complaints, disclosures
          (including to other countries) of personal information and how we
          collect and hold personal information. In some cases, we are subject
          to further obligations under the Privacy Act regarding credit
          information, and this Privacy Policy is not intended to limit or
          exclude those obligations. Additional privacy consents and
          notifications may also apply for our credit-related services.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          What rights do you have in relation to your personal information which
          we hold?
        </h2>
        <p className="mb-1">
          We take reasonable steps to ensure that the personal information we
          collect, use and disclose is accurate, complete and up-to-date. If you
          become aware of any errors in your personal information or you change
          your personal details such as your name, address or bank account
          details, please contact us at your earliest convenience.
        </p>
        <p className="mb-1">
          You can gain access to personal information that we hold about you,
          subject to certain exceptions allowed by law. Please contact us (see
          &lsquo;How to contact us&rsquo; below) if you would like details about
          the personal information we may hold about you or if you would like us
          to update or correct it. We will handle any request in accordance with
          the relevant privacy regulations. We may require you to complete a
          request form and we Doc ID 609560053/v3 4 also reserve the right to
          charge a reasonable fee for gaining access. We endeavour to deal with
          such requests promptly, but requests for a large amount of
          information, or information which is not currently in use, may require
          significant time to extract.
        </p>
        <p className="mb-1">
          Generally, if you request us to do so we will amend any personal
          information about you held by us which is inaccurate, incomplete or
          out of date.
        </p>
        <p className="mb-1">
          If we are not able or unwilling to provide you with access to any
          personal information or to correct any personal information held by
          us, we will provide you with our reasons. If we do not agree to amend
          your personal information and you disagree, you may request that we
          make a note of your request to update the information, which says that
          you believe the information is inaccurate, incomplete, misleading or
          out-of-date.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">
          You can contact us to ask us to do certain things, or to tell us you
          have a concern
        </h2>
        <p className="mb-1">
          You may also contact us to request us to stop using your personal
          information to contact you about our products and services. You can
          contact us if you wish to obtain more information about the way we
          manage personal information about you which we hold or if you are
          concerned that we may have breached our privacy obligations and wish
          to make a complaint.
        </p>
        <p className="mb-1">
          If you make a complaint, we will endeavour to respond and resolve your
          complaint promptly. If your complaint is about credit information, we
          may need to check with the CRB involved. We will let you know we have
          received your complaint (usually within 7 days). We will keep a record
          of your complaint and keep you updated on the steps we are taking to
          help resolve it. We usually give our final response within 45 days.
        </p>
        <p className="mb-1">
          For more information about privacy generally, or if your compliant is
          not resolved to your satisfaction, you may contact the Office of the
          Australian Information Commissioner at
          <a
            href="http://www.oaic.gov.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.oaic.gov.au
          </a>
          and on 1300 363 992.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="font-heading text-h3 mb-1">How to contact us</h2>
        <p className="mb-1">
          Please visit the Contact Us page of this website. You can also contact
          our Privacy Officer via privacy@duluxgroup.com.au or call +613 9263
          5678.
        </p>
      </div>
      <div>
        <h2 className="font-heading text-h3 mb-1">
          Changes to this privacy statement
        </h2>
        <p className="mb-1">
          We may change our privacy policies from time to time to comply with
          new laws or industry codes of practice which are developed or to align
          with changes to our business. It is your responsibility to refer to
          our privacy policies from time to time to familiarise yourself with
          any changes. We encourage you to visit our websites regularly for any
          updates to our privacy policies.
        </p>
        <p className="mb-1">Last updated: 21 January 2019</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
