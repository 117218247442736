/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Address from '../../models/Address';
import OrderMethodDTC from '../order/OrderMethodDTC';
import { getEnabledFulfillmentList } from '../../utils/fulfillment';
import { DeliveryMethodType } from '../../types';
import useRemoteConfig from '../../hooks/useRemoteConfig';

const StoreSearch = (props) => {
  const remoteConfig = useRemoteConfig();
  const globalFulfillmentOptions = getEnabledFulfillmentList(
    remoteConfig.fulfillmentOptions
  );

  function storesGloballyEnabled() {
    return globalFulfillmentOptions.includes(
      DeliveryMethodType.SCHEDULED_PICKUP
    );
  }

  // The an individual store's fulfillment config has scheduled_pickup enabled
  function storeHasScheduledPickupEnabled(store) {
    return getEnabledFulfillmentList(store.fulfillmentOptions).includes(
      DeliveryMethodType.SCHEDULED_PICKUP
    );
  }

  const orderLocations = props.stores.map((data, key) => (
    <OrderMethodDTC
      key={key}
      title={data.area}
      label={data.shortTitle}
      type={data.type || 'dulux'}
      enabled={storesGloballyEnabled() && storeHasScheduledPickupEnabled(data)}
      onClick={() => props.onSelect(new Address(data.address))}
    />
  ));

  function render() {
    return (
      <>
        <p className="p-1">
          As this is a trial, we have only included participating stores.
        </p>
        <div className="h-full overflow-scroll">{orderLocations}</div>
      </>
    );
  }

  return <div className="h-full flex flex-col">{render()}</div>;
};

StoreSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  stores: PropTypes.array,
};

StoreSearch.defaultProps = {
  onSelect: null,
  stores: [],
};

export default StoreSearch;
