import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

export function removeObject(key: string) {
  return Storage.remove({ key });
}

export async function setObject(key: string, value: any) {
  const json = JSON.stringify(value);

  await Storage.set({ key, value: json });
}

export async function getItem(key: string) {
  const ret = await Storage.get({ key });
  return ret.value;
}

export async function getObject(key: string) {
  const value = await getItem(key);

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (err1) {
    console.error('Error decoding JSON from storage', err1);

    try {
      await removeObject(key);
    } catch (err2) {
      console.error('Error removing invalid JSON from storage', err2);
    }
  }

  return null;
}
