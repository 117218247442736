import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import HeaderTitle from '../../components/header/HeaderTitle';
import Estimate from '../../services/fulfillment/estimate';
import HeaderSubTitle from '../../components/header/HeaderSubTitle';
import { get } from 'lodash-es';
import { DeliveryMethodType } from '../../types';

const EstimatePage = () => {
  const estimate = new Estimate();

  const [properties, setProperties] = useState(
    JSON.stringify(estimate.deliveryProperties)
  );

  const [date, setDate] = useState(new Date().toLocaleString());
  const dateObject = new Date();

  const [ignore, setIgnore] = useState(0);

  try {
    dateObject.setTime(Date.parse(date));

    if (dateObject instanceof Date) {
      estimate.simulateDate = dateObject;
    }
  } catch (e) {
    console.warn(e);
  }

  try {
    estimate.deliveryProperties = JSON.parse(properties);
  } catch (e) {
    console.warn(e);
  }

  let earliestTimes = [];
  let scheduleOptions = [];

  try {
    earliestTimes = [
      estimate.earliestDeliveryTime(DeliveryMethodType.PRIORITY_DELIVERY),
      estimate.earliestDeliveryTime(DeliveryMethodType.PRIORITY_PICKUP),
      estimate.earliestDeliveryTime(DeliveryMethodType.REGULAR_DELIVERY),
      estimate.earliestDeliveryTime(DeliveryMethodType.REGULAR_PICKUP),
    ];

    scheduleOptions = [
      estimate.deliveryTimes(DeliveryMethodType.PRIORITY_DELIVERY, 5, ignore),
      estimate.deliveryTimes(DeliveryMethodType.PRIORITY_PICKUP, 5, ignore),
      estimate.deliveryTimes(DeliveryMethodType.REGULAR_DELIVERY, 5, ignore),
      estimate.deliveryTimes(DeliveryMethodType.REGULAR_PICKUP, 5, ignore),
    ];
  } catch (e) {
    console.warn(e);
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderTitle title="Dev" borderColour="#e2e8f0" />
        <HeaderSubTitle title="Estimation Logic" backgroundColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        <div className="my-1">
          <textarea
            className="w-full border p-1"
            value={properties}
            onChange={(e) => setProperties(e.target.value)}
          />
        </div>

        <div className="my-1">
          <input
            className="w-full border p-1"
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Date"
          />
        </div>

        <div className="my-1">
          <input
            className="w-full border p-1"
            type="number"
            value={ignore}
            onChange={(e) => setIgnore(Number(e.target.value))}
            placeholder="Slots to ignore"
          />
        </div>

        <div className="my-1">For date: {dateObject.toLocaleString()}</div>

        <div className="mt-2">
          <strong>Earliest times:</strong>

          {earliestTimes.map((time, i) => (
            <div key={i}>
              {time && (
                <div className="my-2">
                  <strong className="mb-1">{time.method}</strong>
                  <ul>
                    <li>Label: {time.label}</li>
                    <li>Dispatch: {time.dispatch.toLocaleString()}</li>
                    <li>Delivery: {time.delivery.toLocaleString()}</li>
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-2">
          <strong>Schedule Options:</strong>

          {scheduleOptions.map((schedule, i) => (
            <div className="my-2" key={i}>
              <strong className="mb-1">
                {get(schedule.flat(), '[0].method')}
              </strong>
              {schedule.map((day, di) => (
                <div key={di} className="mb-1">
                  <ul>
                    {day.map((time, ti) => (
                      <li key={ti}>{time.delivery.toLocaleString()}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EstimatePage;
