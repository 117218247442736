import React from 'react';
import PropTypes from 'prop-types';
import { IonItem, IonLabel, IonSelect } from '@ionic/react';
import useInputValidation from '../../hooks/useInputValidation';

const InputSelectGroup = (props) => {
  const {
    labelClass,
    setLabelClass,
    handleOnBlur,
    makeDirty,
    hasError,
    borderClass,
    marginBottomClass,
  } = useInputValidation(props.value, props.required);
  const ionlabelClass = `text-h2 leading-h4 ` + labelClass;
  const ionItemClass =
    `relative ion-bbe-bg-light ` +
    `ion-no-padding ion-item-inner-padding-start-none ion-item-inner-padding-end-none`;
  const ionSelectClass =
    `ion-bbe-select ion-bbe-placeholder ion-padding w-full font-heading leading-larger ` +
    `ion-bbe-input--standard text-inspirations-blue bg-dulux-white rounded-xl border ` +
    `pr-4 mt-2 ` +
    `${marginBottomClass} ${borderClass}`;

  function handleChange(e) {
    props.onChange(e.detail.value);
    makeDirty();
  }

  return (
    <>
      <IonItem lines="none" className={ionItemClass}>
        {props.label && (
          <IonLabel position="floating" className="leading-none">
            <div className={ionlabelClass}>{props.label}</div>
          </IonLabel>
        )}
        <IonSelect
          interface={props.interface}
          interfaceOptions={props.interfaceOptions}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onIonBlur={() => handleOnBlur()}
          onIonFocus={() => setLabelClass('text-inspirations-blue')}
          onIonChange={(e) => handleChange(e)}
          className={ionSelectClass}
        >
          {props.options}
        </IonSelect>
      </IonItem>
      {hasError && <div className="text-dulux-error">{props.errorMessage}</div>}
      {props.serverErrorMessage && (
        <div className="text-dulux-error">
          {props.serverErrorMessage(props.name)}
        </div>
      )}
    </>
  );
};

InputSelectGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.node,
  placeholder: PropTypes.string,
  interface: PropTypes.string,
  interfaceOptions: PropTypes.object,
  errorMessage: PropTypes.string,
  serverErrorMessage: PropTypes.func,
};

InputSelectGroup.defaultProps = {
  name: '',
  onChange: null,
  required: true,
  label: '',
  options: null,
  value: '',
  placeholder: '',
  interface: 'alert',
  interfaceOptions: {
    cssClass: 'ion-bbe-interface ion-text-wrap',
  },
  errorMessage: 'This field is required',
  serverErrorMessage: null,
};

export default InputSelectGroup;
