import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OrderMethodItem from './OrderMethodItem';
import OrderMethodSchedule from './OrderMethodSchedule';
import OrderMethods from '../order/OrderMethods';
import ShowAllDTC from './ShowAllDTC';
import { selectPricingTotals } from '../../store/selectors';
import { DeliveryMethodType } from '../../types';
import { DeliveryMethodGroupType } from '../../store/reducers/checkout/types';

const OrderMethodGroups = (props) => {
  const pricingTotals = useSelector(selectPricingTotals);
  const scheduleFreeLabel = 'Free*';
  const schedulePriceLabel = '$25.00*';
  const scheduleFreeAmountMin = 150;

  function getScheduleType(type) {
    switch (type) {
      case DeliveryMethodGroupType.DELIVERY:
        return DeliveryMethodType.SCHEDULED_DELIVERY;
      case DeliveryMethodGroupType.PICKUP:
        return DeliveryMethodType.SCHEDULED_PICKUP;
      default:
        return '';
    }
  }

  function getScheduleMethodType(type) {
    switch (type) {
      case DeliveryMethodGroupType.DELIVERY:
        return DeliveryMethodType.SCHEDULED_DELIVERY;
      case DeliveryMethodGroupType.PICKUP:
        return DeliveryMethodType.SCHEDULED_PICKUP;
      case DeliveryMethodGroupType.LOCKER:
        return DeliveryMethodType.AFTER_HOURS_PICKUP;
      default:
        return '';
    }
  }

  // Check the schedule type is in the allowed global config
  function scheduleMethodTypeEnabled(type) {
    return props.fulfillmentOptions.includes(getScheduleType(type));
  }

  function renderPrice(label, freeLabel, freeMinAmount) {
    if (!freeLabel && !freeMinAmount) {
      return label;
    }

    if (pricingTotals && pricingTotals.grossValue >= freeMinAmount) {
      return freeLabel;
    }

    return label;
  }

  function scheduleMaxLitres(items) {
    if (items && items.length > 0) {
      return items[items.length - 1].maxLitres;
    }

    return undefined;
  }

  function showPrice(type) {
    switch (type) {
      case DeliveryMethodGroupType.DELIVERY:
        return true;
      default:
        return false;
    }
  }

  function renderMethodItems(items) {
    return items.map((item, key) => {
      return (
        <OrderMethodItem
          key={key}
          title={item.title}
          enabled={props.fulfillmentOptions.includes(item.type)}
          time={item.deliveryTime.label}
          popular={item.popular}
          totalLitres={props.totalLitres}
          maxLitres={item.maxLitres}
          price={() =>
            renderPrice(
              item.price.label,
              item.price.freeLabel,
              item.price.freeAmountMin
            )
          }
          onClick={() =>
            props.onClick(item.type, item.nextUrl, item.deliveryTime)
          }
        />
      );
    });
  }

  function renderScheduledItem(method) {
    return (
      <OrderMethodSchedule
        onClick={() => {
          props.onClick(
            getScheduleMethodType(method.type),
            '/checkout/delivery-times'
          );
        }}
        time="Select time"
        showPrice={showPrice(method.type)}
        totalLitres={props.totalLitres}
        maxLitres={scheduleMaxLitres(method.items)}
        enabled={scheduleMethodTypeEnabled(method.type)}
        hasBorder={method.type !== DeliveryMethodGroupType.PICKUP}
        price={() =>
          renderPrice(
            schedulePriceLabel,
            scheduleFreeLabel,
            scheduleFreeAmountMin
          )
        }
      />
    );
  }

  // Each Delivery method group has a different way of determining its methods
  // Delivery method uses items
  // Pickup uses stores
  function getGroupItems(method) {
    switch (method.type) {
      case DeliveryMethodGroupType.DELIVERY:
        return renderMethodItems(method.items);
      case DeliveryMethodGroupType.PICKUP:
        return props.renderPriorityPickupStores();
      default:
        return true;
    }
  }

  const orderMethods = props.methods.map((method, i) => {
    const groupItems = getGroupItems(method);
    const scheduledItem = renderScheduledItem(method);

    if (groupItems || scheduledItem) {
      return (
        <OrderMethods
          key={i}
          type={method.type}
          title={method.title}
          info={method.deliveryInfo}
          groupItems={groupItems}
          renderPrice={renderPrice}
          scheduledItem={scheduledItem}
          onClick={props.onClick}
          method={method}
          totalLitres={props.totalLitres}
        />
      );
    }

    return null;
  });

  return (
    <div>
      {orderMethods}
      <ShowAllDTC
        setAllStoresVisible={props.setAllStoresVisible}
        showAllStores={props.showAllStores}
        renderStores={props.renderStores}
      />
    </div>
  );
};

OrderMethodGroups.propTypes = {
  methods: PropTypes.array,
  totalLitres: PropTypes.number,
  onClick: PropTypes.func,
  renderPriorityPickupStores: PropTypes.func,
  renderStores: PropTypes.func,
  fulfillmentOptions: PropTypes.array,
  setAllStoresVisible: PropTypes.func,
  showAllStores: PropTypes.bool,
};

OrderMethodGroups.defaultProps = {
  methods: [],
  totalLitres: 0,
  onClick: null,
  renderPriorityPickupStores: null,
  fulfillmentOptions: [
    DeliveryMethodType.REGULAR_PICKUP,
    DeliveryMethodType.REGULAR_DELIVERY,
    DeliveryMethodType.PRIORITY_PICKUP,
    DeliveryMethodType.PRIORITY_DELIVERY,
    DeliveryMethodType.SCHEDULED_PICKUP,
    DeliveryMethodType.SCHEDULED_DELIVERY,
  ],
  setAllStoresVisible: null,
  showAllStores: false,
  renderStores: null,
};

export default OrderMethodGroups;
