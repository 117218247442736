import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StarIcon } from '../../assets/icons/feedback/star.svg';
import { colourForRating, descriptionForRating } from '../../utils/rating';

const OrderFeedback = (props) => {
  const stars = [...Array(5).keys()].map((i) => {
    const rating = i + 1;

    return (
      <div key={rating} className="w-1/5 mr-1">
        <div
          className="pb-100 rounded-sm relative"
          style={{ backgroundColor: colourForRating(rating) }}
        >
          <div
            className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center text-dulux-dark text-h3"
            onClick={() => props.onClick(rating)}
          >
            <div
              className="absolute"
              style={{
                top: '51%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {rating}
            </div>
            <StarIcon />
          </div>
        </div>
        {rating % 2 !== 0 && (
          <h3 className="font-heading text-dulux-dark text-center mt-1">
            {descriptionForRating(rating)}
          </h3>
        )}
      </div>
    );
  });

  return <div className="bg-white flex py-1">{stars}</div>;
};

OrderFeedback.propTypes = {
  onClick: PropTypes.func.isRequired,
};

OrderFeedback.defaultProps = {
  onClick: null,
};

export default OrderFeedback;
