import { createSelector } from 'reselect';
import { selectCheckout } from './root';
import { DeliveryMethodGroupType } from '../reducers/checkout/types';
import Estimate from '../../services/fulfillment/estimate';
import { find } from 'lodash';
import { DeliveryMethodType } from '../../types';

export interface OrderMethod {
  name: string;
  title: string;
  type: DeliveryMethodType;
  time: string;
  timeLabel: string;
  addressLabel: string;
  notesTitle: string;
  notesDescription: string;
}

// This is a function rather than a const so that earliestDeliveryTime() is
// evaluated when this data is requested.
export const getOrderMethods = () => [
  {
    type: DeliveryMethodType.REGULAR_DELIVERY,
    name: 'Regular delivery',
    title: 'Choose delivery time',
    timeLabel: 'Arrives between',
    addressLabel: 'Delivery address',
    notesTitle: 'Delivery instructions and notes',
    notesDescription:
      'E.g. Commercial building access info, who will be receiving the order, service road, etc',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.REGULAR_PICKUP,
    name: 'Regular pickup',
    title: 'Choose pickup time',
    timeLabel: 'Estimated pickup window',
    addressLabel: 'Pickup location',
    notesTitle: 'Store instructions and notes',
    notesDescription: 'E.g. Subbie pickup, tint cups required...',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.AFTER_HOURS_PICKUP,
    name: 'After hours pickup',
    title: 'Choose pickup time',
    timeLabel: 'Pick up from a secure locker',
    addressLabel: 'Pickup location',
    notesTitle: 'Store instructions and notes',
    notesDescription: 'E.g. Subbie pickup, tint cups required...',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.PRIORITY_PICKUP,
    name: 'Priority pickup',
    title: 'Choose pickup time',
    timeLabel: 'Ready by or before',
    addressLabel: 'Pickup location',
    notesTitle: 'Store instructions and notes',
    notesDescription: 'E.g. Subbie pickup, tint cups required...',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.PRIORITY_DELIVERY,
    name: 'Priority delivery',
    title: 'Choose delivery time',
    timeLabel: 'Arrives on or before',
    addressLabel: 'Delivery address',
    notesTitle: 'Delivery instructions and notes',
    notesDescription:
      'E.g. Commercial building access info, who will be receiving the order, service road, etc',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.SCHEDULED_DELIVERY,
    name: 'Scheduled delivery',
    title: 'Choose delivery time',
    timeLabel: 'Arrives between',
    addressLabel: 'Delivery address',
    notesTitle: 'Delivery instructions and notes',
    notesDescription:
      'E.g. Commercial building access info, who will be receiving the order, service road, etc',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
  {
    type: DeliveryMethodType.SCHEDULED_PICKUP,
    name: 'Scheduled pickup',
    title: 'Choose pickup time',
    timeLabel: 'Estimated pickup window',
    addressLabel: 'Pickup location',
    notesTitle: 'Store instructions and notes',
    notesDescription: 'E.g. Subbie pickup, tint cups required...',
    referenceTitle: 'Order reference',
    referenceDescription: 'E.g. Job address, client, PO no.',
  },
];

export const getOrderMethodGroupData = () => [
  {
    title: 'Delivery',
    type: DeliveryMethodGroupType.DELIVERY,
    deliveryInfo: [
      {
        title: 'Priority',
        description:
          'These deliveries are prioritised, prepared and delivered directly to you. Place your order up to 2hrs before stores close for same-day service. A $50 delivery fee applies to all orders, max.150L.',
      },
      {
        title: 'Standard',
        description:
          'Place your order on our delivery run. Order by 10am for same-day delivery. Free delivery on orders over $150.',
      },
      {
        title: 'Scheduled',
        description:
          'Orders must be placed 24hrs+ in advance and can be booked up to 1wk ahead. Free delivery on orders over $150.',
      },
    ],
    items: [
      {
        type: DeliveryMethodType.PRIORITY_DELIVERY,
        name: 'Priority delivery $50',
        title: 'Priority',
        label: 'Fastest',
        availability: 'Available',
        timeLabel: 'Arrives by',
        popular: false,
        deliveryTime: new Estimate().earliestDeliveryTime(
          DeliveryMethodType.PRIORITY_DELIVERY
        ),
        maxLitres: 150,
        price: {
          label: '$50.00',
          value: 50,
        },
        nextUrl: '/checkout/location',
      },
      {
        type: DeliveryMethodType.REGULAR_DELIVERY,
        title: 'Standard',
        name: 'Regular delivery',
        label: 'Popular',
        availability: 'Available',
        timeLabel: 'Arrives by',
        deliveryTime: new Estimate().earliestDeliveryTime(
          DeliveryMethodType.REGULAR_DELIVERY
        ),
        price: {
          label: '$25.00*',
          value: 25,
          freeLabel: 'Free*',
          freeAmountMin: 150,
        },
        popular: true,
        borderColour: '#e2e8f0',
        backgroundColour: '#002C65',
        nextUrl: '/checkout/location',
      },
    ],
  },
  {
    title: 'Pickup',
    type: DeliveryMethodGroupType.PICKUP,
    deliveryInfo: [
      {
        title: 'Priority',
        description:
          'Jump the queue and place your order for free pickup from selected stores. Order up to 30 mins before stores close for same-day service. Please note, if there are any changes to your pickup time you will be notified by your selected store.',
      },
      {
        title: 'Scheduled',
        description:
          'Plan ahead by scheduling free pickup from selected stores. Orders can be placed from 1 hour to 1 week ahead of pickup. Please note, if there are any changes to your pickup time you will be notified by your selected store.',
      },
    ],
  },
  // {
  //   title: 'Locker',
  //   type: DeliveryMethodGroupType.LOCKER,
  //   maxLitres: 10,
  //   deliveryInfo: [
  //     {
  //       description:
  //         'Skip the queue and have your order stored in a secure locker for after hours collection between 5pm-7am. Order by 3pm to arrange an after hours locker.',
  //     },
  //   ],
  // },
];

export const selectDeliveryMethod = createSelector(
  [selectCheckout],
  (checkout) => checkout.deliveryMethod
);

export const selectDeliveryMethodObject = createSelector(
  [selectDeliveryMethod],
  (deliveryMethod) => {
    return find(getOrderMethods(), (method) => deliveryMethod === method.type);
  }
);

export const selectLocationAddress = createSelector(
  [selectCheckout],
  (checkout) => checkout.locationAddress
);

export const selectDeliveryTimeframe = createSelector(
  [selectCheckout],
  (checkout) => checkout.deliveryTimeframe
);

export const selectSkipLocation = createSelector(
  [selectCheckout],
  (checkout) => checkout.skipLocation
);

export const selectShowAllStores = createSelector(
  [selectCheckout],
  (checkout) => checkout.showAllStores
);

export const selectDeliveryTimeframeLabel = createSelector(
  [selectDeliveryTimeframe],
  (timeframe) => (timeframe ? timeframe.label : '')
);

export const selectDeliveryNotes = createSelector(
  [selectCheckout],
  (checkout) => checkout.deliveryNotes
);

export const selectOrderReference = createSelector(
  [selectCheckout],
  (checkout) => {
    if (checkout.orderReference) {
      return checkout.orderReference;
    }
  }
);
