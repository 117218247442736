// import Analytics from '../services/analytics/analytics';
import { Action, applyMiddleware, createStore } from 'redux';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
// import monitorReducersEnhancer from './enhancers/monitorReducer';
// import loggerMiddleware from './middleware/logger';
// import analyticsEnhancer from './enhancers/analytics';
import rootReducer, { RootState } from './reducers';

export type Thunk<R> = ThunkAction<R, RootState, null, Action>;
export type Dispatch = ThunkDispatch<RootState, null, Action>;

export default function configureStore(preloadedState = {}) {
  const middleware = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);

  const enhancers = [
    middlewareEnhancer,
    // monitorReducersEnhancer,
    // analyticsEnhancer(analytics),
  ];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}
