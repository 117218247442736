import { Attribute, LineItem } from '../firebase/types';
import { EnrichedCartItem } from '../../store/reducers/cart/types';
import { OrderMethod } from '../../store/selectors';
import { DeliveryOption } from '../fulfillment/estimate';
import { DELIVERY_METHOD_KEY, DELIVERY_TIME_KEY } from '../../utils/order';
import { format } from 'date-fns';
import { StructuredAddress } from '../../models/Address';

interface ShopifyOrderData {
  lineItems: LineItem[];
  attributes: Attribute[];
  address: StructuredAddress;
  tags: string[];
}

function getLineItems(cartItems: EnrichedCartItem[]): LineItem[] {
  return cartItems.map(
    (item): LineItem => {
      const attributes = [];

      if (item.colour) {
        if (item.colour.colourBrand) {
          attributes.push({
            name: 'Colour Brand',
            value: item.colour.colourBrand,
          });
        }

        attributes.push({ name: 'Colour', value: item.colour.colourName });

        if (item.colourStrength) {
          attributes.push({
            name: 'Colour Strength',
            value: item.colourStrength.toString(),
          });
        }

        attributes.push({ name: 'Colour Id', value: item.colour.colourId });

        if (item.colour.atlasCode) {
          attributes.push({
            name: 'Colour Atlas Code',
            value: item.colour.atlasCode,
          });
        }
      }

      return {
        sku: item.product.productId,
        title: item.product.fullName,
        price: 1,
        quantity: item.quantity,
        attributes,
      };
    }
  );
}

function getTags(deliveryOption: DeliveryOption) {
  return [deliveryOption.method];
}

export function createShopifyOrderData(
  cartItems: EnrichedCartItem[],
  orderMethod: OrderMethod,
  deliveryOption: DeliveryOption,
  structuredAddress: StructuredAddress,
  orderReference: string
): ShopifyOrderData {
  const attributes = [
    {
      name: 'Order Reference',
      value: orderReference || '',
    },
    {
      name: 'Delivery Method',
      value: orderMethod.name,
    },
    {
      name: 'Delivery Time',
      value: format(deliveryOption.delivery, 'PPPp'),
    },
    {
      name: DELIVERY_METHOD_KEY,
      value: orderMethod.type,
    },
    {
      name: DELIVERY_TIME_KEY,
      value: deliveryOption.delivery.toISOString(),
    },
  ];

  return {
    lineItems: getLineItems(cartItems),
    attributes,
    address: structuredAddress,
    tags: getTags(deliveryOption),
  };
}
