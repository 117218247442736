import React from 'react';
import ReactDOM from 'react-dom';
import { IonApp } from '@ionic/react';
import * as Sentry from '@sentry/browser';
import { IonReactRouter } from '@ionic/react-router';
import { Provider } from 'react-redux';
import configureStore from './store';
import App from './App';
import Firebase from './services/firebase/firebase';
import Analytics from './services/analytics/analytics';
import { FirebaseProvider } from './services/firebase/context';
import { AnalyticsProvider } from './services/analytics/context';
import Push from './services/push/push';
import { PushProvider } from './services/push/context';
import { isProduction } from './utils/environment';

const store = configureStore();
const push = new Push();
const analytics = new Analytics();
const firebase = new Firebase(store.dispatch, push, analytics);

// Initialise error logging
Sentry.init({
  dsn: 'https://59723aec36f940e9b6ee64182dddd8d0@sentry.io/5166747',
  enabled: isProduction,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_BUILD_ENV,
});

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider value={firebase}>
      <AnalyticsProvider value={analytics}>
        <PushProvider value={push}>
          <IonApp>
            <IonReactRouter>
              <App />
            </IonReactRouter>
          </IonApp>
        </PushProvider>
      </AnalyticsProvider>
    </FirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
