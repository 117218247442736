import {
  ClearVerificationIdAction,
  ClearVerificationErrorAction,
  ClearLoginErrorAction,
  InitialisedAction,
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
  LoginTimeoutAction,
  LogoutAction,
  ReceiveUserDataAction,
  ReceiveVerificationCodeAction,
  ReceiveVerificationIdAction,
  UserActionType,
  VerificationFailureAction,
  VerificationRequestAction,
  VerificationTimeoutAction,
  ReceiveRepDataAction,
} from '../reducers/user/types';
import { UserAuthFields, UserDataFields } from '../../services/firebase/types';
import { DuluxRep } from '../../types';

export const verificationRequest = (
  phoneNumber: string
): VerificationRequestAction => {
  return {
    type: UserActionType.VERIFICATION_REQUEST,
    payload: { phoneNumber },
  };
};

export const verificationFailure = (
  error: string
): VerificationFailureAction => {
  return {
    type: UserActionType.VERIFICATION_FAILURE,
    payload: {
      error,
    },
  };
};

export const verificationTimeout = (): VerificationTimeoutAction => {
  return {
    type: UserActionType.VERIFICATION_TIMEOUT,
  };
};

export const receiveVerificationId = (
  verificationId: string
): ReceiveVerificationIdAction => {
  return {
    type: UserActionType.RECEIVE_VERIFICATION_ID,
    payload: { verificationId },
  };
};

export const clearVerificationId = (): ClearVerificationIdAction => {
  return {
    type: UserActionType.CLEAR_VERIFICATION_ID,
  };
};

export const clearVerificationError = (): ClearVerificationErrorAction => {
  return {
    type: UserActionType.CLEAR_VERIFICATION_ERROR,
  };
};

export const clearLoginError = (): ClearLoginErrorAction => {
  return {
    type: UserActionType.CLEAR_LOGIN_ERROR,
  };
};

export const receiveVerificationIdAndCode = (
  verificationId: string,
  verificationCode: string
): ReceiveVerificationCodeAction => {
  return {
    type: UserActionType.RECEIVE_VERIFICATION_ID_AND_CODE,
    payload: { verificationId, verificationCode },
  };
};

export const loginRequest = (): LoginRequestAction => {
  return {
    type: UserActionType.LOGIN_REQUEST,
  };
};

export const loginSuccess = (user: UserAuthFields): LoginSuccessAction => {
  return {
    type: UserActionType.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailure = (error: string): LoginFailureAction => {
  return {
    type: UserActionType.LOGIN_FAILURE,
    payload: {
      error,
    },
  };
};

export const loginTimeout = (): LoginTimeoutAction => {
  return {
    type: UserActionType.LOGIN_TIMEOUT,
  };
};

export const logout = (): LogoutAction => {
  return {
    type: UserActionType.LOGOUT,
  };
};

export const receiveUserData = (
  user: UserDataFields
): ReceiveUserDataAction => {
  return {
    type: UserActionType.RECEIVE_USER_DATA,
    payload: { user },
  };
};

export const receiveDuluxRepData = (rep?: DuluxRep): ReceiveRepDataAction => {
  return {
    type: UserActionType.RECEIVE_REP_DATA,
    payload: { rep },
  };
};

export const initialised = (): InitialisedAction => {
  return {
    type: UserActionType.INITIALISED,
  };
};
