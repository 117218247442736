import React from 'react';
import PropTypes from 'prop-types';

const HomeInfo = (props) => {
  function handleClick() {
    if (props.onClick) {
      return props.onClick();
    }
  }

  return (
    <div className="px-2 pt-2">
      <div className="p-2 border rounded" onClick={() => handleClick()}>
        <img src={props.image} alt={props.altText} className="mb-2" />
        <h2 className="font-heading text-dulux-dark text-h2 border-b pb-1">
          {props.title}
        </h2>
        <p className="mt-1 text-dulux-dark">{props.text}</p>
      </div>
    </div>
  );
};

HomeInfo.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  altText: PropTypes.string,
  onClick: PropTypes.func,
};

HomeInfo.defaultProps = {
  image: '',
  onClick: null,
};

export default HomeInfo;
