import { remoteConfig } from 'firebase';
import { DeliveryMethodType } from '../../../types';
import { RemoteConfigOverride } from '../types';
import {
  buildIsProduction,
  buildEnvironment,
} from '../../../utils/environment';

/*
 * minimumFetchIntervalMillis defaults to 12 hours if not set. This is
 * recommended for Production by Google. Any calls to read the remoteConfig
 * will return cached values for the interval period.
 */
export default class FirebaseRemoteConfig {
  private global: remoteConfig.RemoteConfig;
  private combinedConfig: RemoteConfigOverride;
  private readonly minimumFetchIntervalMillis: number = 60000; // 1 minute throttling for dev
  private readonly updateRemoteFetchAndActivateInterval: number = 20000; // Fetch and activate new config in local app
  private readonly updateConfigInterval: number = 10000; // Update local property every 10 seconds
  private readonly defaultFulfillmentConfig: {} = {
    [DeliveryMethodType.REGULAR_PICKUP]: {
      enabled: true,
    },
    [DeliveryMethodType.REGULAR_DELIVERY]: {
      enabled: true,
    },
    [DeliveryMethodType.PRIORITY_PICKUP]: {
      enabled: true,
    },
    [DeliveryMethodType.PRIORITY_DELIVERY]: {
      enabled: true,
    },
    [DeliveryMethodType.SCHEDULED_PICKUP]: {
      enabled: true,
    },
    [DeliveryMethodType.SCHEDULED_DELIVERY]: {
      enabled: true,
    },
  };

  constructor() {
    this.global = remoteConfig();
    this.global.settings = {
      fetchTimeoutMillis: this.minimumFetchIntervalMillis,
      minimumFetchIntervalMillis: this.minimumFetchIntervalMillis,
    };
    this.combinedConfig = {
      fulfillmentOptions: this.defaultFulfillmentConfig,
      firebaseConfig: {},
    };
  }

  getEnvironmentBoolean(key: string) {
    return this.global.getBoolean(
      buildIsProduction ? key : `${buildEnvironment.toLowerCase()}_${key}`
    );
  }

  combineConfig() {
    return {
      firebaseConfig: {
        ...this.global.getAll(),
      },
      fulfillmentOptions: {
        [DeliveryMethodType.REGULAR_PICKUP]: {
          enabled: this.getEnvironmentBoolean('regular_pickup_enabled'),
        },
        [DeliveryMethodType.REGULAR_DELIVERY]: {
          enabled: this.getEnvironmentBoolean('regular_delivery_enabled'),
        },
        [DeliveryMethodType.PRIORITY_PICKUP]: {
          enabled: this.getEnvironmentBoolean('priority_pickup_enabled'),
        },
        [DeliveryMethodType.PRIORITY_DELIVERY]: {
          enabled: this.getEnvironmentBoolean('priority_delivery_enabled'),
        },
        [DeliveryMethodType.SCHEDULED_PICKUP]: {
          enabled: this.getEnvironmentBoolean('scheduled_pickup_enabled'),
        },
        [DeliveryMethodType.SCHEDULED_DELIVERY]: {
          enabled: this.getEnvironmentBoolean('scheduled_delivery_enabled'),
        },
      },
    };
  }

  getCombinedConfig() {
    return this.combinedConfig;
  }

  setCombinedConfig(config: RemoteConfigOverride) {
    this.combinedConfig = config;
  }

  fetchAndActivate() {
    this.global.fetchAndActivate().catch((err) => {
      console.error(err);
    });
  }

  // We want to only fetch remoteConfig from the server and activate
  // at the next interval. Initial load we want to use the local defaults
  // set in the constructor.
  boot() {
    setInterval(() => {
      this.fetchAndActivate();
    }, this.updateRemoteFetchAndActivateInterval);

    setInterval(() => {
      this.setCombinedConfig(this.combineConfig());
    }, this.updateConfigInterval);
  }
}
