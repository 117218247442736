import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTextarea } from '@ionic/react';
import { useHistory } from 'react-router';
import { batch, useSelector, useDispatch } from 'react-redux';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import {
  selectLocationAddressFormattedAddress,
  selectDeliveryNotes,
  selectDeliveryMethodObject,
  selectSkipLocation,
  selectOrderReference,
} from '../../store/selectors';
import {
  applyOrderReference,
  applyDeliveryNotes,
  clearSkipLocation,
} from '../../store/actions';
import Button from '../../components/ui/Button';
import Loader from '../../components/ui/Loader';
import { isDelivery, getNote } from '../../utils/text';
import PropTypes from 'prop-types';
import { useAnalyticsContext } from '../../services/analytics/context';
import FooterCapContainer from '../../components/ui/FooterCapContainer';
import useFulfillmentMethodEnabled from '../../hooks/useFulfillmentMethodEnabled';

const DeliveryNotes = (props) => {
  const CONTENT_LOADER_MAX = 50; // chars
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalyticsContext();
  const methodEnabled = useFulfillmentMethodEnabled();

  const [note, setNote] = useState('');
  const [reference, setReference] = useState('');
  const [backTitle, setBackTitle] = useState('Confirm address');
  const locationAddressTitle = useSelector(
    selectLocationAddressFormattedAddress
  );
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const orderReference = useSelector(selectOrderReference);
  const deliveryNotes = useSelector(selectDeliveryNotes);
  const deliveryMethod = useSelector(selectDeliveryMethodObject);
  const skipLocation = useSelector(selectSkipLocation);

  useEffect(() => {
    if (!methodEnabled) {
      if (history.location.pathname === '/checkout/notes') {
        history.push('/checkout?error=true');
      }
    }
  }, [history, methodEnabled]);

  useEffect(() => {
    setReference(orderReference);
    setNote(deliveryNotes);
  }, [orderReference, deliveryNotes]);

  const nextStep = () => {
    batch(() => {
      dispatch(applyDeliveryNotes(note));
      dispatch(applyOrderReference(reference));
    });

    setLoaderEnabled(true);

    analytics.ecommerce.checkoutStepCompleted(4);

    setTimeout(function () {
      setLoaderEnabled(false);
      return history.push('/checkout/summary');
    }, 1000);
  };

  const handleBackClick = () => {
    history.goBack();

    if (skipLocation === history.location.pathname) {
      dispatch(clearSkipLocation());
    }
  };

  function getLoaderContent() {
    const content = getNote(orderReference, deliveryNotes);

    if (content.length > CONTENT_LOADER_MAX) {
      return content.substr(0, CONTENT_LOADER_MAX - 1) + '...';
    }

    return content;
  }

  useEffect(() => {
    if (skipLocation === history.location.pathname) {
      setBackTitle('Order method');
      return;
    }

    if (deliveryMethod) {
      if (!isDelivery(deliveryMethod.type)) {
        setBackTitle('Choose store');
        return;
      }
    }

    setBackTitle('Confirm address');
  }, [deliveryMethod, skipLocation, history.location.pathname]);

  return (
    <IonPage>
      <IonHeader>
        {locationAddressTitle && deliveryMethod && (
          <>
            <HeaderBack
              title={backTitle}
              description={locationAddressTitle}
              onClick={handleBackClick}
            />
            <HeaderTitle title="Add order notes" borderColour="#e2e8f0" />
          </>
        )}
      </IonHeader>
      <IonContent>
        <div className="h-full bg-dulux-bg flex flex-col">
          <div className="h-full overflow-scroll">
            {deliveryMethod && (
              <>
                <div className="bg-white border-b">
                  <div className="p-1 flex flex-col">
                    <p className="text-gray-600">
                      {deliveryMethod.referenceTitle}
                    </p>
                    <IonTextarea
                      className="bg-white"
                      rows="1"
                      placeholder={
                        deliveryMethod
                          ? deliveryMethod.referenceDescription
                          : 'E.g. Job address, client, PO no.'
                      }
                      maxlength={props.noteCharLimit}
                      autofocus={true}
                      autoGrow={false}
                      value={reference}
                      onIonChange={(e) => setReference(e.target.value)}
                    />
                  </div>
                </div>
                <div className="bg-white border-b">
                  <div className="p-1 flex flex-col">
                    <p className="text-gray-600">{deliveryMethod.notesTitle}</p>
                    <IonTextarea
                      className="bg-white"
                      rows="2"
                      placeholder={
                        deliveryMethod
                          ? deliveryMethod.notesDescription
                          : 'E.g. Rear access, call on arrival, leave unattended, tint cups required...'
                      }
                      maxlength={props.noteCharLimit}
                      autofocus={false}
                      autoGrow={false}
                      value={note}
                      onIonChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <FooterCapContainer>
            <Button onClick={nextStep}>{props.buttonTitle}</Button>
          </FooterCapContainer>
        </div>
      </IonContent>
      <Loader
        isEnabled={loaderEnabled}
        title={getLoaderContent()}
        isSpinnerEnabled={note === ''}
      />
    </IonPage>
  );
};

DeliveryNotes.propTypes = {
  noteCharLimit: PropTypes.number,
  buttonTitle: PropTypes.string,
};

DeliveryNotes.defaultProps = {
  noteCharLimit: 255,
  buttonTitle: 'Next',
};

export default DeliveryNotes;
