import { OrderActionType, OrderActionTypes, OrderState } from './types';
import { keyBy, filter } from 'lodash';
import { UserActionType, UserActionTypes } from '../user/types';

const initialState: OrderState = {
  byId: {},
};

const orders = (
  state: OrderState = initialState,
  action: OrderActionTypes | UserActionTypes
): OrderState => {
  switch (action.type) {
    case OrderActionType.RECEIVE_ORDERS:
      return {
        ...state,
        byId: keyBy(action.payload.orders, 'id'),
      };

    case OrderActionType.RECEIVE_ORDER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.order.id]: action.payload.order,
        },
      };

    case OrderActionType.APPLY_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload.currentOrder,
      };

    case OrderActionType.CLEANUP_ORDERS: {
      // Remove the orders from the state that we didn't specify
      const newOrders = filter(Object.values(state.byId), (order) => {
        return action.payload.orderIds.indexOf(order.id) !== -1;
      });

      return {
        ...state,
        byId: keyBy(newOrders, 'id'),
      };
    }

    case UserActionType.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default orders;
