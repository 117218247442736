import { Action } from 'redux';
import {
  User,
  UserAuthFields,
  UserDataFields,
} from '../../../services/firebase/types';
import { DuluxRep } from '../../../types';

interface UserAuthState {
  isInitialising: boolean;
  phoneNumber: string;
  verificationId: string;
  verificationCode: string;
  isLoadingVerification: boolean;
  verificationError?: string;
  isLoadingLogin: boolean;
  loginError?: string;
}

export interface UserState {
  auth: UserAuthState;
  user?: User;
  rep?: DuluxRep;
}

export enum UserActionType {
  VERIFICATION_REQUEST = 'VERIFICATION_REQUEST',
  VERIFICATION_FAILURE = 'VERIFICATION_FAILURE',
  VERIFICATION_TIMEOUT = 'VERIFICATION_TIMEOUT',
  CLEAR_VERIFICATION_ERROR = 'CLEAR_VERIFICATION_ERROR',

  RECEIVE_VERIFICATION_ID = 'RECEIVE_VERIFICATION_ID',
  RECEIVE_VERIFICATION_ID_AND_CODE = 'RECEIVE_VERIFICATION_ID_AND_CODE',
  CLEAR_VERIFICATION_ID = 'CLEAR_VERIFICATION_ID',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGIN_TIMEOUT = 'LOGIN_TIMEOUT',
  CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR',

  LOGOUT = 'LOGOUT',

  RECEIVE_USER_DATA = 'RECEIVE_USER_DATA',
  RECEIVE_REP_DATA = 'RECEIVE_REP_DATA',
  INITIALISED = 'INITIALISED',
}

export interface VerificationRequestAction extends Action {
  type: UserActionType.VERIFICATION_REQUEST;
  payload: {
    phoneNumber: string;
  };
}

export interface VerificationFailureAction extends Action {
  type: UserActionType.VERIFICATION_FAILURE;
  payload: {
    error: string;
  };
}

export interface VerificationTimeoutAction extends Action {
  type: UserActionType.VERIFICATION_TIMEOUT;
}

export interface ReceiveVerificationIdAction extends Action {
  type: UserActionType.RECEIVE_VERIFICATION_ID;
  payload: {
    verificationId: string;
  };
}

export interface ReceiveVerificationCodeAction extends Action {
  type: UserActionType.RECEIVE_VERIFICATION_ID_AND_CODE;
  payload: {
    verificationId: string;
    verificationCode: string;
  };
}

export interface ClearVerificationIdAction extends Action {
  type: UserActionType.CLEAR_VERIFICATION_ID;
}

export interface ClearVerificationErrorAction extends Action {
  type: UserActionType.CLEAR_VERIFICATION_ERROR;
}

export interface ClearLoginErrorAction extends Action {
  type: UserActionType.CLEAR_LOGIN_ERROR;
}

export interface LoginRequestAction extends Action {
  type: UserActionType.LOGIN_REQUEST;
}

export interface LoginSuccessAction extends Action {
  type: UserActionType.LOGIN_SUCCESS;
  payload: UserAuthFields;
}

export interface LoginFailureAction extends Action {
  type: UserActionType.LOGIN_FAILURE;
  payload: {
    error: string;
  };
}

export interface LoginTimeoutAction extends Action {
  type: UserActionType.LOGIN_TIMEOUT;
}

export interface LogoutAction extends Action {
  type: UserActionType.LOGOUT;
}

export interface ReceiveUserDataAction extends Action {
  type: UserActionType.RECEIVE_USER_DATA;
  payload: {
    user: Partial<UserDataFields>;
  };
}

export interface ReceiveRepDataAction extends Action {
  type: UserActionType.RECEIVE_REP_DATA;
  payload: {
    rep?: DuluxRep;
  };
}

export interface InitialisedAction extends Action {
  type: UserActionType.INITIALISED;
}

export type UserActionTypes =
  | VerificationRequestAction
  | VerificationFailureAction
  | VerificationTimeoutAction
  | ReceiveVerificationIdAction
  | ReceiveVerificationCodeAction
  | ClearVerificationIdAction
  | ClearVerificationErrorAction
  | ClearLoginErrorAction
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LoginTimeoutAction
  | LogoutAction
  | ReceiveUserDataAction
  | ReceiveRepDataAction
  | InitialisedAction;
