import { Action } from 'redux';

export interface CatalogState {
  activeTab?: string | CatalogTabType;
}

export enum CatalogTabType {
  PAINT = 'PAINT',
  ACCESSORIES = 'ACCESSORIES',
}

export enum CatalogActionType {
  APPLY_ACTIVE_TAB = 'APPLY_ACTIVE_TAB',
  CLEAR_ACTIVE_TAB = 'CLEAR_ACTIVE_TAB',
}

export interface ApplyActiveTabAction extends Action {
  type: CatalogActionType.APPLY_ACTIVE_TAB;
  payload: CatalogState;
}

export interface ClearActiveTabAction extends Action {
  type: CatalogActionType.CLEAR_ACTIVE_TAB;
}

export type CatalogActionTypes = ApplyActiveTabAction | ClearActiveTabAction;
