import currency from 'currency.js';
import { selectPricing } from './root';
import { getCartItems } from '../reducers';
import { createSelector } from 'reselect';
import { find } from 'lodash';

export const selectPricingIsLoading = createSelector(
  [selectPricing],
  (pricing) => {
    return pricing.isLoadingPricing;
  }
);

export const selectPricingTotals = createSelector(
  [selectPricing],
  (pricing) => {
    let netValueFormatted = null;
    let taxAmountFormatted = null;
    let grossValueFormatted = null;

    if (pricing.total?.netValue) {
      netValueFormatted = currency(pricing.total.netValue, {
        formatWithSymbol: true,
      }).format();
    }

    if (pricing.total?.taxAmount) {
      taxAmountFormatted = currency(pricing.total.taxAmount, {
        formatWithSymbol: true,
      }).format();
    }

    if (pricing.total?.grossValue) {
      grossValueFormatted = currency(pricing.total.grossValue, {
        formatWithSymbol: true,
      }).format();
    }

    return {
      ...pricing.total,
      grossValueFormatted,
      taxAmountFormatted,
      netValueFormatted,
    };
  }
);

export const selectCartItemsWithPricing = createSelector(
  [selectPricing, getCartItems],
  (pricing, enrichedCartItems) => {
    return enrichedCartItems.map((cartItem, index) => {
      const foundPricing = find(pricing.items, (price) => {
        return (
          price.sku === cartItem.productId && price.itemNumber === index + 1
        );
      });

      if (!foundPricing) {
        return cartItem;
      }

      const grossValueFormatted = currency(foundPricing.grossValue, {
        formatWithSymbol: true,
      }).format();

      const taxAmountFormatted = currency(foundPricing.taxAmount, {
        formatWithSymbol: true,
      }).format();

      const netValueFormatted = currency(foundPricing.netValue, {
        formatWithSymbol: true,
      }).format();

      return {
        ...cartItem,
        ...foundPricing,
        grossValueFormatted,
        taxAmountFormatted,
        netValueFormatted,
      };
    });
  }
);
