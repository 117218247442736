/* eslint-disable @typescript-eslint/camelcase */
import { geocodeByAddress } from 'react-places-autocomplete';

interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeocoderResult {
  address_components: GeocoderAddressComponent[];
  formatted_address: string;
  // geometry: GeocoderGeometry;
  partial_match: boolean;
  place_id: string;
  postcode_localities: string[];
  types: string[];
}

export interface StructuredAddress {
  address1: string;
  address2: string;
  city: string;
  province: string;
  province_code: string;
  zip: string;
  country: string;
  country_code: string;
}

export interface GoogleAddressPieces extends StructuredAddress {
  subpremise?: string;
  street_number: string;
}

export default class Address {
  address: string;
  private geocodeCache?: GeocoderResult;

  constructor(address: string) {
    this.address = address;
  }

  get geocode(): Promise<GeocoderResult> {
    return new Promise((resolve, reject) => {
      // If it's cached then return that
      if (this.geocodeCache) resolve(this.geocodeCache);

      // Otherwise geocode the address and store it in cache
      geocodeByAddress(this.address)
        .then((results) => results[0])
        .then((result) => (this.geocodeCache = result))
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }

  get structured(): Promise<StructuredAddress> {
    return this.geocode
      .then((result) => this.mapGoogleAddressPieces(result))
      .then((pieces) => ({
        // Take the address from the formatted address so that it contains the unit number
        address1: pieces.subpremise
          ? `${pieces.subpremise}/${pieces.street_number} ${pieces.address1}`
          : this.address.split(',')[0],
        address2: pieces.address2,
        city: pieces.city,
        province: pieces.province,
        province_code: pieces.province_code,
        country: pieces.country,
        country_code: pieces.country_code,
        zip: pieces.zip,
      }));
  }

  mapGoogleAddressPieces(geocoderResult: GeocoderResult): GoogleAddressPieces {
    console.log('mapping', geocoderResult.address_components);
    /**
     * Map each address component to its corresponding Shopify field
     * component.types are a list of labels that the data maps to.
     * We take the first to reduce complexity for our mapping
     */
    const mappedAddressPieces = geocoderResult.address_components.map(
      (component) => {
        switch (component.types[0]) {
          case 'subpremise':
            return { subpremise: component.long_name };

          case 'street_number':
            return { street_number: component.long_name };

          case 'route':
            return { address1: component.long_name };

          case 'locality':
            return { address2: component.long_name };

          case 'administrative_area_level_1':
            return {
              province: component.long_name,
              province_code: component.short_name,
            };

          case 'administrative_area_level_2':
            return { city: component.short_name };

          case 'postal_code':
            return { zip: component.long_name };

          case 'country':
            return {
              country: component.long_name,
              country_code: component.short_name,
            };

          default:
            return {};
        }
      }
    );

    // Get a single, flattened key-value object
    return mappedAddressPieces.reduce((acc: any, current: any) => {
      return {
        ...acc,
        ...current,
      };
    }, {});
  }
}
