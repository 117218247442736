import PropTypes from 'prop-types';
import React from 'react';
import ColourSearch from '../components/search/ColourSearch';

const ColourSearchContainer = (props) => {
  return (
    <ColourSearch
      onColourSearchItemClick={props.onColourSearchItemClick}
      onColourConfirmClick={props.onColourConfirmClick}
      searchColourIdSelected={props.searchColourIdSelected}
      setSearchColourIdSelected={props.setSearchColourIdSelected}
      searchColourStrengthEnabled={props.searchColourStrengthEnabled}
      setSearchColourStrengthEnabled={props.setSearchColourStrengthEnabled}
      popularColours={props.popularColoursData}
      renderBrand={props.renderBrand}
      searchEnabled={props.searchEnabled}
    />
  );
};

ColourSearchContainer.propTypes = {
  popularColoursData: PropTypes.array,
  onColourSearchItemClick: PropTypes.func,
  onColourConfirmClick: PropTypes.func,
  searchColourIdSelected: PropTypes.string,
  setSearchColourIdSelected: PropTypes.func,
  searchColourStrengthEnabled: PropTypes.bool,
  setSearchColourStrengthEnabled: PropTypes.func,
  renderBrand: PropTypes.func,
  searchEnabled: PropTypes.bool,
};

ColourSearchContainer.defaultProps = {
  popularColoursData: [],
  onColourSearchItemClick: null,
  onColourConfirmClick: null,
  searchColourIdSelected: '',
  setSearchColourIdSelected: null,
  searchColourStrengthEnabled: null,
  setSearchColourStrengthEnabled: null,
  renderBrand: null,
  searchEnabled: false,
};

export default ColourSearchContainer;
