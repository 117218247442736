import Firebase from '../services/firebase/firebase';

export function submitFeedback(
  firebase: Firebase,
  order: any,
  score: number,
  comments?: string,
  requestCallback = false
) {
  return firebase.createTicket([
    {
      property: 'subject',
      value: `Post Order Survey: ${order ? order.name : ''} `,
    },
    {
      property: 'content',
      value:
        `Score: ${score}\n\n` +
        (comments ? `Comments: ${comments}\n\n` : '') +
        `Order: https://dulux-trade-direct.myshopify.com/admin/orders/${
          order ? order.id : ''
        }`,
    },
    {
      property: 'trade_order_survey_score',
      value: score,
    },
    {
      property: 'trade_order_survey_orderid',
      value: order.id || '',
    },
    {
      property: 'trade_order_survey_ordernumber',
      value: order.name || '',
    },
    {
      property: 'trade_order_survey_comments',
      value: comments || '',
    },
    {
      property: 'trade_order_survey_callback',
      value: requestCallback,
    },
  ]);
}
