import { useState, useEffect } from 'react';
import { getObject } from '../utils/storage';

export default function useOnboardingViewed() {
  const storageOnboardingViewedKey = 'ONBOARDING_VIEWED';
  const [onboardingViewed, setOnboardingViewed] = useState(true);

  useEffect(() => {
    getObject(storageOnboardingViewedKey).then((data) => {
      if (data) {
        setOnboardingViewed(data);
        return;
      }

      setOnboardingViewed(false);
    });
  });

  return {
    onboardingViewed,
    storageOnboardingViewedKey,
  };
}
