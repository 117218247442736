import React from 'react';
import PropTypes from 'prop-types';
import CatalogPaintItem from '../../components/catalog/CatalogPaintItem';
import WashAndWearLowSheenLogo from '../../assets/images/product/paint/logo/wash&wear-low-sheen@2x.png';
import OneStepPrepLogo from '../../assets/images/product/paint/logo/1step-prep@2x.png';
import AquanamelLogo from '../../assets/images/product/paint/logo/aquanamel-gloss@2x.png';
import WeathershieldLogo from '../../assets/images/product/paint/logo/weathershield-2@2x.png';
import SuperEnamelLogo from '../../assets/images/product/paint/logo/super-enamel-gloss@2x.png';
import CeilingLogo from '../../assets/images/product/paint/logo/ceiling-white@2x.png';
import EnamelLogo from '../../assets/images/product/paint/logo/professional-enamel@2x.png';
import InteriorExteriorLogo from '../../assets/images/product/paint/logo/professional-interior-exterior@2x.png';
import InteriorLogo from '../../assets/images/product/paint/logo/professional-interior@2x.png';
import PrepLogo from '../../assets/images/product/paint/logo/professional-preparation@2x.png';
import BergerGoldLabelLogo from '../../assets/images/product/paint/logo/berger-gold-label@2x.png';
import HadrianLogo from '../../assets/images/product/paint/logo/hadrian@2x.png';

const CatalogPaint = (props) => {
  return (
    <>
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: 'Wash&Wear',
          })
        }
        background="#010C20"
        image={WashAndWearLowSheenLogo}
        name="Wash&amp;Wear"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: '1Step Prep',
          })
        }
        background="#757575"
        image={OneStepPrepLogo}
        name="1Step Prep"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: 'Aquanamel',
          })
        }
        background="#2D89CA"
        image={AquanamelLogo}
        name="Aquanamel"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: 'Weathershield',
          })
        }
        background="#F2CB0A"
        image={WeathershieldLogo}
        name="Weathershield"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: 'Super Enamel',
          })
        }
        background="#010C20"
        image={SuperEnamelLogo}
        name="Super Enamel"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux',
            name: 'Ceiling White',
          })
        }
        background="#89CCCB"
        image={CeilingLogo}
        name="Ceiling White"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux Professional',
            name: 'Professional Enamel',
          })
        }
        background="#17571A"
        image={EnamelLogo}
        name="Professional Enamel"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux Professional',
            name: 'Professional Interior/Exterior',
          })
        }
        background="#611D72"
        image={InteriorExteriorLogo}
        name="Professional Interior/Exterior"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux Professional',
            name: 'Professional Interior',
          })
        }
        background="#13377E"
        image={InteriorLogo}
        name="Professional Interior"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Dulux Professional',
            name: 'Professional Preparation',
          })
        }
        background="#5A504D"
        image={PrepLogo}
        name="Professional Preparation"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Berger',
            name: 'Berger Gold Label',
          })
        }
        background="#010C20"
        image={BergerGoldLabelLogo}
        name="Berger Gold Label"
      />
      <CatalogPaintItem
        onClick={() =>
          props.onClick({
            range: 'Hadrian',
            name: 'Hadrian',
          })
        }
        background="#50534A"
        image={HadrianLogo}
        name="Hadrian"
      />
    </>
  );
};

CatalogPaint.propTypes = {
  onClick: PropTypes.func,
};

CatalogPaint.defaultProps = {
  onClick: '',
};

export default CatalogPaint;
