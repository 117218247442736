import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/64/schedule.svg';

const OrderMethodSchedule = (props) => {
  let available = props.enabled;

  if (
    props.maxLitres &&
    props.totalLitres &&
    props.totalLitres > props.maxLitres
  ) {
    available = false;
  }

  const time = available ? props.time : 'Unavailable';

  function getScheduleColumnClass() {
    let classNames = 'flex items-center py-3 relative w-4/12 ';

    return (classNames += props.showPrice ? 'pl-1' : '');
  }

  function getTimeColumnClass() {
    const classNames = 'flex flex-col justify-center w-4/12 py-3 ';
    let positionClass = 'items-end';

    if (props.showPrice) {
      positionClass = 'items-center';
    }

    return classNames + positionClass;
  }

  function handleClick() {
    if (props.enabled) {
      return props.onClick();
    }
  }

  return (
    <div
      onClick={() => handleClick()}
      className={
        'flex justify-between px-1 ' +
        (props.hasBorder ? 'border-b  ' : '') +
        (available ? '' : 'opacity-50')
      }
    >
      <div className="flex items-center w-2/12 py-3">
        <div className="pb-100 w-full rounded relative bg-gray-300">
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
            <ScheduleIcon />
          </div>
        </div>
      </div>
      <div className={getScheduleColumnClass()}>
        <span className="font-heading text-h3">Schedule</span>
        <div
          className="absolute top-0 text-technical text-dulux-primary bg-blue-200 px-1 rounded-br rounded-bl"
          style={{
            marginTop: '-1px',
            paddingTop: '.2rem',
            paddingBottom: '.2rem',
          }}
        >
          Plan ahead
        </div>
      </div>
      {!props.showPrice && <div className="w-1/12" />}
      {time && (
        <div className={getTimeColumnClass()}>
          <div
            className={
              'border inline-flex items-center rounded-full text-center ' +
              (available
                ? 'border-dulux-primary bg-dulux-primary'
                : 'border-dulux-dark')
            }
            style={{ paddingTop: '.2rem', paddingBottom: '.2rem' }}
          >
            <div
              className={
                'iphone-padding ' +
                (available ? 'text-dulux-white' : 'text-gray-800')
              }
            >
              {time}
            </div>
          </div>
          {!props.enabled && (
            <div className="items-center">
              <span className="text-small text-red-500">COVID restriction</span>
            </div>
          )}
        </div>
      )}
      {available && props.showPrice && (
        <div className="flex justify-end items-center py-3">
          <span className="text-dulux-primary text-small sm:text-body">
            {props.price()}
          </span>
        </div>
      )}
    </div>
  );
};

OrderMethodSchedule.propTypes = {
  onClick: PropTypes.func,
  time: PropTypes.string,
  showPrice: PropTypes.bool,
  price: PropTypes.func,
  totalLitres: PropTypes.number,
  maxLitres: PropTypes.number,
  enabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

OrderMethodSchedule.defaultProps = {
  onClick: null,
  time: '',
  showPrice: false,
  price: null,
  totalLitres: 0,
  enabled: true,
  hasBorder: true,
};

export default OrderMethodSchedule;
