import React from 'react';
import PropTypes from 'prop-types';
import contrastingColour from '../../utils/colour';
import { ReactComponent as TimeIcon } from '../../assets/icons/20/time.svg';

const OrderLocation = (props) => {
  return (
    <div className="flex border-b" onClick={props.onClick}>
      {props.image && (
        <div className="w-3/12 flex items-center">
          <img src={props.image} alt={props.title ? props.title : 'location'} />
        </div>
      )}
      <div
        className={
          'p-1 flex flex-col justify-center ' +
          (props.image ? 'w-9/12' : 'w-full')
        }
        style={{
          backgroundColor: props.backgroundColour,
        }}
      >
        {props.label && (
          <div className="flex">
            <span
              className="text-dulux-primary"
              style={{
                color:
                  props.backgroundColour &&
                  contrastingColour(props.backgroundColour),
              }}
            >
              {props.label}
            </span>
          </div>
        )}
        {props.title && (
          <div className="mt-1">
            <span
              className="text-h3 font-heading leading-h3 text-dulux-dark"
              style={{
                color:
                  props.backgroundColour &&
                  contrastingColour(props.backgroundColour),
              }}
            >
              {props.title}
            </span>
          </div>
        )}
        {props.duration && (
          <div className="flex justify-end">
            <span
              className="text-technical text-dulux-dark mr-1"
              style={{
                color:
                  props.backgroundColour &&
                  contrastingColour(props.backgroundColour),
              }}
            >
              {props.duration}
            </span>
            <span
              className="text-dulux-dark"
              style={{
                color:
                  props.backgroundColour &&
                  contrastingColour(props.backgroundColour),
              }}
            >
              <TimeIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

OrderLocation.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColour: PropTypes.string,
};

OrderLocation.defaultProps = {
  label: '',
  title: '',
  duration: '',
  image: '',
  onClick: null,
  backgroundColour: '',
};

export default OrderLocation;
