import { Action } from 'redux';
import { ColourId, ColourStrength, Product } from '../../../types';

export type ProductQuery = Partial<Product>;

export interface QueryState {
  productQuery: ProductQuery;
  colourId?: ColourId | null;
  colourStrength?: ColourStrength | null;
  quantity?: number;
  cartItemId?: number;
}

export enum QueryActionType {
  APPLY_PRODUCT = 'APPLY_PRODUCT',
  APPLY_PRODUCT_SHEEN = 'APPLY_PRODUCT_SHEEN',
  APPLY_PRODUCT_SHEEN_IMAGE = 'APPLY_PRODUCT_SHEEN_IMAGE',
  APPLY_PRODUCT_SIZE = 'APPLY_PRODUCT_SIZE',
  APPLY_COLOUR = 'APPLY_COLOUR',
  APPLY_QUANTITY = 'APPLY_QUANTITY',
  APPLY_SELECTION = 'APPLY_SELECTION',
  CLEAR_PRODUCT_SHEEN = 'CLEAR_PRODUCT_SHEEN',
  CLEAR_PRODUCT_SIZE = 'CLEAR_PRODUCT_SIZE',
  CLEAR_PRODUCT_SHEEN_IMAGE = 'CLEAR_PRODUCT_SHEEN_IMAGE',
  CLEAR_COLOUR = 'CLEAR_COLOUR',
  CLEAR_QUERY = 'CLEAR_QUERY',
  CLEAR_QUANTITY = 'CLEAR_QUANTITY',
}

export interface ApplyProductQueryAction extends Action {
  type: QueryActionType.APPLY_PRODUCT;
  payload: QueryState;
}

export interface ApplyProductSheenQueryAction extends Action {
  type: QueryActionType.APPLY_PRODUCT_SHEEN;
  payload: Pick<Product, 'sheen'>;
}

export interface ApplyProductSheenImageQueryAction extends Action {
  type: QueryActionType.APPLY_PRODUCT_SHEEN_IMAGE;
  payload: Pick<Product, 'sheenImage'>;
}

export interface ApplyProductSizeQueryAction extends Action {
  type: QueryActionType.APPLY_PRODUCT_SIZE;
  payload: Pick<Product, 'size'>;
}

export interface ApplyColourQueryAction extends Action {
  type: QueryActionType.APPLY_COLOUR;
  payload: {
    base: Product['base'];
    colourId: QueryState['colourId'];
    colourStrength?: QueryState['colourStrength'];
  };
}

export interface ApplyQuantityQueryAction extends Action {
  type: QueryActionType.APPLY_QUANTITY;
  payload: {
    quantity: number;
  };
}

export interface ApplySelectionAction extends Action {
  type: QueryActionType.APPLY_SELECTION;
  payload: QueryState;
}

export interface ClearProductSheenQueryAction extends Action {
  type: QueryActionType.CLEAR_PRODUCT_SHEEN;
}

export interface ClearProductSheenImageQueryAction extends Action {
  type: QueryActionType.CLEAR_PRODUCT_SHEEN_IMAGE;
}

export interface ClearProductSizeQueryAction extends Action {
  type: QueryActionType.CLEAR_PRODUCT_SIZE;
}

export interface ClearColourQueryAction extends Action {
  type: QueryActionType.CLEAR_COLOUR;
}

export interface ClearQueryAction extends Action {
  type: QueryActionType.CLEAR_QUERY;
}

export interface ClearQuantityQueryAction extends Action {
  type: QueryActionType.CLEAR_QUANTITY;
}

export type QueryActionTypes =
  | ApplyProductQueryAction
  | ApplyProductSheenQueryAction
  | ApplyProductSheenImageQueryAction
  | ApplyProductSizeQueryAction
  | ApplyColourQueryAction
  | ApplyQuantityQueryAction
  | ApplySelectionAction
  | ClearProductSheenQueryAction
  | ClearProductSizeQueryAction
  | ClearColourQueryAction
  | ClearProductSheenImageQueryAction
  | ClearQueryAction
  | ClearQuantityQueryAction;
