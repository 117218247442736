import React from 'react';
import PropTypes from 'prop-types';

const SettingsUserPreferences = (props) => {
  return (
    <>
      {props.title && (
        <div className="px-1 py-2 bg-gray-100 text-gray-500 font-bold border-b border-t border-gray-300 flex justify-center items-center">
          {props.title}
        </div>
      )}
      {props.children && props.children}
    </>
  );
};

SettingsUserPreferences.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

SettingsUserPreferences.defaultProps = {
  title: '',
  children: null,
};

export default SettingsUserPreferences;
