import { SupportActionType, SupportActionTypes, SupportState } from './types';
import { UserActionType, UserActionTypes } from '../user/types';
import { keyBy } from 'lodash';

const initialState: SupportState = {
  byId: {},
};

const support = (
  state: SupportState = initialState,
  action: SupportActionTypes | UserActionTypes
): SupportState => {
  switch (action.type) {
    case SupportActionType.RECEIVE_DATA: {
      const data = action.payload.categories;

      return {
        ...state,
        byId: keyBy(data, 'key'),
      };
    }

    case SupportActionType.APPLY_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload.currentCategory,
      };

    case SupportActionType.APPLY_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: action.payload.currentQuery,
      };

    case SupportActionType.APPLY_SUPPORT:
      return {
        ...state,
        currentCategory: action.payload.currentCategory,
        currentQuery: action.payload.currentQuery,
      };

    case SupportActionType.CLEAR_CURRENT_CATEGORY:
      return {
        byId: state.byId,
      };

    case SupportActionType.CLEAR_CURRENT_QUERY:
      return {
        ...state,
        currentCategory: state.currentCategory,
        currentQuery: undefined,
      };

    case SupportActionType.CLEAR_SUPPORT:
    case UserActionType.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export const getSupportCategory = (state: SupportState, categoryId: string) => {
  return state.byId[categoryId];
};

export default support;
