import React from 'react';
import PropTypes from 'prop-types';
import titleCase from '../../utils/text';
import { contrastingColour } from '../../utils/colour';
import ProductColourStrength from './ProductColourStrength';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ReactComponent as TimeIcon } from '../../assets/icons/20/time.svg';

const ProductColour = (props) => {
  function renderDateLastUsed(dateString) {
    if (!dateString) {
      return;
    }

    const timeDifference = formatDistanceToNow(parseISO(dateString)).replace(
      'about ',
      ''
    );

    return (
      <div
        className="flex items-center"
        style={{ color: contrastingColour(props.hex) }}
      >
        <span className="text-small">Ordered {timeDifference} ago</span>
        <TimeIcon className="ml-1" />
      </div>
    );
  }

  function renderColourStrength() {
    if (
      props.colourStrengthEnabled &&
      props.colourIdSelected === props.colourId
    ) {
      return (
        <ProductColourStrength
          colourId={props.colourId}
          colourName={props.colourName}
          hex={props.hex}
          hasStrengthOptions={props.hasStrengthOptions}
          onClick={props.onColourConfirmClick}
          renderBrand={props.renderBrand}
          atlasCode={props.atlasCode}
          recentColourStrengths={props.recentColourStrengths}
          renderDateLastUsed={renderDateLastUsed}
        />
      );
    }
  }

  return (
    <>
      {!props.colourStrengthEnabled && (
        <div
          className="p-1 flex flex-col"
          style={{ backgroundColor: props.hex }}
          onClick={() => props.onColourClick(props.colourId, true)}
        >
          <div
            className="mb-3 flex justify-between items-center"
            style={{ color: contrastingColour(props.hex) }}
          >
            {props.renderBrand()}
            {props.atlasCode && (
              <div
                className="text-small"
                style={{ color: contrastingColour(props.hex) }}
              >
                {props.atlasCode}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center">
            {props.colourName && (
              <h2
                className="font-heading text-h2 mb-1 w-6/12"
                style={{ color: contrastingColour(props.hex) }}
              >
                {titleCase(props.colourName)}
              </h2>
            )}
            {renderDateLastUsed(props.dateOrdered)}
          </div>
        </div>
      )}

      {renderColourStrength()}
    </>
  );
};

ProductColour.propTypes = {
  colourId: PropTypes.string,
  colourName: PropTypes.string,
  hex: PropTypes.string,
  recentColourStrengths: PropTypes.object,
  onColourClick: PropTypes.func,
  onColourConfirmClick: PropTypes.func,
  renderBrand: PropTypes.func,
  hasStrengthOptions: PropTypes.bool,
  colourStrengthEnabled: PropTypes.bool,
  setColourStrengthEnabled: PropTypes.func,
  colourIdSelected: PropTypes.string,
  setColourIdSelected: PropTypes.func,
  dateOrdered: PropTypes.string,
  atlasCode: PropTypes.string,
};

ProductColour.defaultProps = {
  colourId: '',
  colourName: '',
  hex: '',
  recentColourStrengths: null,
  onColourClick: null,
  onColourConfirmClick: null,
  renderBrand: null,
  hasStrengthOptions: null,
  colourStrengthEnabled: null,
  setColourStrengthEnabled: null,
  colourIdSelected: '',
  setColourIdSelected: null,
  dateOrdered: null,
  atlasCode: '',
};

export default ProductColour;
