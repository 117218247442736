import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDeliveryMethodObject,
  selectDeliveryTimeframeLabel,
} from '../../store/selectors';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import Loader from '../../components/ui/Loader';
import { applyDeliveryTimeframe } from '../../store/actions';
import Estimate from '../../services/fulfillment/estimate';
import TimeSelect from '../../components/ui/TimeSelect';
import { useAnalyticsContext } from '../../services/analytics/context';
import { DeliveryMethodType } from '../../types';
import useFulfillmentMethodEnabled from '../../hooks/useFulfillmentMethodEnabled';

const DeliveryTimes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalyticsContext();
  const methodEnabled = useFulfillmentMethodEnabled();

  const deliveryMethod = useSelector(selectDeliveryMethodObject);
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const deliveryTimeframeLabel = useSelector(selectDeliveryTimeframeLabel);

  useEffect(() => {
    if (!methodEnabled) {
      if (history.location.pathname === '/checkout/delivery-times') {
        history.push('/checkout?error=true');
      }
    }
  }, [history, methodEnabled]);

  const onClick = (value) => {
    dispatch(applyDeliveryTimeframe(value));

    setLoaderEnabled(true);

    analytics.ecommerce.checkoutStepCompleted(2);

    setTimeout(function () {
      setLoaderEnabled(false);
      return history.push('/checkout/location');
    }, 1000);
  };

  // Make sure the delivery method is set
  if (!deliveryMethod) {
    return null;
  }

  const startingOffset =
    deliveryMethod.type === DeliveryMethodType.SCHEDULED_PICKUP ? 0 : 1;

  const deliveryOptions = new Estimate()
    .deliveryTimes(deliveryMethod.type, 7, startingOffset)
    .flat();

  return (
    <IonPage>
      <IonHeader>
        {deliveryMethod && (
          <>
            <HeaderBack
              description={deliveryMethod.name}
              title="Order method"
            />
            <HeaderTitle title={deliveryMethod.title} borderColour="#e2e8f0" />
          </>
        )}
      </IonHeader>
      <IonContent>
        {deliveryOptions.length && (
          <TimeSelect
            options={deliveryOptions}
            method={deliveryMethod.type}
            onClick={(value) => onClick(value)}
          />
        )}
      </IonContent>
      <Loader isEnabled={loaderEnabled} title={deliveryTimeframeLabel} />
    </IonPage>
  );
};

export default DeliveryTimes;
