/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import { selectColoursById, selectColourState } from './root';
import { selectOrdersDesc } from './orders';
import { selectAvailableColourIds } from './available';
import {
  flatMap,
  get,
  has,
  isNull,
  map,
  reject,
  uniqBy,
  groupBy,
  sortBy,
} from 'lodash';
import { getAttribute, getColourId } from '../../utils/order';
import { getColour } from '../reducers/colours/reducer';

// Top 30 colours have been manually placed first to make this easier
export const selectPopularColours = createSelector(
  [selectColoursById],
  (colours) => Object.values(colours).slice(0, 30)
);

export const selectRecentlyOrderedColours = createSelector(
  [selectOrdersDesc, selectColourState, selectAvailableColourIds],
  (orders, colourState, availableColourIds) => {
    const result = orders.reduce((acc, current: any, currentIndex: any) => {
      const properties = current.line_items.reduce(
        (item_acc: any, item_current: any) => {
          return {
            ...item_acc,
            [item_current.id]: item_current.properties,
          };
        },
        {}
      );

      // Get core data
      let mapped = map(properties, (value) => ({
        colourId: getColourId(value),
        strength: getAttribute(value, 'Colour Strength'),
        dateOrdered: current.created_at,
      }));

      // Filter out bad results
      mapped = reject(mapped, (item) => !item.colourId || !item.strength);

      // Populate with colour data
      mapped = map(mapped, (item) => {
        const colour = getColour(colourState, item.colourId);

        if (!colour) {
          return item;
        }

        const uniqId = item.colourId
          ? `${item.colourId}+${item.strength}`
          : undefined;

        return {
          ...item,
          ...colour,
          uniqId,
        };
      });

      // Remove anything that wasn't populated with colour data
      mapped = reject(mapped, (item) => !has(item, 'colourName'));

      return {
        ...acc,
        [currentIndex]: mapped,
      };
    }, {});

    let data = uniqBy(
      flatMap(result, (entry) => entry),
      'uniqId'
    );

    data = reject(data, (item) => {
      const colourId = get(item, 'colourId');

      return isNull(colourId) || !availableColourIds.includes(colourId);
    });

    const group = groupBy(data, 'colourId');

    // lodash groupBy will auto order the grouped items by colourId ASC
    // We want the grouped data in its original sort order: dateOrdered DESC
    return sortBy(group, (groupData) => data.indexOf(groupData[0]));
  }
);

export const selectRecentlyOrderedColourStrengths = createSelector(
  [selectRecentlyOrderedColours],
  (colourGroups) => {
    return flatMap(colourGroups, (entry) => entry);
  }
);
