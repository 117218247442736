type Rating = number | 1 | 2 | 3 | 4 | 5;

export function colourForRating(rating: Rating) {
  switch (rating) {
    case 1:
      return '#F14F56';
    case 2:
      return '#EA8F3A';
    case 3:
      return '#F2CB0A';
    case 4:
      return '#A8C46E';
    case 5:
      return '#6EC471';
  }
}

export function descriptionForRating(rating: Rating) {
  switch (rating) {
    case 1:
      return 'very difficult';
    case 2:
      return 'difficult';
    case 3:
      return 'neutral';
    case 4:
      return 'easy';
    case 5:
      return 'very easy';
  }
}
