import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

export default function useEffectDeepCompare(callback: any, dependencies: any) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useEffect(callback, useDeepCompareMemoize(dependencies));
}

function deepCompareEquals(a: any, b: any) {
  return isEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  const valuesEqual = deepCompareEquals(value, ref.current);

  if (!valuesEqual) {
    ref.current = value;
  }

  return ref.current;
}
