import { ProductStep } from '../reducers/products/types';
import {
  ApplyColourQueryAction,
  ApplyProductQueryAction,
  ApplyProductSheenQueryAction,
  ApplyProductSheenImageQueryAction,
  ApplyProductSizeQueryAction,
  ApplyQuantityQueryAction,
  ApplySelectionAction,
  ClearColourQueryAction,
  ClearProductSheenQueryAction,
  ClearProductSheenImageQueryAction,
  ClearProductSizeQueryAction,
  ClearQuantityQueryAction,
  ClearQueryAction,
  ProductQuery,
  QueryActionType,
  QueryState,
} from '../reducers/selection/types';
import { Dispatch, Thunk } from '../index';
import { getCartItem, getColour, getProduct, RootState } from '../reducers';
import { pick, get } from 'lodash';
import { CartItemId } from '../reducers/cart/types';
import {
  selectAvailableColours,
  selectAvailableSheens,
  selectAvailableSizes,
} from '../selectors';
import { ColourId, ColourStrength, Product } from '../../types';

/*
 * Validate
 */

function validateSelection(dispatch: Dispatch, getState: () => RootState) {
  function query() {
    return getState().selection.productQuery;
  }

  // If we have a product selected... but no sheens are available
  if ('name' in query() && selectAvailableSheens(getState()).length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(selectProductSheenUnsafe(null));
  }

  // If we have a product selected... but no colours are available
  if ('sheen' in query() && selectAvailableColours(getState()).length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(selectColourUnsafe(null, null, null));
  }

  // If we have a colour selected... but no sizes are available
  if ('base' in query() && selectAvailableSizes(getState()).length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(selectSizeUnsafe(null));
  }
}

/*
 * Product query
 */

const applyProductSelectionQueryUnsafe = (
  query: ProductQuery
): ApplyProductQueryAction => {
  return {
    type: QueryActionType.APPLY_PRODUCT,
    payload: { productQuery: query },
  };
};

export const applyProductSelectionQuery = (
  query: ProductQuery
): Thunk<void> => (dispatch, getState) => {
  dispatch(
    applyProductSelectionQueryUnsafe(
      pick(query, ['range', 'name', 'sheen', 'base', 'size'])
    )
  );

  validateSelection(dispatch, getState);
};

/*
 * Sheen
 */

const selectProductSheenUnsafe = (
  sheen: Product['sheen']
): ApplyProductSheenQueryAction => {
  return {
    type: QueryActionType.APPLY_PRODUCT_SHEEN,
    payload: { sheen },
  };
};

export const selectProductSheen = (sheen: Product['sheen']): Thunk<void> => (
  dispatch,
  getState
) => {
  dispatch(selectProductSheenUnsafe(sheen));

  validateSelection(dispatch, getState);
};

export const clearProductSheen = (): ClearProductSheenQueryAction => ({
  type: QueryActionType.CLEAR_PRODUCT_SHEEN,
});

/*
 * Sheen image
 */

const selectProductSheenImageUnsafe = (
  sheenImage: Product['sheenImage']
): ApplyProductSheenImageQueryAction => {
  return {
    type: QueryActionType.APPLY_PRODUCT_SHEEN_IMAGE,
    payload: { sheenImage },
  };
};

export const selectProductSheenImage = (
  image: Product['sheenImage']
): Thunk<void> => (dispatch, getState) => {
  dispatch(selectProductSheenImageUnsafe(image));
};

export const clearProductSheenImage = (): ClearProductSheenImageQueryAction => ({
  type: QueryActionType.CLEAR_PRODUCT_SHEEN_IMAGE,
});

/*
 * Colour
 */

const selectColourUnsafe = (
  base: Product['base'],
  colourId: QueryState['colourId'],
  colourStrength?: QueryState['colourStrength']
): ApplyColourQueryAction => {
  return {
    type: QueryActionType.APPLY_COLOUR,
    payload: { base, colourId, colourStrength },
  };
};

export const selectColour = (
  colourId: ColourId,
  colourStrength: ColourStrength = 1
): Thunk<void> => (dispatch, getState) => {
  const colour = getColour(getState(), colourId);
  let baseName = get(colour, 'baseName');

  // Product base names are stored in title case, make sure we convert
  baseName = baseName.replace(/\w\S*/g, function (txt) {
    return `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`;
  });

  dispatch(selectColourUnsafe(baseName, colourId, colourStrength));
};

export const clearColour = (): ClearColourQueryAction => ({
  type: QueryActionType.CLEAR_COLOUR,
});

/*
 * Size
 */

const selectSizeUnsafe = (
  size: Product['size']
): ApplyProductSizeQueryAction => {
  return {
    type: QueryActionType.APPLY_PRODUCT_SIZE,
    payload: { size },
  };
};

export const selectSize = (size: Product['size']): Thunk<void> => (
  dispatch,
  getState
) => {
  dispatch(selectSizeUnsafe(size));
};

export const clearProductSize = (): ClearProductSizeQueryAction => ({
  type: QueryActionType.CLEAR_PRODUCT_SIZE,
});

/*
 * Quantity
 */

const selectQuantitySelectionQueryUnsafe = (
  quantity: number
): ApplyQuantityQueryAction => {
  return {
    type: QueryActionType.APPLY_QUANTITY,
    payload: { quantity },
  };
};

export const selectQuantity = (quantity: number): Thunk<void> => (
  dispatch,
  getState
) => {
  dispatch(selectQuantitySelectionQueryUnsafe(quantity));
};

export const clearQuantity = (): ClearQuantityQueryAction => ({
  type: QueryActionType.CLEAR_QUANTITY,
});

/*
 * TODO: Reorganise --------------------------------------
 */

export const clearSelection = (): ClearQueryAction => {
  return {
    type: QueryActionType.CLEAR_QUERY,
  };
};

const applySelectionUnsafe = (
  productQuery: ProductQuery,
  colourId: ColourId | null,
  colourStrength: ColourStrength | null,
  quantity: number,
  cartItemId: number
): ApplySelectionAction => {
  return {
    type: QueryActionType.APPLY_SELECTION,
    payload: {
      productQuery,
      colourId,
      quantity,
      colourStrength,
      cartItemId,
    },
  };
};

export const editCartItemSelection = (
  cartItemId: CartItemId,
  step?: ProductStep
): Thunk<void> => (dispatch, getState) => {
  const cartItem = getCartItem(getState(), cartItemId);

  if (!cartItem) {
    console.error(
      `Cannot edit cartItem with id: ${cartItemId} as it could not be found.`
    );
    return;
  }

  const product = getProduct(getState(), cartItem.productId);

  dispatch(
    applySelectionUnsafe(
      product,
      cartItem.colourId,
      cartItem.colourStrength,
      cartItem.quantity,
      cartItem.cartItemId
    )
  );

  switch (step) {
    case ProductStep.SHEEN:
      dispatch(clearProductSheen());
      break;
    case ProductStep.COLOUR:
      dispatch(clearColour());
      break;
    case ProductStep.SIZE:
      dispatch(clearProductSize());
      break;
    case ProductStep.QUANTITY:
      dispatch(clearQuantity());
      break;
  }
};
