import React from 'react';
import { ReactComponent as DuluxTradeDirect } from '../assets/images/brand/dulux-trade-direct.svg';
import PaintBucket from '../assets/images/loader/paint-bucket.gif';

const Holding = () => {
  return (
    <div className="bg-dulux-light-pressed h-full flex flex-col justify-start items-center pt-9 px-2">
      <DuluxTradeDirect className="mb-6 w-full" />
      <h2 className="font-heading text-h2 pb-2">Your info is being verified</h2>
      <p className="text-center text-h3 px-4">
        We&rsquo;ll provide you with an update on your account status within the
        next 48 hours.
      </p>
      <div className="border rounded-lg mt-3 mb-auto p-1 bg-dulux-white">
        <img
          src={PaintBucket}
          width="180"
          height="auto"
          className="my-0 mx-auto"
          alt="Paint bucket getting filled up"
        />
      </div>
    </div>
  );
};

export default Holding;
