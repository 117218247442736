import { User as FirebaseUser } from 'firebase/app';
import * as firebase from 'firebase';

export interface User {
  uid: FirebaseUser['uid'];
  providerData: FirebaseUser['providerData'];
  phoneNumber: string;

  // We set this variable once the data listener has fired once,
  // it indicates that all of the user data has been loaded in.
  isUserDataLoaded?: true;

  email?: string;
  firstName?: string;
  lastName?: string;

  accountId?: string;
  duluxRepId?: string;
  businessName?: string;

  enableSmsNotification?: boolean;
  enableEmailNotification?: boolean;
  enableMarketingEmails?: boolean;

  staySignedIn?: boolean;
  termsAccepted?: boolean;
  preferredStore?: string;
  status?: UserStatus;

  // Orders relation
  orders?: {
    [key: string]: true;
  };
  orderFeedbackRequired?: string;

  // Features relation
  features?: {
    [key: string]: true;
  };
}

export enum UserType {
  EXISTING_CUSTOMER = 'existing_dulux_customer',
  NEW_CUSTOMER = 'new_customer',
  SUBCONTRACTOR = 'subcontractor',
}

export enum UserStatus {
  REGISTRATION_REQUIRED = 'registration_required',
  VERIFICATION_REQUIRED = 'verification_required',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export type UserAuthFields = Pick<User, 'uid' | 'phoneNumber' | 'providerData'>;
export type UserDataFields = Omit<User, 'uid' | 'phoneNumber' | 'providerData'>;

export interface LineItem {
  sku: string;
  title: string;
  price: number;
  quantity: number;
  attributes?: Attribute[];
}

export interface Attribute {
  name: string;
  value: string;
}

export interface FulfillmentConfig {
  [key: string]: {
    enabled: boolean;
  };
}

export interface RemoteConfigOverride {
  firebaseConfig: {
    [key: string]: firebase.remoteConfig.Value;
  };
  fulfillmentOptions: FulfillmentConfig;
}
