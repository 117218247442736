import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectFeaturesByVoteCount } from '../../store/selectors';
import FeatureRewardsIcon from '../../assets/images/homepage/feature-rewards@2x.png';
import FeatureBulkDealsIcon from '../../assets/images/homepage/feature-bulk-deals@2x.png';
import FeatureAccountBalanceIcon from '../../assets/images/homepage/feature-account-balance@2x.png';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/20/thumbs-up.svg';
import { map, has } from 'lodash';
import { motion } from 'framer-motion';

const FEATURE_REWARDS = 'featureRewards';
const FEATURE_ACCOUNT_BALANCE = 'featureAccountBalance';
const FEATURE_BULK_DEALS = 'featureBulkDeals';

const animation = {
  type: 'spring',
  damping: 15,
  stiffness: 100,
};

const HomeFeatures = (props) => {
  const featuresData = useSelector(selectFeaturesByVoteCount);

  function getFeatureDetails(feature) {
    switch (feature) {
      case FEATURE_REWARDS: {
        return {
          title: 'Rewards',
          description:
            'Be rewarded for every order. Earn points and enjoy discounts on purchases.',
          icon: FeatureRewardsIcon,
          altText: 'Roller brush',
          isLiked: has(props.userFeatures, FEATURE_REWARDS),
        };
      }
      case FEATURE_ACCOUNT_BALANCE: {
        return {
          title: 'Account balance',
          description:
            'Quickly view your account balance and monthly spend from the app.',
          icon: FeatureAccountBalanceIcon,
          altText: 'money on scales',
          isLiked: has(props.userFeatures, FEATURE_ACCOUNT_BALANCE),
        };
      }
      case FEATURE_BULK_DEALS: {
        return {
          title: 'Bulk deals',
          description:
            'Save on product and accessory bundles that you use often.',
          icon: FeatureBulkDealsIcon,
          altText: 'paint cans',
          isLiked: has(props.userFeatures, FEATURE_BULK_DEALS),
        };
      }
      // Should not have a non-mapped Feature
      default:
        return {};
    }
  }

  function renderFeatures() {
    return map(featuresData, (data, rank) => {
      const currentFeature = getFeatureDetails(data.id);

      return (
        <motion.div
          positionTransition={animation}
          key={data.id}
          className="bg-white border-b p-2 flex flex-col"
        >
          <div className="flex w-full items-center border-b pb-1">
            <div className="w-7/12">
              <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                {currentFeature.title}
              </h2>
              <p className="text-dulux-dark text-small">
                {currentFeature.description}
              </p>
            </div>
            <div className="w-5/12">
              <img src={currentFeature.icon} alt={currentFeature.altText} />
            </div>
          </div>
          <div className="flex pt-1 justify-between items-center">
            <div className="flex items-center">
              <div
                className={
                  'w-5 h-5 rounded-full flex justify-center items-center ' +
                  (rank + 1 === 1
                    ? 'bg-dulux-primary text-white'
                    : 'bg-blue-200 text-dulux-primary')
                }
              >
                {rank + 1}
              </div>
              {rank + 1 === 1 && (
                <p className="text-dulux-primary ml-1">Top Feature</p>
              )}
            </div>
            <div
              className={
                'border flex justify-center items-center py-1 px-2 rounded-full ' +
                (currentFeature.isLiked
                  ? 'bg-dulux-primary text-white border-dulux-primary'
                  : 'text-gray-600 bg-white')
              }
              onClick={() => props.handleVote(data.id, !currentFeature.isLiked)}
            >
              <ThumbsUpIcon className="mr-1" />
              {currentFeature.isLiked ? 'Liked' : 'Like'}
            </div>
          </div>
        </motion.div>
      );
    });
  }

  return (
    <>
      <div className="border-t border-b p-2">
        <h2 className="font-heading text-dulux-dark text-h2 mb-1">
          Future releases
        </h2>
        <p className="text-dulux-dark text-small mb-1">
          Let us know which features you&rsquo;d like to see on the app next by
          giving them a thumbs up.
        </p>
      </div>

      {renderFeatures()}

      {/* <div className="border-t border-b bg-gray-200 p-1 py-2">
        <div className="flex flex-col text-center">
          <h2 className="font-heading text-dulux-dark text-h2 mb-1">
            Suggest a feature
          </h2>
          <p className="text-dulux-dark text-small mt-1 mb-2">
            View the price of delivery in checkout and calculate the full cost
            of your order.
          </p>
          <input
            type="text"
            placeholder="Suggest a feature"
            className="p-2 px-3 border border-dulux-dark rounded-full bg-transparent text-dulux-dark placeholder-dulux-dark"
          />
        </div>
      </div> */}
    </>
  );
};

HomeFeatures.propTypes = {
  handleVote: PropTypes.func,
  userFeatures: PropTypes.object,
};

HomeFeatures.defaultProps = {
  handleVote: null,
  userFeatures: null,
};

export default HomeFeatures;
