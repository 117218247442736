import {
  addDays,
  addMinutes,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isSaturday,
  isSunday,
  isToday,
  max,
  roundToNearestMinutes,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfDay,
  startOfHour,
  subHours,
  subMinutes,
} from 'date-fns';
import { DeliveryMethodType, TimeOfDay, WeeklyOpenHours } from '../../types';

interface StoreProperties {
  hours: WeeklyOpenHours;
}

const defaultStoreProperties: StoreProperties = {
  hours: {
    default: { open: 7, close: 17 },
    saturday: { open: 8, close: 11 },
    sunday: undefined,
  },
};

// const stores = {}; // We don't have bespoke store hours yet

interface DeliveryProperty {
  // The number of minutes it will take to deliver
  duration: number;

  // The increment between selectable times, ie: 30 min blocks or 60 min blocks?
  optionsIncrement?: number;

  // If the generated times should be rounded up to the nearest option increment
  // Defaults to true
  roundToIncrement?: boolean;

  // The number of minutes before store closing that this delivery option is available
  // Defaults to how longs it takes to deliver
  closingCutoff?: number;

  // The minimum minutes required to prepare the order
  prepTime?: number;
}

interface DeliveryProperties {
  [key: string]: DeliveryProperty;
}

const defaultDeliveryTimeIncrement = 30;

const deliveryProperties: DeliveryProperties = {
  [DeliveryMethodType.PRIORITY_DELIVERY]: {
    duration: 120,
    optionsIncrement: 30,
    closingCutoff: 120,
    prepTime: 0,
  },
  [DeliveryMethodType.PRIORITY_PICKUP]: {
    duration: 0,
    optionsIncrement: 30,
    roundToIncrement: false,
    closingCutoff: 30,
    prepTime: 30,
  },

  [DeliveryMethodType.REGULAR_DELIVERY]: {
    duration: 240,
    optionsIncrement: 60,
    closingCutoff: 240,
    prepTime: 0,
  },
  [DeliveryMethodType.REGULAR_PICKUP]: {
    duration: 0,
    optionsIncrement: 30,
    closingCutoff: 60,
    prepTime: 60,
  },

  [DeliveryMethodType.SCHEDULED_DELIVERY]: {
    duration: 240,
    optionsIncrement: 60,
    closingCutoff: 240,
    prepTime: 0,
  },
  [DeliveryMethodType.SCHEDULED_PICKUP]: {
    duration: 0,
    optionsIncrement: 30,
    closingCutoff: 60,
    prepTime: 60,
  },

  [DeliveryMethodType.AFTER_HOURS_PICKUP]: { duration: 120 },
};

export interface DeliveryOption {
  method: DeliveryMethodType;
  label: string;
  dispatch: Date;
  delivery: Date;
}

export default class Estimate {
  deliveryProperties: DeliveryProperties = deliveryProperties;
  simulateDate?: Date;

  /**
   * Get the current date/time.
   *
   * This has been extracted to its own function for easy debugging.
   * You can change the returned date to simulate a different time.
   */
  private getCurrentDate() {
    return this.simulateDate || new Date();
  }

  private getDeliveryProperties(): DeliveryProperties {
    return this.deliveryProperties;
  }

  earliestDeliveryTime(deliveryMethod: DeliveryMethodType): DeliveryOption {
    return this.deliveryTimes(deliveryMethod).flat()[0];
  }

  /**
   * Generate delivery times for a given delivery method.
   *
   * @param deliveryMethod: The delivery method to use
   * @param days: The number of days worth of time slots
   * @param startingOffset: The number of days to offset the delivery times by
   */
  deliveryTimes(
    deliveryMethod: DeliveryMethodType,
    days = 5,
    startingOffset = 0
  ): DeliveryOption[][] {
    let firstDay = this.getCurrentDate();

    // If we offset the start time then set the time back as we have more than a day to prepare
    if (startingOffset > 0) {
      firstDay = addDays(firstDay, startingOffset);
      firstDay = subMinutes(
        firstDay,
        this.getDeliveryDurationMinutes(deliveryMethod)
      );
    }

    let totalDeliveryTimesGenerated = 0;

    return [...Array(days).keys()].map((offset) => {
      let day = addDays(firstDay, offset);

      if (offset > 0) {
        day = startOfDay(day);
      }

      // If we're looking at the 2nd day, and the first day had nothing...
      // Then we've "overflowed" and need to add a prep-time in the morning
      // to give staff time to prepare
      const overflowed = totalDeliveryTimesGenerated === 0 && offset > 0;

      const times = this.deliveryTimesForDay(day, deliveryMethod, overflowed);

      totalDeliveryTimesGenerated += times.length;

      return times;
    });
  }

  deliveryTimesForDay(
    day: Date,
    deliveryMethod: DeliveryMethodType,
    isOverflow = false
  ): DeliveryOption[] {
    if (deliveryMethod === DeliveryMethodType.AFTER_HOURS_PICKUP) {
      return this.afterHoursLockerTimesForDay(day);
    }

    if (
      deliveryMethod === DeliveryMethodType.REGULAR_DELIVERY ||
      deliveryMethod === DeliveryMethodType.SCHEDULED_DELIVERY
    ) {
      return this.morningAfternoonDeliveryTimesForDay(day, deliveryMethod);
    }

    return this.deliveryTimeIncrementsForDay(day, deliveryMethod, isOverflow);
  }

  deliveryTimeIncrementsForDay(
    day: Date,
    deliveryMethod: DeliveryMethodType,
    isOverflow = false
  ): DeliveryOption[] {
    const roundedDay = this.roundUp(day, deliveryMethod);
    const dispatchStart = this.getEarliestDispatch(
      roundedDay,
      deliveryMethod,
      isOverflow
    );
    const dispatchEnd = this.getLatestDispatch(roundedDay, deliveryMethod);

    // There aren't any delivery times for this day if the start of the range is tomorrow
    if (!isSameDay(dispatchStart, roundedDay)) {
      return [];
    }

    // Or if the start time has been pushed back past the end time
    if (isAfter(dispatchStart, dispatchEnd)) {
      return [];
    }

    const times = [dispatchStart];
    let nextTime = this.incrementTime(times[times.length - 1], deliveryMethod);

    while (!isAfter(nextTime, dispatchEnd)) {
      times.push(nextTime);
      nextTime = this.incrementTime(nextTime, deliveryMethod);
    }

    return times.map((dispatch) =>
      this.createDeliveryOption(dispatch, deliveryMethod)
    );
  }

  afterHoursLockerTimesForDay(day: Date): DeliveryOption[] {
    const deliveryMethod = DeliveryMethodType.AFTER_HOURS_PICKUP;
    const roundedDay = this.roundUp(day, deliveryMethod);
    const dispatchEnd = this.getLatestDispatch(roundedDay, deliveryMethod);

    // There aren't any delivery times for this day if the start of the range is tomorrow
    if (!isSameDay(dispatchEnd, roundedDay)) {
      return [];
    }

    // After the cutoff, store is closed
    if (isAfter(roundedDay, dispatchEnd)) {
      return [];
    }

    // Ready when the store closes
    const startDate = this.getStoreCloseTime(dispatchEnd);

    // Pick up an hour before store open
    const storeOpen = this.getStoreOpenTime(addDays(dispatchEnd, 1));
    const endDate = subHours(storeOpen, 1);

    const labelStart = format(startDate, isToday(startDate) ? 'p' : 'eee p');
    const labelEnd = format(endDate, 'eee p');

    return [
      {
        method: deliveryMethod,
        dispatch: dispatchEnd,
        delivery: startDate,
        label: `${labelStart} - ${labelEnd}`,
      },
    ];
  }

  morningAfternoonDeliveryTimesForDay(
    day: Date,
    deliveryMethod: DeliveryMethodType
  ): DeliveryOption[] {
    const roundedDay = this.roundUp(day, deliveryMethod);
    const dispatchStart = this.getEarliestDispatch(roundedDay, deliveryMethod);
    const dispatchEnd = this.getLatestDispatch(roundedDay, deliveryMethod);

    // There aren't any delivery times for this day if the start of the range is tomorrow
    if (!isSameDay(dispatchStart, roundedDay)) {
      return [];
    }

    const options = [];

    // Add morning delivery if we can fit it in
    const morningDelivery = this.getDeliveryForDispatch(
      dispatchStart,
      deliveryMethod
    );

    if (
      isBefore(morningDelivery, dispatchEnd) &&
      getHours(morningDelivery) < 12 // Delivery must also be before mid-day
    ) {
      options.push(this.createDeliveryOption(dispatchStart, deliveryMethod));
    }

    // Add afternoon delivery
    const afternoonDelivery = this.getDeliveryForDispatch(
      dispatchEnd,
      deliveryMethod
    );

    // Must be after mid-day
    if (getHours(afternoonDelivery) >= 12) {
      options.push(this.createDeliveryOption(dispatchEnd, deliveryMethod));
    }

    return options;
  }

  // TODO: Check store open hours before default
  getStoreHoursProperty(day: Date) {
    if (isSaturday(day)) {
      return defaultStoreProperties.hours.saturday;
    }

    if (isSunday(day)) {
      return defaultStoreProperties.hours.sunday;
    }

    return defaultStoreProperties.hours.default;
  }

  getDeliveryForDispatch(
    dispatchTime: Date,
    deliveryMethod: DeliveryMethodType
  ): Date {
    return addMinutes(
      dispatchTime,
      this.getDeliveryDurationMinutes(deliveryMethod)
    );
  }

  createDeliveryOption(
    dispatch: Date,
    deliveryMethod: DeliveryMethodType,
    label: string | null = null
  ): DeliveryOption {
    const delivery = this.getDeliveryForDispatch(dispatch, deliveryMethod);
    const dayFormat = isToday(delivery) ? 'p' : 'eee p';

    return {
      method: deliveryMethod,
      dispatch,
      delivery,
      label: label || format(delivery, dayFormat),
    };
  }

  getLatestDispatch(day: Date, deliveryMethod: DeliveryMethodType) {
    const storeClose = this.getStoreCloseTime(day);
    const cutoff = this.getDeliveryCutoffMinutes(deliveryMethod);

    return subMinutes(storeClose, cutoff);
  }

  getEarliestDispatch(
    day: Date,
    deliveryMethod: DeliveryMethodType,
    isOverflow = false
  ): Date {
    const storeOpen = this.getStoreOpenTime(day);
    const prepTime = this.getDeliveryPrepTimeMinutes(deliveryMethod);

    const isSameDayBeforeOpening =
      isSameDay(this.getCurrentDate(), storeOpen) &&
      isBefore(this.getCurrentDate(), storeOpen);

    const earliestDispatch = max([
      this.roundUp(day, deliveryMethod),
      storeOpen,
      // If we've overflowed from the previous day then make sure
      // we give the store prep time in the morning
      addMinutes(storeOpen, isOverflow ? prepTime : 0),
      // Also give prep time if it's the same day and the store hasn't opened yet
      addMinutes(storeOpen, isSameDayBeforeOpening ? prepTime : 0),
      // Account for the minimum prep time on the current day
      this.roundUp(addMinutes(this.getCurrentDate(), prepTime), deliveryMethod),
    ]);

    const storeLatestDispatch = this.getLatestDispatch(day, deliveryMethod);

    // If we're not currently after the dispatch cutoff, then return the time we calculated
    if (!isAfter(day, storeLatestDispatch)) {
      return earliestDispatch;
    }

    // Otherwise, the earliest we can dispatch is tomorrow
    const startOfTomorrow = startOfDay(addDays(day, 1));

    return this.getEarliestDispatch(startOfTomorrow, deliveryMethod, true);
  }

  getLatestDelivery(day: Date, deliveryMethod: DeliveryMethodType): Date {
    return this.getDeliveryForDispatch(
      this.getLatestDispatch(day, deliveryMethod),
      deliveryMethod
    );
  }

  getEarliestDelivery(day: Date, deliveryMethod: DeliveryMethodType): Date {
    return this.getDeliveryForDispatch(
      this.getEarliestDispatch(day, deliveryMethod),
      deliveryMethod
    );
  }

  getStoreOpenTime(day: Date): Date {
    const hours = this.getStoreHoursProperty(day);

    if (!hours) {
      return this.getStoreOpenTime(addDays(day, 1));
    }

    return Estimate.setTimeOfDay(day, hours.open);
  }

  getStoreCloseTime(day: Date): Date {
    const hours = this.getStoreHoursProperty(day);

    if (!hours) {
      return this.getStoreCloseTime(addDays(day, 1));
    }

    return Estimate.setTimeOfDay(day, hours.close);
  }

  private getDeliveryProperty(
    method: DeliveryMethodType,
    property: keyof DeliveryProperty,
    fallback: any = 0
  ) {
    const properties = this.getDeliveryProperties()[method];

    return Object.prototype.hasOwnProperty.call(properties, property)
      ? properties[property]
      : fallback;
  }

  private getDeliveryDurationMinutes(method: DeliveryMethodType) {
    return this.getDeliveryProperty(method, 'duration');
  }

  private getDeliveryIncrementMinutes(method: DeliveryMethodType) {
    return this.getDeliveryProperty(
      method,
      'optionsIncrement',
      defaultDeliveryTimeIncrement
    );
  }

  private getDeliveryShouldRoundToIncrement(method: DeliveryMethodType) {
    return this.getDeliveryProperty(method, 'roundToIncrement', true);
  }

  private getDeliveryCutoffMinutes(method: DeliveryMethodType) {
    return this.getDeliveryProperty(
      method,
      'closingCutoff',
      this.getDeliveryDurationMinutes(method)
    );
  }

  private getDeliveryPrepTimeMinutes(method: DeliveryMethodType) {
    return this.getDeliveryProperty(method, 'prepTime');
  }

  private static roundToNearestHour(time: Date) {
    const roundHour = setHours(
      time,
      getHours(time) + Math.round(getMinutes(time) / 60)
    );

    const roundMinutes = setMinutes(roundHour, 0);
    const roundSeconds = setSeconds(roundMinutes, 0);

    return setMilliseconds(roundSeconds, 0);
  }

  private static setTimeOfDay(date: Date, timeOfDay: number | TimeOfDay) {
    let hour = 0;
    let minute = 0;

    if (typeof timeOfDay === 'number') {
      hour = timeOfDay;
    }

    if (typeof timeOfDay === 'object') {
      hour = timeOfDay.hour;
      minute = timeOfDay.minute || 0;
    }

    return setMinutes(setHours(startOfHour(date), hour), minute);
  }

  private roundUp(time: Date, deliveryMethod: DeliveryMethodType) {
    if (!this.getDeliveryShouldRoundToIncrement(deliveryMethod)) {
      return time;
    }

    const interval = this.getDeliveryIncrementMinutes(deliveryMethod);

    // Round to the nearest hour if the interval is greater than 30mins
    const rounded =
      interval > 30
        ? Estimate.roundToNearestHour(time)
        : roundToNearestMinutes(time, { nearestTo: interval });

    // If it rounded down, add the interval minutes
    if (isBefore(rounded, time)) {
      return addMinutes(rounded, interval);
    }

    return rounded;
  }

  private incrementTime(time: Date, deliveryMethod: DeliveryMethodType) {
    return addMinutes(time, this.getDeliveryIncrementMinutes(deliveryMethod));
  }
}

/*
PRIORITY_PICKUP - 24hours - 30min brackets
PRIORITY_DELIVERY - 24hours - 30min brackets

REGULAR_PICKUP - 7days - 2 brackets per day (morning or afternoon)
REGULAR_DELIVERY - 7days - 2 brackets per day (morning or afternoon)
AFTER_HOURS_PICKUP - 7 days - 1 bracket (5pm - 7am)
 */
