import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import { Plugins } from '@capacitor/core';
import * as Sentry from '@sentry/browser';
import firebaseConfig from './config';
import { Attribute, LineItem, User, UserDataFields } from './types';
import {
  cfaSignInPhone,
  cfaSignInPhoneOnCodeReceived,
  cfaSignInPhoneOnCodeSent,
} from '@bbe-io/capacitor-firebase-auth'; // TODO: Revert to original package once patched
import {
  receiveVerificationIdAndCode,
  receiveVerificationId,
  verificationRequest,
  logout,
  loginRequest,
  loginSuccess,
  loginFailure,
  verificationFailure,
  receiveUserData,
  loginTimeout,
  verificationTimeout,
  initialised,
} from '../../store/actions';
import { Dispatch } from '../../store';
import { hasNativeCapability } from '../../utils/environment';
import { Subscription } from 'rxjs';
import Push from '../push/push';
import FirebaseFunctions from './concerns/functions';
import FirebaseProducts from './concerns/products';
import { StructuredAddress } from '../../models/Address';
import Analytics from '../analytics/analytics';
import { timeoutPromise } from '../../utils/promise';
import FirebaseVoting from './concerns/voting';
import PubSub from '../../models/PubSub';
import FirebaseOrders from './concerns/orders';
import FirebaseDuluxRep from './concerns/duluxRep';
import FirebaseRemoteConfig from './concerns/remoteConfig';
import FirebaseStores from './concerns/stores';

const { Modals } = Plugins;

const loginTimeoutMillis = 30 * 1000; // 10 seconds
const verificationTimeoutMillis = 30 * 1000; // 10 seconds

export const EVENTS = { onUserData: 'onUserData' };

class Firebase {
  // Set to true when Firebase has been successfully booted
  private booted = false;

  readonly dispatch: Dispatch;
  readonly push: Push;
  readonly analytics: Analytics;

  // Internal pub/sub bus
  bus: PubSub;

  // Firebase APIs
  auth: firebase.auth.Auth;
  db: firebase.database.Database;
  functions: FirebaseFunctions;
  products: FirebaseProducts;
  stores: FirebaseStores;
  orders: FirebaseOrders;
  voting: FirebaseVoting;
  duluxRep: FirebaseDuluxRep;
  remoteConfig: FirebaseRemoteConfig;

  // Helpers
  private serverValue: typeof firebase.database.ServerValue;
  private phoneAuthProvider: typeof firebase.auth.PhoneAuthProvider;

  // Data References
  private userRef?: firebase.database.Reference;
  private readonly onUserDataValueListener: any;

  // Timeouts
  private loginTimeout: NodeJS.Timeout | undefined;
  private verificationTimeout: NodeJS.Timeout | undefined;

  // Subscription
  private cfaSignInPhoneOnCodeSent: Subscription | undefined;
  private cfaSignInPhoneOnCodeReceived: Subscription | undefined;

  constructor(dispatch: Dispatch, push: Push, analytics: Analytics) {
    // We accepted the dispatch function so that we could listen for Firebase
    // events and trigger actions within the global store based on them
    this.dispatch = dispatch;
    this.push = push;
    this.analytics = analytics;

    firebase.initializeApp(firebaseConfig);

    // Create the internal pub/sub bus
    this.bus = new PubSub();

    // Helpers
    this.serverValue = firebase.database.ServerValue;
    this.phoneAuthProvider = firebase.auth.PhoneAuthProvider;

    // Firebase APIs
    this.auth = firebase.auth();
    this.db = firebase.database();
    this.functions = new FirebaseFunctions();
    this.remoteConfig = new FirebaseRemoteConfig();
    this.products = new FirebaseProducts(this);
    this.stores = new FirebaseStores(this);
    this.orders = new FirebaseOrders(this);
    this.voting = new FirebaseVoting(this);
    this.duluxRep = new FirebaseDuluxRep(this);

    // Bind listeners
    this.onUserDataValueListener = this.onUserDataValue.bind(this);

    // Boot Firebase after 5 seconds if we haven't for some reason
    setTimeout(() => {
      if (!this.booted) {
        console.warn("Firebase wasn't booted, running backup boot");
        this.boot();
      }
    }, 5000);
  }

  boot() {
    if (this.booted) {
      return console.warn('Firebase already booted');
    }

    this.subscribeToNativeAuthEvents();
    this.subscribeToFirebaseAuthEvents();

    this.products.boot();
    this.stores.boot();
    this.orders.boot();
    this.voting.boot();
    this.duluxRep.boot();
    this.remoteConfig.boot();

    this.booted = true;
  }

  // *** Auth API ***

  getUserRef() {
    return this.userRef;
  }

  getVerifier(container: any | string) {
    return new firebase.auth.RecaptchaVerifier(container, {
      size: 'normal',
    });
  }

  requestVerificationCode(
    phoneNumber: string,
    verifier?: firebase.auth.ApplicationVerifier
  ) {
    // Clear the timeout from a previous request if started
    if (this.verificationTimeout) {
      clearTimeout(this.verificationTimeout);
    }

    this.dispatch(verificationRequest(phoneNumber));

    // Timeout after 5 seconds
    this.verificationTimeout = setTimeout(() => {
      this.dispatch(verificationTimeout());
      this.analytics.trackEvent('User Login Failed', {
        properties: {
          category: 'User',
          label: 'Timeout',
        },
      });
    }, verificationTimeoutMillis);

    if (hasNativeCapability()) {
      return cfaSignInPhone(phoneNumber).subscribe(() => {
        // Nothing to do here, the auth listeners will pickup on
        // the event and dispatch a login success action
        console.log('cfaSignInPhone');
      });
    }

    // On web devices we need to use the JS version with a verifier.
    // We also can't rely on the native hooks and must handle the response here.
    if (!verifier) {
      console.error('A verifier is required for authenticating on web');
      return;
    }

    this.auth
      .signInWithPhoneNumber(phoneNumber, verifier)
      .then((res) => {
        this.dispatch(receiveVerificationId(res.verificationId));
      })
      .catch((error) => {
        this.analytics.trackEvent('User Login Failed', {
          properties: {
            category: 'User',
            label: 'Verification id',
          },
        });
        this.dispatch(verificationFailure(error.message));
      });
  }

  signInWithPhone(verificationCode?: string) {
    // Clear the timeout from a previous request if started
    if (this.loginTimeout) {
      clearTimeout(this.loginTimeout);
    }

    this.dispatch(loginRequest());

    // Timeout after 5 seconds
    this.loginTimeout = setTimeout(() => {
      this.dispatch(loginTimeout());
      this.analytics.trackEvent('User Login Failed', {
        properties: {
          category: 'User',
          label: 'Timeout',
        },
      });
    }, loginTimeoutMillis);

    this.dispatch((dispatch, getState) => {
      const id = getState().user.auth.verificationId;
      const code = verificationCode || getState().user.auth.verificationCode;

      if (!id) {
        this.analytics.trackEvent('User Login Failed', {
          properties: {
            category: 'User',
            label: 'Verification id',
          },
        });
        return this.dispatch(
          verificationFailure(
            'An error occurred signing you in, please try again'
          )
        );
      }

      if (!code) {
        this.analytics.trackEvent('User Login Failed', {
          properties: {
            category: 'User',
            label: 'Verification code',
          },
        });
        return this.dispatch(
          loginFailure('Your verification code must be provided')
        );
      }

      const credential = this.phoneAuthProvider.credential(id, code);

      this.signInWithCredential(credential)
        .then((userCredential) => {
          if (!userCredential.user) {
            this.analytics.trackEvent('User Login Failed', {
              properties: {
                category: 'User',
                label: 'Credentials',
              },
            });
            throw new Error(
              'An error occurred attempting to sign in with your credentials'
            );
          }

          // Nothing to do here, the auth listeners will pickup on
          // the event and dispatch a login success action
          console.log('signInWithCredential');
        })
        .catch((error) => {
          this.analytics.trackEvent('User Login Failed', {
            properties: {
              category: 'User',
              label: 'Credentials',
            },
          });
          this.dispatch(loginFailure(error.message));
        });
    });
  }

  signInWithCredential(credential: firebase.auth.AuthCredential) {
    return this.auth.signInWithCredential(credential);
  }

  signOut() {
    return timeoutPromise(3000, this.unregisterPushNotifications()).finally(
      () =>
        this.auth.signOut().then(() => {
          console.log('Signed Out');
        })
    );
  }

  // *** Auth Events ***

  public subscribeToNativeAuthEvents() {
    // Only works on native devices
    if (!hasNativeCapability()) {
      return;
    }

    // Android and iOS
    // Get the verification ID when a request is sent successfully
    if (this.cfaSignInPhoneOnCodeSent) {
      this.cfaSignInPhoneOnCodeSent.unsubscribe();
    }

    this.cfaSignInPhoneOnCodeSent = cfaSignInPhoneOnCodeSent().subscribe(
      (verificationId) => {
        this.dispatch(receiveVerificationId(verificationId));
      }
    );

    // Android Only
    // Automatically sign the user in when a code is received.
    if (this.cfaSignInPhoneOnCodeReceived) {
      this.cfaSignInPhoneOnCodeReceived.unsubscribe();
    }

    this.cfaSignInPhoneOnCodeReceived = cfaSignInPhoneOnCodeReceived().subscribe(
      (event) => {
        this.dispatch(
          receiveVerificationIdAndCode(
            event.verificationId,
            event.verificationCode
          )
        );
      }
    );
  }

  private subscribeToFirebaseAuthEvents() {
    this.auth.onAuthStateChanged((user) => {
      this.dispatch(initialised());

      // Unsubscribe from any data changes if we are
      if (this.userRef) {
        this.userRef.off('value', this.onUserDataValueListener);
      }

      if (!user) {
        this.analytics.trackEvent('User Logged Out', {
          properties: {
            category: 'User',
          },
        });
        this.analytics.clearUserId();
        this.orders.removeAllListeners();
        return this.dispatch(logout());
      }

      if (!user.phoneNumber) {
        return console.error('User does not have a phone number');
      }

      this.dispatch(
        loginSuccess({
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          providerData: user.providerData,
        })
      );

      Sentry.setUser({
        id: user.uid,
        phoneNumber: user.phoneNumber,
      });

      this.analytics.setUserId(user.uid);

      // Subscribe to updates of user data
      this.userRef = this.user(user.uid);
      this.userRef.on('value', this.onUserDataValueListener);

      // Prefill with default data if it doesn't exist
      this.setInitialUserData();

      this.userRef.once('value').then((result) => {
        this.analytics.identify(result.val());
        this.analytics.trackEvent('User Logged In', {
          properties: {
            category: 'User',
          },
        });
      });

      // Register for push notifications
      this.push.requestPermission().then((granted) => {
        if (granted) {
          this.registerPushNotifications();
        } else {
          Sentry.captureMessage('Push notification access not granted');
        }
      });
    });
  }

  /*
   * Check if the user has data, and prefill it if not.
   */
  setInitialUserData() {
    this.functions
      // TODO: Switch to login function with new auth/registration
      .login()
      .then((result) => {
        // TODO: Might need to account for each user state here?

        // Unknown state...
        if (!result.data) {
          return this.signOutAccountDisable();
        }
      })
      .catch((error) => {
        console.error('Error getting initial user data', error);

        return this.signOutAccountDisable();
      });
  }

  /*
   * Called with a snapshot of the user's data whenever it changes.
   */
  onUserDataValue(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      return;
    }

    const dbUser = snapshot.val();

    // Default empty roles
    if (!dbUser.roles) {
      dbUser.roles = {};
    }

    // Add defaults
    const user = {
      enabled: undefined,
      isUserDataLoaded: true, //  Fake property that we add to all users to tell if the data has loaded
      enableSmsNotification: true,
      enableEmailNotification: true,
      enableMarketingEmails: true,
      staySignedIn: true,
      termsAccepted: false,
      // It's important that we default to an empty object here.
      // If we don't and the user has all their features removed, then it
      // won't be cleared in our state as nothing will overwrite it.
      orders: {},
      features: {},
      ...dbUser,
    };

    // It will be undefined initially, then set to true/false
    if (user.enabled === false) {
      console.log('User not enabled, logging out...');

      return this.signOutAccountDisable();
    }

    this.analytics.identify(dbUser); // TODO: Should this be `user`?

    this.bus.publish(EVENTS.onUserData, user);

    this.dispatch(receiveUserData(user));
  }

  registerPushNotifications() {
    this.push
      .getToken()
      .then((token) => {
        if (token) this.registerPushToken(token);
      })
      .catch((error) => console.error(error));
  }

  unregisterPushNotifications() {
    return this.push.unregister();
  }

  signOutAccountDisable() {
    setTimeout(() => this.signOut(), 1);

    return this.showAccountDisabledModal();
  }

  showAccountDisabledModal() {
    return Modals.alert({
      title: 'Your phone no. is not eligible',
      message:
        'Sorry, Dulux Trade Direct is only available to approved trial customers.',
    });
  }

  /*
   * Update the user's data within Firebase.
   * The changes will be detected by the listener and synced back to us.
   */
  updateUserData(data: UserDataFields) {
    if (!this.userRef) {
      return console.error('No user ref');
    }

    return this.userRef.update(data);
  }

  registerPushToken(token: string) {
    if (!this.userRef) {
      return console.error('No user ref');
    }

    return this.userRef.child('pushTokens').update({ [token]: true });
  }

  // *** User API ***

  initialUser = (phoneNumber: User['phoneNumber']) =>
    this.db.ref(`initialUsers/${phoneNumber}`);

  user = (uid: User['uid']) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Auth ***

  login() {
    return this.functions.login();
  }

  register(data: {
    email: string;
    trade_direct_reg_customertype: string;
    trade_direct_reg_firstname: string;
    trade_direct_reg_lastname: string;
    trade_direct_reg_businessaddress: string;
    trade_direct_reg_businessname: string;
    trade_direct_reg_accountnumber: string;
    trade_direct_reg_preferredstore: string;
  }) {
    return this.functions.register(data);
  }

  // *** Orders ***

  createOrder(
    lineItems: LineItem[],
    checkoutAddress: StructuredAddress,
    attributes: Attribute[] = [],
    tags: string[] = [],
    note = ''
  ) {
    return this.functions.createOrder({
      lineItems,
      attributes,
      checkoutAddress,
      tags,
      note,
    });
  }

  // *** Tickets ***

  createTicket(
    properties: Array<{
      property: string;
      value: any;
    }>
  ) {
    return this.functions.createTicket({ properties });
  }

  // *** Feedback ***

  submitFeedback(rating: 1 | 2 | 3 | 4 | 5, comments?: string) {
    if (!this.userRef) {
      return console.error('No user ref');
    }

    this.userRef.child('feedback').push({ date: new Date(), rating, comments });
  }
}

export default Firebase;
