import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { ReactComponent as DuluxLogo } from '../../assets/images/brand/dulux-logo.svg';
import colourLoaderData from '../../data/colourLoader.json';

const ColourLoader = (props) => {
  const variants = {
    visible: {
      opacity: 1,
      display: 'flex',
    },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };

  function renderLoader() {
    return colourLoaderData.map((colour, key) => {
      return (
        <div
          key={key}
          className={`loader-colour loader-colour-${key} ${colour.theme}`}
          style={{ backgroundColor: colour.hex }}
        >
          <h2 className="loader-content font-heading text-h2">
            {colour.title}
            <sup>&reg;</sup>
          </h2>
          <span className={`loader-dulux-logo ${colour.theme}`}>
            <DuluxLogo style={{ width: '60px', height: '30px' }} />
          </span>
          <span className="loader-colour-code text-small">
            <strong>{colour.codePrefix}</strong> {colour.codeSuffix}
          </span>
        </div>
      );
    });
  }

  return (
    <motion.div
      initial={false}
      variants={variants}
      animate={props.isEnabled ? 'visible' : 'hidden'}
      className="absolute top-0 left-0 bottom-0 right-0 flex flex-col items-center justify-center"
      style={{ backgroundColor: props.background }}
    >
      {renderLoader()}
    </motion.div>
  );
};

ColourLoader.propTypes = {
  background: PropTypes.string,
  isEnabled: PropTypes.bool,
};

ColourLoader.defaultProps = {
  background: '#DDDDDD',
  isEnabled: false,
};

export default ColourLoader;
