import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  getOrderDeliveryTimeLabel,
  getOrderTimeLabel,
} from '../../utils/order';
import useTimeout from '../../hooks/useTimeout';

const HomeOrders = (props) => {
  const [timePassed, setTimePassed] = useState(false);

  const renderOrders = () => {
    const orderNumber = 2;
    if (!props.orders) {
      return [];
    }
    return props.orders
      .slice(Math.max(props.orders.length - orderNumber, 1))
      .map((order) => {
        const methodLabel = getOrderTimeLabel(order);
        const timeLabel = getOrderDeliveryTimeLabel(order);
        return (
          <div
            key={order.id}
            onClick={() => props.orderOnClick(order.id)}
            className="p-1 border-b flex justify-between items-center"
          >
            <div className="text-dulux-dark text-small">
              <strong>Order {order.order_number}</strong> {methodLabel}
            </div>
            <div className="bg-dulux-dark text-white rounded-full text-center text-small mt-1 px-1 py-1 w-6/12">
              {timeLabel}
            </div>
          </div>
        );
      });
  };

  useTimeout(
    () => {
      setTimePassed(true);
    },
    2000,
    true
  );

  return (
    <div
      className="bg-white"
      style={{ borderRadius: '1rem', marginTop: '-1rem' }}
    >
      <div className="p-1 border-b flex justify-between items-center">
        <h2 className="text-dulux-dark font-heading text-h2">Orders</h2>
        <p
          className="text-dulux-primary"
          onClick={() => props.allOrdersOnClick()}
        >
          See all
        </p>
      </div>
      {!props.loading || props.orders.length ? (
        renderOrders()
      ) : (
        <div className="p-1 py-2 border-b flex flex-col justify-center items-center">
          {timePassed ? (
            <>
              <h3 className="font-heading text-dulux-dark text-small">
                It looks like you have no past orders
              </h3>
            </>
          ) : (
            <>
              <div className="spinner px-1">
                <div className="bg-dulux-dark" />
                <div className="bg-dulux-dark" />
                <div className="bg-dulux-dark" />
                <div className="bg-dulux-dark" />
              </div>
              <h3 className="font-heading text-dulux-dark text-small">
                Loading orders
              </h3>
            </>
          )}
        </div>
      )}
    </div>
  );
};

HomeOrders.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.array,
  orderOnClick: PropTypes.func,
  allOrdersOnClick: PropTypes.func,
};

HomeOrders.defaultProps = {
  loading: true,
  orders: [],
  orderOnClick: null,
  allOrdersOnClick: null,
};

export default HomeOrders;
