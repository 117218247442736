import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonButton } from '@ionic/react';
import { addToCart } from '../../store/actions';
import { RootState } from '../../store/reducers';

const DevTools: React.FC = () => {
  const products = useSelector((s: RootState) => s.products);
  const colours = useSelector((s: RootState) => s.colours);
  const dispatch = useDispatch();

  function addRandomToCart() {
    const productKeys = Object.keys(products.byId);
    const colourKeys = Object.keys(colours.byId);

    const product =
      products.byId[productKeys[(productKeys.length * Math.random()) | 0]];
    const colour =
      colours.byId[colourKeys[(colourKeys.length * Math.random()) | 0]];

    if (product && colour) {
      dispatch(addToCart(product.productId, colour.colourId));
    }
  }

  return (
    <div>
      <IonButton onClick={addRandomToCart}>+ Random Product to Cart</IonButton>
    </div>
  );
};

export default DevTools;
