import { useSelector } from 'react-redux';
import { getEnabledFulfillmentList } from '../utils/fulfillment';
import useRemoteConfig from './useRemoteConfig';
import { DeliveryMethodType } from '../types';
import {
  selectDeliveryMethod,
  selectLocationAddress,
  selectStoresPriorityPickupEnabled,
  selectStoresScheduledPickupEnabled,
} from '../store/selectors';
import { find } from 'lodash';

export default function useFulfillmentMethodEnabled() {
  const remoteConfig = useRemoteConfig();
  const deliveryMethodType = useSelector(selectDeliveryMethod);
  const checkoutAddress = useSelector(selectLocationAddress);
  const storesPriorityPickupEnabled = useSelector(
    selectStoresPriorityPickupEnabled
  );
  const storesScheduledPickupEnabled = useSelector(
    selectStoresScheduledPickupEnabled
  );
  const enabledGlobalMethods = getEnabledFulfillmentList(
    remoteConfig.fulfillmentOptions
  );

  let enabled = true;

  if (
    enabledGlobalMethods &&
    !enabledGlobalMethods.includes(deliveryMethodType as string)
  ) {
    enabled = false;
  } else if (deliveryMethodType === DeliveryMethodType.PRIORITY_PICKUP) {
    enabled = !!find(
      storesPriorityPickupEnabled,
      (store) => store.address === checkoutAddress?.address
    );
  } else if (deliveryMethodType === DeliveryMethodType.SCHEDULED_PICKUP) {
    if (checkoutAddress) {
      enabled = !!find(
        storesScheduledPickupEnabled,
        (store) => store.address === checkoutAddress?.address
      );
    }
  }

  return enabled;
}
