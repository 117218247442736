import React from 'react';
import PropTypes from 'prop-types';
import accessoryCategories from '../../data/accessoryCategories.ts';
import CatalogAccessoryItem from '../../components/catalog/CatalogAccessoryItem';

const CatalogAccessories = (props) => {
  function handleClick(category, Icon) {
    props.onClick({
      range: category.range,
      name: category.name,
    });

    props.setAccessoryIcon(Icon);
  }

  const content = accessoryCategories.map((category, key) => {
    const Icon = React.createElement(category.icon, {
      className: category.iconClasses,
    });

    return (
      <CatalogAccessoryItem
        key={key}
        onClick={() => handleClick(category, Icon)}
        text={category.text}
        icon={Icon}
      />
    );
  });

  return <>{content}</>;
};

CatalogAccessories.propTypes = {
  onClick: PropTypes.func,
  setAccessoryIcon: PropTypes.func,
};

CatalogAccessories.defaultProps = {
  onClick: null,
  setAccessoryIcon: null,
};

export default CatalogAccessories;
