export interface Subscription {
  unsubscribe: () => void;
}

interface SubscriberFunction {
  (data: any): void;
}

interface Subscribers {
  [key: string]: SubscriberFunction[];
}

export default class PubSub {
  subscribers: Subscribers = {};

  /**
   * Publish an update to all subscribers.
   */
  publish(eventName: string, data: any) {
    if (!Array.isArray(this.subscribers[eventName])) {
      return;
    }

    this.subscribers[eventName].forEach((subscriberCallback) =>
      subscriberCallback(data)
    );
  }

  /**
   * Subscribe to updates.
   */
  subscribe(eventName: string, callback: SubscriberFunction): Subscription {
    if (!Array.isArray(this.subscribers[eventName])) {
      this.subscribers[eventName] = [];
    }

    const index = this.subscribers[eventName].push(callback) - 1;

    // Return the function to unsubscribe
    return {
      unsubscribe: () => {
        this.subscribers[eventName].splice(index, 1);
      },
    };
  }
}
