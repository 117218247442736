import { Store } from '../../types';
import { StoresActionType } from '../reducers/stores/types';

type StoresMap = {
  [id: string]: Store;
};

export const receiveStores = (stores: StoresMap) => ({
  type: StoresActionType.RECEIVE,
  payload: { byId: stores },
});
