import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  getOrderMethodGroupData,
  selectDeliveryMethodObject,
  selectLocationAddressFormattedAddress,
  selectPriorityPickupOptions,
  selectPreferredStore,
  selectShowAllStores,
  selectStores,
} from '../../store/selectors';
import { IonAlert, IonContent, IonHeader, IonPage } from '@ionic/react';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import OrderMethodGroups from '../../components/order/OrderMethodGroups';
import Loader from '../../components/ui/Loader';
import { ReactComponent as CollectIcon } from '../../assets/icons/64/collect.svg';
import { ReactComponent as LockerIcon } from '../../assets/icons/64/locker.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/64/delivery.svg';
import { ReactComponent as PriorityDeliveryIcon } from '../../assets/icons/64/priority-delivery.svg';
import { ReactComponent as PriorityPickupIcon } from '../../assets/icons/64/priority-pickup.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/64/schedule.svg';
import {
  applyDeliveryAddress,
  applyDeliveryMethod,
  applyDeliveryTimeframe,
  applyShowAllStores,
  applySkipLocation,
  clearCheckout,
} from '../../store/actions';
import { getStreetViewImageUrl } from '../../services/google/street-view';
import useTotalLitres from '../../hooks/cart/useTotalLitres';
import { useAnalyticsContext } from '../../services/analytics/context';
import { DeliveryMethodType } from '../../types';
import useRemoteConfig from '../../hooks/useRemoteConfig';
import { getEnabledFulfillmentList } from '../../utils/fulfillment';
import qs from 'qs';
import Address from '../../models/Address';
import Estimate from '../../services/fulfillment/estimate';
import OrderMethodDTC from '../../components/order/OrderMethodDTC';
import { DeliveryMethodGroupType } from '../../store/reducers/checkout/types';

const DeliveryMethod = () => {
  const recentPickupStoresToDisplay = 1;
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalyticsContext();
  const remoteConfig = useRemoteConfig();
  const totalLitres = useTotalLitres();
  const stores = useSelector(selectStores);
  const userPreferredStore = useSelector(selectPreferredStore);
  const recentPickupStores = useSelector(selectPriorityPickupOptions);
  const [showAlert, setShowAlert] = useState(false);
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(false);
  const deliveryMethodObject = useSelector(selectDeliveryMethodObject);
  const locationAddressTitle = useSelector(
    selectLocationAddressFormattedAddress
  );
  const showAllStores = useSelector(selectShowAllStores);
  const fulfillmentOptions = getEnabledFulfillmentList(
    remoteConfig.fulfillmentOptions
  );
  const streetViewImage = getStreetViewImageUrl(locationAddressTitle, 400);
  const streetViewImageAltText = `Street view of ${locationAddressTitle}`;

  const displayIcon = () => {
    switch (deliveryMethodObject.type) {
      case DeliveryMethodType.PRIORITY_PICKUP:
        return <PriorityPickupIcon />;
      case DeliveryMethodType.PRIORITY_DELIVERY:
        return <PriorityDeliveryIcon />;
      case DeliveryMethodType.REGULAR_DELIVERY:
        return <DeliveryIcon />;
      case DeliveryMethodType.REGULAR_PICKUP:
        return <CollectIcon />;
      case DeliveryMethodType.SCHEDULED_DELIVERY:
      case DeliveryMethodType.SCHEDULED_PICKUP:
        return <ScheduleIcon />;
      case DeliveryMethodType.AFTER_HOURS_PICKUP:
        return <LockerIcon />;
      default:
        return '';
    }
  };

  const showLoader = () => {
    setLoaderEnabled(true);
    setTimeout(function () {
      setLoaderEnabled(false);
    }, 1000);
  };

  const onClick = (
    deliveryMethod,
    url,
    deliveryTimeframe = null,
    deliveryAddress = null
  ) => {
    showLoader();
    setAddressEnabled(false);

    batch(() => {
      dispatch(applyDeliveryMethod(deliveryMethod));
      let skipLocation = null;

      if (deliveryTimeframe) {
        skipLocation = '/checkout/location';
        dispatch(applyDeliveryTimeframe(deliveryTimeframe));
      }

      if (deliveryAddress) {
        skipLocation = '/checkout/notes';
        setAddressEnabled(true);
        dispatch(applyDeliveryAddress(deliveryAddress));
      }

      if (skipLocation) {
        dispatch(applySkipLocation(skipLocation));
      }
    });

    analytics.ecommerce.checkoutStepCompleted(1);

    // To hit the pages so back button works properly
    setTimeout(function () {
      history.push(url);
    }, 1000);
  };

  useEffect(() => {
    const urlParams = history.location.search;
    const qsMap = qs.parse(urlParams);
    const error = qsMap['?error'];

    if (error && !showAlert) {
      setShowAlert(true);
    }
  }, [showAlert, history.location.search]);

  // The stores in the list are considered priority_pickup
  function storesGloballyEnabled() {
    return fulfillmentOptions.includes(DeliveryMethodType.PRIORITY_PICKUP);
  }

  // The an individual store's fulfillment config has priority_pickup enabled
  function storeHasPriorityPickupEnabled(store) {
    return getEnabledFulfillmentList(store.fulfillmentOptions).includes(
      DeliveryMethodType.PRIORITY_PICKUP
    );
  }

  function getDeliveryMethodType(type) {
    switch (type) {
      case DeliveryMethodGroupType.PICKUP:
        return DeliveryMethodType.PRIORITY_PICKUP;
      case DeliveryMethodGroupType.LOCKER:
        return DeliveryMethodType.AFTER_HOURS_PICKUP;
      default:
        return '';
    }
  }

  function setAllStoresVisible(value) {
    dispatch(applyShowAllStores(value));
  }

  function renderDTCStore(groupType, item, key, preferred = false) {
    const earliestTime = new Estimate().earliestDeliveryTime(
      getDeliveryMethodType(groupType)
    );

    return (
      <OrderMethodDTC
        key={key}
        title={item.area || item.title}
        label={item.shortTitle}
        type={item.type || 'dulux'}
        preferred={preferred}
        time={earliestTime.label}
        timeLabel="Latest ready"
        totalLitres={totalLitres}
        maxLitres={item.maxLitres}
        enabled={storesGloballyEnabled() && storeHasPriorityPickupEnabled(item)}
        onClick={() =>
          onClick(
            getDeliveryMethodType(groupType),
            '/checkout/notes',
            earliestTime,
            new Address(item.address)
          )
        }
      />
    );
  }

  function renderPriorityPickupStores() {
    const preferredStoreFragment = (
      <React.Fragment key={'preferred-pickup'}>
        {renderDTCStore(
          DeliveryMethodGroupType.PICKUP,
          userPreferredStore,
          'preferred-pickup',
          true
        )}
      </React.Fragment>
    );

    const topRecentPickupStores = recentPickupStores.slice(
      0,
      recentPickupStoresToDisplay
    );

    const storesList = topRecentPickupStores.map((item, key) => {
      return renderDTCStore(DeliveryMethodGroupType.PICKUP, item, key);
    });

    const pickupStoresFragment = (
      <React.Fragment key={'last-used-pickups'}>{storesList}</React.Fragment>
    );

    return [preferredStoreFragment, pickupStoresFragment];
  }

  function renderStores(groupType) {
    return stores.map((item, key) => {
      return renderDTCStore(
        groupType,
        item,
        key,
        item.id === userPreferredStore.id
      );
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderBack title="Cart" />
        <HeaderTitle title="Choose method" borderColour="#e2e8f0" />
      </IonHeader>
      <IonContent>
        <IonAlert
          isOpen={showAlert}
          header={
            'Unfortunately, that fulfillment method has become unavailable'
          }
          message={'We apologise for the inconvenience, please select another.'}
          backdropDismiss={false}
          buttons={[
            {
              text: 'Accept',
              handler: () => {
                history.replace(history.location.pathname);
                setShowAlert(false);
                dispatch(clearCheckout());
              },
            },
          ]}
        />
        <OrderMethodGroups
          totalLitres={totalLitres}
          onClick={onClick}
          methods={getOrderMethodGroupData()}
          renderPriorityPickupStores={renderPriorityPickupStores}
          renderStores={renderStores}
          fulfillmentOptions={fulfillmentOptions}
          setAllStoresVisible={setAllStoresVisible}
          showAllStores={showAllStores}
        />
      </IonContent>
      <Loader
        isEnabled={loaderEnabled}
        isAddress={addressEnabled}
        background={addressEnabled ? '#002C65' : '#FFFFFF'}
        /*eslint-disable */
        title={
          addressEnabled
            ? locationAddressTitle
            : deliveryMethodObject
            ? deliveryMethodObject.name
            : ''
        }
        /*eslint-enable */
        icon={addressEnabled ? '' : deliveryMethodObject ? displayIcon() : ''}
        image={
          addressEnabled ? (
            <img src={streetViewImage} alt={streetViewImageAltText} />
          ) : (
            ''
          )
        }
      />
    </IonPage>
  );
};

export default DeliveryMethod;
