import React from 'react';
import { ReactComponent as DuluxLogo } from '../../assets/images/brand/dulux-logo.svg';
import { ReactComponent as LockerIcon } from '../../assets/icons/64/locker.svg';

const HomeAbout = (props) => {
  return (
    <>
      <div className="border-t">
        <div className="p-2">
          <DuluxLogo className="w-12 mb-2" />
          <h2 className="font-heading text-dulux-dark text-h2 mb-1">
            About Trade Direct
          </h2>
          <p className="text-dulux-dark text-small mb-1">
            Trade Direct is currently available through Preston and Doncaster
            Dulux Trade Centres.
          </p>
          <p className="text-dulux-dark text-small">
            While some features may be temporarily unavailable, exciting new
            features and updates will be released as the program develops.
          </p>
        </div>
      </div>
      <div className="border-t border-b bg-gray-200 p-2">
        <div className="flex items-center border-b pb-1 mb-1">
          <div className="w-8/12 pr-1">
            <h2 className="font-heading text-dulux-dark text-h2 mb-1">
              After hours lockers
            </h2>
            <p className="text-dulux-dark text-small">
              Skip the queue and have your order stored in a secure locker for
              pickup after hours.
            </p>
          </div>
          <div className="w-4/12 pr-1">
            <div className="w-full pb-100 bg-dulux-dark text-white rounded-full relative">
              <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
                <LockerIcon style={{ height: '50%', width: '50%' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-5/12">
            <div className="text-technical text-center border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
              Coming soon
            </div>
          </div>
          <div className="ml-1 text-gray-600 text-technical">
            Preston and Doncaster Jul &rsquo;20
          </div>
        </div>
      </div>
    </>
  );
};

HomeAbout.propTypes = {};

export default HomeAbout;
