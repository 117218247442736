import React, { useEffect } from 'react';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
  isPlatform,
  setupConfig,
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import useOnboardingViewed from './hooks/useOnboardingViewed';
import useAuthenticatedRedirect from './hooks/useAuthenticatedRedirect';
import useNotificationToast from './hooks/useNotificationToast';
import useAuthenticated from './hooks/useAuthenticated';
import useTermsAccepted from './hooks/useTermsAccepted';
import useRegistrationStatus from './hooks/useRegistrationStatus';
import useTracking from './hooks/useTracking';
import useEffectDeepCompare from './hooks/useEffectDeepCompare';
import Home from './pages/Home';
import Login from './pages/Login';
import Catalog from './pages/Catalog';
import Settings from './pages/Settings';
import Terms from './pages/Terms';
import Onboarding from './pages/Onboarding';
import Register from './pages/Register';
import TermsAndConditionsOfUsePage from './pages/settings/TermsAndConditionsOfUsePage';
import PrivacyPolicyPage from './pages/settings/PrivacyPolicyPage';
import StandardConditionsOfSalePage from './pages/settings/StandardConditionsOfSalePage';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Components from './pages/Components';
import Holding from './pages/Holding';
import VerificationHolding from './pages/VerficationHolding';
import Orders from './pages/Orders';
import OrderDetail from './pages/orders/OrderDetail';
import OrderStatusPage from './pages/orders/OrderStatus';
import DeliveryMethod from './pages/checkout/DeliveryMethod';
import DeliveryTimes from './pages/checkout/DeliveryTimes';
import DeliveryLocation from './pages/checkout/DeliveryLocation';
import DeliveryNotes from './pages/checkout/DeliveryNotes';
import Summary from './pages/checkout/Summary';
import OrderFeedbackPage from './pages/orders/OrderFeedback';
import PickupAndDelivery from './pages/PickupAndDelivery';
import SupportIndex from './pages/support/SupportIndex';
import SupportSelect from './pages/support/SupportSelect';
import SupportDetail from './pages/support/SupportDetail';
import SupportRep from './pages/support/SupportRep';
import ColourLoader from './components/loader/ColourLoader';
import OrderFeedbackModal from './components/modals/OrderFeedbackModal';
import { selectCartItemCount, selectCartItems } from './store/selectors';
import {
  clearPricing,
  completePricing,
  receivePricing,
  requestPricing,
} from './store/actions';
import { ColourActionType } from './store/reducers/colours/types';
import { SupportActionType } from './store/reducers/support/types';
import { UserStatus } from './services/firebase/types';
import ColourData from './data/colours.json';
import SupportData from './data/support.json';
import { Plugins } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';

import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './css/index.css';

import OrderIcon from './assets/icons/20/order.svg';
import PaintIcon from './assets/icons/20/paint-tin.svg';
import CartIcon from './assets/icons/20/cart.svg';
import HomeIcon from './assets/icons/20/home.svg';
import { useFirebaseContext } from './services/firebase/context';
import EstimatePage from './pages/dev/Estimate';

const { SplashScreen } = Plugins;

const App: React.FC = () => {
  useTracking();
  const dispatch = useDispatch();
  const firebase = useFirebaseContext();
  const { isUserDataLoaded, isLoading } = useAuthenticatedRedirect();
  const {
    onboardingViewed,
    storageOnboardingViewedKey,
  } = useOnboardingViewed();
  const areTermsAccepted = useTermsAccepted();
  const isAuthenticated = useAuthenticated();
  const userStatus = useRegistrationStatus();
  const uniqueItemCount = useSelector(selectCartItemCount);
  const cartItems = useSelector(selectCartItems);
  const cartItemsMapped = cartItems.map((item) => {
    return {
      sku: item.productId,
      quantity: item.quantity,
    };
  });

  const {
    showToast,
    toastHeader,
    toastMessage,
    dismissToast,
    handleToast,
  } = useNotificationToast();

  useEffect(() => {
    if (userStatus === UserStatus.DENIED) {
      firebase
        .signOut()
        .then(() => console.log('Signed out from UserStatus.DENIED'));
    }
  }, [userStatus, firebase]);

  useEffect(() => {
    setupConfig({ animated: false, swipeBackEnabled: false });
    SplashScreen.hide(); // Hide the splashscreen as soon as the app loads
    firebase.boot(); // Boot firebase when ready
  }, [firebase]);

  // Import data once on mount
  useEffect(() => {
    // Batch the calls so that they only result in a single render
    batch(() => {
      dispatch({
        type: ColourActionType.RECEIVE,
        payload: ColourData,
      });
      dispatch({
        type: SupportActionType.RECEIVE_DATA,
        payload: { categories: SupportData },
      });
    });
  }, [dispatch]);

  // Cart item pricing
  useEffectDeepCompare(() => {
    if (cartItems.length === 0) {
      dispatch(clearPricing());
      return;
    }

    dispatch(requestPricing(true));

    firebase.functions
      .checkPrice({ items: cartItemsMapped })
      .then((res) => {
        dispatch(receivePricing(res.data));
      })
      .catch((e) => {
        dispatch(completePricing(false));
      });
  }, [cartItemsMapped]);

  function renderVerificationHoldingRoute() {
    return (
      <IonRouterOutlet
        key="verification-holding-router-outlet"
        animated={false}
      >
        <Route path="/verification-holding" component={VerificationHolding} />
      </IonRouterOutlet>
    );
  }

  function renderRegistrationRoute() {
    return (
      <IonRouterOutlet key="registration-router-outlet" animated={false}>
        <Route path="/register" component={Register} />
      </IonRouterOutlet>
    );
  }

  function renderHoldingRoute() {
    return (
      <IonRouterOutlet key="holding-router-outlet" animated={false}>
        <Route path="/holding" component={Holding} />
      </IonRouterOutlet>
    );
  }

  function renderLoginRoute() {
    return (
      <IonRouterOutlet key="login-router-outlet" animated={false}>
        <Route path="/login" component={Login} />
      </IonRouterOutlet>
    );
  }

  function renderTermsRoute() {
    return (
      <IonRouterOutlet key="terms-router-outlet" animated={false}>
        <Route path="/terms" component={Terms} />
      </IonRouterOutlet>
    );
  }

  function renderOnboardingRoute() {
    return (
      <IonRouterOutlet key="onboarding-router-outlet" animated={false}>
        <Route
          path="/onboarding"
          render={(props) => (
            <Onboarding onboardingKey={storageOnboardingViewedKey} {...props} />
          )}
        />
      </IonRouterOutlet>
    );
  }

  function renderRoutes() {
    return (
      <IonRouterOutlet key="app-router-outlet" animated={false}>
        <Route path="/home" component={Home} />
        <Route path="/cart" component={Cart} />
        <Route path="/catalog" component={Catalog} />
        <Route path="/product" component={Product} />
        <Route exact path="/settings" component={Settings} />
        <Route
          exact
          path="/settings/terms-and-conditions-of-use"
          component={TermsAndConditionsOfUsePage}
        />
        <Route
          exact
          path="/settings/privacy-policy"
          component={PrivacyPolicyPage}
        />
        <Route
          exact
          path="/settings/standard-conditions-of-sale"
          component={StandardConditionsOfSalePage}
        />
        <Route
          exact
          path="/pickup-and-delivery"
          component={PickupAndDelivery}
        />
        <Route exact path="/checkout" component={DeliveryMethod} />
        <Route
          exact
          path="/checkout/delivery-times"
          component={DeliveryTimes}
        />
        <Route exact path="/checkout/location" component={DeliveryLocation} />
        <Route exact path="/checkout/notes" component={DeliveryNotes} />
        <Route exact path="/checkout/summary" component={Summary} />
        <Route exact path="/orders" component={Orders} />
        <Route exact path="/orders/detail" component={OrderDetail} />
        <Route exact path="/orders/detail/status" component={OrderStatusPage} />
        <Route exact path="/orders/feedback" component={OrderFeedbackPage} />
        <Route exact path="/support" component={SupportIndex} />
        <Route exact path="/support/select" component={SupportSelect} />
        <Route exact path="/support/select/detail" component={SupportDetail} />
        <Route exact path="/support/contact" component={SupportRep} />
        <Route path="/components" component={Components} />
        <Route path="/dev/estimate" component={EstimatePage} />
        <Redirect exact from="/" to="/login" />
      </IonRouterOutlet>
    );
  }

  /* The logic here is in line with the useAuthenticatedRedirect hook.
   * this logic renders the routes available to be routed to while
   * the hook handles the actual redirecting.
   */
  function renderTabs() {
    if (!isAuthenticated) {
      return renderLoginRoute();
    }

    // Holding screen for new user with no data
    // This route is to await a data from Hubspot > Firebase > App
    if (!isUserDataLoaded) {
      return renderVerificationHoldingRoute();
    }

    if (userStatus === UserStatus.REGISTRATION_REQUIRED) {
      return renderRegistrationRoute();
    }

    if (userStatus === UserStatus.VERIFICATION_REQUIRED) {
      return renderHoldingRoute();
    }

    if (!areTermsAccepted) {
      return renderTermsRoute();
    }

    if (!onboardingViewed) {
      return renderOnboardingRoute();
    }

    if (isUserDataLoaded) {
      return (
        <IonTabs>
          {renderRoutes()}
          <IonTabBar slot="bottom" className="pt-1 border-t-2">
            <IonTabButton tab="catalog" href="/catalog">
              <IonIcon icon={PaintIcon} />
              <IonLabel>Paint</IonLabel>
            </IonTabButton>
            <IonTabButton tab="orders" href="/orders">
              <IonIcon icon={OrderIcon} />
              <IonLabel>Orders</IonLabel>
            </IonTabButton>
            <IonTabButton tab="cart" href="/cart">
              <IonIcon icon={CartIcon} />
              <IonLabel>
                Cart {uniqueItemCount >= 1 && <>({uniqueItemCount})</>}
              </IonLabel>
            </IonTabButton>
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={HomeIcon} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      );
    }
  }

  function showLoader() {
    return isLoading;
  }

  function renderDisplay() {
    return (
      <>
        {renderTabs()}

        <OrderFeedbackModal />

        {/* No need to display the toast on iOS as the native notification is visible*/}
        <IonToast
          isOpen={showToast && !isPlatform('ios')}
          header={toastHeader}
          message={toastMessage}
          position="top"
          color="primary"
          buttons={[
            {
              text: 'View',
              handler: handleToast,
            },
            {
              text: 'Dismiss',
              role: 'cancel',
              handler: dismissToast,
            },
          ]}
        />
        <ColourLoader isEnabled={showLoader()} />
      </>
    );
  }

  return renderDisplay();
};
export default App;
