import * as firebase from 'firebase/app';
import Firebase from '../firebase';
import { getObject, removeObject, setObject } from '../../../utils/storage';
import BackupStoreData from '../../../data/backup/stores.json';
import { Store } from '../../../types';
import { receiveStores } from '../../../store/actions/stores';
import { buildIsMaster } from '../../../utils/environment';

const STORAGE_KEY = 'FIREBASE_STORES';

const PRODUCTION_STORES = '/stores';
const MASTER_STORES = '/env/master/stores';

type StoreWithoutId = Omit<Store, 'id'>;

type StoresMap = {
  [id: string]: Store;
};

type StoresMapWithoutId = {
  [id: string]: StoreWithoutId;
};

export default class FirebaseStores {
  private firebase: Firebase;

  private readonly storesRef: firebase.database.Reference;
  private readonly onSubscribeListener: any;

  constructor(fb: Firebase) {
    this.firebase = fb;
    this.storesRef = this.firebase.db.ref(this.getStoresRefString());
    this.onSubscribeListener = this.onSubscribe.bind(this);
  }

  boot() {
    this.subscribe();
  }

  getStoresRefString() {
    if (buildIsMaster) {
      return MASTER_STORES;
    }

    return PRODUCTION_STORES;
  }

  getStoresWithId(stores: StoresMapWithoutId): StoresMap {
    const entries = Object.entries(stores).map(([id, storeWithoutId]) => [
      id, // Key
      { id, ...storeWithoutId }, // Value
    ]);

    return Object.fromEntries(entries);
  }

  async loadBackupStores() {
    let imported = false;

    try {
      const stores = await getObject(STORAGE_KEY);

      if (stores) {
        this.importStores(stores);
        imported = true;
      }
    } catch (e) {
      console.error('Error importing backup stores', e);
      removeObject(STORAGE_KEY); // Clean up since the import failed
    }

    // Fallback to the local JSON
    if (!imported) {
      this.importStores(this.getStoresWithId(BackupStoreData));
    }
  }

  setBackupProducts(stores: StoresMap) {
    return setObject(STORAGE_KEY, stores);
  }

  subscribe() {
    if (this.storesRef) {
      this.storesRef.off('value', this.onSubscribeListener);
    }

    // Make sure we load the back products before we attempt to fetch updated ones
    // Since it all happens async, we don't want the backup to come in after the network and overwrite
    this.loadBackupStores().finally(() => {
      this.storesRef.on('value', this.onSubscribeListener);
    });
  }

  onSubscribe(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      return;
    }

    try {
      const val = snapshot.val() as StoresMapWithoutId;
      const stores = this.getStoresWithId(val);

      this.importStores(stores);
      this.setBackupProducts(stores);
    } catch (e) {
      console.error('Error importing products');
      this.loadBackupStores();
    }
  }

  importStores(stores: StoresMap) {
    this.firebase.dispatch(receiveStores(stores));
  }
}
