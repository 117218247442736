import React from 'react';
import PropTypes from 'prop-types';
import contrastingColour from '../../utils/colour';

const CartItem = (props) => {
  function displayImage() {
    if (props.isAccessory) {
      return (
        <img
          src={`/assets/images/product/accessories/${props.image}@2x.png`}
          alt="product"
        />
      );
    } else {
      return (
        <img
          src={`/assets/images/product/paint/sheen/${props.image}@2x.png`}
          alt="product"
        />
      );
    }
  }

  function renderProductName() {
    if (props.isAccessory) {
      return props.sheen;
    }

    return props.colourName
      ? `${props.name} ${props.sheen} ${props.colourName} `
      : `${props.name} ${props.sheen} `;
  }

  function renderStrengthIcon() {
    if (!props.isAccessory && props.displayStrengthIcon) {
      return props.displayStrengthIcon();
    }

    return '';
  }

  function renderPrice() {
    if (props.pricesCalculated) {
      if (!props.pricingIsLoading) {
        return props.price || <span className="italic">N/A</span>;
      }
    }

    return '';
  }

  return (
    <div
      className="flex border-b border-gray-400 px-1 py-2"
      style={{
        color: contrastingColour(props.backgroundColour || '#FFFFFF'),
        backgroundColor: props.backgroundColour || '#FFFFFF',
      }}
      onClick={() => props.onClick()}
    >
      {props.quantity && (
        <div className="w-2/12 flex items-center">
          <div
            className="border rounded-full w-4 h-4 text-small flex justify-center items-center"
            style={{
              borderColor: contrastingColour(
                props.backgroundColour || '#FFFFFF'
              ),
            }}
          >
            {props.quantity}
          </div>
        </div>
      )}
      {props.image && (
        <div className="w-3/12 flex items-center pl-1">{displayImage()}</div>
      )}
      {(props.name || props.sheen || props.size) && (
        <div
          className={
            'px-1 relative flex items-center ' +
            (props.hasPrice ? 'w-5/12' : 'w-7/12')
          }
        >
          <span className="text-small">
            {renderProductName()} {renderStrengthIcon()} {props.size}
          </span>
        </div>
      )}
      {props.hasPrice && (
        <div className="w-2/12 flex justify-center items-center pl-1">
          <span className="text-small">{renderPrice()}</span>
        </div>
      )}
    </div>
  );
};

CartItem.propTypes = {
  onClick: PropTypes.func,
  isAccessory: PropTypes.bool,
  backgroundColour: PropTypes.string,
  quantity: PropTypes.number,
  image: PropTypes.string,
  range: PropTypes.string,
  name: PropTypes.string,
  sheen: PropTypes.string,
  colourBrand: PropTypes.string,
  colourName: PropTypes.string,
  displayStrengthIcon: PropTypes.func,
  size: PropTypes.string,
  price: PropTypes.string,
  pricingIsLoading: PropTypes.bool,
  pricesCalculated: PropTypes.bool,
  hasPrice: PropTypes.bool,
};

CartItem.defaultProps = {
  onClick: null,
  isAccessory: false,
  backgroundColour: '',
  quantity: 0,
  image: '',
  range: '',
  name: '',
  sheen: '',
  colourBrand: '',
  colourName: '',
  displayStrengthIcon: null,
  size: '',
  price: '',
  pricingIsLoading: true,
  pricesCalculated: false,
  hasPrice: false,
};

export default CartItem;
