import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../components/ui/Button';
import { useFirebaseContext } from '../../services/firebase/context';
import { useAnalyticsContext } from '../../services/analytics/context';
import FooterCapContainer from '../ui/FooterCapContainer';

const TermsAndConditionsOfUse = (props) => {
  const analytics = useAnalyticsContext();
  const firebase = useFirebaseContext();
  const history = useHistory();

  function onClick() {
    analytics.trackEvent('Terms Agreed', {
      properties: {
        category: 'Login',
        label: 'Terms and conditions of use',
      },
    });
    firebase.updateUserData({
      termsAccepted: true,
    });

    history.push('/home');
  }

  function getTheme() {
    return props.isButtonEnabled ? 'primary' : 'ghost';
  }

  function renderAcceptance() {
    if (!props.showButton) {
      return;
    }

    return (
      <FooterCapContainer>
        <Button
          disabled={!props.isButtonEnabled}
          onClick={() => onClick()}
          theme={getTheme()}
        >
          I agree
        </Button>
      </FooterCapContainer>
    );
  }

  return (
    <div>
      <div className="p-1">
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">Terms of use</h2>
          <p className="mb-1">
            This app is owned by Dulux Australia, a division of DuluxGroup
            (Australia) Pty Limited (ACN 000 049 427) (“DuluxGroup”). Throughout
            these terms and this app, the terms “we”, “us”, “our” refer to
            DuluxGroup and any of our affiliated companies (as relevant).
          </p>
          <p className="mb-1">
            If you use our app, you are agreeing to be bound by the terms and
            conditions listed below and any other laws or regulations which
            apply to this app. We reserve the right to terminate your use of
            this app if you breach any of these terms and conditions.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">General terms of use</h2>
          <p className="mb-1">
            In using this app, you must not interfere or attempt to interfere
            with the app, including any of its security related functions or any
            platform on which it is hosted.
          </p>
          <p className="mb-1">
            We do not warrant that the app will be available at all times
            without interruption.
          </p>
          <p className="mb-1">
            You are solely responsible for any activity on your account,
            including any orders submitted. It is your responsibility to keep
            your password secure. DuluxGroup does not take any responsibility
            for any unauthorised use of your account.
          </p>
          <p className="mb-1">
            Due to photographic and screen limitations, the colours of some
            products may differ slightly in appearance from the way they appear
            on this app. If you require verification, we recommend that you
            order sample pots of colours prior to purchasing the full product.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">General disclaimer</h2>
          <p className="mb-1">
            The information in this app has been compiled on the basis of
            general information current at the time of publication. Changes in
            circumstances after publication may affect the completeness or
            accuracy of this information. To the maximum extent permitted by
            law, we disclaim all liability for any errors or omissions contained
            in this information or any failure to update or correct this
            information. It is your responsibility to assess and verify the
            security, accuracy, completeness, currency and reliability of the
            information on this app, and to seek professional advice where
            necessary.
          </p>
          <p className="mb-1">
            Nothing contained on this app is to be interpreted as a
            recommendation to use any product, process or formulation or any
            information on this app in a manner which infringes the intellectual
            property rights of any person, company or entity. We make no
            representations or warranties that use of the information on this
            app will not infringe such intellectual property rights.
          </p>
          <p className="mb-1">
            We make no representation that the products displayed on this app
            are currently available or that their specifications are current and
            complete. Display of any product does not constitute an offer or
            undertaking by us.
          </p>
          <p className="mb-1">
            We make this app and its contents available on an as-is basis. We
            make no representations or warranties of any kind with respect to
            this app or its contents. To the maximum extent permitted by law, we
            disclaim any such representations or warranties as to the security,
            completeness, accuracy, merchantability or fitness for purpose of
            this app, including without limitation the information on this app,
            products referred to on this app and any email correspondence
            between you and us.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">
            Intellectual property statement
          </h2>
          <p className="mb-1">
            Unless otherwise indicated, all copyright and other intellectual
            property rights in the app design, text, images, graphics, data,
            information, logos, and other materials on this app, including the
            selection and arrangement thereof, and all software relating to this
            app, belongs to or is licensed by DuluxGroup. This copyright is
            protected by Australian, New Zealand and international copyright
            laws. Unless otherwise indicated, all trade marks, service marks,
            trade names, branding and get-up (trade dress) on this app belong to
            DuluxGroup, its related entities or its licensors. DuluxGroup, its
            related entities or its licensors do not give you any licence or
            right to use them. The Colorbond trade mark and where applicable any
            associated Colorbond colours are the trade marks of Bluescope Steel
            Limited.
          </p>
          <p className="mb-1">
            Subject to the following paragraph, no material from this app may be
            copied, reproduced, distributed, modified, uploaded, transmitted,
            reused, re-posted, published or framed within another app or website
            without our prior written permission.
          </p>
          <p className="mb-1">
            We grant you permission to download a copy of the material on this
            app in the usual course of operating your app browser, for your
            personal non-commercial use. You may also print hard copies of the
            material on this app, provided you retain all copyright and other
            proprietary notices on that material.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">Limitation of liability</h2>
          <p className="mb-1">
            The use of or reliance on the information on this app is at your own
            risk. DuluxGroup, its related entities, its directors, employees,
            shareholders, agents and other representatives will not, under any
            circumstances, be liable for any injury, loss, damage, claims,
            expenses, or liabilities whatsoever arising out of or related to the
            use, or inability to use, the information on this app or provided
            through this app by email. This limitation of liability includes,
            but is not limited to, compensatory, punitive, direct, incidental,
            indirect or consequential damages, loss of data, income or profit,
            loss of or damage to property and third party claims.
          </p>
          <p className="mb-1">
            To the maximum extent permitted by law, DuluxGroup does not accept
            any responsibility to maintain the material and services made
            available on this web site or to supply any corrections, updates, or
            releases in connection therewith. Any material on this app is
            subject to change without notice.
          </p>
          <p className="mb-1">
            Nothing in these terms operates to limit or exclude liability that
            cannot by law be limited or excluded. If you are a consumer for the
            purposes of applicable law in Australia or New Zealand, with the
            consequence that certain guarantees and certain rights and remedies
            are conferred on you which cannot be excluded, restricted or
            modified, then to the maximum extent permitted by law, our liability
            to you is limited at our option to: (a) in the case of goods: (i)
            replacement of the goods or the supply of equivalent goods; (ii)
            repair of the goods; (iii) payment of the cost of replacing the
            goods or of acquiring equivalent goods; or (iv) payment of the cost
            of having the goods repaired; and (b) in the case of services: (i)
            resupply of the services; or (ii) payment of the cost of resupplying
            the services.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">Privacy policy</h2>
          <p className="mb-1">
            When you use this app our hosting platforms or Internet Service
            Provider (ISP) may make a record of your visit and log your IP or
            server address, your domain name, the date, time and duration of
            your visit, the pages and documents you accessed, the previous site
            you had accessed (ie, the referring url) and the type of browser you
            are using. This information is collected for statistical purposes
            only, and we will not make any attempt to identify users according
            to their browsing activity. It is also possible that your ISP is
            logging your activity while you visit our site. This is beyond the
            control of DuluxGroup.
          </p>
          <p className="mb-1">
            While you are visiting our app we may use cookies to facilitate a
            more personalised browsing experience. You may disable cookies in
            your browser’s settings. Except where you volunteer personal or
            business information, we will not procure information about you via
            any alternative means while you visit our site. While we employ
            advanced data encryption technology when interfacing with its
            customers, suppliers and other partners, users should be aware that
            there is an inherent risk in transmitting any data electronically.
            This risk is inherent in all Internet dealings.
          </p>
          <p className="mb-1">
            Any personal information sent to this app is subject to our Privacy
            Policy and privacy laws. If you do supply personal information, we
            are required to comply with our Privacy Policy and relevant privacy
            laws when we collect and use such information. Read our{' '}
            <a
              href="https://www.dulux.com.au/footer/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-dulux-primary"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">
            Unsolicited materials and ideas
          </h2>
          <p className="mb-1">
            It is our policy not to accept information from third parties which
            is confidential or proprietary. If you wish to retain the
            confidentiality of your information, do not submit it to us via
            email or otherwise.
          </p>
          <p className="mb-1">
            Any non-personal information you submit to us will become our
            property, and we may use it for any purpose and in any way,
            including but not limited to, reproduction, disclosure,
            transmission, publication, and broadcasting or otherwise, without
            any obligation to reference or compensate you.
          </p>
          <p className="mb-1">
            We are free to use any unsolicited ideas, artwork, inventions,
            developments, suggestions or concepts contained in any communication
            sent to this app by you for any purposes (including but not limited
            to the developing, manufacturing, advertising and marketing of
            products), without any obligation to reference or compensate you.
          </p>
          <p className="mb-1">
            This will apply even if your material includes a statement contrary
            to these terms of use.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">Indemnity</h2>
          <p className="mb-1">
            You agree to indemnify DuluxGroup, its related entities, its
            directors, employees, shareholders, agents and other persons
            involved in the creation of this app for all damages, losses,
            penalties, fines, expenses and costs (including legal costs) which
            arise out of or relate to your use of this app, any information that
            you provide to DuluxGroup via this app or any damage that you may
            cause to this app. This indemnification includes, without
            limitation, liability relating to copyright infringement,
            defamation, invasion of privacy, trade mark infringement and
            breaches of consumer law.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="font-heading text-h3 mb-1">Jurisdiction</h2>
          <p className="mb-1">
            If a dispute arises regarding these terms of use, the laws of the
            State of Victoria, Australia will apply. In relation to any such
            dispute, you agree to submit to the non-exclusive jurisdiction of
            the courts of the State of Victoria, Australia, and the courts of
            appeal from them, and waive any rights that you may have to
            challenge the appropriateness of that forum.
          </p>
          <p className="mb-1">
            If you access this app in a jurisdiction other than Victoria,
            Australia, you are responsible for compliance with the laws of that
            jurisdiction, to the extent that they apply.
          </p>
          <p className="mb-1">
            If the information on this app is translated into or presented in
            languages other than English, the English version of that
            information will be authoritative in relation to any disputes
            regarding its interpretation.
          </p>
        </div>
        <div>
          <h2 className="font-heading text-h3 mb-1">Updates to terms</h2>
          <p className="mb-1">
            We reserve the right to make any changes and corrections to these
            terms at any time.
          </p>
          <p className="mb-3">
            If any provision of these terms is held void, unenforceable or
            illegal, that provision will be severed, and the remaining
            provisions will have full force and effect.
          </p>
        </div>
      </div>
      {renderAcceptance()}
    </div>
  );
};

TermsAndConditionsOfUse.propTypes = {
  showButton: PropTypes.bool,
  isButtonEnabled: PropTypes.bool,
};

TermsAndConditionsOfUse.defaultProps = {
  showButton: false,
  isButtonEnabled: false,
};

export default TermsAndConditionsOfUse;
