import React from 'react';
import PropTypes from 'prop-types';
import { contrastingColour } from '../../utils/colour';
import { motion } from 'framer-motion';

const Loader = (props) => {
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
      display: 'block',
    },
    hidden: {
      opacity: 0,
      scale: 0.7,
      transitionEnd: {
        display: 'none',
      },
    },
  };
  return (
    <motion.div
      className="fixed w-full h-full overflow-hidden"
      style={{
        zIndex: 9999999,
      }}
      initial={false}
      variants={variants}
      animate={props.isEnabled ? 'visible' : 'hidden'}
      transition={{ ease: 'easeOut', duration: 0.15 }}
    >
      <div
        className={
          'absolute top-0 left-0 bottom-0 right-0 flex flex-col items-center ' +
          (props.isAddress ? 'justify-start' : 'justify-center')
        }
        style={{
          background: props.background,
        }}
      >
        {props.image && (
          <div
            className={
              'mb-1 flex items-center justify-center ' +
              (props.isAddress ? '' : 'px-1')
            }
          >
            {props.image}
          </div>
        )}

        {props.emoji && (
          <div
            className="mb-1 px-1"
            style={{
              color: contrastingColour(props.background),
              fontSize: '3.5rem',
            }}
          >
            {props.emoji}
          </div>
        )}

        {props.icon && (
          <div
            className="mb-1 px-1"
            style={{ color: contrastingColour(props.background) }}
          >
            {props.icon}
          </div>
        )}

        {props.isSpinnerEnabled && (
          <div className="spinner px-1">
            <div
              style={{
                backgroundColor: contrastingColour(props.background),
              }}
            />
            <div
              style={{
                backgroundColor: contrastingColour(props.background),
              }}
            />
            <div
              style={{
                backgroundColor: contrastingColour(props.background),
              }}
            />
            <div
              style={{
                backgroundColor: contrastingColour(props.background),
              }}
            />
          </div>
        )}

        {props.title && (
          <div
            className="text-h2 font-heading text-center px-1"
            style={{ color: contrastingColour(props.background) }}
          >
            {props.title}
          </div>
        )}
      </div>
    </motion.div>
  );
};

Loader.propTypes = {
  title: PropTypes.string,
  emoji: PropTypes.string,
  isSpinnerEnabled: PropTypes.bool,
  icon: PropTypes.node,
  isEnabled: PropTypes.bool,
  background: PropTypes.string,
  image: PropTypes.node,
  isAddress: PropTypes.bool,
};

Loader.defaultProps = {
  title: '',
  emoji: '',
  isSpinnerEnabled: false,
  icon: null,
  isEnabled: false,
  background: '#FFFFFF',
  image: null,
  imageAltText: '',
  isAddress: false,
};

export default Loader;
