import { find } from 'lodash';
import { format, isSameDay, isToday, parseISO } from 'date-fns';
import { DeliveryMethodType, OrderStatus, PrimaryOrderStatus } from '../types';

/**
 * !!! IMPORTANT !!!
 *
 * The functions in this file have been copied to functions/src/util/order.ts
 * They've been copied to there so that they can be reused without hoisting the
 * root functions project up a level.
 *
 * ---- Please remember to keep these files in sync ----
 */

export const DELIVERY_METHOD_KEY = '_DELIVERY_METHOD';
export const DELIVERY_TIME_KEY = '_DELIVERY_TIME';

export function getFinancialStatus(order: any): 'authorized' | 'paid' {
  if (order.financial_status) {
    return order.financial_status;
  }

  return 'authorized';
}

export function getFulfillmentStatus(order: any): 'unfulfilled' | 'fulfilled' {
  if (order.fulfillment_status) {
    return order.fulfillment_status;
  }

  return 'unfulfilled';
}

export function isCancelled(order: any): boolean {
  return !!order.cancelled_at;
}

export function isFulfilled(order: any): boolean {
  return getFulfillmentStatus(order) === 'fulfilled';
}

export function isNotFulfilled(order: any): boolean {
  return !isFulfilled(order);
}

export function getAttribute(object: any, key: string, fallback: any = null) {
  const attributes = object || [];
  const attribute = find(attributes, { name: key });

  if (attribute) {
    return attribute.value;
  }

  return fallback;
}

export function getColourId(object: any) {
  return getAttribute(
    object,
    'Colour ID',
    // TODO: Remove fallback
    // During dev we accidentally had a lowercase 'd', once we wipe
    // the orders and go live we don't need to account for it
    getAttribute(object, 'Colour Id')
  );
}

export function getOrderDeliveryMethod(order: any): DeliveryMethodType {
  return getAttribute(
    order.note_attributes,
    DELIVERY_METHOD_KEY,
    DeliveryMethodType.REGULAR_DELIVERY
  );
}

export function isDelivery(order: any): boolean {
  const method = getOrderDeliveryMethod(order);

  return (
    method === DeliveryMethodType.PRIORITY_DELIVERY ||
    method === DeliveryMethodType.REGULAR_DELIVERY
  );
}

export function getOrderTimeLabel(order: any) {
  switch (getOrderDeliveryMethod(order)) {
    case DeliveryMethodType.PRIORITY_DELIVERY:
    case DeliveryMethodType.REGULAR_DELIVERY:
      return 'delivering by';
    case DeliveryMethodType.PRIORITY_PICKUP:
    case DeliveryMethodType.REGULAR_PICKUP:
    case DeliveryMethodType.AFTER_HOURS_PICKUP:
      return 'pickup ready';
  }
}

export function isPickup(order: any): boolean {
  return !isDelivery(order);
}

export function getOrderCreatedTime(order: any): Date | undefined {
  try {
    return parseISO(order.created_at);
  } catch (e) {
    return undefined;
  }
}

export function getOrderDeliveryTime(order: any): Date | undefined {
  const attribute = getAttribute(order.note_attributes, DELIVERY_TIME_KEY);

  try {
    return attribute ? parseISO(attribute) : undefined;
  } catch (e) {
    return undefined;
  }
}

export function getOrderDeliveryTimeLabel(order: any): string {
  const deliveryTime = getOrderDeliveryTime(order);

  if (deliveryTime) {
    return isToday(deliveryTime)
      ? format(deliveryTime, 'eee p')
      : format(deliveryTime, 'eee p');
  }

  // Fallback to the delivery time in Shopify
  return getAttribute(order.note_attributes, 'Delivery Time');
}

export function getOrderDeliveryFullTimeLabel(order: any): string {
  const deliveryTime = getOrderDeliveryTime(order);

  if (deliveryTime) {
    return format(deliveryTime, 'eee do MMM, p');
  }

  // Fallback to the delivery time in Shopify
  return getAttribute(order.note_attributes, 'Delivery Time');
}

export function isScheduled(order: any): boolean {
  const deliveryTime = getOrderDeliveryTime(order);
  const createdAt = getOrderCreatedTime(order);

  if (!(deliveryTime instanceof Date) || !(createdAt instanceof Date)) {
    return false;
  }

  // Considered to be "scheduled" if the order is not or the same day
  return !isSameDay(createdAt, deliveryTime);
}

export function getPrimaryOrderStatus(order: any): PrimaryOrderStatus {
  const financial = getFinancialStatus(order);
  const fulfillment = getFulfillmentStatus(order);

  if (isCancelled(order)) {
    return PrimaryOrderStatus.CANCELLED;
  }

  if (financial === 'paid' && fulfillment === 'fulfilled') {
    return PrimaryOrderStatus.DELIVERED;
  }

  if (financial === 'paid' && fulfillment === 'unfulfilled') {
    return PrimaryOrderStatus.IN_TRANSIT;
  }

  return PrimaryOrderStatus.RECEIVED;
}

export function getOrderStatus(order: any): OrderStatus {
  const deliveryMethod = getOrderDeliveryMethod(order);

  switch (getPrimaryOrderStatus(order)) {
    case PrimaryOrderStatus.CANCELLED:
      return OrderStatus.CANCELLED;

    case PrimaryOrderStatus.RECEIVED:
      return OrderStatus.RECEIVED;

    case PrimaryOrderStatus.IN_TRANSIT:
      switch (deliveryMethod) {
        case DeliveryMethodType.REGULAR_DELIVERY:
        case DeliveryMethodType.PRIORITY_DELIVERY:
          return OrderStatus.OUT_FOR_DELIVERY;

        case DeliveryMethodType.PRIORITY_PICKUP:
        case DeliveryMethodType.REGULAR_PICKUP:
          return OrderStatus.AWAITING_PICKUP_IN_STORE;

        case DeliveryMethodType.AFTER_HOURS_PICKUP:
          return OrderStatus.AWAITING_PICKUP_IN_LOCKER;

        default:
          return OrderStatus.OUT_FOR_DELIVERY;
      }

    case PrimaryOrderStatus.DELIVERED:
      switch (deliveryMethod) {
        case DeliveryMethodType.REGULAR_DELIVERY:
        case DeliveryMethodType.PRIORITY_DELIVERY:
          return OrderStatus.DELIVERED;

        case DeliveryMethodType.PRIORITY_PICKUP:
        case DeliveryMethodType.REGULAR_PICKUP:
        case DeliveryMethodType.AFTER_HOURS_PICKUP:
          return OrderStatus.PICKED_UP;

        default:
          return OrderStatus.DELIVERED;
      }

    default:
      return OrderStatus.RECEIVED;
  }
}

export function getOrderStatusDescription(status: OrderStatus): string {
  switch (status) {
    case OrderStatus.RECEIVED:
      return 'Confirmed';
    default:
      return status;
  }
}
