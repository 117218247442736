import React from 'react';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import HeaderTitle from '../components/header/HeaderTitle';
import {
  selectCurrentOrderId,
  selectFulfilledOrders,
  selectActiveOrdersByDeliveryTimeAsc,
  selectIsLoadingOrders,
} from '../store/selectors';
import { applyCurrentOrder } from '../store/actions';
import OrderItem from '../components/order/OrderItem';
import {
  isCancelled,
  getOrderStatus,
  getOrderDeliveryMethod,
  getOrderDeliveryTimeLabel,
  isFulfilled,
} from '../utils/order';
import { selectedTimeLabel } from '../utils/text';

const Orders = () => {
  const history = useHistory();
  const isLoadingOrders = useSelector(selectIsLoadingOrders);
  const activeOrders = useSelector(selectActiveOrdersByDeliveryTimeAsc);
  const fulfilledOrders = useSelector(selectFulfilledOrders);
  const dispatch = useDispatch();
  const currentOrderId = useSelector(selectCurrentOrderId);

  function onClick(orderId) {
    dispatch(applyCurrentOrder(orderId));
    history.push('/orders/detail');
    history.push('/orders/detail/status');
  }

  function timeDeliveryLabel(order) {
    if (isCancelled(order) || isFulfilled(order)) {
      return '';
    }

    const methodType = getOrderDeliveryMethod(order);
    return selectedTimeLabel(methodType);
  }

  function getOrderNumberLabel(num) {
    const orderNum = String(num);

    return `Order ${orderNum}`;
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderTitle title="Orders" borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>
          <div className="h-full bg-dulux-bg overflow-scroll">
            <div className="bg-dulux-white text-dulux-dark flex justify-start items-center top-0 left-0 w-full py-1 px-1 border-b">
              <h2 className="text-h3 leading-h2 font-heading m-0 text-gray-600">
                Current orders
              </h2>
            </div>
            {!activeOrders.length ? (
              <div className="bg-dulux-bg flex flex-col justify-center items-center p-2">
                {!isLoadingOrders ? (
                  <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                    It appears you have no current orders
                  </h2>
                ) : (
                  <>
                    <div className="spinner px-1 mb-1">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                      Loading current orders
                    </h2>
                  </>
                )}
              </div>
            ) : (
              <>
                {activeOrders.map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      orderId={order.id}
                      currentOrderId={currentOrderId}
                      orderData={order}
                      label={getOrderNumberLabel(order.order_number)}
                      onClick={() => onClick(order.id)}
                      status={getOrderStatus(order)}
                      address={
                        order.shipping_address &&
                        order.shipping_address.address1
                      }
                      timeLabel={timeDeliveryLabel(order)}
                      time={getOrderDeliveryTimeLabel(order)}
                    />
                  );
                })}
              </>
            )}
            <div className="bg-dulux-white text-dulux-dark flex justify-start items-center top-0 left-0 w-full py-1 px-1 border-b">
              <h2 className="text-h3 leading-h2 font-heading m-0 text-gray-600">
                Past orders
              </h2>
            </div>
            {!fulfilledOrders.length ? (
              <div className="bg-dulux-bg flex flex-col justify-center items-center p-2">
                {!isLoadingOrders ? (
                  <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                    It looks like you have no past orders
                  </h2>
                ) : (
                  <>
                    <div className="spinner px-1 mb-1">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                      Loading past orders
                    </h2>
                  </>
                )}
              </div>
            ) : (
              <>
                {fulfilledOrders.map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      orderId={order.id}
                      currentOrderId={currentOrderId}
                      orderData={order}
                      label={`Order ${order.order_number}`}
                      onClick={() => onClick(order.id)}
                      status={getOrderStatus(order)}
                      address={
                        order.shipping_address &&
                        order.shipping_address.address1
                      }
                      timeLabel={timeDeliveryLabel(order)}
                      time={getOrderDeliveryTimeLabel(order)}
                    />
                  );
                })}
              </>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Orders;
