import { StoresActionType, StoresActionTypes, StoresState } from './types';
import { StoreId } from '../../../types';

const initialState: StoresState = {
  byId: {},
};

const stores = (
  state: StoresState = initialState,
  action: StoresActionTypes
): StoresState => {
  switch (action.type) {
    case StoresActionType.RECEIVE:
      return {
        ...state,
        byId: action.payload.byId,
      };

    default:
      return state;
  }
};

export const getStore = (state: StoresState, storeId: StoreId) => {
  return state.byId[storeId];
};

export default stores;
