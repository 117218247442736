import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  colourStrengths,
  contrastingColour,
  getStrengthIconOutline,
} from '../../utils/colour';

const ColourStrengthSelector = (props) => {
  const [colourStrength, setColourStrength] = useState();
  const [colourStrengthSelected, setColourStrengthSelected] = useState(false);

  function selectStrength(value) {
    setColourStrengthSelected(true);
    setColourStrength(value);
    props.onClick(value);
  }

  function getStrengthOutlineIcon(strength) {
    const StrengthIcon = getStrengthIconOutline(strength);
    if (StrengthIcon) {
      return <StrengthIcon />;
    }
  }

  function renderLastUsed(value) {
    if (props.recentColourStrengths) {
      const entry = props.recentColourStrengths[value];

      if (entry) {
        return props.renderDateLastUsed(entry.dateOrdered);
      }
    }
  }

  function getClasses(value) {
    let classes = 'p-1';

    if (renderLastUsed(value)) {
      classes += ' flex justify-between items-center';
    }

    return classes;
  }

  return (
    <>
      {colourStrengths.map((strength, key) => (
        <div key={key}>
          {!colourStrengthSelected && (
            <div
              key={strength}
              className="border-b"
              style={{ borderColor: contrastingColour(props.backgroundColour) }}
              onClick={() => selectStrength(strength.value)}
            >
              <div
                className={getClasses(strength.value)}
                style={{ color: contrastingColour(props.backgroundColour) }}
              >
                {getStrengthOutlineIcon(strength.value)}
                {renderLastUsed(strength.value)}
              </div>
            </div>
          )}
          {colourStrengthSelected && colourStrength === strength.value && (
            <div
              key={strength}
              className="border-b"
              style={{ borderColor: contrastingColour(props.backgroundColour) }}
              onClick={() => selectStrength(strength.value)}
            >
              <div
                className={getClasses(strength.value)}
                style={{ color: contrastingColour(props.backgroundColour) }}
              >
                {getStrengthOutlineIcon(strength.value)}
                {renderLastUsed(strength.value)}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

ColourStrengthSelector.propTypes = {
  onClick: PropTypes.func,
  backgroundColour: PropTypes.string,
  recentColourStrengths: PropTypes.object,
  renderDateLastUsed: PropTypes.func,
};

ColourStrengthSelector.defaultProps = {
  onClick: null,
  backgroundColour: '',
  recentColourStrengths: null,
  renderDateLastUsed: null,
};

export default ColourStrengthSelector;
