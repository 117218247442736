import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/20/chevron-right.svg';
import ReactHtmlParser from 'react-html-parser';
import {
  clearSupportCategory,
  selectSupportCurrentQuery,
} from '../../store/actions';
import { selectSelectedSupportCategory } from '../../store/selectors';

const SupportSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const category = useSelector(selectSelectedSupportCategory);

  function onClick(categoryItem) {
    dispatch(selectSupportCurrentQuery(categoryItem));
    history.push('/support/select/detail');
  }

  function handleBackClick() {
    dispatch(clearSupportCategory());
    history.goBack();
  }

  function getTitle() {
    if (!category) {
      return;
    }

    if (category.key === 'my-app') {
      return 'I want to...';
    }

    return 'How can we help?';
  }

  const supportItems = () => {
    if (!category) {
      return;
    }

    return category.items.map((item, key) => {
      return (
        <div
          className="p-1 border-b flex justify-between items-center"
          key={key}
          onClick={() => onClick(item)}
        >
          <p className="text-dulux-dark">{ReactHtmlParser(item.query)}</p>
          <div>
            <div className="bg-blue-100 w-6 h-6 flex justify-center items-center">
              <ChevronRightIcon className="text-dulux-primary" />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderBack
            title="Back"
            description={category && category.name}
            onClick={handleBackClick}
          />
          <HeaderTitle title={getTitle()} borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>{supportItems()}</IonContent>
      </IonPage>
    </>
  );
};

export default SupportSelect;
