import { useDispatch } from 'react-redux';
import { usePushContext } from '../services/push/context';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { applyCurrentOrder } from '../store/actions';
import { get } from 'lodash';
import { PushNotification } from '@capacitor/core';

type NotificationToastHookResponse = {
  showToast: boolean;
  toastHeader: string | undefined;
  toastMessage: string | undefined;
  toastAction: string | undefined;
  dismissToast: () => void;
  handleToast: () => void;
};

export default function useNotificationToast(): NotificationToastHookResponse {
  const push = usePushContext();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);
  const [toastHeader, setToastHeader] = useState<string | undefined>();
  const [toastMessage, setToastMessage] = useState<string | undefined>();
  const [toastAction, setToastAction] = useState<string | undefined>();

  const handleNotification = useRef<() => void | undefined>();

  // Subscribe to notifications on first load
  useEffect(() => {
    function handleNotificationAction(notification: PushNotification) {
      const data = notification.data || {};

      if (!data || !data.action) {
        return;
      }

      if (data.action === 'order.show' && data.orderId) {
        dispatch(applyCurrentOrder(Number(data.orderId)));
        history.push('/orders');
        history.push('/orders/detail');
        history.push('/orders/detail/status');
      }

      if (data.action === 'order.review' && data.orderId) {
        dispatch(applyCurrentOrder(Number(data.orderId)));
        history.push('/orders');
        history.push('/orders/detail');
      }
    }

    push.onNotificationActionPerformed((notification) => {
      handleNotificationAction(notification.notification);
    });

    push.onNotificationReceived((notification) => {
      if (!notification.body) {
        return;
      }

      setToastHeader(notification.title);
      setToastMessage(notification.body);
      setToastAction(get(notification, 'data.action', undefined));

      handleNotification.current = () => {
        handleNotificationAction(notification);
      };

      setShowToast(true);
    });
  }, [push, history, dispatch]);

  function onHandle() {
    const handle = handleNotification.current;
    if (handle) handle();
    setShowToast(false);
  }

  function onDismiss() {
    setShowToast(false);
  }

  return {
    showToast,
    toastHeader,
    toastMessage,
    toastAction,
    dismissToast: onDismiss,
    handleToast: onHandle,
  };
}
