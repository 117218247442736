import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import InputTextGroup from '../form/InputTextGroup';
import { ReactComponent as DuluxTradeDirect } from '../../assets/images/brand/dulux-trade-direct.svg';

const LoginVerification = (props) => {
  function submit(e) {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  const inputClasses = 'ion-bbe-password-placeholder text-center mb-1 text-h3';
  const inputErrorClass = props.inputError
    ? 'text-dulux-error border-dulux-red'
    : 'text-inspirations-blue border-dulux-blue';

  return (
    <div className="bg-dulux-light-pressed h-full flex flex-col overflow-hidden">
      <div className="mt-2">
        <DuluxTradeDirect className="mb-3 w-full" />
      </div>
      <div className="p-1 mb-auto text-center">
        {props.title && (
          <h2 className="font-heading text-h2 mb-1 pt-1">{props.title}</h2>
        )}
        {props.inputError ? (
          <p className="text-h3 mb-1">{props.inputErrorMessage}</p>
        ) : (
          props.description && (
            <p className="text-h3 mb-1">{props.description}</p>
          )
        )}
        <form onSubmit={submit} noValidate>
          <InputTextGroup
            autofocus={true}
            type={props.inputType}
            inputMode={props.inputMode}
            placeholder={props.inputPlaceholder}
            value={props.inputValue}
            onInput={props.inputOnChange}
            errorMessage=""
            inputClassNames={`${inputClasses} ${inputErrorClass}`}
          />

          {props.buttonTitle && (
            <Button type="submit">{props.buttonTitle}</Button>
          )}
        </form>
      </div>
    </div>
  );
};

LoginVerification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  inputType: PropTypes.string,
  inputMode: PropTypes.string,
  inputValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputOnChange: PropTypes.func,
  inputError: PropTypes.bool,
  setInputValue: PropTypes.func,
  verificationId: PropTypes.string,
  inputErrorMessage: PropTypes.string,
  autocomplete: PropTypes.string,
  onSubmit: PropTypes.func,
};

LoginVerification.defaultProps = {
  title: '',
  description: '',
  buttonTitle: '',
  inputPlaceholder: '',
  inputType: 'text',
  inputMode: 'text',
  inputValue: '',
  inputOnChange: null,
  inputError: false,
  verificationId: '',
  inputErrorMessage: 'Please enter a valid input',
  autocomplete: '',
  setInputValue: null,
  onClick: null,
};

export default LoginVerification;
