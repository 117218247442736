import { useSelector } from 'react-redux';
import { selectRootState } from '../../store/selectors';
import { getCartItems } from '../../store/reducers';

export default function useTotalLitres() {
  const rootState = useSelector(selectRootState);
  const cartItems = getCartItems(rootState);

  return cartItems.reduce((acc, item) => {
    // Item has a size
    if (!item.product.size) {
      return acc;
    }

    const size = item.product.size;

    // Size is in litres
    if (size.indexOf('L') === -1) {
      return acc;
    }

    // Extract the number
    const matches = size.match(/[0-9]+/g);

    if (!matches || matches.length === 0) {
      return acc;
    }

    const litres = parseInt(matches[0], 10) * item.quantity;

    return acc + litres;
  }, 0);
}
