import React from 'react';
import PropTypes from 'prop-types';

const FooterCapContainer = (props) => {
  const roundedClass = props.rounded ? ' rounded-t-xl ' : '';

  return (
    <div
      className={`flex bg-white space-x-1 justify-center items-center ${roundedClass} ${props.className}`}
      style={{
        position: 'relative',
        padding: '12px',
        boxShadow:
          '0px -4px 5px rgba(7, 10, 23, 0.05), 0px -3px 8px rgba(8, 14, 35, 0.04), 0px -8px 12px rgba(5, 7, 48, 0.03)',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

FooterCapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  style: PropTypes.object,
};

FooterCapContainer.defaultProps = {
  style: {},
  children: {},
  className: '',
  rounded: true,
};

export default FooterCapContainer;
