// !IMPORTANT! The index signature parameter type in ColourMap below
// cannot be a type alias, make sure you update it if you change this
import { Action } from 'redux';
import { Colour, ColourId } from '../../../types';

interface ColourMap {
  [key: string]: Colour;
}

interface ColourBaseMap {
  [key: string]: ColourId[];
}

export interface ColourState {
  byId: ColourMap;
  byBase: ColourBaseMap;
}

export enum ColourActionType {
  RECEIVE = 'COLOURS_RECEIVE',
}

export interface ReceiveColoursAction extends Action {
  type: ColourActionType.RECEIVE;
  payload: ColourState;
}

export type ColourActionTypes = ReceiveColoursAction;
