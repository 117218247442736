import { RootState } from '../reducers';

export const selectUser = (state: RootState) => state.user.user || null;

export const selectDuluxRep = (state: RootState) => state.user.rep || null;

export const selectUserUid = (state: RootState) =>
  state.user.user && state.user.user.uid;

export const selectUserStatus = (state: RootState) =>
  (state.user.user && state.user.user.status) || null;

export const selectUserTermsAccepted = (state: RootState) => {
  const terms = state.user.user && state.user.user.termsAccepted;

  // Default to true if not set
  // We can't use "(terms) || true" as seen in other selectors as it will
  // evaluate as "falsy" and always be true;
  return terms === undefined ? true : terms;
};

export const selectOrderFeedbackRequired = (state: RootState) => {
  return state.user.user && state.user.user.orderFeedbackRequired;
};

export const selectUserPreferredStoreId = (state: RootState) => {
  return (state.user.user && state.user.user.preferredStore) || '';
};

export const selectIsUserDataLoaded = (state: RootState) =>
  (state.user.user && state.user.user.isUserDataLoaded) || false;

export const selectUserInitialising = (state: RootState) =>
  state.user.auth.isInitialising;
