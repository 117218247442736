import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import { ReactComponent as DuluxTradeDirect } from '../../assets/images/brand/dulux-trade-direct.svg';
import Order from '../../assets/images/carousel/signin/order.png';
import Choose from '../../assets/images/carousel/signin/choose.png';
import Enjoy from '../../assets/images/carousel/signin/enjoy.png';
import { IonSlides, IonSlide } from '@ionic/react';
import { useAnalyticsContext } from '../../services/analytics/context';

const LoginGetStarted = (props) => {
  const analytics = useAnalyticsContext();
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  function handleSlide(e) {
    analytics.trackEvent('Carousel Swiped', {
      properties: {
        category: 'Welcome',
        label: 'Login',
      },
    });
  }

  return (
    <div className="bg-dulux-light-pressed h-full flex flex-col overflow-scroll">
      <div className="my-auto">
        <DuluxTradeDirect className="w-full" />
      </div>
      <div className="mt-2">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={(e) => handleSlide(e)}
        >
          <IonSlide>
            <div className="w-full p-1 text-center">
              <img
                src={Order}
                className="px-6 mb-auto"
                alt="The product screen in app with icons paint and accessories"
              />
              <h2 className="font-heading text-h2 pt-2">Order</h2>
              <p className="py-2">
                Safely shop the same products you see in-store, selected
                specifically for our Trade customers.
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="w-full p-1 text-center">
              <img
                src={Choose}
                className="px-6 mb-auto"
                alt="A ute pickup truck with icons Pickup and Delivery"
              />
              <h2 className="font-heading text-h2 pt-2">Choose</h2>
              <p className="py-2">
                Enjoy priority pickup and delivery that’s quick, convenient, and
                contactless.
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="w-full p-1 text-center">
              <img
                src={Enjoy}
                className="px-6 mb-auto"
                alt="A wrist watch with icons Save time and finish early"
              />
              <h2 className="font-heading text-h2 pt-2">Enjoy</h2>
              <p className="py-2">
                Get back to business sooner by spending less time on the road,
                in traffic or waiting in line.
              </p>
            </div>
          </IonSlide>
        </IonSlides>
      </div>
      <div className="mt-2 mb-auto">
        <div className="px-1 pb-1 mt-3">
          <Button onClick={props.onClick}>Get started</Button>
        </div>
      </div>
    </div>
  );
};

LoginGetStarted.propTypes = {
  onClick: PropTypes.func,
};

LoginGetStarted.defaultProps = {
  onClick: null,
};

export default LoginGetStarted;
