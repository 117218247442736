import React from 'react';
import PropTypes from 'prop-types';
import PrivacyPolicy from './PrivacyPolicy';
import FooterCapContainer from '../ui/FooterCapContainer';
import Button from '../ui/Button';
import { IonModal } from '@ionic/react';

const PrivacyPolicyModal = (props) => {
  return (
    <IonModal isOpen={props.showModal} cssClass="modal py-2">
      <div className="overflow-scroll px-1 py-3 mt-auto">
        <PrivacyPolicy />
      </div>
      <FooterCapContainer
        style={{
          paddingTop: '30px',
          paddingBottom: '20px',
          marginBottom: '10px',
        }}
      >
        <Button onClick={() => props.setShowModal(false)}>Close</Button>
      </FooterCapContainer>
    </IonModal>
  );
};

PrivacyPolicyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

PrivacyPolicyModal.defaultProps = {
  showModal: false,
  setShowModal: null,
};

export default PrivacyPolicyModal;
