import {
  ApplyCurrentOrderAction,
  CleanupOrdersAction,
  Order,
  OrderActionType,
  ReceiveOrderAction,
} from '../reducers/orders/types';

export const receiveOrder = (order: Order): ReceiveOrderAction => {
  return {
    type: OrderActionType.RECEIVE_ORDER,
    payload: {
      order,
    },
  };
};

export const applyCurrentOrder = (orderId: number): ApplyCurrentOrderAction => {
  return {
    type: OrderActionType.APPLY_CURRENT_ORDER,
    payload: {
      currentOrder: orderId,
    },
  };
};

export const cleanupOrders = (orderIds: Order['id'][]): CleanupOrdersAction => {
  return {
    type: OrderActionType.CLEANUP_ORDERS,
    payload: {
      orderIds,
    },
  };
};
