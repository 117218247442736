import { useState, useEffect } from 'react';
import { find, get } from 'lodash';
import weatherMapping from '../data/weatherMapping.json';
import { CurrentWeatherResponse, getCurrentWeather } from '../services/weather';
import { getObject, setObject } from '../utils/storage';
import {
  addMinutes,
  formatISO,
  isBefore,
  parseISO,
  subMinutes,
  isValid,
} from 'date-fns';
import useInterval from './useInterval';

const storageStaleTime = 15; // in minutes
const storageWeatherKey = 'WEATHER';
const storageWeatherFetchedKey = 'WEATHER_FETCHED';

export default function useWeather() {
  const [weatherData, setWeatherData] = useState<CurrentWeatherResponse>();

  let humidity = null;
  let temperature = null;
  let weatherIcon = null;
  let weatherHex = '#262F3E';
  let weatherName = 'Dulux Blue';

  if (weatherData) {
    const iconId = get(weatherData, 'weather[0].icon');
    const colourData = find(weatherMapping, (item) => item.id === iconId);
    const temp = get(weatherData, 'main.temp');

    if (colourData) {
      weatherHex = colourData.hex;
      weatherName = colourData.name;
    }

    weatherIcon = iconId;
    humidity = get(weatherData, 'main.humidity');

    if (temp) {
      temperature = Math.round(temp);
    }
  }

  function retrieveWeatherData() {
    getCurrentWeather()
      .then((weatherResponse) => {
        setObject(storageWeatherKey, weatherResponse.data).then(() =>
          setWeatherData(weatherResponse.data)
        );

        setObject(storageWeatherFetchedKey, formatISO(new Date())).then();
      })
      .catch((e) => {
        console.log('Weather not updated. Issue encountered:', e);
      });
  }

  function checkWeather() {
    getObject(storageWeatherKey)
      .then((data) => {
        // STORAGE EMPTY, fetching from API
        if (!data) {
          return retrieveWeatherData();
        }

        // Loading weather from STORAGE;
        getObject(storageWeatherFetchedKey).then((timestamp) => {
          // Can't tell when it was last updated, fetching from api
          if (!timestamp) {
            return retrieveWeatherData();
          }

          const now = new Date();

          const dateFetched = parseISO(timestamp);
          const dataExpires = isValid(dateFetched)
            ? addMinutes(dateFetched, storageStaleTime)
            : subMinutes(now, 1); // Force a stale cache if it's invalid

          if (isBefore(dataExpires, now)) {
            // STORAGE STALE, fetching from API
            retrieveWeatherData();
          } else {
            // STORAGE FRESH, using storage
            setWeatherData(data);
          }
        });
      })
      .catch((reason) => {
        // CATCH BLOCK, fetching from API
        retrieveWeatherData();
      });
  }

  // Initialise the weather on first load
  // eslint-disable-next-line
  useEffect(() => checkWeather(), []);

  // Check the weather every 30 seconds, will default to cache if not stale
  useInterval(() => checkWeather(), 30000);

  return {
    humidity,
    temperature,
    weatherIcon,
    weatherHex,
    weatherName,
  };
}
