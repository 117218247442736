import { Action } from 'redux';

export interface PricingItem {
  grossValue: number;
  itemNumber: number;
  netValue: number;
  sku: string;
  taxAmount: number;
}

export interface PricingTotal {
  grossValue: number;
  netValue: number;
  taxAmount: number;
}

export interface PricingState {
  isLoadingPricing: boolean;
  items?: PricingItem[];
  total?: PricingTotal;
}

export enum PricingActionType {
  REQUEST = 'REQUEST_PRICING',
  RECEIVE = 'RECEIVE_PRICING',
  COMPLETE = 'COMPLETE_PRICING',
  CLEAR = 'CLEAR_PRICING',
}

export interface RequestPricingAction extends Action {
  type: PricingActionType.REQUEST;
  payload: Pick<PricingState, 'isLoadingPricing'>;
}

export interface ReceivePricingAction extends Action {
  type: PricingActionType.RECEIVE;
  payload: PricingState;
}

export interface CompletePricingAction extends Action {
  type: PricingActionType.COMPLETE;
  payload: Pick<PricingState, 'isLoadingPricing'>;
}

export interface ClearPricingAction extends Action {
  type: PricingActionType.CLEAR;
}

export type PricingActionTypes =
  | RequestPricingAction
  | ReceivePricingAction
  | CompletePricingAction
  | ClearPricingAction;
