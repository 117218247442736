import Firebase, { EVENTS } from '../firebase';
import * as firebase from 'firebase';
import { receiveDuluxRepData } from '../../../store/actions';
import { User } from '../types';
import { Subscription } from '../../../models/PubSub';

export default class FirebaseDuluxRep {
  private firebase: Firebase;
  private busSubscription: Subscription | undefined;

  private duluxRepRef: firebase.database.Reference | undefined;
  private onDuluxRepValueListener: any;

  constructor(fb: Firebase) {
    this.firebase = fb;

    this.onDuluxRepValueListener = this.onDuluxRepValue.bind(this);
  }

  boot() {
    if (this.busSubscription) {
      this.busSubscription.unsubscribe();
    }

    this.busSubscription = this.firebase.bus.subscribe(
      EVENTS.onUserData,
      (user) => this.onUserData(user)
    );
  }

  onUserData(user: User | null | undefined) {
    // If we don't have a user then we're probably logged out - cleanup
    if (!user) {
      this.removeListener();
      return this.setRep(undefined);
    }

    if (!user.duluxRepId) {
      return console.log('User does not have an associated rep');
    }

    // Subscribe to updates to the rep data
    this.duluxRepRef = this.rep(user.duluxRepId);
    this.duluxRepRef.on('value', this.onDuluxRepValueListener);
  }

  removeListener() {
    const ref = this.duluxRepRef;

    if (!ref) {
      return console.error(
        'Attempting to remove rep listener that does not exist'
      );
    }

    ref.off('value', this.onDuluxRepValueListener);
  }

  /*
   * Called with a snapshot of the rep changes.
   */
  onDuluxRepValue(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      // Rep was deleted, set to undefined
      this.setRep(undefined);
    }

    this.setRep(snapshot.val());
  }

  setRep(data?: any) {
    this.firebase.dispatch(receiveDuluxRepData(data));
  }

  rep = (id: string | number) => this.firebase.db.ref(`duluxReps/${id}`);
}
