import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectIsUserDataLoaded,
  selectUserInitialising,
} from '../store/selectors';
import useAuthenticated from './useAuthenticated';
import useTermsAccepted from './useTermsAccepted';
import useOnboardingViewed from './useOnboardingViewed';
import useRegistrationStatus from './useRegistrationStatus';
import { UserStatus } from '../services/firebase/types';
import { isNull } from 'lodash';

export default function useAuthenticatedRedirect() {
  const history = useHistory();
  const userStatus = useRegistrationStatus();
  const { onboardingViewed } = useOnboardingViewed();
  const isAuthenticated = useAuthenticated();
  const areTermsAccepted = useTermsAccepted();
  const isLoading = useSelector(selectUserInitialising);
  const isUserDataLoaded = useSelector(selectIsUserDataLoaded);
  const onLoginPage = useRouteMatch({ path: '/login' });

  const [delayedLoading, setDelayedLoading] = useState(true);

  // Add a small delay to isLoading to allow reducers to resolve
  useEffect(() => {
    const timeout = setTimeout(() => setDelayedLoading(isLoading), 100);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  // Redirect users to/from the login page if they're authenticated
  useEffect(() => {
    if (!history || delayedLoading) {
      return;
    }

    if (!isAuthenticated && !onLoginPage) {
      history.push('/login');
    }

    if (isAuthenticated) {
      if (!isUserDataLoaded) {
        history.push('/verification-holding');
        return;
      }

      if (isUserDataLoaded) {
        if (isNull(userStatus)) {
          history.push('/verification-holding');
          return;
        }
        if (userStatus === UserStatus.REGISTRATION_REQUIRED) {
          history.push('/register');
          return;
        }
        if (userStatus === UserStatus.VERIFICATION_REQUIRED) {
          history.push('/holding');
          return;
        }
        if (!areTermsAccepted) {
          history.push('/terms');
          return;
        }
        if (!onboardingViewed) {
          history.push('/onboarding');
          return;
        }
        history.push('/home');
      }
    }
  }, [
    history,
    userStatus,
    isAuthenticated,
    isUserDataLoaded,
    onboardingViewed,
    areTermsAccepted,
    delayedLoading,
    onLoginPage,
  ]);

  return { isUserDataLoaded, isLoading: delayedLoading };
}
