import React from 'react';
import PropTypes from 'prop-types';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import useInputValidation from '../../hooks/useInputValidation';

const InputTextGroup = (props) => {
  const {
    labelClass,
    setLabelClass,
    handleOnBlur,
    makeDirty,
    hasError,
    borderClass,
    marginBottomClass,
  } = useInputValidation(props.value, props.required);

  const ionlabelClass = `text-h2 leading-h4 ` + labelClass;
  const ionItemClass =
    `ion-bbe-item relative ion-bbe-bg-light ion-no-padding ` +
    `ion-item-inner-padding-start-none ion-item-inner-padding-end-none`;
  const textInputClass =
    `ion-padding w-full font-heading ` +
    `ion-bbe-input--standard ion-bbe-text-input bg-white mt-2 ` +
    `rounded-xl border ` +
    `${marginBottomClass} ${borderClass} ${props.inputClassNames}`;

  return (
    <IonItem lines="none" className={ionItemClass}>
      {props.label && (
        <IonLabel position="floating" className="leading-none">
          <div className={ionlabelClass}>{props.label}</div>
        </IonLabel>
      )}
      <IonInput
        autofocus={props.autofocus}
        autocapitalize={props.autocapitalize}
        inputmode={props.inputMode}
        name={props.name}
        type={props.type}
        value={props.value}
        debounce={200}
        onIonBlur={() => handleOnBlur()}
        onIonChange={() => makeDirty()}
        onIonFocus={() => setLabelClass('text-inspirations-blue')}
        onIonInput={(e) => props.onInput(e.target.value)}
        placeholder={props.placeholder}
        className={textInputClass}
      />
      {props.required && hasError && (
        <div className="text-dulux-error">{props.errorMessage}</div>
      )}

      {props.serverErrorMessage && (
        <div className="text-dulux-error">
          {props.serverErrorMessage(props.name)}
        </div>
      )}
    </IonItem>
  );
};

InputTextGroup.propTypes = {
  type: PropTypes.string,
  inputMode: PropTypes.string,
  name: PropTypes.string,
  onInput: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  getBorderStyle: PropTypes.func,
  errorMessage: PropTypes.string,
  serverErrorMessage: PropTypes.func,
  autofocus: PropTypes.bool,
  autocapitalize: PropTypes.string,
  inputClassNames: PropTypes.string,
};

InputTextGroup.defaultProps = {
  type: 'text',
  inputMode: 'text',
  name: '',
  onInput: null,
  required: true,
  label: '',
  value: '',
  placeholder: '',
  getBorderStyle: null,
  errorMessage: 'This field is required',
  serverErrorMessage: null,
  autofocus: false,
  autocapitalize: null,
  inputClassNames: 'text-inspirations-blue',
};
export default InputTextGroup;
