import React, { useState } from 'react';
import PropTypes from 'prop-types';
import titleCase from '../../utils/text';
import {
  // getColourStrengthIcon,
  contrastingColour,
} from '../../utils/colour';
import ColourStrengthSelector from '../../components/search/ColourStrengthSelector';
import Button from '../../components/ui/Button';
import FooterCapContainer from '../ui/FooterCapContainer';

const ProductColourStrength = (props) => {
  const [colourStrength, setColourStrength] = useState(
    props.hasStrengthOptions ? '' : 1
  );

  // function displayStrengthIcon(strength) {
  //   const StrengthIcon = getColourStrengthIcon(strength);
  //   if (StrengthIcon) {
  //     return <StrengthIcon className="w-4 h-4" />;
  //   }
  // }

  return (
    <div
      className="h-full flex flex-col overflow-scroll"
      key={props.colourId}
      style={{ backgroundColor: props.hex }}
    >
      <div
        className="mb-3 p-1 flex justify-between items-center"
        style={{ color: contrastingColour(props.hex) }}
      >
        {props.renderBrand()}
        {props.atlasCode && (
          <div
            className="text-small"
            style={{ color: contrastingColour(props.hex) }}
          >
            {props.atlasCode}
          </div>
        )}
      </div>
      <div
        className="border-b"
        style={{ borderColor: contrastingColour(props.hex) }}
      >
        <h2
          className="font-heading text-h2 px-1 pb-1"
          style={{ color: contrastingColour(props.hex) }}
        >
          {titleCase(props.colourName)}
        </h2>
      </div>

      {props.hasStrengthOptions && (
        <div>
          <ColourStrengthSelector
            backgroundColour={props.hex}
            onClick={(value) => setColourStrength(value)}
            recentColourStrengths={props.recentColourStrengths}
            renderDateLastUsed={props.renderDateLastUsed}
          />
        </div>
      )}

      {colourStrength && (
        <FooterCapContainer className="mt-auto">
          <Button onClick={() => props.onClick(props.colourId, colourStrength)}>
            Select colour
            {/*<span className="p-1">{displayStrengthIcon(colourStrength)}</span>*/}
          </Button>
        </FooterCapContainer>
      )}
    </div>
  );
};

ProductColourStrength.propTypes = {
  colourId: PropTypes.string,
  colourName: PropTypes.string,
  hex: PropTypes.string,
  colourStrength: PropTypes.number,
  recentColourStrengths: PropTypes.object,
  hasStrengthOptions: PropTypes.bool,
  onClick: PropTypes.func,
  renderBrand: PropTypes.func.isRequired,
  atlasCode: PropTypes.string,
  renderDateLastUsed: PropTypes.func,
};

ProductColourStrength.defaultProps = {
  colourId: '',
  colourName: '',
  hex: '',
  colourStrength: 1,
  recentColourStrengths: null,
  hasStrengthOptions: null,
  onClick: null,
  renderBrand: null,
  atlasCode: '',
  renderDateLastUsed: null,
};

export default ProductColourStrength;
