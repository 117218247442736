/* eslint-disable @typescript-eslint/camelcase */
import { DeliveryMethodGroupType } from '../store/reducers/checkout/types';
import { DeliveryMethodType } from '../types';
import { find } from 'lodash';
import stores from '../data/backup/stores.json';

export function titleCase(title: string) {
  return title.replace(/\w\S*/g, function (piece) {
    return piece.charAt(0).toUpperCase() + piece.substr(1).toLowerCase();
  });
}

export function getDeliveryMethodGroupTimeLabel(type: DeliveryMethodGroupType) {
  switch (type) {
    case DeliveryMethodGroupType.PICKUP:
      return 'Ready by';
    case DeliveryMethodGroupType.LOCKER:
      return 'Ready from';
    default:
      return '';
  }
}

export function selectedTimeLabel(type: DeliveryMethodType) {
  switch (type) {
    case DeliveryMethodType.PRIORITY_DELIVERY:
      return 'Arrives by';
    case DeliveryMethodType.REGULAR_DELIVERY:
      return 'Arrives by';
    case DeliveryMethodType.PRIORITY_PICKUP:
    case DeliveryMethodType.REGULAR_PICKUP:
      return 'Ready by';
    case DeliveryMethodType.AFTER_HOURS_PICKUP:
      return 'Ready from';
  }
}

export function isDelivery(method: DeliveryMethodType) {
  return (
    method === DeliveryMethodType.PRIORITY_DELIVERY ||
    method === DeliveryMethodType.REGULAR_DELIVERY
  );
}

export function isPickup(method: DeliveryMethodType) {
  return !isDelivery(method);
}

export function selectedLocationLabel(method: DeliveryMethodType) {
  return isDelivery(method) ? 'Delivery address' : 'Pickup location';
}

export function selectedNoteLabel(method: DeliveryMethodType) {
  return isDelivery(method) ? 'Delivery instructions' : 'Store instructions';
}

export function selectedOrderSummaryTitle(type: DeliveryMethodType) {
  switch (type) {
    case DeliveryMethodType.PRIORITY_PICKUP:
    case DeliveryMethodType.REGULAR_PICKUP:
      return 'Pickup';
    case DeliveryMethodType.AFTER_HOURS_PICKUP:
      return 'Locker';
    default:
      return '';
  }
}

export function getStoreName(address: string) {
  const store = find(Object.values(stores), { address });

  if (store) {
    return store.label;
  }

  return null;
}

export function getNote(reference: string, note: string) {
  if (note && reference) {
    return `${reference} - ${note}`;
  }

  if (!note && reference) {
    return reference;
  }

  if (note && !reference) {
    return note;
  }

  return '';
}

export default titleCase;
