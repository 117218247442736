import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import sheenBackground from '../../utils/sheen';
import {
  getStrengthIconOutlineSmall,
  contrastingColour,
} from '../../utils/colour';
import titleCase from '../../utils/text';
import {
  productBackgroundColour,
  productImage,
  isAccessory,
} from '../../utils/product';
import { ProductStep } from '../../store/reducers/products/types';
import { IonAlert } from '@ionic/react';
import { useSelector } from 'react-redux';
import { selectEnrichedAvailableSheens } from '../../store/selectors';
import FooterCapContainer from '../ui/FooterCapContainer';

const ProductSummary = (props) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [sheenBgColour, setSheenBgColour] = useState('#FFFFFF');

  const availableSheens = useSelector(selectEnrichedAvailableSheens);

  useEffect(() => {
    if (availableSheens && availableSheens.length) {
      setSheenBgColour(availableSheens[0].colour);
      return;
    }

    if (props.productQuery.sheen) {
      setSheenBgColour(sheenBackground(props.productQuery.sheen));
      return;
    }

    setSheenBgColour('#FFFFFF');
  }, [availableSheens, props.productQuery.sheen]);

  function onSheenClick() {
    props.onClearSheen();
    props.setSkipLocation(ProductStep.SHEEN);
  }

  function onColourClick() {
    props.onClearColour();
    props.setSkipLocation(ProductStep.COLOUR);
  }

  function onSizeClick() {
    props.onClearSize();
    props.setSkipLocation(ProductStep.SIZE);
  }

  function onQuantityClear() {
    props.onClearQuantity();
    props.setSkipLocation(ProductStep.QUANTITY);
  }

  function displayStrengthIcon(strength) {
    const StrengthIcon = getStrengthIconOutlineSmall(strength);
    if (StrengthIcon) {
      return <StrengthIcon className="w-3 h-3 ml-1" />;
    }
  }

  function image() {
    let src = productImage(props.productQuery.range, props.productQuery.name);

    if (isAccessory(props.productQuery.range)) {
      src = `/assets/images/product/accessories/${props.productQuery.sheenImage}@2x.png`;
    }

    if (src) {
      return (
        <img
          src={src}
          className={isAccessory(props.productQuery.range) ? '' : 'py-2'}
          style={{
            width: isAccessory(props.productQuery.range) ? '6rem' : '12rem',
          }}
          alt={props.productQuery.name}
        />
      );
    }

    return (
      <h2 className="text-h2 font-heading text-dulux-dark pb-1">
        {props.productQuery.name}
      </h2>
    );
  }

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="overflow-scroll h-full">
          {props.productQuery.name && (
            /* eslint-disable */
            <div
              className="w-full p-1 flex flex-col"
              style={{
                background: isAccessory(props.productQuery.range)
                  ? sheenBgColour
                  : productBackgroundColour(
                      props.productQuery.range,
                      props.productQuery.name
                    ),
              }}
            >
              {/* eslint-enable */}
              <div className="flex justify-center items-center">{image()}</div>
            </div>
          )}
          {props.productQuery.sheen && (
            <div
              className="w-full p-1 flex flex-col"
              onClick={onSheenClick}
              style={{
                background: sheenBgColour,
              }}
            >
              <div
                className="text-center pb-3"
                style={{
                  color: contrastingColour(sheenBgColour),
                }}
              >
                {isAccessory(props.productQuery.range) ? 'Accessory' : 'Sheen'}
              </div>
              <div className="flex justify-center items-center pb-1">
                <h2
                  className="text-h2 font-heading text-center"
                  style={{
                    color: contrastingColour(sheenBgColour),
                  }}
                >
                  {props.productQuery.sheen}
                </h2>
              </div>
            </div>
          )}
          {props.colourQuery.colourName && (
            <div
              className="w-full p-1 flex flex-col"
              onClick={onColourClick}
              style={{
                background: props.colourQuery.hex,
              }}
            >
              <div
                className="text-center pb-3"
                style={{
                  color: contrastingColour(props.colourQuery.hex),
                }}
              >
                Colour
              </div>
              <div className="flex flex-wrap justify-center items-center pb-1">
                <h2
                  className="text-h2 font-heading text-center"
                  style={{
                    color: contrastingColour(props.colourQuery.hex),
                  }}
                >
                  {`${titleCase(props.colourQuery.colourBrand)} `}
                  {titleCase(props.colourQuery.colourName)}
                </h2>
                <span
                  style={{
                    color: contrastingColour(props.colourQuery.hex),
                  }}
                >
                  {displayStrengthIcon(props.colourStrength)}
                </span>
              </div>
            </div>
          )}
          {props.productQuery.size && (
            <div
              className="w-full p-1 flex flex-col bg-gray-400"
              onClick={onSizeClick}
            >
              <div className="text-dulux-dark text-center pb-3">Size</div>
              <div className="flex justify-center items-center pb-1">
                <h2 className="text-dulux-dark text-h2 font-heading text-center">
                  {props.productQuery.size}
                </h2>
              </div>
            </div>
          )}
          {props.quantity && (
            <div
              className="w-full p-1 flex flex-col bg-white"
              onClick={onQuantityClear}
            >
              <div className="text-dulux-dark text-center pb-3">Quantity</div>
              <div className="flex justify-center items-center pb-1">
                <h2 className="text-dulux-dark text-h2 font-heading text-center">
                  {props.quantity}
                </h2>
              </div>
            </div>
          )}
        </div>
        <FooterCapContainer className="mt-auto">
          {props.isEditing && (
            <Button
              onClick={() => setShowDeleteAlert(true)}
              theme="error-ghost"
            >
              Delete item
            </Button>
          )}
          <Button onClick={props.onAddToCart}>
            {props.isEditing ? 'Update item' : 'Add to cart'}
          </Button>
        </FooterCapContainer>
      </div>
      <IonAlert
        isOpen={showDeleteAlert}
        header={'Delete product'}
        message={'Are you sure you would like to delete this product?'}
        backdropDismiss={false}
        buttons={[
          {
            text: 'Cancel',
            handler: () => {
              setShowDeleteAlert(false);
            },
          },
          {
            text: 'Confirm',
            handler: () => {
              props.onRemoveFromCart();
            },
          },
        ]}
      />
    </>
  );
};

ProductSummary.propTypes = {
  productQuery: PropTypes.object,
  colourQuery: PropTypes.object,
  colourStrength: PropTypes.number,
  quantity: PropTypes.number,
  sheenImage: PropTypes.string,
  setSkipLocation: PropTypes.func,
  onAddToCart: PropTypes.func,
  onClearSheen: PropTypes.func,
  onClearColour: PropTypes.func,
  onClearSize: PropTypes.func,
  onClearQuantity: PropTypes.func,
  onClearProduct: PropTypes.func,
  isEditing: PropTypes.bool,
  onRemoveFromCart: PropTypes.func,
};

ProductSummary.defaultProps = {
  productQuery: {},
  colourQuery: {},
  colourStrength: 1,
  quantity: 0,
  sheenImage: null,
  setSkipLocation: null,
  onAddToCart: null,
  onClearSheen: null,
  onClearColour: null,
  onClearSize: null,
  onClearQuantity: null,
  onClearProduct: null,
  isEditing: false,
};

export default ProductSummary;
