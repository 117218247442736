import { useState } from 'react';
import useInterval from './useInterval';
import { useFirebaseContext } from '../services/firebase/context';
import { RemoteConfigOverride } from '../services/firebase/types';

export default function useRemoteConfig() {
  const checkRemoteConfigInterval = 10000; // 10 seconds
  const firebase = useFirebaseContext();
  const [config, setConfig] = useState<RemoteConfigOverride>(
    firebase.remoteConfig.getCombinedConfig()
  );

  useInterval(() => {
    setConfig(firebase.remoteConfig.getCombinedConfig());
  }, checkRemoteConfigInterval);

  return config;
}
