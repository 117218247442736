import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { IonHeader, IonContent, IonPage, isPlatform } from '@ionic/react';
import { useFirebaseContext } from '../services/firebase/context';
import {
  clearVerificationId,
  clearVerificationError,
  clearLoginError,
} from '../store/actions';
import LoginGetStarted from '../components/login/LoginGetStarted';
import LoginVerification from '../components/login/LoginVerification';
import Loader from '../components/ui/Loader';
import HeaderBack from '../components/header/HeaderBack';
import { useAnalyticsContext } from '../services/analytics/context';

const Login = () => {
  const analytics = useAnalyticsContext();
  const dispatch = useDispatch();
  const isLoadingVerification = useSelector(
    (s) => s.user.auth.isLoadingVerification
  );
  const isLoadingLogin = useSelector((s) => s.user.auth.isLoadingLogin);
  const verificationId = useSelector((s) => s.user.auth.verificationId);
  const storeVerificationCode = useSelector(
    (s) => s.user.auth.verificationCode
  );
  const verificationError = useSelector(
    (s) => s.user.auth.verificationError || null
  );
  const loginError = useSelector((s) => s.user.auth.loginError || null);

  const firebase = useFirebaseContext();
  const recaptchaRef = useRef();
  const mustUseVerifier = !isPlatform('capacitor');
  const [verifier, setVerifier] = useState(undefined);

  const [showGetStarted, setShowGetStarted] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState();

  useEffect(() => {
    if (showGetStarted) {
      analytics.page('/login/welcome', {
        properties: {
          url: '/login/welcome',
        },
      });
      return;
    }

    if (!verificationId) {
      analytics.page('/login/phone', {
        properties: {
          url: '/login/phone',
        },
      });
    } else {
      analytics.page('/login/code', {
        properties: {
          url: '/login/code',
        },
      });
    }
  }, [analytics, showGetStarted, verificationId]);

  function requestCode() {
    // There is a bug that drops the listeners if the user signs in and then signs out
    // To fix this we must resubscribe before attempting the flow again
    firebase.subscribeToNativeAuthEvents();

    if (parsedPhoneNumber && !parsedPhoneNumber.isValid()) {
      console.error('invalid phone number');
      setPhoneNumberError(true);
      return;
    }

    try {
      firebase.requestVerificationCode(
        parsedPhoneNumber.format('E.164'),
        verifier
      );
      setPhoneNumberError(false);
    } catch (e) {
      setPhoneNumberError(true);
    }
  }

  function login() {
    firebase.signInWithPhone(verificationCode);
  }

  function phoneFormatted() {
    if (!phoneNumberError) {
      // Parse to AU format and then replace spaces with &nbsp (non-breaking)
      return parsedPhoneNumber.formatNational('AU').replace(/ /g, '\u00a0');
    }
  }

  useEffect(() => {
    try {
      setParsedPhoneNumber(parsePhoneNumberFromString(phoneNumber, 'AU'));
    } catch (e) {
      // Invalid phone number
      console.log('Invalid phone number');
    }
  }, [phoneNumber]);

  // Set the verification code if we receive one from the device automatically.
  // This isn't required, but it's nice to show the user what's happening...
  useEffect(() => {
    setVerificationCode(storeVerificationCode);
  }, [storeVerificationCode]);

  // Display the recaptcha challenge if required
  useEffect(() => {
    if (!mustUseVerifier) {
      return;
    }

    const v = firebase.getVerifier(recaptchaRef.current);

    v.render();
    setVerifier(v);

    return () => v.clear();
  }, [firebase, mustUseVerifier, recaptchaRef]);

  function getBackTitle() {
    if (!showGetStarted && !verificationId) {
      return 'Back';
    }

    return 'Mobile no.';
  }

  function getBackDescription() {
    if (!showGetStarted && verificationId) {
      return phoneFormatted();
    }
  }

  function inputError() {
    return phoneNumberError || !!verificationError;
  }

  /* This is complicated because the first 'page' is a step
   * in useState, but the phone number and code verification
   * are determined by useSelector values
   */
  function handleBack() {
    if (!showGetStarted && verificationId) {
      dispatch(clearVerificationId());

      if (verificationCode) {
        setVerificationCode('');
        dispatch(clearVerificationError());
      }

      if (loginError) {
        dispatch(clearLoginError());
      }

      return;
    }

    setPhoneNumber('');
    setPhoneNumberError(false);
    setShowGetStarted(true);
  }

  return (
    <>
      <IonPage>
        {!showGetStarted && (
          <IonHeader>
            <HeaderBack
              title={getBackTitle()}
              description={getBackDescription()}
              onClick={handleBack}
            />
          </IonHeader>
        )}
        <IonContent>
          {showGetStarted && (
            <LoginGetStarted onClick={() => setShowGetStarted(false)} />
          )}

          {!showGetStarted && (
            <>
              {!verificationId && (
                <LoginVerification
                  title="Enter your mobile no."
                  description="We will SMS you a code to verify your account."
                  buttonTitle="Confirm"
                  onSubmit={() => requestCode()}
                  inputType="text"
                  inputMode="tel"
                  inputValue={phoneNumber}
                  inputPlaceholder="&#183;&#183;&#183;&#183;&nbsp;&#183;&#183;&#183;&nbsp;&#183;&#183;&#183;"
                  inputOnChange={setPhoneNumber}
                  inputError={inputError()}
                  inputErrorMessage={
                    verificationError ||
                    "That number isn't quite right.\n" +
                      "Please enter a mobile no. beginning with '04' or '+614'."
                  }
                />
              )}
              {verificationId && (
                <LoginVerification
                  title="Enter your code"
                  description={`We've texted a six-digit code to ${phoneFormatted()}`}
                  buttonTitle="Confirm"
                  onSubmit={() => login()}
                  inputType="text"
                  inputMode="numeric"
                  inputValue={verificationCode}
                  inputPlaceholder="&#183;&#183;&#183;&#183;&#183;&#183;"
                  inputOnChange={setVerificationCode}
                  inputError={!!loginError}
                  inputErrorMessage={
                    "That number isn't quite right.\n" +
                    'Wait a moment and try again.'
                  }
                  autocomplete="one-time-code"
                />
              )}
            </>
          )}
          {mustUseVerifier && (
            <div className="pt-1 px-2">
              <p className="py-1">
                You must solve the captcha below to continue within the web
                version of this app.
              </p>
              <p>
                <strong>
                  This limitation does not exist in the mobile app
                </strong>
              </p>
              <div ref={recaptchaRef} />
            </div>
          )}
        </IonContent>
        <Loader
          isEnabled={isLoadingVerification}
          title="Sending SMS"
          isSpinnerEnabled
        />
        <Loader
          isEnabled={isLoadingLogin}
          title="Authenticating"
          isSpinnerEnabled
        />
      </IonPage>
    </>
  );
};

export default Login;
