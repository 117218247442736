import { Action } from 'redux';
import { DeliveryOption } from '../../../services/fulfillment/estimate';
import Address from '../../../models/Address';
import { DeliveryMethodType } from '../../../types';

export interface GoogleAddress {
  address_components: object[];
  formatted_address: string;
  geometry?: object;
  place_id?: string;
  types?: string[];
}

export interface CheckoutState {
  deliveryMethod?: DeliveryMethodType;
  deliveryTimeframe?: DeliveryTimeframeType;
  locationAddress?: Address;
  assistedDelivery: boolean;
  constructionSite: boolean;
  callOnArrival: boolean;
  showAllStores?: boolean;
  deliveryNotes?: string;
  orderReference?: string;
  skipLocation?: string;
}
/*
PRIORITY_PICKUP - 24hours - 30min brackets
PRIORITY_DELIVERY - 24hours - 30min brackets

REGULAR_PICKUP - 7days, 2 brackets per day
REGULAR_DELIVERY - 7days, 2 brackets per day

AFTER_HOURS_PICKUP - 7days, 5pm - 7am
 */

export type DeliveryTimeframeType = DeliveryOption;

export enum DeliveryMethodGroupType {
  PICKUP = 'pickup',
  LOCKER = 'locker',
  DELIVERY = 'delivery',
}

export enum CheckoutActionType {
  APPLY_DELIVERY_METHOD = 'APPLY_DELIVERY_METHOD',
  APPLY_DELIVERY_TIMEFRAME = 'APPLY_DELIVERY_TIMEFRAME',
  APPLY_LOCATION_ADDRESS = 'APPLY_LOCATION_ADDRESS',
  APPLY_ASSISTED_DELIVERY = 'APPLY_ASSISTED_DELIVERY',
  APPLY_CONSTRUCTION_SITE = 'APPLY_CONSTRUCTION_SITE',
  APPLY_CALL_ON_ARRIVAL = 'APPLY_CALL_ON_ARRIVALS',
  APPLY_DELIVERY_NOTES = 'APPLY_DELIVERY_NOTES',
  APPLY_ORDER_REFERENCE = 'APPLY_ORDER_REFERENCE',
  APPLY_SKIP_LOCATION = 'APPLY_SKIP_LOCATION',
  APPLY_SHOW_ALL_STORES = 'APPLY_SHOW_ALL_STORES',
  CLEAR_DELIVERY_METHOD = 'CLEAR_DELIVERY_METHOD',
  CLEAR_DELIVERY_TIMEFRAME = 'CLEAR_DELIVERY_TIMEFRAME',
  CLEAR_LOCATION_ADDRESS = 'CLEAR_LOCATION_ADDRESS',
  CLEAR_ASSISTED_DELIVERY = 'CLEAR_ASSISTED_DELIVERY',
  CLEAR_CONSTRUCTION_SITE = 'CLEAR_CONSTRUCTION_SITE',
  CLEAR_CALL_ON_ARRIVAL = 'CLEAR_CALL_ON_ARRIVALS',
  CLEAR_DELIVERY_NOTES = 'CLEAR_DELIVERY_NOTES',
  CLEAR_ORDER_REFERENCE = 'CLEAR_ORDER_REFERENCE',
  CLEAR_SKIP_LOCATION = 'CLEAR_SKIP_LOCATION',
  CLEAR_SHOW_ALL_STORES = 'CLEAR_SHOW_ALL_STORES',
  CLEAR_CHECKOUT = 'CLEAR_CHECKOUT',
}

export interface ApplyDeliveryMethodAction extends Action {
  type: CheckoutActionType.APPLY_DELIVERY_METHOD;
  payload: Pick<CheckoutState, 'deliveryMethod'>;
}

export interface ApplyDeliveryTimeframeAction extends Action {
  type: CheckoutActionType.APPLY_DELIVERY_TIMEFRAME;
  payload: Pick<CheckoutState, 'deliveryTimeframe'>;
}

export interface ApplyLocationAddressAction extends Action {
  type: CheckoutActionType.APPLY_LOCATION_ADDRESS;
  payload: Pick<CheckoutState, 'locationAddress'>;
}

export interface ApplyAssistedDeliveryAction extends Action {
  type: CheckoutActionType.APPLY_ASSISTED_DELIVERY;
  payload: Pick<CheckoutState, 'assistedDelivery'>;
}

export interface ApplyConstructionSiteAction extends Action {
  type: CheckoutActionType.APPLY_CONSTRUCTION_SITE;
  payload: Pick<CheckoutState, 'constructionSite'>;
}

export interface ApplyCallOnArrivalAction extends Action {
  type: CheckoutActionType.APPLY_CALL_ON_ARRIVAL;
  payload: Pick<CheckoutState, 'callOnArrival'>;
}

export interface ApplyDeliveryNotesAction extends Action {
  type: CheckoutActionType.APPLY_DELIVERY_NOTES;
  payload: Pick<CheckoutState, 'deliveryNotes'>;
}

export interface ApplyOrderReferenceAction extends Action {
  type: CheckoutActionType.APPLY_ORDER_REFERENCE;
  payload: Pick<CheckoutState, 'orderReference'>;
}

export interface ApplySkipLocationAction extends Action {
  type: CheckoutActionType.APPLY_SKIP_LOCATION;
  payload: Pick<CheckoutState, 'skipLocation'>;
}

export interface ApplyShowAllStoresAction extends Action {
  type: CheckoutActionType.APPLY_SHOW_ALL_STORES;
  payload: Pick<CheckoutState, 'showAllStores'>;
}

export interface ClearDeliveryMethodAction extends Action {
  type: CheckoutActionType.CLEAR_DELIVERY_METHOD;
}

export interface ClearDeliveryTimeframeAction extends Action {
  type: CheckoutActionType.CLEAR_DELIVERY_TIMEFRAME;
}

export interface ClearLocationAddressAction extends Action {
  type: CheckoutActionType.CLEAR_LOCATION_ADDRESS;
}

export interface ClearAssistedDeliveryAction extends Action {
  type: CheckoutActionType.CLEAR_ASSISTED_DELIVERY;
}

export interface ClearConstructionSiteAction extends Action {
  type: CheckoutActionType.CLEAR_CONSTRUCTION_SITE;
}

export interface ClearCallOnArrivalAction extends Action {
  type: CheckoutActionType.CLEAR_CALL_ON_ARRIVAL;
}

export interface ClearDeliveryNotesAction extends Action {
  type: CheckoutActionType.CLEAR_DELIVERY_NOTES;
}

export interface ClearOrderReferenceAction extends Action {
  type: CheckoutActionType.CLEAR_ORDER_REFERENCE;
}

export interface ClearSkipLocationAction extends Action {
  type: CheckoutActionType.CLEAR_SKIP_LOCATION;
}

export interface ClearShowAllStoresAction extends Action {
  type: CheckoutActionType.CLEAR_SHOW_ALL_STORES;
}

export interface ClearCheckoutAction extends Action {
  type: CheckoutActionType.CLEAR_CHECKOUT;
}

export type CheckoutActionTypes =
  | ApplyDeliveryMethodAction
  | ApplyDeliveryTimeframeAction
  | ApplyLocationAddressAction
  | ApplyAssistedDeliveryAction
  | ApplyConstructionSiteAction
  | ApplyCallOnArrivalAction
  | ApplyDeliveryNotesAction
  | ApplyOrderReferenceAction
  | ApplySkipLocationAction
  | ApplyShowAllStoresAction
  | ClearDeliveryMethodAction
  | ClearDeliveryTimeframeAction
  | ClearLocationAddressAction
  | ClearAssistedDeliveryAction
  | ClearConstructionSiteAction
  | ClearCallOnArrivalAction
  | ClearDeliveryNotesAction
  | ClearOrderReferenceAction
  | ClearSkipLocationAction
  | ClearShowAllStoresAction
  | ClearCheckoutAction;
