import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import HeaderBack from '../../components/header/HeaderBack';
import HeaderTitle from '../../components/header/HeaderTitle';
import Button from '../../components/ui/Button';
import StandardConditionsOfSale from '../../components/terms/StandardConditionsOfSale';
import FooterCapContainer from '../../components/ui/FooterCapContainer';

const StandardConditionsOfSalePage = () => {
  const history = useHistory();
  function onBackClick() {
    history.goBack();
  }
  return (
    <IonPage>
      <IonHeader>
        <HeaderBack title="Back" description="Settings" onClick={onBackClick} />
        <HeaderTitle
          title="Standard conditions of sale - Online retail store"
          borderColour="#e2e8f0"
        />
      </IonHeader>
      <IonContent>
        <StandardConditionsOfSale />
        <FooterCapContainer>
          <Button onClick={() => onBackClick()}>Back to settings</Button>
        </FooterCapContainer>
      </IonContent>
    </IonPage>
  );
};

export default StandardConditionsOfSalePage;
