import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import OrderStatus from '../../components/order/OrderStatus';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import HeaderTitle from '../../components/header/HeaderTitle';
import HeaderBack from '../../components/header/HeaderBack';
import { useSelector } from 'react-redux';
import { selectCurrentOrder } from '../../store/selectors';
import { selectedTimeLabel } from '../../utils/text';
import {
  getOrderDeliveryMethod,
  getOrderStatus,
  getAttribute,
  getOrderDeliveryFullTimeLabel,
  isCancelled,
  isFulfilled,
  getOrderStatusDescription,
} from '../../utils/order';
import { DeliveryMethodType } from '../../types';

const OrderStatusPage = (props) => {
  const history = useHistory();
  const order = useSelector(selectCurrentOrder);
  const [deliveryTimeTitle, setDeliveryTimeTitle] = useState(
    DeliveryMethodType.REGULAR_DELIVERY
  );

  const orderNumber = order ? String(order.order_number) : null;
  const orderStatus = order ? getOrderStatus(order) : null;
  const deliveryMethodLabel = order
    ? getAttribute(order.note_attributes, 'Delivery Method')
    : null;

  useEffect(() => {
    if (!order) {
      return;
    }

    let label = '';
    if (!isCancelled(order) && !isFulfilled(order)) {
      label = selectedTimeLabel(getOrderDeliveryMethod(order));
    }

    const timeLabel = getOrderDeliveryFullTimeLabel(order);

    setDeliveryTimeTitle(`${label} ${timeLabel}`);
  }, [order]);

  function determineButtonEnabled() {
    return false;
  }

  function handleBack() {
    history.push('/orders/detail');
  }

  function renderOrderStatus() {
    if (order) {
      return (
        <OrderStatus
          orderNumber={orderNumber}
          method={deliveryMethodLabel}
          time={deliveryTimeTitle}
          status={getOrderStatusDescription(orderStatus)}
          isButtonEnabled={determineButtonEnabled()}
        />
      );
    }

    return <OrderStatus status="Syncing data..." />;
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderTitle title={`Order ${orderNumber}`} borderColour="#e2e8f0" />
        <HeaderBack title="Order detail" onClick={handleBack} />
      </IonHeader>
      <IonContent>{renderOrderStatus()}</IonContent>
    </IonPage>
  );
};

export default OrderStatusPage;
