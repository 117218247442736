import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IonModal, IonTextarea } from '@ionic/react';
import OrderFeedbackOutline from '../order/OrderFeedbackOutline';
import { submitFeedback } from '../../utils/orderSurvey';
import { useFirebaseContext } from '../../services/firebase/context';
import { useAnalyticsContext } from '../../services/analytics/context';
import useOrderFeedbackRequired from '../../hooks/useOrderFeedbackRequired';
import { selectOrdersByIds } from '../../store/selectors';
import useTermsAccepted from '../../hooks/useTermsAccepted';
import useAuthenticated from '../../hooks/useAuthenticated';
import { getAttribute } from '../../utils/order';

const OrderFeedbackModal = (props) => {
  const firebase = useFirebaseContext();
  const analytics = useAnalyticsContext();
  const areTermsAccepted = useTermsAccepted();
  const isAuthenticated = useAuthenticated();
  const feedbackOrderId = useOrderFeedbackRequired();
  const ordersById = useSelector(selectOrdersByIds);
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState();
  const [rating, setRating] = useState();
  const [comment, setComment] = useState();

  useEffect(() => {
    const feedbackOrder = ordersById[feedbackOrderId];
    setOrder(feedbackOrder);

    if (!areTermsAccepted || !isAuthenticated) {
      setShowModal(false);
      return;
    }

    setShowModal(!!feedbackOrder);
  }, [feedbackOrderId, ordersById, areTermsAccepted, isAuthenticated]);

  function handleFeedbackSubmit(requestCallback) {
    firebase.updateUserData({
      orderFeedbackRequired: '',
    });

    // submit the feedback
    submitFeedback(firebase, order, rating, comment, requestCallback)
      .then(() => {
        analytics.trackEvent('Survey Submitted', {
          properties: {
            category: 'Order feedback',
            label: `Rating: ${rating}`,
            value: rating,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setRating(undefined);
        setComment(undefined);
      });
  }

  function renderOrderDetails() {
    if (order) {
      return (
        <p className="text-h3 text-center mt-1">
          Your last order {order.name}{' '}
          {getAttribute(order.note_attributes, 'Order Reference')}
        </p>
      );
    }
  }

  function renderRating() {
    return (
      <>
        <p className="text-h3 text-dulux-dark text-center mb-1">
          How would you rate
          <br />
          Dulux Trade Direct in terms
          <br />
          of ease of ordering?
        </p>
        <hr className="border-gray-600" />
        <OrderFeedbackOutline onClick={(r) => setRating(r)} />
        <hr className="border-gray-600" />
        {renderOrderDetails()}
      </>
    );
  }

  function renderComment() {
    return (
      <>
        <div className="h-full flex flex-col">
          <p className="text-h3 text-center mb-2">
            We&rsquo;d like to know more about your experience.
          </p>
          <IonTextarea
            className="w-full p-1 bg-white border border-gray-600 textarea-placeholder-center"
            rows="2"
            placeholder="Add your comments"
            autoGrow={false}
            autofocus={true}
            value={comment}
            maxlength={255}
            debounce={100}
            onIonChange={(e) => setComment(e.target.value)}
          />
          <span
            onClick={() => handleFeedbackSubmit(false)}
            className="text-dulux-primary text-h3 text-center mt-3"
          >
            Send feedback
          </span>
          <span
            onClick={() => handleFeedbackSubmit(true)}
            className="text-white text-h3 text-center absolute bottom-0 left-0 right-0 -mb-6"
          >
            Send and request a callback
          </span>
        </div>
      </>
    );
  }

  function renderContent() {
    return rating ? renderComment() : renderRating();
  }

  function getCssClass() {
    const baseClass = 'modal-feedback';

    return rating ? baseClass + ' modal-smallest' : baseClass;
  }

  return (
    <IonModal
      isOpen={showModal}
      backdropDismiss={false}
      cssClass={getCssClass()}
    >
      {renderContent()}
    </IonModal>
  );
};

export default OrderFeedbackModal;
