import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const RandomColour = () => {
  const colours = useSelector((s) => s.colours);

  const colourKeys = useMemo(() => Object.keys(colours.byId), [colours]);

  const colour =
    colours.byId[colourKeys[(colourKeys.length * Math.random()) | 0]];

  return (
    colour && (
      <>
        <p>{colour.colourName}</p>
        <p>{colour.hex}</p>
      </>
    )
  );
};

export default RandomColour;
