/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import {
  selectColourState,
  selectProductBaseQuery,
  selectProductNameQuery,
  selectProductQuery,
  selectProductRangeQuery,
  selectProductsById,
  selectProductSheenQuery,
  selectProductSizeQuery,
} from './root';
import { getColour, getColourIdsForBase } from '../reducers/colours/reducer';
import { selectDeliveryOrdersDesc } from './orders';
import {
  filterProducts,
  getAvailableProductProperties,
} from '../../utils/product';
import { find, uniqBy, reject, isNull } from 'lodash';
import { ColourId } from '../../types';

export const selectAvailableProducts = createSelector(
  [selectProductsById, selectProductQuery],
  (productsById, query) => {
    return filterProducts(productsById, query);
  }
);

export const selectAvailableSheens = createSelector(
  [
    selectAvailableProducts,
    selectProductRangeQuery,
    selectProductNameQuery,
    selectProductSheenQuery,
  ],
  (availableProducts, queryRange, queryName, querySheen) => {
    // Make sure a product name and range has been selected before processing
    if (queryName === undefined) {
      return [];
    }

    // If we've already selected a sheen then just return that
    if (querySheen) {
      return [querySheen];
    }

    return getAvailableProductProperties(availableProducts, 'sheen');
  }
);

export const selectEnrichedAvailableSheens = createSelector(
  [selectAvailableProducts, selectAvailableSheens],
  (availableProducts, availableSheens) => {
    return availableSheens.map((sheen) => {
      const product = find(availableProducts, { sheen });

      return {
        sheen,
        image: product && (product.sheenImage || null),
        colour: product && (product.sheenColour || null),
      };
    });
  }
);

export const selectAvailableBases = createSelector(
  [selectAvailableProducts, selectProductSheenQuery, selectProductBaseQuery],
  (availableProducts, querySheen, queryBase) => {
    // Make sure a sheen has been selected before processing
    if (querySheen === undefined) {
      return [];
    }

    // If we've already selected a base then just return that
    if (queryBase) {
      return [queryBase];
    }

    return getAvailableProductProperties(availableProducts, 'base');
  }
);

export const selectAvailableColourIds = createSelector(
  [selectColourState, selectAvailableBases],
  (colourState, availableBases) => {
    const ids = new Set();

    availableBases.forEach(function (base) {
      getColourIdsForBase(colourState, base).forEach((id) => {
        ids.add(id);
      });
    });

    return Array.from(ids) as ColourId[];
  }
);

export const selectAvailableColours = createSelector(
  [selectColourState, selectAvailableColourIds],
  (colourState, availableColourIds) => {
    return availableColourIds.map((colourId) =>
      getColour(colourState, colourId)
    );
  }
);

export const selectAvailableSizes = createSelector(
  [selectAvailableProducts, selectProductNameQuery, selectProductSizeQuery],
  (availableProducts, queryName, querySize) => {
    // Make sure a product name and range has been selected before processing
    if (queryName === undefined) {
      return [];
    }

    // If we've already selected a size then just return that
    if (querySize) {
      return [querySize];
    }

    return getAvailableProductProperties(availableProducts, 'size');
  }
);

/*
 * The popular colours have been ordered in the product import.
 * Default first 30 are the popular colours
 */
export const selectAvailablePopularColours = createSelector(
  [selectAvailableColours],
  (colours) => colours.slice(0, 30)
);

export const selectRecentDeliveryAddresses = createSelector(
  [selectDeliveryOrdersDesc],
  (orders) => {
    const result = orders.map((order: any) => {
      const address = order.shipping_address;

      if (!address) {
        return null;
      }

      const address2 = address.address2 ?? '';

      const formatted_address =
        `${address.address1}, ` +
        `${address2 ? `${address2} ` : ''}` +
        `${address.province_code}, ` +
        `${address.country}`;

      return {
        ...address,
        formatted_address,
        dateOrdered: order.created_at,
        latLon: `${address.latitude} ${address.longitude}`,
      };
    });

    return uniqBy(reject(result, isNull), 'latLon');
  }
);
