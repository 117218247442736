import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import HeaderTitle from '../components/header/HeaderTitle';
import TermsAndConditionsOfUse from '../components/terms/TermsAndConditionsOfUse';

const Terms = () => {
  const targetScrollPercent = 85; // 100% would require scrolling past the bottom
  const [scrolledDepthTriggered, setScrolledDepthTriggered] = useState(false);

  function scrolledToEnd(event) {
    if (scrolledDepthTriggered) {
      return;
    }

    event.target.getScrollElement().then((element) => {
      const scrollHeight = element.scrollHeight - element.clientHeight;
      const currentScrollDepth = event.detail.scrollTop;
      const triggerDepth = (scrollHeight / 100) * targetScrollPercent;

      if (currentScrollDepth > triggerDepth) {
        setScrolledDepthTriggered(true);
      }
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <HeaderTitle
          title="Terms and conditions of use"
          borderColour="#e2e8f0"
        />
      </IonHeader>
      <IonContent scrollEvents="true" onIonScroll={(e) => scrolledToEnd(e)}>
        <TermsAndConditionsOfUse
          showButton={true}
          isButtonEnabled={scrolledDepthTriggered}
        />
      </IonContent>
    </IonPage>
  );
};

export default Terms;
