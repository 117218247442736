import { createSelector } from 'reselect';
import { getColour } from '../reducers/colours/reducer';
import { selectColourState, selectSelectedColourId } from './root';

export const selectSelectedColour = createSelector(
  [selectColourState, selectSelectedColourId],
  (colourState, selectedColourId) => {
    if (!selectedColourId) {
      return null;
    }

    return getColour(colourState, selectedColourId);
  }
);
