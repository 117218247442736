module.exports = {
  apiKey: 'AIzaSyC92SY1JYFl1fmhuMS4BzIHakYZpNItw1U',
  authDomain: 'dulux-trade-direct.firebaseapp.com',
  databaseURL: 'https://dulux-trade-direct.firebaseio.com',
  projectId: 'dulux-trade-direct',
  storageBucket: 'dulux-trade-direct.appspot.com',
  messagingSenderId: '535719649350',
  appId: '1:535719649350:web:673a6f1c2aa34c57176a47',
  measurementId: 'G-2P8N55NFZV',
};
