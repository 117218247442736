import React from 'react';
import { IonPage } from '@ionic/react';
import Loader from '../components/ui/Loader';

const VerificationHolding = () => {
  return (
    <IonPage>
      <Loader title="Verifying" isEnabled={true} isSpinnerEnabled={true} />
    </IonPage>
  );
};

export default VerificationHolding;
