import * as firebase from 'firebase/app';
import Firebase from '../firebase';
import { receiveRawProducts } from '../../../store/actions';
import { getObject, removeObject, setObject } from '../../../utils/storage';
import ProductData from '../../../data/backup/products.json';
import { buildIsMaster } from '../../../utils/environment';

const STORAGE_KEY = 'FIREBASE_PRODUCTS';

const PRODUCTION_PRODUCTS = '/products';
const MASTER_PRODUCTS = '/env/master/products';

export default class FirebaseProducts {
  private firebase: Firebase;

  private readonly productRef: firebase.database.Reference;
  private readonly onSubscribeListener: any;

  constructor(fb: Firebase) {
    this.firebase = fb;
    this.productRef = this.firebase.db.ref(this.getProductRefString());
    this.onSubscribeListener = this.onSubscribe.bind(this);
  }

  getProductRefString() {
    if (buildIsMaster) {
      return MASTER_PRODUCTS;
    }

    return PRODUCTION_PRODUCTS;
  }

  boot() {
    this.subscribe();
  }

  async loadBackupProducts() {
    let imported = false;

    try {
      const products = await getObject(STORAGE_KEY);

      if (products) {
        this.importProducts(products);
        imported = true;
      }
    } catch (e) {
      console.error('Error importing backup products', e);
      removeObject(STORAGE_KEY); // Clean up since the import failed
    }

    // Fallback to the local JSON
    if (!imported) {
      this.importProducts(ProductData);
    }
  }

  setBackupProducts(products: any[]) {
    return setObject(STORAGE_KEY, products);
  }

  subscribe() {
    if (this.productRef) {
      this.productRef.off('value', this.onSubscribeListener);
    }

    // Make sure we load the back products before we attempt to fetch updated ones
    // Since it all happens async, we don't want the backup to come in after the network and overwrite
    this.loadBackupProducts().finally(() => {
      this.productRef.on('value', this.onSubscribeListener);
    });
  }

  onSubscribe(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      return;
    }

    try {
      const products = Object.values(snapshot.val());

      this.importProducts(products);
      this.setBackupProducts(products);
    } catch (e) {
      console.error('Error importing products');
      this.loadBackupProducts();
    }
  }

  importProducts(products: any[]) {
    this.firebase.dispatch(receiveRawProducts(products));
  }
}
