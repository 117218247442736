import { ProductActionType } from '../reducers/products/types';
import { Product } from '../../types';

export const receiveRawProducts = (products: any[]) => ({
  type: ProductActionType.RECEIVE_RAW,
  payload: { products },
});

export const receiveProducts = (products: Product[]) => ({
  type: ProductActionType.RECEIVE,
  payload: { products },
});
