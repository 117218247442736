import axios from 'axios';

const API_KEY = 'b21f0bfcf3b75853b787c6a269a0ad8c';

// https://openweathermap.org/weather-conditions
interface WeatherItem {
  id: number; // Weather condition id
  main: string; // Group of weather parameters (Rain, Snow, Extreme etc.)
  description: string; // Weather condition within the group
  icon: string; // Weather icon id
}

export interface CurrentWeatherResponse {
  weather: WeatherItem[];
  main: {
    temp: number; // Temperature. Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    feels_like: number; // Temperature. This temperature parameter accounts for the human perception of weather. Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    pressure: number; // Atmospheric pressure (on the sea level, if there is no sea_level or grnd_level data), hPa
    humidity: number; // Humidity, %
    temp_min: number; // Minimum temperature at the moment. This is deviation from current temp that is possible for large cities and megalopolises geographically expanded (use these parameter optionally). Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    temp_max: number; // Maximum temperature at the moment. This is deviation from current temp that is possible for large cities and megalopolises geographically expanded (use these parameter optionally). Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    sea_level: number; // Atmospheric pressure on the sea level, hPa
    grnd_level: number; // Atmospheric pressure on the ground level, hPa
  };
  wind: {
    speed: number; // Wind speed, meter/sec
    deg: number; // Wind direction, degrees
  };
  clouds: {
    all: number; // Cloudiness, %
  };
  rain: {
    '1h': number; // Rain volume for the last 1 hour, mm
    '3h': number; // Rain volume for the last 3 hours, mm
  };
  snow: {
    '1h': number; // Snow volume for the last 1 hour, mm
    '3h': number; // Snow volume for the last 3 hours, mm
  };
  dt: number; // Time of data calculation, unix, UTC
  timezone: number; //S hift in seconds from UTC
  id: number; // City ID
  name: string; // City name
}

export function getCurrentWeather(location = 'Melbourne,Victoria,Australia') {
  return axios.get<CurrentWeatherResponse>(
    `https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=${API_KEY}&units=metric`
  );
}
