import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fuzzysort from 'fuzzysort';
import { concat, pickBy, flatMap, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { selectAvailableColours } from '../../store/selectors';
import Search from '../../components/ui/Search';
import ProductColour from '../product/ProductColour';
import DuluxAuthenticColourLogo from '../../assets/images/brand/dulux-authentic-colour@2x.png';

const ColourSearch = (props) => {
  const [search, setSearch] = useState('');
  const colours = useSelector(selectAvailableColours);
  const debouncedQuery = useDebounce(search, 200);
  const [duluxResults, setDuluxResults] = useState([]);
  const [otherResults, setOtherResults] = useState([]);
  // const [isSearching, setIsSearching] = useState(false);

  const renderPopular = () => {
    return props.popularColours.map((colour) => (
      <ProductColour
        key={colour.colourId}
        onColourClick={props.onColourSearchItemClick}
        onColourConfirmClick={props.onColourConfirmClick}
        colourId={colour.colourId}
        hasStrengthOptions={colour.strengths || false}
        hex={colour.hex}
        colourName={colour.colourName}
        atlasCode={colour.atlasCode}
        renderBrand={() => props.renderBrand(colour.productBrand, colour.hex)}
        colourStrengthEnabled={props.searchColourStrengthEnabled}
        setColourStrengthEnabled={props.setSearchColourStrengthEnabled}
        colourIdSelected={props.searchColourIdSelected}
        setColourIdSelected={props.setSearchColourIdSelected}
      />
    ));
  };

  useEffect(() => {
    const searchPromise = fuzzysort.goAsync(debouncedQuery, colours, {
      keys: ['colourName', 'atlasCode'],

      threshold: -50, // Don't return bad results
      limit: props.resultLimit, // Don't return more results than needed
      allowTypo: true,
    });

    searchPromise
      .then((searchResult) => {
        // There can be duplicates in a search result for some reason
        // unique by the colourId
        const result = uniqBy(searchResult, 'obj.id');

        const withinThreshold = result.filter(
          (colour) => colour.score >= props.duluxThreshold
        );

        const outsideThreshold = result.filter(
          (colour) => colour.score < props.duluxThreshold
        );

        let duluxColours = pickBy(withinThreshold, (colour) => {
          return colour.obj.colourBrand === 'DULUX';
        });

        duluxColours = flatMap(duluxColours, (colour) => [colour]);
        setDuluxResults(duluxColours);

        let otherColours = pickBy(withinThreshold, (colour) => {
          return colour.obj.colourBrand !== 'DULUX';
        });

        otherColours = concat(
          flatMap(otherColours, (colour) => [colour]),
          outsideThreshold
        );

        setOtherResults(otherColours);
      })
      .finally(() => {
        // setIsSearching(false);
      });

    // Cancel the search if the input changes
    return () => {
      searchPromise.cancel();
    };
  }, [debouncedQuery, colours, props.duluxThreshold, props.resultLimit]);

  function renderProducts(dataset) {
    return dataset.map((result, key) => (
      <ProductColour
        key={`${key}-${result.obj.colourId}`}
        onColourClick={props.onColourSearchItemClick}
        onColourConfirmClick={props.onColourConfirmClick}
        colourId={result.obj.colourId}
        hasStrengthOptions={result.obj.strengths || false}
        hex={result.obj.hex}
        colourName={result.obj.colourName}
        atlasCode={result.obj.atlasCode}
        renderBrand={() =>
          props.renderBrand(result.obj.productBrand, result.obj.hex)
        }
        colourStrengthEnabled={props.searchColourStrengthEnabled}
        setColourStrengthEnabled={props.setSearchColourStrengthEnabled}
        colourIdSelected={props.searchColourIdSelected}
        setColourIdSelected={props.setSearchColourIdSelected}
      />
    ));
  }

  function renderResults() {
    const duluxProducts = renderProducts(duluxResults);
    const otherProducts = renderProducts(otherResults);
    let duluxFragment;
    let otherFragment;

    if (duluxResults.length) {
      duluxFragment = (
        <React.Fragment key="duluxColoursBlock">
          {!props.searchColourStrengthEnabled && (
            <div className="flex items-center p-1">
              <div className="w-8/12">
                <h2 className="font-heading text-dulux-dark text-h3">
                  Dulux Authentic Colour
                </h2>
                <p className="text-small my-1">
                  Only Dulux pigments can produce the iconic range of colours
                  you see here.
                </p>
                <p className="text-dulux-primary">
                  {duluxResults.length} Results
                </p>
              </div>
              <div className="w-4/12">
                <img
                  src={DuluxAuthenticColourLogo}
                  alt="Dulux Authentic Colour"
                />
              </div>
            </div>
          )}
          {duluxProducts}
        </React.Fragment>
      );
    }

    if (otherResults.length) {
      otherFragment = (
        <React.Fragment key="otherColoursBlock">
          {!props.searchColourStrengthEnabled && (
            <div className="p-1">
              <h2 className="font-heading text-dulux-dark text-h3">
                Other results
              </h2>
              <p className="text-dulux-primary mt-1">
                {otherResults.length} Results
              </p>
            </div>
          )}
          {otherProducts}
        </React.Fragment>
      );
    }

    return [duluxFragment, otherFragment];
  }

  function handleOnSearch(value) {
    // setIsSearching(true);
    setSearch(value);
  }

  function render() {
    if (search.length === 0) {
      return renderPopular();
    }

    if (duluxResults.length || otherResults.length) {
      return renderResults();
    }

    return (
      <div className="p-2">
        We didn&rsquo;t find a match for your search terms. Check the spelling
        or try using fewer words.
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {props.searchEnabled && (
        <div
          className={
            'overflow-scroll ' +
            (props.searchColourStrengthEnabled ? 'h-full' : '')
          }
        >
          {render()}
        </div>
      )}
      {!props.searchColourStrengthEnabled && props.searchEnabled && (
        <div className="mt-auto">
          <Search
            placeholder="Find a colour"
            value={search}
            onChange={(e) => handleOnSearch(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

ColourSearch.propTypes = {
  onColourSearchItemClick: PropTypes.func.isRequired,
  onColourConfirmClick: PropTypes.func.isRequired,
  searchColourIdSelected: PropTypes.string,
  setSearchColourIdSelected: PropTypes.func,
  searchColourStrengthEnabled: PropTypes.bool,
  setSearchColourStrengthEnabled: PropTypes.func,
  popularColours: PropTypes.array.isRequired,
  renderBrand: PropTypes.func.isRequired,
  duluxThreshold: PropTypes.number,
  searchEnabled: PropTypes.bool,
  resultLimit: PropTypes.number,
};

ColourSearch.defaultProps = {
  duluxThreshold: -25,
  resultLimit: 20,
  renderBrand: null,
  searchEnabled: null,
  searchColourIdSelected: '',
  setSearchColourIdSelected: null,
  searchColourStrengthEnabled: null,
  setSearchColourStrengthEnabled: null,
};

export default ColourSearch;
