import {
  ApplySupportCategoryAction,
  ApplySupportCurrentQueryAction,
  ClearSupportAction,
  ClearSupportCategoryAction,
  ClearSupportCurrentQueryAction,
  SupportActionType,
  SupportState,
  Support,
  ReceiveDataAction,
} from '../reducers/support/types';

export const receiveData = (supportData: Support[]): ReceiveDataAction => {
  return {
    type: SupportActionType.RECEIVE_DATA,
    payload: { categories: supportData },
  };
};

export const selectSupportCategory = (
  currentCategory: SupportState['currentCategory']
): ApplySupportCategoryAction => {
  return {
    type: SupportActionType.APPLY_CURRENT_CATEGORY,
    payload: { currentCategory },
  };
};

export const selectSupportCurrentQuery = (
  currentQuery: SupportState['currentQuery']
): ApplySupportCurrentQueryAction => {
  return {
    type: SupportActionType.APPLY_CURRENT_QUERY,
    payload: { currentQuery },
  };
};

export const clearSupportCategory = (): ClearSupportCategoryAction => ({
  type: SupportActionType.CLEAR_CURRENT_CATEGORY,
});

export const clearSupportCurrentQuery = (): ClearSupportCurrentQueryAction => ({
  type: SupportActionType.CLEAR_CURRENT_QUERY,
});

export const clearSupport = (): ClearSupportAction => ({
  type: SupportActionType.CLEAR_SUPPORT,
});
