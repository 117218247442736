import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SettingsIcon } from '../../assets/icons/32/settings.svg';
import contrastingColour from '../../utils/colour';

const HomeSummary = (props) => {
  return (
    <>
      <div
        className="home-summary-top transition-colors"
        style={{
          backgroundColor: props.backgroundColour,
          marginBottom: '-1px',
        }}
      />
      <div
        className="p-1 pt-2 flex flex-col pb-4 transition-colors"
        style={{
          backgroundColor: props.backgroundColour,
          height: 'calc(100% - 6rem)',
        }}
      >
        <div className="flex">
          <div className="w-8/12 flex flex-col justify-between">
            {props.temperature && (
              <div
                className="font-heading transition-colors"
                style={{
                  fontSize: '6rem',
                  lineHeight: '0.8',
                  color: contrastingColour(props.backgroundColour),
                }}
              >
                {props.temperature}&deg;
              </div>
            )}
            {props.humidity && (
              <div
                className="font-heading text-h3 mt-1 transition-colors"
                style={{
                  color: contrastingColour(props.backgroundColour),
                }}
              >
                {props.humidity}% humidity
              </div>
            )}
          </div>
          <div className="w-4/12">
            <div
              className="border pb-100 rounded relative rounded-bl-none transition-colors"
              style={{
                borderColor: contrastingColour(props.backgroundColour),
              }}
            >
              <div className="absolute top-0 bottom-0 left-0 right-0 p-1 flex flex-col justify-end transition-colors">
                <div
                  style={{
                    color: contrastingColour(props.backgroundColour),
                  }}
                >
                  {props.colourLogo && props.colourLogo}
                </div>
                {props.colourName && (
                  <div
                    className="font-heading text-h3 transition-colors"
                    style={{
                      color: contrastingColour(props.backgroundColour),
                    }}
                  >
                    {props.colourName}
                  </div>
                )}
              </div>
              <div
                className="w-4 h-4 border rounded absolute bottom-0 left-0 rounded-tr-none transition-colors"
                style={{
                  transform: 'translate(-100%, 100%)',
                  borderColor: contrastingColour(props.backgroundColour),
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-between mt-auto border-b pb-2 mb-2 transition-colors"
          style={{
            borderColor: contrastingColour(props.backgroundColour),
          }}
        >
          {props.firstName && (
            <h2
              className="font-heading text-h1 transition-colors"
              style={{
                color: contrastingColour(props.backgroundColour),
              }}
            >
              Hi, {props.firstName}.
            </h2>
          )}
          {props.settingsOnClick && (
            <SettingsIcon
              className="transition-colors w-5 h-5"
              style={{
                color: contrastingColour(props.backgroundColour),
              }}
              onClick={() => props.settingsOnClick()}
            />
          )}
        </div>
      </div>
    </>
  );
};

HomeSummary.propTypes = {
  backgroundColour: PropTypes.string,
  temperature: PropTypes.number,
  humidity: PropTypes.number,
  colourName: PropTypes.string,
  colourLogo: PropTypes.node,
  firstName: PropTypes.string,
  settingsOnClick: PropTypes.func,
};

HomeSummary.defaultProps = {
  backgroundColour: '#FFFFFF',
  temperature: null,
  humidity: null,
  colourName: '',
  colourLogo: null,
  firstName: '',
  settingsOnClick: null,
};

export default HomeSummary;
