import React from 'react';
import PropTypes from 'prop-types';
import { getStreetViewImageUrl } from '../../services/google/street-view';
import contrastingColour from '../../utils/colour';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ReactComponent as TimeIcon } from '../../assets/icons/20/time.svg';

const GoogleMapSearchItem = (props) => {
  function renderDateLastUsed(dateString) {
    if (!dateString) {
      return;
    }

    const timeDifference = formatDistanceToNow(parseISO(dateString)).replace(
      'about ',
      ''
    );

    return (
      <div className="flex items-center">
        {timeDifference}
        <TimeIcon className="ml-1" />
      </div>
    );
  }

  return (
    <div className="flex border-b" onClick={props.onClick} {...props.itemProps}>
      <div className="w-3/12 flex items-center">
        <img
          src={getStreetViewImageUrl(props.description)}
          width={400}
          height={400}
          alt={`Street view of ${props.description}`}
        />
      </div>
      <div
        className={'p-1 flex flex-col justify-center w-9/12'}
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        <div className="py-1">
          <span
            className="text-h3 font-heading leading-h3 text-dulux-dark"
            style={{
              color: contrastingColour('#FFFFFF'),
            }}
          >
            {props.label}
          </span>
        </div>

        {renderDateLastUsed(props.dateOrdered)}
      </div>
    </div>
  );
};

GoogleMapSearchItem.propTypes = {
  itemProps: PropTypes.object,
  description: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  dateOrdered: PropTypes.string,
};

GoogleMapSearchItem.defaultProps = {
  itemProps: null,
  onClick: null,
  dateOrdered: null,
};

export default GoogleMapSearchItem;
