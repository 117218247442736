import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { contrastingColour } from '../../utils/colour';

import { ReactComponent as UpArrowIcon } from '../../assets/icons/20/arrow-up.svg';

const HeaderBack = (props) => {
  const history = useHistory();

  function handleClick() {
    if (props.onClick) {
      return props.onClick();
    } else {
      return history.goBack();
    }
  }

  return (
    <div
      onClick={handleClick}
      className="text-white py-1 px-1 flex flex-wrap items-center justify-between"
      style={{
        color: contrastingColour(props.backgroundColour),
        backgroundColor: props.backgroundColour,
      }}
    >
      {props.description && (
        <p className="w-6/12 text-small pr-1 text-overflow-ellipsis whitespace-no-wrap overflow-hidden">
          {props.description}
        </p>
      )}

      <div
        className={
          'flex flex-wrap items-center ' + (!props.description ? 'ml-auto' : '')
        }
      >
        {props.title && <p className="mr-1 text-small">{props.title}</p>}
        <UpArrowIcon />
      </div>
    </div>
  );
};

HeaderBack.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColour: PropTypes.string,
  onClick: PropTypes.func,
};

HeaderBack.defaultProps = {
  title: '',
  description: '',
  backgroundColour: '#EDF2F7',
};

export default HeaderBack;
