import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  function themeClass() {
    switch (props.theme) {
      case 'primary':
        return 'bg-dulux-primary text-dulux-white hover:bg-dulux-primary-pressed active:bg-dulux-primary-pressed focus:bg-dulux-primary-pressed icon-dulux-white';
      case 'dark':
        return 'bg-dulux-dark text-dulux-white hover:bg-dulux-primary-pressed active:bg-dulux-primary-pressed focus:bg-dulux-primary-pressed icon-dulux-white';
      case 'light':
        return 'bg-white text-dulux-dark hover:bg-dulux-light-pressed active:bg-dulux-light-pressed focus:bg-dulux-light-pressed icon-dulux-primary';
      case 'success':
        return 'bg-dulux-success text-dulux-dark hover:bg-dulux-success-pressed active:bg-dulux-success-pressed focus:bg-dulux-success-pressed icon-dulux-blue';
      case 'error':
        return 'bg-dulux-error text-dulux-white hover:bg-dulux-error-pressed active:bg-dulux-error-pressed focus:bg-dulux-error-pressed icon-dulux-white';
      case 'error-ghost':
        return 'bg-dulux-white border-solid border text-dulux-error border-dulux-error icon-dulux-white';
      case 'ghost':
        return 'text-dulux-primary border-solid border border-dulux-primary hover:bg-dulux-primary-pressed active:bg-dulux-primary-pressed focus:bg-dulux-primary-pressed hover:text-dulux-white active:text-dulux-white focus:text-dulux-white hover:border-dulux-primary-pressed active:border-dulux-primary-pressed focus:border-dulux-primary-pressed icon-dulux-primary hover:icon-dulux-white focus:icon-dulux-white active:icon-dulux-white';
      case 'ghost-light':
        return 'text-dulux-white border-solid border border-dulux-white hover:bg-dulux-white active:bg-dulux-white focus:bg-dulux-white hover:text-dulux-dark active:text-dulux-dark focus:text-dulux-dark hover:border-dulux-white active:border-dulux-white focus:border-dulux-white icon-dulux-white hover:icon-dulux-blue focus:icon-dulux-blue active:icon-dulux-blue';
      case 'light-dark':
        return 'bg-dulux-white text-dulux-dark hover:bg-dulux-light-pressed active:bg-dulux-light-pressed focus:bg-dulux-light-pressed icon-dulux-dark';
      default:
        return 'bg-dulux-primary text-dulux-white hover:bg-dulux-primary-pressed active:bg-dulux-primary-pressed focus:bg-dulux-primary-pressed icon-dulux-white';
    }
  }
  function disabledThemeClass() {
    switch (props.theme) {
      case 'primary':
        return 'bg-dulux-field-disabled text-dulux-white ';
      case 'light':
        return 'text-dulux-field-disabled ';
      case 'success':
        return 'bg-dulux-field-disabled text-dulux-white ';
      case 'error':
      case 'error-ghost':
        return 'bg-dulux-field-disabled text-dulux-white ';
      case 'ghost':
      case 'ghost-light':
        return 'border-solid border border-dulux-field-disabled text-dulux-field-disabled ';
      default:
        return 'bg-dulux-field-disabled text-dulux-white ';
    }
  }

  function alignmentClass() {
    switch (props.align) {
      case 'left':
        return ' justify-start ';
      case 'right':
        return ' justify-end ';
      default:
        return ' justify-center ';
    }
  }

  function roundedClass() {
    return props.rounded ? ' rounded-xl ' : '';
  }

  function heightClass() {
    return props.tall ? ' h-9 ' : ' h-7 ';
  }

  if (props.url) {
    return (
      <a
        id={props.id}
        title={props.title}
        className={
          props.disabled
            ? 'w-full px-2 text-body-large flex items-center ' +
              disabledThemeClass() +
              roundedClass() +
              alignmentClass() +
              heightClass()
            : 'w-full px-2 text-body-large flex items-center ' +
              themeClass() +
              roundedClass() +
              alignmentClass() +
              heightClass()
        }
        href={props.url}
        target={props.target}
        onClick={props.onClick}
        style={{ minHeight: '56px' }}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      id={props.id}
      title={props.title}
      type={props.type}
      className={
        props.disabled
          ? 'w-full px-2 text-body-large flex items-center ' +
            disabledThemeClass() +
            roundedClass() +
            alignmentClass() +
            heightClass()
          : 'w-full px-2 text-body-large flex items-center ' +
            themeClass() +
            roundedClass() +
            alignmentClass() +
            heightClass()
      }
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon && <div className="mr-1">{props.icon}</div>}
      {props.children}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  url: PropTypes.string,
  target: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.node,
  align: PropTypes.string,
  rounded: PropTypes.bool,
  tall: PropTypes.bool,
};

Button.defaultProps = {
  title: '',
  type: 'button',
  target: '',
  theme: '',
  children: 'Button',
  disabled: false,
  rounded: true,
  tall: false,
};

export default Button;
