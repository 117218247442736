import React from 'react';
import PropTypes from 'prop-types';
import ProductSummary from '../../components/product/ProductSummary';
import ColourSearchContainer from '../../containers/ColourSearchContainer';
import { ProductStep } from '../../store/reducers/products/types';
import Button from '../ui/Button';
import { ReactComponent as SearchIcon } from '../../assets/icons/32/search.svg';
import FooterCapContainer from '../ui/FooterCapContainer';

const ProductSelection = (props) => {
  function productSelectOptions() {
    switch (props.currentStep) {
      case ProductStep.SHEEN:
        return props.sheenOptions;
      case ProductStep.COLOUR:
        return props.colourOptions;
      case ProductStep.SIZE:
        return props.sizeOptions;
      case ProductStep.QUANTITY:
        return props.quantityOptions;
      default:
        return '';
    }
  }

  function colourProps() {
    if (!props.colour) {
      return {};
    }
    return {
      colourQuery: props.colour,
    };
  }

  // wrapping <div> required for ios
  // without it, height of button is cutoff
  function renderSearchButton() {
    if (
      props.currentStep === ProductStep.COLOUR &&
      !props.colourStrengthEnabled
    ) {
      return (
        <div>
          <FooterCapContainer className="mt-auto min-h-full">
            <Button
              onClick={() => props.setSearchEnabled(true)}
              icon={<SearchIcon />}
              align="left"
            >
              Find a colour
            </Button>
          </FooterCapContainer>
        </div>
      );
    }
  }

  function showProductSelect() {
    if (props.currentStep === ProductStep.ADDED_TO_CART) {
      return;
    }
    return (
      <div className="flex flex-col h-full">
        {props.currentStep === ProductStep.COLOUR && (
          <div className="bg-gray-200 flex justify-center items-center border-b border-gray-400 p-2">
            <span className="text-h3 text-gray-600">Recently used</span>
          </div>
        )}
        <div
          className={
            'overflow-scroll ' + (props.colourStrengthEnabled ? 'h-full' : '')
          }
        >
          {productSelectOptions()}
        </div>
        {renderSearchButton()}
      </div>
    );
  }

  return (
    <>
      {!props.searchEnabled && showProductSelect()}
      {props.searchEnabled && (
        <ColourSearchContainer
          onColourSearchItemClick={props.onColourSearchItemClick}
          onColourConfirmClick={props.onColourConfirmClick}
          searchColourIdSelected={props.searchColourIdSelected}
          setSearchColourIdSelected={props.setSearchColourIdSelected}
          searchColourStrengthEnabled={props.searchColourStrengthEnabled}
          setSearchColourStrengthEnabled={props.setSearchColourStrengthEnabled}
          popularColoursData={props.popularColours}
          renderBrand={props.renderBrand}
          searchEnabled={props.searchEnabled}
        />
      )}
      {props.currentStep === ProductStep.ADDED_TO_CART && (
        <ProductSummary
          productQuery={props.productQuery}
          {...colourProps()}
          colourStrength={props.colourStrength}
          quantity={props.qty}
          setSkipLocation={props.setSkipLocation}
          onAddToCart={props.onAddToCart}
          onClearSheen={props.onClearSheen}
          onClearColour={props.onClearColour}
          onClearSize={props.onClearSize}
          onClearQuantity={props.onClearQuantity}
          onClearProduct={props.onClearProduct}
          isEditing={props.isEditing}
          onRemoveFromCart={props.onRemoveFromCart}
        />
      )}
    </>
  );
};

ProductSelection.propTypes = {
  currentStep: PropTypes.oneOf([
    ProductStep.ADDED_TO_CART,
    ProductStep.SHEEN,
    ProductStep.COLOUR,
    ProductStep.SIZE,
    ProductStep.QUANTITY,
  ]),
  sheenOptions: PropTypes.array,
  colourOptions: PropTypes.array,
  sizeOptions: PropTypes.array,
  quantityOptions: PropTypes.array,
  productQuery: PropTypes.object,
  colour: PropTypes.object,
  popularColours: PropTypes.array,
  colourStrength: PropTypes.number,
  qty: PropTypes.number,
  setSkipLocation: PropTypes.func,
  renderBrand: PropTypes.func,
  searchEnabled: PropTypes.bool,
  setSearchEnabled: PropTypes.func,
  onColourSearchItemClick: PropTypes.func,
  onColourConfirmClick: PropTypes.func,
  searchColourIdSelected: PropTypes.string,
  setSearchColourIdSelected: PropTypes.func,
  colourStrengthEnabled: PropTypes.bool,
  searchColourStrengthEnabled: PropTypes.bool,
  setSearchColourStrengthEnabled: PropTypes.func,
  onAddToCart: PropTypes.func,
  onClearSheen: PropTypes.func,
  onClearColour: PropTypes.func,
  onClearSize: PropTypes.func,
  onClearQuantity: PropTypes.func,
  onClearProduct: PropTypes.func,
  isEditing: PropTypes.bool,
  onRemoveFromCart: PropTypes.func,
};

ProductSelection.defaultProps = {
  sheenOptions: [],
  colourOptions: [],
  sizeOptions: [],
  quantityOptions: [],
  productQuery: {},
  colour: {},
  popularColours: [],
  colourStrength: 1,
  qty: 0,
  setSkipLocation: null,
  renderBrand: null,
  searchEnabled: false,
  setSearchEnabled: null,
  onColourSearchItemClick: null,
  onColourConfirmClick: null,
  searchColourIdSelected: null,
  setSearchColourIdSelected: null,
  colourStrengthEnabled: null,
  searchColourStrengthEnabled: null,
  setSearchColourStrengthEnabled: null,
  onAddToCart: null,
  onClearSheen: null,
  onClearColour: null,
  onClearSize: null,
  onClearQuantity: null,
  onClearProduct: null,
  isEditing: false,
};

export default ProductSelection;
