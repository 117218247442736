import React from 'react';
import PropTypes from 'prop-types';

const SplitButtons = (props) => {
  return <div className="flex">{props.children}</div>;
};

SplitButtons.propTypes = { children: PropTypes.node };

export default SplitButtons;
