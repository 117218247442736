import React from 'react';
import PropTypes from 'prop-types';
import { descriptionForRating } from '../../utils/rating';

const OrderFeedbackOutline = (props) => {
  const stars = [...Array(5).keys()].map((i) => {
    const rating = i + 1;

    return (
      <div key={rating} className="">
        <div className="relative pb-100 rounded-lg border border-current text-dulux-primary">
          <div
            className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
            onClick={() => props.onClick(rating)}
          >
            <div className="text-center text-technical text-dulux-primary">
              {descriptionForRating(rating)}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <div className="bg-white grid grid-cols-5 gap-1 py-1">{stars}</div>;
};

OrderFeedbackOutline.propTypes = {
  onClick: PropTypes.func.isRequired,
};

OrderFeedbackOutline.defaultProps = {
  onClick: null,
};

export default OrderFeedbackOutline;
