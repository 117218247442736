/* eslint-disable @typescript-eslint/camelcase */
import Analytics from '../analytics';
import { EnrichedCartItem } from '../../../store/reducers/cart/types';
import { isAccessory } from '../../../utils/product';

interface ProductWithPrice extends EnrichedCartItem {
  position?: number;
  grossValue?: number;
  taxAmount?: number;
  netValue?: number;
  grossValueFormatted?: string;
  taxAmountFormatted?: string;
  netValueFormatted?: string;
}

interface CheckoutStepData {
  shipping_method?: string;
  payment_method?: string;
}

interface OrderData {
  order_id?: string | number;
  total?: number;
  subtotal?: number;
  revenue?: number;
  shipping?: number;
  tax?: number;
  discount?: number;
  products: ProductWithPrice[];
}

interface CartData {
  products: ProductWithPrice[];
}

export default class Ecommerce {
  private analytics: Analytics;

  constructor(analytics: Analytics) {
    this.analytics = analytics;
  }

  private track(event: string, properties: {}) {
    return this.analytics.trackEvent(event, { properties });
  }

  private static getProductData(item: ProductWithPrice) {
    return {
      product_id: item.product.productId,
      sku: item.product.productId,
      category: isAccessory(item.product.range) ? 'Accessory' : 'Paint',
      name: item.product.fullName,
      // brand: '',
      variant: item.colour
        ? item.colour.colourBrand
          ? `${item.colour.colourBrand} ${item.colour.colourName}`
          : item.colour.colourName
        : undefined,
      price: item.grossValue,
      quantity: item.quantity,
      // coupon: '',
      // position: '',
      // url: '',
      // image_url: '',
    };
  }

  /*
   * Browsing
   */

  // productsSearched(query: string) {
  //   this.track('Products Searched', {
  //     query,
  //   });
  // }

  // productListViewed(items: ProductWithPrice[]) {
  //   this.track('Product List Viewed', {
  //     list_id: 'hot_deals_1',
  //     category: 'Deals',
  //     products: items.map(Ecommerce.getProductData),
  //   });
  // }

  // productListFiltered(items: ProductWithPrice[]) {
  //   this.track('Product List Filtered', {
  //     list_id: 'todays_deals_may_11_2019',
  //     filters: [
  //       {
  //         type: 'department',
  //         value: 'beauty',
  //       },
  //       {
  //         type: 'price',
  //         value: 'under-$25',
  //       },
  //     ],
  //     sorts: [
  //       {
  //         type: 'price',
  //         value: 'desc',
  //       },
  //     ],
  //     products: items.map(Ecommerce.getProductData),
  //   });
  // }

  /*
   * Promotions
   */

  // promotionViewed() {
  //   this.track('Promotion Viewed', {
  //     promotion_id: 'promo_1',
  //     creative: 'top_banner_2',
  //     name: '75% store-wide shoe sale',
  //     position: 'home_banner_top',
  //   });
  // }

  // promotionClicked() {
  //   this.track('Promotion Clicked', {
  //     promotion_id: 'promo_1',
  //     creative: 'top_banner_2',
  //     name: '75% store-wide shoe sale',
  //     position: 'home_banner_top',
  //   });
  // }

  /*
   * Core Ordering
   */

  productClicked(item: ProductWithPrice) {
    this.track('Product Clicked', Ecommerce.getProductData(item));
  }

  productViewed(item: ProductWithPrice) {
    this.track('Product Viewed', Ecommerce.getProductData(item));
  }

  productAdded(item: ProductWithPrice) {
    this.track('Product Added', {
      // cart_id: '',
      ...Ecommerce.getProductData(item),
    });
  }

  productRemoved(item: ProductWithPrice) {
    this.track('Product Removed', {
      // cart_id: '',
      ...Ecommerce.getProductData(item),
    });
  }

  cartViewed(data: CartData) {
    this.track('Cart Viewed', {
      // cart_id: '',
      ...data,
      products: data.products.map(Ecommerce.getProductData),
    });
  }

  checkoutStarted(data: OrderData) {
    return this.track('Checkout Started', {
      // affiliation: 'Google Store',
      // coupon: '',
      currency: 'AUD',
      ...data,
      products: data.products.map(Ecommerce.getProductData),
    });
  }

  checkoutStepViewed(step: number, data: CheckoutStepData = {}) {
    this.track('Checkout Step Viewed', {
      // checkout_id: '',
      step,
      ...data,
    });
  }

  checkoutStepCompleted(step: number, data: CheckoutStepData = {}) {
    this.track('Checkout Step Completed', {
      // checkout_id: '',
      step,
      ...data,
    });
  }

  // paymentInfoEntered(query: string) {
  //   this.track('Payment Info Entered', {
  //     // checkout_id: '',
  //     // order_id: '',
  //   });
  // }

  private orderEvent(event: string, data: OrderData) {
    return this.track(event, {
      // affiliation: 'Google Store',
      // coupon: '',
      currency: 'AUD',
      ...data,
      products: data.products.map(Ecommerce.getProductData),
    });
  }

  orderCompleted(data: OrderData) {
    return this.orderEvent('Order Completed', data);
  }

  orderUpdated(data: OrderData) {
    return this.orderEvent('Order Updated', data);
  }

  orderRefunded(data: OrderData) {
    return this.orderEvent('Order Refunded', data);
  }

  orderCancelled(data: OrderData) {
    return this.orderEvent('Order Cancelled', data);
  }
}
