import Firebase from '../firebase';
import { applyFeatureVotes } from '../../../store/actions';
import * as firebase from 'firebase';

const FEATURES_COUNT_REF = '/featuresCount';

export default class FirebaseVoting {
  private firebase: Firebase;
  private readonly onFeatureVoteCountListener: any;

  constructor(fb: Firebase) {
    this.firebase = fb;

    this.onFeatureVoteCountListener = this.onFeatureVoteCount.bind(this);
  }

  boot() {
    this.subscribeToFeatureVoteCount();
  }

  /**
   * Listen for changes to the value of the feature vote count.
   */
  subscribeToFeatureVoteCount() {
    const featureCountRef = this.firebase.db.ref(FEATURES_COUNT_REF);

    // Unsubscribe listeners if they exist
    if (this.onFeatureVoteCountListener) {
      featureCountRef.off('value', this.onFeatureVoteCountListener);
    }

    featureCountRef.on('value', this.onFeatureVoteCountListener);
  }

  /**
   * Called when a feature vote count changes
   */
  onFeatureVoteCount(snapshot: firebase.database.DataSnapshot) {
    if (!snapshot.exists()) {
      return console.error('Snapshot of feature votes does not exist');
    }

    // Update the count in the store for each feature
    snapshot.forEach((feature) => {
      if (!feature.key) return;

      this.firebase.dispatch(applyFeatureVotes(feature.key, feature.val()));
    });
  }

  /**
   * Cast a vote for the user against a feature
   */
  castVote(feature: string, vote: boolean) {
    const userRef = this.firebase.getUserRef();

    if (!userRef) {
      return console.error('No user ref');
    }

    return userRef.once('value').then((user) => {
      if (!user.exists() || !user.key) {
        return;
      }

      // Add vote if true
      if (vote) {
        return this.addVote(feature, user.key);
      }

      // Remove vote if false
      return this.removeVote(feature, user.key);
    });
  }

  addVote(feature: string, userKey: string) {
    return this.firebase.db.ref().update({
      [`features/${feature}/${userKey}`]: true,
      [`users/${userKey}/features/${feature}`]: true,
    });
  }

  removeVote(feature: string, userKey: string) {
    return Promise.all([
      this.firebase.db.ref(`features/${feature}/${userKey}`).remove(),
      this.firebase.db.ref(`users/${userKey}/features/${feature}`).remove(),
    ]);
  }
}
