import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DuluxLogo } from '../../assets/images/brand/dulux-logo.svg';
import { ReactComponent as InspirationsLogo } from '../../assets/images/brand/inspiratons/inspirations-stacked.svg';

const OrderMethodDTC = (props) => {
  const time = props.enabled ? props.time : 'Unavailable';
  const timeLabel = props.enabled ? props.timeLabel : 'COVID restriction';

  function logo() {
    if (props.type === 'inspirations') {
      return <InspirationsLogo />;
    }

    return <DuluxLogo />;
  }

  function logoBackgroundClassName() {
    if (props.type === 'inspirations') {
      return 'bg-inspirations-blue';
    }

    return 'bg-dulux-blue';
  }

  function handleClick() {
    if (props.enabled) {
      return props.onClick();
    }
  }

  return (
    <div
      onClick={() => handleClick()}
      className={
        'flex relative justify-between px-1 border-b ' +
        (props.enabled ? '' : 'opacity-50')
      }
    >
      <div className="flex items-center w-2/12 py-3">
        <div
          className={`pb-100 w-full rounded relative text-white ${logoBackgroundClassName()}`}
        >
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
            {logo()}
          </div>
        </div>
      </div>
      {(props.title || props.label) && (
        <div className="flex flex-col justify-center w-5/12 py-3 pl-1">
          {props.preferred && (
            <div
              className="absolute top-0 text-technical px-2 rounded-br rounded-bl"
              style={{
                marginTop: '-1px',
                paddingTop: '.2rem',
                paddingBottom: '.2rem',
                backgroundColor: '#F2CB0A',
              }}
            >
              Preferred
            </div>
          )}
          {props.title && (
            <span className="font-heading text-h3">{props.title}</span>
          )}
          {props.label && <span>{props.label}</span>}
        </div>
      )}
      <div className="flex flex-col items-end justify-center w-5/12 py-3">
        {time && (
          <div
            className="px-2 border inline-flex items-center rounded-full border-dulux-dark text-center"
            style={{ paddingTop: '.2rem', paddingBottom: '.2rem' }}
          >
            {time}
          </div>
        )}
        {timeLabel && (
          <span className={`text-small ${!props.enabled && 'text-red-500'}`}>
            {timeLabel}
          </span>
        )}
      </div>
    </div>
  );
};

OrderMethodDTC.propTypes = {
  onClick: PropTypes.func,
  preferred: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['dulux', 'inspirations']),
  time: PropTypes.string,
  timeLabel: PropTypes.string,
  totalLitres: PropTypes.number,
  maxLitres: PropTypes.number,
  enabled: PropTypes.bool,
};

OrderMethodDTC.defaultProps = {
  onClick: null,
  preferred: false,
  title: '',
  label: '',
  type: 'dulux',
  time: '',
  timeLabel: '',
  totalLitres: 0,
  enabled: true,
};

export default OrderMethodDTC;
