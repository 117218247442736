import { Action } from 'redux';

export interface Feature {
  feature: string;
  votes: number;
}

export enum FeatureActionType {
  APPLY_FEATURE_VOTES = 'APPLY_FEATURE_VOTES',
}

export interface ApplyFeatureVotesAction extends Action {
  type: FeatureActionType.APPLY_FEATURE_VOTES;
  payload: Feature;
}

export type FeatureActionTypes = ApplyFeatureVotesAction;
