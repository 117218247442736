import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { minimiseOnEnter } from '../../utils/keyboard';
// import { ReactComponent as SearchIcon } from '../../assets/icons/32/search.svg';
import { IonInput } from '@ionic/react';

const Search = (props) => {
  const [searchInput, setSearchInput] = useState();

  useEffect(() => {
    if (searchInput) {
      searchInput.setFocus();
    }
  });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="relative">
        <IonInput
          className="search-input text-h3 text-dulux-dark placeholder-dulux-dark leading-h2 font-heading border-b"
          placeholder={props.placeholder}
          type="search"
          size={64}
          value={props.value}
          autofocus={true}
          ref={(el) => setSearchInput(el)}
          onIonChange={props.onChange}
          onIonInput={minimiseOnEnter}
        />
      </div>
    </form>
  );
};

Search.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

Search.defaultProps = {
  placeholder: 'Search',
};

export default Search;
