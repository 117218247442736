import React from 'react';
import PropTypes from 'prop-types';
import { CatalogTabType } from '../../store/reducers/catalog/types';

const CatalogTabs = (props) => {
  return (
    <div className="flex border-b">
      <div
        onClick={(e) => props.onClick(e)}
        id={CatalogTabType.PAINT}
        className={
          'w-1/2 flex justify-center items-center px-1 py-2 border-r font-heading text-h2 ' +
          (props.selectedTab === CatalogTabType.PAINT
            ? 'bg-white text-dulux-dark'
            : 'bg-gray-200 text-gray-500')
        }
      >
        Paint
      </div>
      <div
        onClick={(e) => props.onClick(e)}
        id={CatalogTabType.ACCESSORIES}
        className={
          'w-1/2 flex justify-center items-center px-1 py-2 font-heading text-h2 ' +
          (props.selectedTab === CatalogTabType.ACCESSORIES
            ? 'bg-white text-dulux-dark'
            : 'bg-gray-200 text-gray-500')
        }
      >
        Accessories
      </div>
    </div>
  );
};

CatalogTabs.propTypes = {
  onClick: PropTypes.func,
  selectedTab: PropTypes.string,
};

CatalogTabs.defaultProps = {
  onClick: null,
  selectedTab: '',
};

export default CatalogTabs;
