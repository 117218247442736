import { DeviceInfo, Plugins } from '@capacitor/core';
import analyticsConfig from './config';
import AnalyticsNode from 'analytics-node';
import { User } from '../firebase/types';
import { v4 as uuidv4 } from 'uuid';
import Ecommerce from './events/Ecommerce';
import { getObject, setObject } from '../../utils/storage';

const { Device } = Plugins;

const LOADING_DELAY = 50;

const ANONYMOUS_ID_KEY = 'ANALYTICS_ANONYMOUS_ID';

class Analytics {
  /**
   * We use a loading state to delay events until we've retrieved the
   * anonymous ID, this stops duplicate users from being tracked.
   * @private
   */
  private loading = true;

  private analytics: AnalyticsNode;

  private userId: string | number | undefined;
  private anonymousId: string;
  private deviceInfo: DeviceInfo | undefined;
  private languageCode: string | undefined;

  ecommerce: Ecommerce;

  constructor() {
    this.analytics = new AnalyticsNode(analyticsConfig.writeKey);

    this.anonymousId = uuidv4(); // Fallback ID
    this.retrieveAnonymousId();

    Device.getInfo().then((info) => {
      this.deviceInfo = info;
    });

    Device.getLanguageCode().then((code) => {
      this.languageCode = code.value;
    });

    this.ecommerce = new Ecommerce(this);
  }

  retrieveAnonymousId() {
    // Check storage for previous ID
    getObject(ANONYMOUS_ID_KEY)
      .then((id) => {
        if (typeof id === 'string' && id.length > 0) {
          // If it exists, switch to the stored ID
          this.anonymousId = id;
        } else {
          // If not, set the current key in storage
          setObject(ANONYMOUS_ID_KEY, this.anonymousId);
        }
      })
      .catch(() => {
        // Reset the storage if we see an error, in case something is wrong
        // with what we have locally
        setObject(ANONYMOUS_ID_KEY, uuidv4());
      })
      .finally(() => {
        // Stop loading so that events can fire
        this.loading = false;
      });
  }

  setUserId(userId: string | number) {
    this.userId = userId;
  }

  clearUserId() {
    this.userId = undefined;
  }

  getAnonymousId() {
    return this.anonymousId;
  }

  getUserId() {
    return this.userId;
  }

  private getCommonFields() {
    return {
      userId: this.getUserId(),
      anonymousId: this.getAnonymousId(),
      context: {
        app: {
          name: 'Trade Direct',
          namespace: 'au.com.dulux.tradeDirect',
          version: process.env.REACT_APP_VERSION || this.deviceInfo?.appVersion,
          build: this.deviceInfo?.appBuild,
        },
        device: {
          id: this.deviceInfo?.uuid,
          manufacturer: this.deviceInfo?.manufacturer,
          model: this.deviceInfo?.model,
          name: this.deviceInfo?.name,
          type: this.deviceInfo?.operatingSystem,
        },
        locale: this.languageCode,
      },
    };
  }

  track(data: any): NodeJS.Timeout | AnalyticsNode {
    // Delay if we're still loading the anonymous ID
    if (this.loading) {
      return setTimeout(() => this.track(data), LOADING_DELAY);
    }

    return this.analytics.track({
      ...this.getCommonFields(),
      ...data,
    });
  }

  trackEvent(event: string, data: {} = {}) {
    return this.track({ event, ...data });
  }

  page(name: string, data: {} = {}): NodeJS.Timeout | AnalyticsNode {
    // Delay if we're still loading the anonymous ID
    if (this.loading) {
      return setTimeout(() => this.page(name, data), LOADING_DELAY);
    }

    return this.analytics.page({
      ...this.getCommonFields(),
      name,
      ...data,
    });
  }

  identify(user: User): NodeJS.Timeout | AnalyticsNode {
    // Delay if we're still loading the anonymous ID
    if (this.loading) {
      return setTimeout(() => this.identify(user), LOADING_DELAY);
    }

    return this.analytics.identify({
      ...this.getCommonFields(),
      traits: {
        id: user.uid,
        email: user.email,
        phone: user.phoneNumber,
        firstName: user.firstName,
        lastName: user.firstName,
      },
    });
  }
}

export default Analytics;
