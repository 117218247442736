import React from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  clearSelection,
  editCartItemSelection,
  cartPricesCalculated,
} from '../store/actions';
import {
  selectPricingTotals,
  selectPricingIsLoading,
  selectCartItemsWithPricing,
  selectCartPricesCalculated,
} from '../store/selectors';
import { useHistory } from 'react-router-dom';
import HeaderTitle from '../components/header/HeaderTitle';
import CartItem from '../components/cart/CartItem';
import CartTotalsDisplay from '../components/cart/CartTotalsDisplay';
import Button from '../components/ui/Button';
import { isAccessory } from '../utils/product';
import { useAnalyticsContext } from '../services/analytics/context';
import titleCase from '../utils/text';
import { displayStrengthIconInline } from '../utils/productImages';
import FooterCapContainer from '../components/ui/FooterCapContainer';

const Cart = (props) => {
  const analytics = useAnalyticsContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const pricingIsLoading = useSelector(selectPricingIsLoading);
  const pricingItems = useSelector(selectCartItemsWithPricing);
  const pricingTotals = useSelector(selectPricingTotals);
  const pricesCalculated = useSelector(selectCartPricesCalculated);

  function cartItemOnClick(cartItemId) {
    dispatch(editCartItemSelection(cartItemId));
    history.push('/product');
  }

  function checkoutOnClick() {
    analytics.ecommerce.checkoutStarted({
      total: pricingTotals.grossValue || 1,
      subtotal: pricingTotals.netValue || 1,
      revenue: pricingTotals.netValue || 1,
      tax: pricingTotals.taxAmount || 1,
      products: pricingItems,
    });
    history.push('/checkout');
  }

  function handleShowPricing() {
    analytics.trackEvent('Pricing Requested', {
      properties: {
        category: 'Cart',
        label: 'Order total',
      },
    });
    dispatch(cartPricesCalculated(true));
  }

  const items = pricingItems.map((item, i) => {
    return (
      <CartItem
        key={i}
        onClick={() => cartItemOnClick(item.cartItemId)}
        isAccessory={isAccessory(item.product.range)}
        backgroundColour={item.colour && item.colour.hex}
        quantity={item.quantity}
        image={item.product.sheenImage}
        range={item.product.range}
        name={item.product.name}
        sheen={item.product.sheen}
        colourBrand={item.colour && item.colour.colourBrand}
        colourName={item.colour && titleCase(item.colour.colourName)}
        displayStrengthIcon={() =>
          displayStrengthIconInline(item.colourStrength)
        }
        size={item.product.size}
        price={item.netValueFormatted}
        pricingIsLoading={pricingIsLoading}
        pricesCalculated={pricesCalculated}
        hasPrice
      />
    );
  });

  function resetProcess() {
    batch(() => {
      dispatch(clearSelection());
      dispatch(cartPricesCalculated(false));
    });
    history.push('/catalog');
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderTitle title="Cart" borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>
          <div className="bg-dulux-white h-full pb-1">
            {items.length === 0 && (
              <>
                <div className="flex flex-col h-full items-center">
                  <div className="h-full flex items-center">
                    <h2 className="font-heading text-h3 leading-h3 text-dulux-dark">
                      There are no items in your cart
                    </h2>
                  </div>
                </div>
              </>
            )}
            {items.length >= 1 && (
              <div>
                <div className="bg-dulux-dark text-white text-center p-1">
                  Spend $150 and get FREE standard delivery
                </div>
                {items}
                <CartTotalsDisplay
                  handleShowPricing={handleShowPricing}
                  pricesCalculated={pricesCalculated}
                  pricingIsLoading={pricingIsLoading}
                  subtotal={pricingTotals.netValueFormatted}
                  tax={pricingTotals.taxAmountFormatted}
                  total={pricingTotals.grossValueFormatted}
                />
              </div>
            )}
          </div>
        </IonContent>

        {items.length >= 1 ? (
          <FooterCapContainer>
            <Button onClick={resetProcess} theme="ghost">
              Add item
            </Button>
            <Button onClick={checkoutOnClick}>Checkout</Button>
          </FooterCapContainer>
        ) : (
          <FooterCapContainer>
            <Button onClick={resetProcess}>Add item</Button>
          </FooterCapContainer>
        )}
      </IonPage>
    </>
  );
};

Cart.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  items: PropTypes.array,
};

Cart.defaultProps = {
  title: 'Cart',
  href: '/cart/',
  items: [],
};

export default Cart;
