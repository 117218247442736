import React from 'react';
import PropTypes from 'prop-types';

const ProductSize = (props) => {
  return (
    <div className="bg-white border-b flex" onClick={() => props.onClick()}>
      {props.title && (
        <div
          className={
            'w-8/12 p-1 flex items-end ' + (props.image ? 'pb-4' : 'py-2')
          }
        >
          <h2 className="font-heading text-h2 text-dulux-dark">
            {props.title}
          </h2>
        </div>
      )}
      {props.image && (
        <div className="w-4/12">
          <img src={props.image} alt="product size" />
        </div>
      )}
    </div>
  );
};

ProductSize.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  image: PropTypes.string,
};

ProductSize.defaultProps = {
  onClick: null,
  title: '',
  image: '',
};

export default ProductSize;
