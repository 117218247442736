import React from 'react';
import PropTypes from 'prop-types';

const CatalogPaintItem = (props) => {
  return (
    <div className="bg-white border-b" onClick={() => props.onClick({})}>
      <div className="flex justify-between items-center">
        {props.text && (
          <div className="px-1 py-2 font-heading text-dulux-dark text-h2">
            {props.text}
          </div>
        )}
        {props.icon && props.icon}
      </div>
    </div>
  );
};

CatalogPaintItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.node,
};

CatalogPaintItem.defaultProps = {
  onClick: null,
  text: '',
  icon: null,
};

export default CatalogPaintItem;
