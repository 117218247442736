import { ReactComponent as QuarterIcon } from '../assets/icons/colour-strength/large/1-4.svg';
import { ReactComponent as HalfIcon } from '../assets/icons/colour-strength/large/1-2.svg';
import { ReactComponent as EighthIcon } from '../assets/icons/colour-strength/large/1-8.svg';
import { ReactComponent as ThreeQuarterIcon } from '../assets/icons/colour-strength/large/3-4.svg';
import { ReactComponent as FullIconOutline } from '../assets/icons/colour-strength/large/full-outline.svg';
import { ReactComponent as ThreeQuarterIconOutline } from '../assets/icons/colour-strength/large/3-4-outline.svg';
import { ReactComponent as HalfIconOutline } from '../assets/icons/colour-strength/large/1-2-outline.svg';
import { ReactComponent as QuarterIconOutline } from '../assets/icons/colour-strength/large/1-4-outline.svg';
import { ReactComponent as EighthIconOutline } from '../assets/icons/colour-strength/large/1-8-outline.svg';

import { ReactComponent as ThreeQuarterIconOutlineSmall } from '../assets/icons/colour-strength/no-border/3-4-outline.svg';
import { ReactComponent as HalfIconOutlineSmall } from '../assets/icons/colour-strength/no-border/1-2-outline.svg';
import { ReactComponent as QuarterIconOutlineSmall } from '../assets/icons/colour-strength/no-border/1-4-outline.svg';
import { ReactComponent as EighthIconOutlineSmall } from '../assets/icons/colour-strength/no-border/1-8-outline.svg';

type ColourCode = string | Array<number>;

export const colourStrengths = [
  { value: 1 },
  { value: 0.75 },
  { value: 0.5 },
  { value: 0.25 },
  { value: 0.125 },
];

export function hexToRGBArray(colour: string): Array<number> {
  if (colour.charAt(0) === '#') {
    // eslint-disable-next-line no-param-reassign
    colour = colour.substr(1);
  }

  if (colour.length === 3) {
    // eslint-disable-next-line no-param-reassign
    colour =
      colour.charAt(0) +
      colour.charAt(0) +
      colour.charAt(1) +
      colour.charAt(1) +
      colour.charAt(2) +
      colour.charAt(2);
  }

  if (colour.length !== 6) {
    throw new Error(`Invalid hex colour: ${colour}`);
  }

  const rgb = [];

  for (let i = 0; i <= 2; i++) {
    rgb[i] = parseInt(colour.substr(i * 2, 2), 16);
  }

  return rgb;
}

// Luma is a weighted sum of the R, G, and B values, adjusted for human perception of relative brightness.
export function luma(colour: ColourCode) {
  let rgb = typeof colour === 'string' ? hexToRGBArray(colour) : colour;

  // https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
  rgb = rgb.map((bitValue) => {
    const value = bitValue / 255;

    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
}

// https://www.w3.org/TR/WCAG20/
export function contrast(colour1: ColourCode, colour2: ColourCode) {
  const lum1 = luma(colour1);
  const lum2 = luma(colour2);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

export function contrastingColour(
  background: ColourCode,
  foregroundColours: ColourCode[] = ['#041A37', '#FFFFFF']
) {
  return foregroundColours.reduce((first, second) => {
    const firstContrast = contrast(first, background);
    const secondContrast = contrast(second, background);

    return firstContrast > secondContrast ? first : second;
  });
}

export function getColourStrengthIcon(strength: number) {
  switch (strength) {
    case 0.25:
      return QuarterIcon;
    case 0.5:
      return HalfIcon;
    case 0.125:
      return EighthIcon;
    case 0.75:
      return ThreeQuarterIcon;
    case 1:
    default:
      return '';
  }
}

export function getStrengthIconOutline(strength: number) {
  switch (strength) {
    case 1:
      return FullIconOutline;
    case 0.75:
      return ThreeQuarterIconOutline;
    case 0.5:
      return HalfIconOutline;
    case 0.25:
      return QuarterIconOutline;
    case 0.125:
      return EighthIconOutline;
    default:
      return FullIconOutline;
  }
}

export function getStrengthIconOutlineSmall(strength: number) {
  switch (strength) {
    case 0.75:
      return ThreeQuarterIconOutlineSmall;
    case 0.5:
      return HalfIconOutlineSmall;
    case 0.25:
      return QuarterIconOutlineSmall;
    case 0.125:
      return EighthIconOutlineSmall;
    case 1:
    default:
      return '';
  }
}

export default contrastingColour;
