import React from 'react';
import { IonContent, IonPage, IonHeader } from '@ionic/react';
import { useHistory } from 'react-router';
import HeaderTitle from '../components/header/HeaderTitle';
import HeaderBack from '../components/header/HeaderBack';
import Button from '../components/ui/Button';
import { ReactComponent as DeliveryIcon } from '../assets/icons/64/delivery.svg';
import { ReactComponent as PriorityDeliveryIcon } from '../assets/icons/64/priority-delivery.svg';
import { ReactComponent as PriorityPickupIcon } from '../assets/icons/64/priority-pickup.svg';
import { ReactComponent as DuluxLogo } from '../assets/images/brand/dulux-logo.svg';
import { ReactComponent as InspirationsLogo } from '../assets/images/brand/inspiratons/inspirations-stacked.svg';
import { ReactComponent as ScheduledIcon } from '../assets/icons/64/schedule.svg';

const PickupAndDelivery = (props) => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  function handleOrderPaintClick() {
    history.push('/catalog');
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <HeaderBack title="Home" onClick={handleBackClick} />
          <HeaderTitle title="Available options" borderColour="#e2e8f0" />
        </IonHeader>
        <IonContent>
          <div className="border-b p-2 flex flex-col">
            <div className="flex items-center">
              <div className="w-7/12">
                <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                  Priority pickup
                </h2>
                <p className="text-dulux-dark text-small">
                  Place and pick up your order from from selected stores. Jump
                  the queue and minimise waiting.
                </p>
              </div>
              <div className="w-5/12 pl-2">
                <div className="bg-gray-300 pb-100 rounded-full w-full relative">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <PriorityPickupIcon className="text-dulux-dark" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center pt-1 border-t mt-2">
              <div className="flex items-center">
                <div className="text-small border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
                  2Hrs
                </div>
                <div className="ml-1 text-gray-600 text-small">
                  Order before 2pm
                </div>
              </div>
              <p className="text-dulux-primary">Free</p>
            </div>
          </div>
          <div className="border-b p-2 flex flex-col">
            <div className="flex items-center">
              <div className="w-7/12">
                <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                  Scheduled pickup
                </h2>
                <p className="text-dulux-dark text-small">
                  Plan ahead by scheduling a free pickup from your preferred
                  store.
                </p>
              </div>
              <div className="w-5/12 pl-2">
                <div className="bg-gray-300 pb-100 rounded-full w-full relative">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <ScheduledIcon className="text-dulux-dark" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center pt-1 border-t mt-2">
              <div className="flex items-center">
                <div className="text-small border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
                  Plan
                </div>
                <div className="ml-1 text-gray-600 text-small">
                  Up to 1 week ahead
                </div>
              </div>
              <p className="text-dulux-primary">Free</p>
            </div>
          </div>
          <div className="border-b p-2 flex flex-col">
            <div className="flex items-center">
              <div className="w-7/12">
                <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                  Priority delivery
                </h2>
                <p className="text-dulux-dark text-small">
                  We prepare and deliver your order directly to you (max.150L).
                </p>
              </div>
              <div className="w-5/12 pl-2">
                <div className="bg-gray-300 pb-100 rounded-full w-full relative">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <PriorityDeliveryIcon className="text-dulux-dark" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center pt-1 border-t mt-2">
              <div className="flex items-center">
                <div className="text-small border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
                  2Hrs
                </div>
                <div className="ml-1 text-gray-600 text-small">
                  Order before 2pm
                </div>
              </div>
              <p className="text-dulux-primary">$50</p>
            </div>
          </div>
          <div className="border-b p-2 flex flex-col">
            <div className="flex items-center">
              <div className="w-7/12">
                <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                  Standard delivery
                </h2>
                <p className="text-dulux-dark text-small">
                  Place your order on our delivery run. Free delivery on orders
                  over $150.
                </p>
              </div>
              <div className="w-5/12 pl-2">
                <div className="bg-gray-300 pb-100 rounded-full w-full relative">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <DeliveryIcon className="text-dulux-dark" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center pt-1 border-t mt-2">
              <div className="flex items-center">
                <div className="text-small border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
                  4Hrs
                </div>
                <div className="ml-1 text-gray-600 text-small">
                  Order before 10am
                </div>
              </div>
              <p className="text-dulux-primary">$25/Free</p>
            </div>
          </div>
          <div className="border-b p-2 flex flex-col">
            <div className="flex items-center">
              <div className="w-7/12">
                <h2 className="font-heading text-dulux-dark text-h2 mb-1">
                  Scheduled delivery
                </h2>
                <p className="text-dulux-dark text-small">
                  Skip the queue altogether with scheduled delivery. Free
                  delivery on orders over $150.
                </p>
              </div>
              <div className="w-5/12 pl-2">
                <div className="bg-gray-300 pb-100 rounded-full w-full relative">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <ScheduledIcon className="text-dulux-dark" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center pt-1 border-t mt-2">
              <div className="flex items-center">
                <div className="text-small border px-2 py-1 border border-dulux-primary text-dulux-primary rounded-full flex justify-center items-center">
                  Plan
                </div>
                <div className="ml-1 text-gray-600 text-small">
                  Up to 1 week ahead
                </div>
              </div>
              <p className="text-dulux-primary">$25/Free</p>
            </div>
          </div>
          <div className="border-t border-b bg-gray-200 py-2 px-6 flex items-center">
            <div className="text-center text-gray-600">
              This trial is offered by the participating trade centres. More
              will be added soon.
            </div>
          </div>
          <div className="bg-dulux-white text-dulux-dark flex justify-start items-center top-0 left-0 w-full py-1 px-1 border-b">
            <h1 className="text-h2 leading-h2 font-heading m-0">
              Participating stores
            </h1>
          </div>
          <div className="flex items-center justify-between px-1 border-b">
            <div className="flex items-center w-2/12 py-3">
              <div className="pb-100 w-full rounded relative text-white bg-dulux-blue">
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
                  <DuluxLogo />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-10/12 py-3 pl-1">
              <span className="font-heading text-h3">Preston</span>
              <span>148-150 Bell St, Preston</span>
              <span>
                <a href="tel:03-9484-5711">(03) 9484 5711</a>
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between px-1 border-b">
            <div className="flex items-center w-2/12 py-3">
              <div className="pb-100 w-full rounded relative text-white bg-dulux-blue">
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
                  <DuluxLogo />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-10/12 py-3 pl-1">
              <span className="font-heading text-h3">Doncaster</span>
              <span>150 Doncaster Rd, Doncaster</span>
              <span>
                <a href="tel:03-9840-1267">(03) 9840 1267</a>
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between px-1 border-b">
            <div className="flex items-center w-2/12 py-3">
              <div className="pb-100 w-full rounded relative text-white bg-inspirations-blue">
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
                  <InspirationsLogo />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-10/12 py-3 pl-1">
              <span className="font-heading text-h3">Grovedale</span>
              <span>138 Torquay Rd, Grovedale</span>
              <span>
                <a href="tel:03-5243-8566">(03) 5243 8566</a>
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between px-1 border-b">
            <div className="flex items-center w-2/12 py-3">
              <div className="pb-100 w-full rounded relative text-white bg-dulux-blue">
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-1">
                  <DuluxLogo />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-10/12 py-3 pl-1">
              <span className="font-heading text-h3">Geelong West</span>
              <span>72 Pakington St, Geelong West</span>
              <span>
                <a href="tel:03-5229-5875">(03) 5229 5875</a>
              </span>
            </div>
          </div>
          <Button onClick={handleOrderPaintClick}>Order</Button>
        </IonContent>
      </IonPage>
    </>
  );
};

PickupAndDelivery.propTypes = {};

export default PickupAndDelivery;
